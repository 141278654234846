import React, { useState, useEffect, Suspense, lazy, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import LoadingOverlay from "../components/LoadingOverlay/LoadingOverlay";
import { userDataAtom } from "../store/atoms/userDataAtom";
import { useRecoilValue } from "recoil";
import UserProfile from "../pages/Profile/UserProfile";

const Profile = lazy(() => import("../pages/Profile/Profile"));
const InitialProfile = lazy(() => import("../pages/SignUp/InitialProfile"));

function ProfileGaurd() {
  const navigate = useNavigate();
  const [userTypePresent, setUserTypePresent] = useState(null); // null indicates loading state

  const userDetails = useRecoilValue(userDataAtom);

  useMemo(() => {
    if (userDetails?.userProfile && userDetails?.isLoading === false) {
      setUserTypePresent(userDetails?.userProfile?.hasOwnProperty("userType"));
    } else {
      setUserTypePresent(false);
    }
  }, [userDetails]);

  useEffect(() => {
    if (userTypePresent === false) {
      navigate("/usertyperedirect");
    }
  }, [userTypePresent, navigate]);

  if (userTypePresent === null) {
    return <LoadingOverlay />;
  }

  return (
    <Suspense fallback={<LoadingOverlay />}>
      {userTypePresent === true ? <UserProfile /> : <InitialProfile />}
    </Suspense>
  );
}

export default ProfileGaurd;
