import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// STYLING IMPORT
import "./UserProfile.css";

// LOADER IMPORT
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";

// REACT ICONS
import { GoArrowLeft } from "react-icons/go";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiHomeOfficeLine,
} from "react-icons/ri";
import { GrPowerReset } from "react-icons/gr";
import { IoClose, IoLocationOutline, IoLogoWhatsapp } from "react-icons/io5";
import {
  MdDriveFileRenameOutline,
  MdOutlineCompareArrows,
  MdOutlineEmail,
  MdOutlinePhoneEnabled,
  MdOutlineSpeakerNotes,
} from "react-icons/md";
import { TbBuildingEstate, TbInputCheck, TbSettings2 } from "react-icons/tb";
import {
  LuFileImage,
  LuCalendarClock,
  LuFile,
  LuUser2,
  LuUsers2,
  LuTextCursorInput,
} from "react-icons/lu";
import { PiBracketsCurlyBold, PiCity } from "react-icons/pi";
import { FiKey } from "react-icons/fi";
import {
  BsBuildingLock,
  BsCurrencyDollar,
  BsCurrencyRupee,
} from "react-icons/bs";
import {
  AiOutlineAlert,
  AiOutlineExpandAlt,
  AiOutlineFileImage,
  AiOutlineProject,
  AiOutlineSafetyCertificate,
} from "react-icons/ai";
import { FaRegCircleCheck } from "react-icons/fa6";
import { CgTag } from "react-icons/cg";
import { GrCurrency } from "react-icons/gr";
import { LiaAddressCardSolid, LiaUserTagSolid } from "react-icons/lia";

// ANT DESIGN IMPORT
import {
  Checkbox,
  message,
  Popconfirm,
  Select,
  Progress,
  Switch,
  Collapse,
  List,
} from "antd";
import { AimOutlined, LoadingOutlined } from "@ant-design/icons";

// RECOIL INTEGRATION
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userDataAtom } from "../../store/atoms/userDataAtom";
import { fetchLatestUserDetails } from "../../store/atoms/fetchLatestUserDetails";

// ZOD
import {
  agentEmployeeSchema,
  agentSchema,
  builderEmployeeSchema,
  builderSchema,
  ownerSchema,
  projectSchema,
  salespersonSchema,
} from "../../schemas/userTypeRedirectSchema";
import { updateProfileSchema } from "../../schemas/updateProfileSchema";

// CITIES IMPORT
import citiesData from "../../utils/CitiesData";

// UTILS
import { IsBase64Image } from "../../utils/IsBase64";
import { HiOutlineDocumentReport } from "react-icons/hi";

// REUSABLE FORM INPUT COMPONENT
const FormInput = ({
  icon: Icon,
  placeholder,
  value = "",
  onChange,
  type = "text",
}) => (
  <div className="utr-form-input">
    <Icon className="utr-form-input-icon" />
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={value.trim() !== "" ? "utr-form-input-selected" : ""}
    />
  </div>
);

// REUSABLE IMAGE UPLOAD COMPONENT
const ImageUpload = ({ image, onImageChange, onRemove, label }) => (
  <>
    {image ? (
      <div className="utr-form-images-sub">
        {IsBase64Image(image) ? (
          <img src={`data:image/jpeg;base64,${image}`} alt={label} />
        ) : (
          <img src={URL.createObjectURL(image)} alt={label} />
        )}
        <div className="utr-form-images-remove" onClick={onRemove}>
          <IoClose className="utr-form-images-remove-icon" />
        </div>
      </div>
    ) : (
      <div className="utr-form-images-sub">
        <label htmlFor={`utr-image-upload-${label}`}>
          <LuFileImage className="utr-form-images-sub-icon" />
          <h5>{label}</h5>
        </label>
        <input
          id={`utr-image-upload-${label}`}
          type="file"
          accept="image/*"
          onChange={onImageChange}
          style={{ display: "none" }}
        />
      </div>
    )}
  </>
);

// REUSABLE FILE UPLOAD COMPONENT
const FileUpload = ({ file, onFileChange, onRemove, label }) => (
  <>
    {file ? (
      <div className="utr-form-images-sub utr-form-images-sub-pdf">
        {file.type === "application/pdf" ? (
          <>
            {/* <iframe
              src={URL.createObjectURL(file)}
              title={label}
              className="utr-form-files-preview"
            /> */}
            <div className="utr-form-files-preview-doc">
              <LuFile className="utr-form-images-sub-icon" />
              <h5>{file.name}</h5>
            </div>
          </>
        ) : (
          <div className="utr-form-files-preview-doc">
            <LuFile className="utr-form-images-sub-icon" />
            <h5>{file.name}</h5>
          </div>
        )}
        <div className="utr-form-images-remove" onClick={onRemove}>
          <IoClose className="utr-form-images-remove-icon" />
        </div>
      </div>
    ) : (
      <div className="utr-form-images-sub">
        <label htmlFor={`utr-file-upload-${label}`}>
          <LuFileImage className="utr-form-images-sub-icon" />
          <h5>{label}</h5>
        </label>
        <input
          id={`utr-file-upload-${label}`}
          type="file"
          accept=".pdf,.doc,.docx"
          onChange={onFileChange}
          style={{ display: "none" }}
        />
      </div>
    )}
  </>
);

function UserProfile() {
  const baseURL = process.env.REACT_APP_base_URL;
  const userDetails = useRecoilValue(userDataAtom);
  const userId = userDetails?.user?._id;
  const userType = userDetails?.userProfile?.userType;
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Proptzo - User Profile";
  }, []);

  const [selectedSection, setSelectedSection] = useState("Business Info");
  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  // Cities with their GeoCodes
  const cityOptions = citiesData.map((city) => ({
    cityName: city.city,
    geocode: city.geoCode,
  }));
  const filterCityData = (selectedCity) =>
    citiesData.filter((city) => city.city === selectedCity)[0];

  // Business Info
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsappMobile, setWhatsappMobile] = useState("");

  const [showSaveUserDetailsBtn, setShowSaveUserDetailsBtn] = useState(false);
  const setFetchLatestUserData = useSetRecoilState(fetchLatestUserDetails);
  const handleMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setMobile(value);
    }
  };
  const formatMobileDisplay = (mobile) => {
    const formatted = mobile.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };
  const handleWhatsappMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setWhatsappMobile(value);
    }
  };
  const formatWhatsappMobileDisplay = (whatsappMobile) => {
    const formatted = whatsappMobile.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };
  const [checked, setChecked] = useState(false);
  const onSameWhatsappMobileAsMobileChange = useCallback(
    (isChecked) => {
      setChecked(isChecked);
      if (isChecked) {
        setWhatsappMobile(mobile);
      } else {
        setWhatsappMobile("");
      }
    },
    [setChecked, setWhatsappMobile, mobile]
  );
  const switchBackgroundColor = checked ? "var(--primary-color)" : "#ccc";
  const [profileImage, setProfileImage] = useState(null);

  // Builder Firm Details
  const [builderDetails, setBuilderDetails] = useState({
    builderFirmName: "",
    builderReraNo: "",
    builderReraNoType: "",
    builderOfficeCity: "",
    builderOfficeWebsite: "",
    builderAddress: "",
    builderProfilePhoto: null,
    builderFirmLogo: null,
    builderOfficeName: "",
  });
  // Agent Firm Details
  const [agentDetails, setAgentDetails] = useState({
    agentFirmName: "",
    agentReraNo: "",
    agentReraNoType: "",
    agentOfficeCity: "",
    agentOfficeWebsite: "",
    agentAddress: "",
    agentProfilePhoto: null,
    agentFirmLogo: null,
    agentOfficeName: "",
  });

  // Builder Address
  const [builderAddressCharacterCount, setBuilderAddressCharacterCount] =
    useState(0);
  const handleBuilderAddressChange = (e) => {
    const newBuilderAddress = e.target.value;
    if (newBuilderAddress.length <= 100) {
      setBuilderDetails({
        ...builderDetails,
        builderAddress: newBuilderAddress,
      });
      setBuilderAddressCharacterCount(newBuilderAddress.length);
    } else {
      setBuilderDetails({
        ...builderDetails,
        builderAddress: newBuilderAddress.slice(0, 100),
      });
      setBuilderAddressCharacterCount(100);
    }
  };

  const builderAddressSpanContent =
    builderAddressCharacterCount ||
    builderDetails?.builderAddress?.length === 100
      ? "Max character limit reached"
      : "Provide complete address";

  // Agent Address
  const [agentAddressCharacterCount, setAgentAddressCharacterCount] =
    useState(0);
  const handleagentAddressChange = (e) => {
    const newAgentAddress = e.target.value;
    if (newAgentAddress.length <= 100) {
      setAgentDetails({
        ...agentDetails,
        agentAddress: newAgentAddress,
      });
      setAgentAddressCharacterCount(newAgentAddress.length);
    } else {
      setAgentDetails({
        ...agentDetails,
        agentAddress: newAgentAddress.slice(0, 100),
      });
      setAgentAddressCharacterCount(100);
    }
  };

  const agentAddressSpanContent =
    agentAddressCharacterCount || agentDetails?.agentAddress?.length === 100
      ? "Max character limit reached"
      : "Provide complete address";

  // Builder Employees
  const [builderEmployeeDetails, setBuilderEmployeeDetails] = useState({
    employeeName: "",
    employeeEmail: "",
    employeeMobileNo: "",
    employeeDesignation: "",
    employeeID: "",
    employeeImage: null,
    employeementLetterImage: null,
    employeeIdCardImage: null,
  });
  // Builder Employee Mobile
  const handleBuilderEmployeeMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setBuilderEmployeeDetails((prev) => ({
        ...prev,
        employeeMobileNo: value,
      }));
    }
  };
  const formatBuilderEmployeeMobileDisplay = (employeeMobileNo) => {
    const formatted = employeeMobileNo.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };

  // Builder Added Employees List
  const [builderAddedEmployeesList, setBuilderAddedEmployeesList] = useState(
    []
  );
  const addBuilderEmployee = async () => {
    // validate here in zod the builder employee data object ha no missing required fields

    if (
      builderEmployeeSchema.safeParse({
        employeeFullName: builderEmployeeDetails.employeeName,
      }).success === false
    ) {
      const errorMessage = builderEmployeeSchema.safeParse({
        employeeFullName: builderEmployeeDetails.employeeName,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Employee name");
      message.error(customMessage);
      return;
    }

    if (
      builderEmployeeSchema.safeParse({
        employeeDesignation: builderEmployeeDetails.employeeDesignation,
      }).success === false
    ) {
      const errorMessage = builderEmployeeSchema.safeParse({
        employeeDesignation: builderEmployeeDetails.employeeDesignation,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Employee designation"
      );
      message.error(customMessage);
      return;
    }

    if (
      builderEmployeeSchema.safeParse({
        employeeEmail: builderEmployeeDetails.employeeEmail,
      }).success === false
    ) {
      const errorMessage = builderEmployeeSchema.safeParse({
        employeeEmail: builderEmployeeDetails.employeeEmail,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Employee email");
      message.error(customMessage);
      return;
    }

    if (
      builderEmployeeSchema.safeParse({
        employeeMobileNo: builderEmployeeDetails.employeeMobileNo,
      }).success === false
    ) {
      const errorMessage = builderEmployeeSchema.safeParse({
        employeeMobileNo: builderEmployeeDetails.employeeMobileNo,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Employee mobile no"
      );
      message.error(customMessage);
      return;
    }

    if (
      builderEmployeeSchema.safeParse({
        employeeId: builderEmployeeDetails.employeeID,
      }).success === false
    ) {
      const errorMessage = builderEmployeeSchema.safeParse({
        employeeId: builderEmployeeDetails.employeeID,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Employee id");
      message.error(customMessage);
      return;
    }

    if (!builderEmployeeDetails.employeeImage) {
      message.error("Employee image required");
      return;
    }

    if (!builderEmployeeDetails.employeeIdCardImage) {
      message.error("Employee id image required");
      return;
    }

    if (
      builderAddedEmployeesList.length > 0 &&
      builderAddedEmployeesList.some(
        (employee) =>
          employee.employeeEmail === builderEmployeeDetails.employeeEmail ||
          employee.employeeMobileNo ===
            builderEmployeeDetails.employeeMobileNo.replace(
              /(\d{2})(\d{0,10})/,
              "+$1 $2"
            )
      )
    ) {
      const identifier = builderAddedEmployeesList.some(
        (employee) =>
          employee.employeeEmail === builderEmployeeDetails.employeeEmail
      )
        ? "email"
        : builderAddedEmployeesList.some(
            (employee) =>
              employee.employeeMobileNo ===
              builderEmployeeDetails.employeeMobileNo.replace(
                /(\d{2})(\d{0,10})/,
                "+$1 $2"
              )
          )
        ? "mobile no"
        : "credentials";
      message.error(
        `Employee with this ${identifier} already exists in the employee list`
      );
      return;
    }

    const formData = new FormData();

    formData.append("userAccessId", userId);

    if (userProfileData?.userType === "Builder") {
      // formData.append("userId", builderEmployeeDetails.employeeUserId);
      formData.append("employeeName", builderEmployeeDetails.employeeName);
      formData.append("employeeEmail", builderEmployeeDetails.employeeEmail);
      formData.append(
        "employeeMobileNo",
        builderEmployeeDetails.employeeMobileNo
      );
      formData.append(
        "employeeDesignation",
        builderEmployeeDetails.employeeDesignation
      );
      formData.append(
        profileImageFieldName,
        !IsBase64Image(builderEmployeeDetails.employeeImage)
          ? builderEmployeeDetails.employeeImage
          : ""
      );
      formData.append("selectedEmployeeIdType", "Employee id");
      formData.append("employeeID", builderEmployeeDetails.employeeID);
      formData.append(
        idCardImageFieldName,
        !IsBase64Image(builderEmployeeDetails.employeeIdCardImage)
          ? builderEmployeeDetails.employeeIdCardImage
          : ""
      );
    }
    try {
      message.info("Sending invitation request");
      setLoading(true);

      const invitationResponse = await axios.post(
        `${baseURL}/api/mail/sendMail`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "multipart/formdata",
          },
        }
      );

      if (invitationResponse.data.recruitmentStatus === "Invitation sent") {
        message.open({
          type: "success",
          content: invitationResponse.data.message,
        });
        setLoading(false);
      } else if (
        invitationResponse.data.recruitmentStatus === "Already recruited"
      ) {
        message.open({
          type: "info",
          content: invitationResponse.data.message,
        });
        setLoading(false);
      }

      setTimeout(() => {
        setLoading(false);
      }, 2000);

      setBuilderAddedEmployeesList((prev) => [
        ...prev,
        {
          ...builderEmployeeDetails,
          employeeMobileNo: formatBuilderEmployeeMobileDisplay(
            builderEmployeeDetails.employeeMobileNo
          ),
        },
      ]);
      setBuilderEmployeeDetails({
        employeeName: "",
        employeeEmail: "",
        employeeMobileNo: "",
        employeeDesignation: "",
        employeeID: "",
        employeeImage: null,
        employeementLetterImage: null,
        employeeIdCardImage: null,
      });
    } catch (err) {
      message.error(err.response.data.message);
      return;
    }
  };
  const removeBuilderEmployee = (index) => {
    setBuilderAddedEmployeesList((prev) => prev.filter((_, i) => i !== index));
  };
  const renderBuilderEmployeeImage = (image, placeholderText) => {
    if (image) {
      return URL.createObjectURL(image);
    }
    return `https://placehold.co/50x50?text=${placeholderText}`;
  };
  const builderEmployeesForm = useMemo(
    () => (
      <>
        <div className="utr-form-category">
          <h4>Employee Details</h4>
          <div className="utr-form-inputs">
            <FormInput
              icon={MdDriveFileRenameOutline}
              placeholder="Employee Full Name"
              value={builderEmployeeDetails.employeeName}
              onChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeName: e.target.value,
                }))
              }
            />
            <FormInput
              icon={LiaUserTagSolid}
              placeholder="Employee Designation"
              value={builderEmployeeDetails.employeeDesignation}
              onChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeDesignation: e.target.value,
                }))
              }
            />
            <FormInput
              icon={MdOutlineEmail}
              placeholder="Employee Email"
              value={builderEmployeeDetails.employeeEmail}
              onChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeEmail: e.target.value,
                }))
              }
            />
            <FormInput
              icon={MdOutlinePhoneEnabled}
              placeholder="Employee Mobile No."
              value={formatBuilderEmployeeMobileDisplay(
                builderEmployeeDetails.employeeMobileNo
              )}
              onChange={handleBuilderEmployeeMobileChange}
              maxLength="14"
            />
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Employee ID"
              value={builderEmployeeDetails.employeeID}
              onChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeID: e.target.value,
                }))
              }
            />
          </div>
        </div>
        <div className="utr-form-category">
          <h4>Employee Photos</h4>
          <div className="utr-form-images">
            <ImageUpload
              image={builderEmployeeDetails.employeeImage}
              onImageChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeImage: null,
                }))
              }
              label="Profile"
            />
            <ImageUpload
              image={builderEmployeeDetails.employeementLetterImage}
              onImageChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeementLetterImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeementLetterImage: null,
                }))
              }
              label="Employment Letter"
            />
            <ImageUpload
              image={builderEmployeeDetails.employeeIdCardImage}
              onImageChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeIdCardImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeIdCardImage: null,
                }))
              }
              label="ID Card"
            />
          </div>
          <button
            className="utr-add-project-employee-add"
            onClick={addBuilderEmployee}
          >
            Add Employee
          </button>
        </div>
      </>
    ),
    [builderEmployeeDetails]
  );
  // Builder Employees Reset
  const confirmBuilderEmployeesReset = (e) => {
    message.success("Employees Data Reset success!");
    setBuilderEmployeeDetails({
      employeeName: "",
      employeeDesignation: "",
      employeeEmail: "",
      employeeMobileNo: "",
      employeeID: "",
      employeeImage: null,
      employeementLetterImage: null,
      employeeIdCardImage: null,
    });
  };
  const cancelBuilderEmployeesReset = (e) => {
    message.error("Employess reset cancelled!");
  };
  const builderEmployeesCollapse = useMemo(
    () => [
      {
        key: "1",
        label: `Add Employee`,
        children: builderEmployeesForm,
        extra: (
          <Popconfirm
            title="Reset Employee Data"
            description="Are you sure to reset all employee data?"
            onConfirm={confirmBuilderEmployeesReset}
            onCancel={cancelBuilderEmployeesReset}
            okText="Reset"
            cancelText="Cancel"
          >
            <GrPowerReset className="utr-collapse-icon" />
          </Popconfirm>
        ),
      },
    ],
    [builderEmployeesForm]
  );

  // Agent Employees
  const [agentEmployeeDetails, setAgentEmployeeDetails] = useState({
    employeeName: "",
    employeeEmail: "",
    employeeMobileNo: "",
    employeeDesignation: "",
    employeeIdType: "",
    employeeID: "",
    employeeAadhaarCardNo: "",
    employeePanCardNo: "",
    employeeVoterCardNo: "",
    employeePassportNo: "",
    employeeDrivingLicenseNo: "",
    employeeImage: null,
    employeementLetterImage: null,
    employeeIdCardImage: null,
    employeeAadhaarcardFrontImage: null,
    employeeAadhaarcardBackImage: null,
    employeePancardImage: null,
    employeeVoterIdFrontImage: null,
    employeeVoterIdBackImage: null,
    employeePassportImage: null,
    employeeDrivinglicenseFrontImage: null,
    employeeDrivinglicenseBackImage: null,
  });
  // Agent Employee Mobile
  const handleAgentEmployeeMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setAgentEmployeeDetails((prev) => ({
        ...prev,
        employeeMobileNo: value,
      }));
    }
  };
  const formatAgentEmployeeMobileDisplay = (employeeMobileNo) => {
    const formatted = employeeMobileNo.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };
  // Reset Employee Id Type (Related Fields) OnChnage - Id Proofs Reset
  const resetEmployeeIdProofDetails = () => ({
    employeeID: "",
    employeeAadhaarCardNo: "",
    employeePanCardNo: "",
    employeeVoterCardNo: "",
    employeePassportNo: "",
    employeeDrivingLicenseNo: "",
    employeeIdCardImage: null,
    employeeAadhaarcardFrontImage: null,
    employeeAadhaarcardBackImage: null,
    employeePancardImage: null,
    employeeVoterIdFrontImage: null,
    employeeVoterIdBackImage: null,
    employeePassportImage: null,
    employeeDrivinglicenseFrontImage: null,
    employeeDrivinglicenseBackImage: null,
  });
  const handleAgentEmployeeIdTypeChange = (value) => {
    setAgentEmployeeDetails((prev) => ({
      ...prev,
      employeeIdType: value,
      ...resetEmployeeIdProofDetails(),
    }));
  };
  // Agent Employee Aadhaar Card
  const handleAgentEmployeeAadhaarCardNo = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 4);
    setAgentEmployeeDetails((prev) => ({
      ...prev,
      employeeAadhaarCardNo: numericValue,
    }));
  };
  // Agent Added Employees List
  const [agentAddedEmployeesList, setAgentAddedEmployeesList] = useState([]);
  // console.log(agentAddedEmployeesList);
  const addAgentEmployee = async () => {
    if (
      agentEmployeeSchema.safeParse({
        employeeFullName: agentEmployeeDetails.employeeName,
      }).success === false
    ) {
      const errorMessage = agentEmployeeSchema.safeParse({
        employeeFullName: agentEmployeeDetails.employeeName,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Employee name");
      message.error(customMessage);
      return;
    }

    if (
      agentEmployeeSchema.safeParse({
        employeeDesignation: agentEmployeeDetails.employeeDesignation,
      }).success === false
    ) {
      const errorMessage = agentEmployeeSchema.safeParse({
        employeeDesignation: agentEmployeeDetails.employeeDesignation,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Employee designation"
      );
      message.error(customMessage);
      return;
    }

    if (
      agentEmployeeSchema.safeParse({
        employeeEmail: agentEmployeeDetails.employeeEmail,
      }).success === false
    ) {
      const errorMessage = agentEmployeeSchema.safeParse({
        employeeEmail: agentEmployeeDetails.employeeEmail,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Employee email");
      message.error(customMessage);
      return;
    }

    if (
      agentEmployeeSchema.safeParse({
        employeeMobileNo: agentEmployeeDetails.employeeMobileNo,
      }).success === false
    ) {
      const errorMessage = agentEmployeeSchema.safeParse({
        employeeMobileNo: agentEmployeeDetails.employeeMobileNo,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Employee mobile no"
      );
      message.error(customMessage);
      return;
    }

    if (
      agentEmployeeSchema.safeParse({
        employeeIdType: agentEmployeeDetails.employeeIdType,
      }).success === false
    ) {
      message.error("Select atleast one employee id type");
      return;
    }

    if (agentEmployeeDetails.employeeIdType === "Employee ID Card") {
      if (
        agentEmployeeSchema.safeParse({
          employeeID: agentEmployeeDetails.employeeID,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeeID: agentEmployeeDetails.employeeID,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Employee ID");
        message.error(customMessage);
        return;
      }
      if (!agentEmployeeDetails.employeeIdCardImage) {
        message.error("Employee ID card Image required");
        return;
      }
    }

    if (agentEmployeeDetails.employeeIdType === "Aadhaar Card") {
      if (
        agentEmployeeSchema.safeParse({
          employeeAadhaarCardNo: agentEmployeeDetails.employeeAadhaarCardNo,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeeAadhaarCardNo: agentEmployeeDetails.employeeAadhaarCardNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(
          /^String/,
          "Aadhaar card no."
        );
        message.error(customMessage);
        return;
      }
      if (!agentEmployeeDetails.employeeAadhaarcardFrontImage) {
        message.error("Employee aadhaar card front image required");
        return;
      }

      if (!agentEmployeeDetails.employeeAadhaarcardBackImage) {
        message.error("Employee aadhaar card back image required");
        return;
      }
    }

    if (agentEmployeeDetails.employeeIdType === "Pan Card") {
      if (
        agentEmployeeSchema.safeParse({
          employeePanCardNo: agentEmployeeDetails.employeePanCardNo,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeePanCardNo: agentEmployeeDetails.employeePanCardNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Pan card no.");
        message.error(customMessage);
        return;
      }

      if (!agentEmployeeDetails.employeePancardImage) {
        message.error("Employee pancard image required");
        return;
      }
    }

    if (agentEmployeeDetails.employeeIdType === "Voter Card") {
      if (
        agentEmployeeSchema.safeParse({
          employeeVoterCardNo: agentEmployeeDetails.employeeVoterCardNo,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeeVoterCardNo: agentEmployeeDetails.employeeVoterCardNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Voter card no.");
        message.error(customMessage);
        return;
      }
      if (!agentEmployeeDetails.employeeVoterIdFrontImage) {
        message.error("Employee voter card front image required");
        return;
      }

      if (!agentEmployeeDetails.employeeVoterIdBackImage) {
        message.error("Employee voter card back image required");
        return;
      }
    }

    if (agentEmployeeDetails.employeeIdType === "Passport") {
      if (
        agentEmployeeSchema.safeParse({
          employeePassportNo: agentEmployeeDetails.employeePassportNo,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeePassportNo: agentEmployeeDetails.employeePassportNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Passport no.");
        message.error(customMessage);
        return;
      }

      if (!agentEmployeeDetails.employeePassportImage) {
        message.error("Employee passport image required");
        return;
      }
    }

    if (agentEmployeeDetails.employeeIdType === "Driving License") {
      if (
        agentEmployeeSchema.safeParse({
          employeeDrivingLicenseNo:
            agentEmployeeDetails.employeeDrivingLicenseNo,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeeDrivingLicenseNo:
            agentEmployeeDetails.employeeDrivingLicenseNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(
          /^String/,
          "Driving license no."
        );
        message.error(customMessage);
        return;
      }
      if (!agentEmployeeDetails.employeeDrivinglicenseFrontImage) {
        message.error("Employee driving license front image required");
        return;
      }

      if (!agentEmployeeDetails.employeeDrivinglicenseBackImage) {
        message.error("Employee driving license back image required");
        return;
      }
    }

    if (!agentEmployeeDetails.employeeImage) {
      message.error("Employee image required");
      return;
    }

    if (
      agentAddedEmployeesList.length > 0 &&
      agentAddedEmployeesList.some(
        (employee) =>
          employee.employeeEmail === agentEmployeeDetails.employeeEmail ||
          employee.employeeMobileNo ===
            agentEmployeeDetails.employeeMobileNo.replace(
              /(\d{2})(\d{0,10})/,
              "+$1 $2"
            )
      )
    ) {
      const identifier = agentAddedEmployeesList.some(
        (employee) =>
          employee.employeeEmail === agentEmployeeDetails.employeeEmail
      )
        ? "email"
        : agentAddedEmployeesList.some(
            (employee) =>
              employee.employeeMobileNo ===
              agentEmployeeDetails.employeeMobileNo.replace(
                /(\d{2})(\d{0,10})/,
                "+$1 $2"
              )
          )
        ? "mobile no"
        : "credentials";
      message.error(
        `Employee with this ${identifier} already exists in the employee list`
      );
      return;
    }

    try {
      const response = await axios.get(
        `${baseURL}/api/profile/checkDuplicateCredentials`,
        {
          params: {
            email: agentEmployeeDetails.employeeEmail,
            mobileNo: agentEmployeeDetails.employeeMobileNo,
          },
        }
      );
      if (
        response.data.message === "User added successfully" ||
        response.status === 200 ||
        response.statusText === "OK"
      ) {
        message.success(response.data.message);
        setAgentAddedEmployeesList((prev) => [
          ...prev,
          {
            ...agentEmployeeDetails,
            employeeMobileNo: formatAgentEmployeeMobileDisplay(
              agentEmployeeDetails.employeeMobileNo
            ),
          },
        ]);
        setAgentEmployeeDetails({
          employeeName: "",
          employeeEmail: "",
          employeeMobileNo: "",
          employeeDesignation: "",
          employeeIdType: "",
          employeeID: "",
          employeeAadhaarCardNo: "",
          employeePanCardNo: "",
          employeeVoterCardNo: "",
          employeePassportNo: "",
          employeeDrivingLicenseNo: "",
          employeeImage: null,
          employeementLetterImage: null,
          employeeIdCardImage: null,
          employeeAadhaarcardFrontImage: null,
          employeeAadhaarcardBackImage: null,
          employeePancardImage: null,
          employeeVoterIdFrontImage: null,
          employeeVoterIdBackImage: null,
          employeePassportImage: null,
          employeeDrivinglicenseFrontImage: null,
          employeeDrivinglicenseBackImage: null,
        });
      }
    } catch (err) {
      message.error(err.response.data.message);
      return;
    }
  };
  const removeAgentEmployee = (index) => {
    setAgentAddedEmployeesList((prev) => prev.filter((_, i) => i !== index));
  };
  const renderAgentEmployeeImage = (image, placeholderText) => {
    if (image) {
      return URL.createObjectURL(image);
    }
    return `https://placehold.co/50x50?text=${placeholderText}`;
  };
  const agentEmployeesForm = (
    <>
      <div className="utr-form-category">
        <h4> Employee Details</h4>
        <div className="utr-form-inputs">
          <FormInput
            icon={MdDriveFileRenameOutline}
            placeholder="Employee Full Name"
            value={agentEmployeeDetails.employeeName}
            onChange={(e) =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeeName: e.target.value,
              }))
            }
          />
          <FormInput
            icon={LiaUserTagSolid}
            placeholder="Employee Designation"
            value={agentEmployeeDetails.employeeDesignation}
            onChange={(e) =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeeDesignation: e.target.value,
              }))
            }
          />
          <FormInput
            icon={MdOutlineEmail}
            placeholder="Employee Email"
            value={agentEmployeeDetails.employeeEmail}
            onChange={(e) =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeeEmail: e.target.value,
              }))
            }
          />
          <FormInput
            icon={MdOutlinePhoneEnabled}
            placeholder="Employee Mobile No."
            value={formatAgentEmployeeMobileDisplay(
              agentEmployeeDetails.employeeMobileNo
            )}
            onChange={handleAgentEmployeeMobileChange}
            maxLength="14"
          />
          <div className="utr-form-input">
            <HiOutlineDocumentReport className="utr-form-input-icon" />
            <Select
              showSearch
              name="agentEmployeeIdType"
              className="utr-form-input-select"
              placeholder="Select ID Type"
              value={agentEmployeeDetails.employeeIdType || undefined}
              optionFilterProp="label"
              onChange={handleAgentEmployeeIdTypeChange}
              options={[
                {
                  value: "Employee ID Card",
                  label: "Employee ID Card",
                },
                {
                  value: "Aadhaar Card",
                  label: "Employee Aadhaar Card",
                },
                {
                  value: "Pan Card",
                  label: "Employee Pan Card",
                },
                {
                  value: "Voter Card",
                  label: "Employee Voter Card",
                },
                {
                  value: "Passport",
                  label: "Employee Passport",
                },
                {
                  value: "Driving License",
                  label: "Employee Driving License",
                },
              ]}
            />
          </div>
          {agentEmployeeDetails.employeeIdType === "Employee ID Card" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Employee ID"
              value={agentEmployeeDetails.employeeID}
              onChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeeID: e.target.value,
                }))
              }
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Aadhaar Card" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Aadhaar Card No. (last 4 digits)"
              value={agentEmployeeDetails.employeeAadhaarCardNo}
              onChange={handleAgentEmployeeAadhaarCardNo}
              maxLength="4"
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Pan Card" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Pan Card No."
              value={agentEmployeeDetails.employeePanCardNo}
              onChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePanCardNo: e.target.value,
                }))
              }
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Voter Card" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Voter Card No."
              value={agentEmployeeDetails.employeeVoterCardNo}
              onChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeeVoterCardNo: e.target.value,
                }))
              }
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Passport" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Passport No."
              value={agentEmployeeDetails.employeePassportNo}
              onChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePassportNo: e.target.value,
                }))
              }
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Driving License" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Driving License No."
              value={agentEmployeeDetails.employeeDrivingLicenseNo}
              onChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeeDrivingLicenseNo: e.target.value,
                }))
              }
            />
          )}
        </div>
      </div>
      <div className="utr-form-category">
        <h4>Employee Photos</h4>
        <div className="utr-form-images">
          <ImageUpload
            image={agentEmployeeDetails.employeeImage}
            onImageChange={(e) =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeeImage: e.target.files[0],
              }))
            }
            onRemove={() =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeeImage: null,
              }))
            }
            label="Employee Photo"
          />
          <ImageUpload
            image={agentEmployeeDetails.employeementLetterImage}
            onImageChange={(e) =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeementLetterImage: e.target.files[0],
              }))
            }
            onRemove={() =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeementLetterImage: null,
              }))
            }
            label="Employeement Letter"
          />
          {agentEmployeeDetails.employeeIdType === "Employee ID Card" && (
            <ImageUpload
              image={agentEmployeeDetails.employeeIdCardImage}
              onImageChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeeIdCardImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeeIdCardImage: null,
                }))
              }
              label="ID Card"
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Aadhaar Card" && (
            <>
              <ImageUpload
                image={agentEmployeeDetails.employeeAadhaarcardFrontImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeAadhaarcardFrontImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeAadhaarcardFrontImage: null,
                  }))
                }
                label="Aadhaar Front"
              />
              <ImageUpload
                image={agentEmployeeDetails.employeeAadhaarcardBackImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeAadhaarcardBackImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeAadhaarcardBackImage: null,
                  }))
                }
                label="Aadhaar Back"
              />
            </>
          )}
          {agentEmployeeDetails.employeeIdType === "Pan Card" && (
            <ImageUpload
              image={agentEmployeeDetails.employeePancardImage}
              onImageChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePancardImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePancardImage: null,
                }))
              }
              label="Pan Card"
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Voter Card" && (
            <>
              <ImageUpload
                image={agentEmployeeDetails.employeeVoterIdFrontImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeVoterIdFrontImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeVoterIdFrontImage: null,
                  }))
                }
                label="Voter Front"
              />
              <ImageUpload
                image={agentEmployeeDetails.employeeVoterIdBackImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeVoterIdBackImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeVoterIdBackImage: null,
                  }))
                }
                label="Voter Back"
              />
            </>
          )}
          {agentEmployeeDetails.employeeIdType === "Passport" && (
            <ImageUpload
              image={agentEmployeeDetails.employeePassportImage}
              onImageChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePassportImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePassportImage: null,
                }))
              }
              label="Passport"
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Driving License" && (
            <>
              <ImageUpload
                image={agentEmployeeDetails.employeeDrivinglicenseFrontImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeDrivinglicenseFrontImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeDrivinglicenseFrontImage: null,
                  }))
                }
                label="Driving License Front"
              />
              <ImageUpload
                image={agentEmployeeDetails.employeeDrivinglicenseBackImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeDrivinglicenseBackImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeDrivinglicenseBackImage: null,
                  }))
                }
                label="Driving License Back"
              />
            </>
          )}
        </div>
        <button
          className="utr-add-project-employee-add"
          onClick={addAgentEmployee}
        >
          Add Employee
        </button>
      </div>
      {agentAddedEmployeesList.length > 0 && (
        <>
          <div className="utr-form-category">
            <h4>
              {agentAddedEmployeesList.length === 1
                ? "Added Employee"
                : "Added Employees"}{" "}
              ({agentAddedEmployeesList.length})
            </h4>
            <List
              itemLayout="horizontal"
              dataSource={agentAddedEmployeesList}
              renderItem={(item, index) => (
                <List.Item
                  key={index}
                  actions={[
                    <h5
                      className="utr-employees-remove red"
                      onClick={() => removeAgentEmployee(index)}
                    >
                      remove
                    </h5>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <img
                        src={renderAgentEmployeeImage(
                          item.employeeImage,
                          item.employeeName.charAt(0)
                        )}
                        alt="Profile"
                        className="utr-employees-img"
                      />
                    }
                    title={
                      <div className="utr-employees-name">
                        {item.employeeName}
                      </div>
                    }
                    description={
                      <div>
                        <div className="utr-employees-description">
                          {item.employeeDesignation} / {item.employeeEmail} /{" "}
                          {item.employeeMobileNo} / {item.employeeID}
                        </div>
                        {item.employeementLetterImage ? (
                          <img
                            src={renderAgentEmployeeImage(
                              item.employeementLetterImage,
                              "EL"
                            )}
                            alt="Employment Letter"
                            className="utr-employees-img-docs"
                          />
                        ) : (
                          <img
                            src={`https://placehold.co/30x30?text=EL`}
                            alt="Employment Letter Placeholder"
                            className="utr-employees-img-docs"
                          />
                        )}
                        {item.employeeIdType === "Employee ID Card" &&
                        item.employeeIdCardImage ? (
                          <img
                            src={renderAgentEmployeeImage(
                              item.employeeIdCardImage,
                              "ID"
                            )}
                            alt="ID Card"
                            className="utr-employees-img-docs"
                          />
                        ) : item.employeeIdType === "Aadhaar Card" &&
                          item.employeeAadhaarcardFrontImage &&
                          item.employeeAadhaarcardBackImage ? (
                          <>
                            <img
                              src={renderAgentEmployeeImage(
                                item.employeeAadhaarcardFrontImage,
                                "Aadhaar Front"
                              )}
                              alt="Aadhaar Front"
                              className="utr-employees-img-docs"
                            />
                            <img
                              src={renderAgentEmployeeImage(
                                item.employeeAadhaarcardBackImage,
                                "Aadhaar Back"
                              )}
                              alt="Aadhaar Back"
                              className="utr-employees-img-docs"
                            />
                          </>
                        ) : item.employeeIdType === "Pan Card" &&
                          item.employeePancardImage ? (
                          <img
                            src={renderAgentEmployeeImage(
                              item.employeePancardImage,
                              "PAN"
                            )}
                            alt="PAN Card"
                            className="utr-employees-img-docs"
                          />
                        ) : item.employeeIdType === "Voter Card" &&
                          item.employeeVoterIdFrontImage &&
                          item.employeeVoterIdBackImage ? (
                          <>
                            <img
                              src={renderAgentEmployeeImage(
                                item.employeeVoterIdFrontImage,
                                "Voter Front"
                              )}
                              alt="Voter Front"
                              className="utr-employees-img-docs"
                            />
                            <img
                              src={renderAgentEmployeeImage(
                                item.employeeVoterIdBackImage,
                                "Voter Back"
                              )}
                              alt="Voter Back"
                              className="utr-employees-img-docs"
                            />
                          </>
                        ) : item.employeeIdType === "Passport" &&
                          item.employeePassportImage ? (
                          <img
                            src={renderAgentEmployeeImage(
                              item.employeePassportImage,
                              "Passport"
                            )}
                            alt="Passport"
                            className="utr-employees-img-docs"
                          />
                        ) : item.employeeIdType === "Driving License" &&
                          item.employeeDrivinglicenseFrontImage &&
                          item.employeeDrivinglicenseBackImage ? (
                          <>
                            <img
                              src={renderAgentEmployeeImage(
                                item.employeeDrivinglicenseFrontImage,
                                "Driving Front"
                              )}
                              alt="Driving Front"
                              className="utr-employees-img-docs"
                            />
                            <img
                              src={renderAgentEmployeeImage(
                                item.employeeDrivinglicenseBackImage,
                                "Driving Back"
                              )}
                              alt="Driving Back"
                              className="utr-employees-img-docs"
                            />
                          </>
                        ) : (
                          <img
                            src={`https://placehold.co/30x30?text=${item.employeeIdType.charAt(
                              0
                            )}`}
                            alt={`${item.employeeIdType} Placeholder`}
                            className="utr-employees-img-docs"
                          />
                        )}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
          <div className="utr-form-category">
            <h4>Note</h4>
            <h6>
              (You can also add these employees later on the profile page under
              the "{userType} Employees" section.)
            </h6>
          </div>
        </>
      )}
    </>
  );

  // Agent Employees Reset
  const confirmAgentEmployeesReset = (e) => {
    message.success("Employees Data Reset success!");
    setAgentEmployeeDetails({
      employeeName: "",
      employeeEmail: "",
      employeeMobileNo: "",
      employeeDesignation: "",
      employeeIdType: "",
      employeeID: "",
      employeeAadhaarCardNo: "",
      employeePanCardNo: "",
      employeeVoterCardNo: "",
      employeePassportNo: "",
      employeeDrivingLicenseNo: "",
      employeeImage: null,
      employeementLetterImage: null,
      employeeIdCardImage: null,
      employeeAadhaarcardFrontImage: null,
      employeeAadhaarcardBackImage: null,
      employeePancardImage: null,
      employeeVoterIdFrontImage: null,
      employeeVoterIdBackImage: null,
      employeePassportImage: null,
      employeeDrivinglicenseFrontImage: null,
      employeeDrivinglicenseBackImage: null,
    });
  };
  const cancelAgentEmployeesReset = (e) => {
    message.error("Employees reset cancelled!");
  };

  const agentEmployeesCollapse = [
    {
      key: "1",
      label: `${agentAddedEmployeesList.length} ${
        agentAddedEmployeesList.length === 1 ? "Employee" : "Employees"
      } added`,
      children: agentEmployeesForm,
      extra: (
        <Popconfirm
          title="Reset Employee Data"
          description="Are you sure to reset all employee data?"
          onConfirm={confirmAgentEmployeesReset}
          onCancel={cancelAgentEmployeesReset}
          okText="Reset"
          cancelText="Cancel"
        >
          <GrPowerReset className="utr-collapse-icon" />
        </Popconfirm>
      ),
    },
  ];

  const [userData, setUserData] = useState(null);
  const [userProfileData, setUserProfileData] = useState(null);
  const [userProfileDataWithEmployeeList, setUserProfileDataWithEmployeeList] =
    useState(null);

  const fetchUserData = useCallback(async () => {
    try {
      const _userProfileData = await axios.get(
        `${baseURL}/api/profile/getUserDetailsById/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      setUserProfileDataWithEmployeeList(_userProfileData.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, [baseURL, userId, setUserProfileDataWithEmployeeList]);

  useMemo(() => {
    if (userId && baseURL) {
      fetchUserData();
    }
  }, [userId, baseURL, fetchUserData]);

  const [selectedEmployee, setSelectedEmployee] = useState(null);

  // FIELD NAMES TO USE FOR IMAGE STORING IN MULTER

  const [profileImageFieldName, setProfileImageFieldName] = useState(null);
  const [idCardImageFieldName, setIdCardImageFieldName] = useState(null);
  const [aadhaarcardFrontImageFieldName, setAadhaarcardFrontImageFieldName] =
    useState(null);
  const [aadhaarcardBackImageFieldName, setAadhaarcardBackImageFieldName] =
    useState(null);
  const [
    drivinglicenseFrontImageFieldName,
    setDrivinglicenseFrontImageFieldName,
  ] = useState(null);
  const [
    drivinglicenseBackImageFieldName,
    setDrivinglicenseBackImageFieldName,
  ] = useState(null);
  const [pancardImageFieldName, setPancardImageFieldName] = useState(null);
  const [passportImageFieldName, setPassportImageFieldName] = useState(null);
  const [voterIdFrontImageFieldName, setVoterIdFrontImageFieldName] =
    useState(null);
  const [voterIdBackImageFieldName, setVoterIdBackImageFieldName] =
    useState(null);
  const [isImagesFieldNameFetched, setIsImagesFieldNameFetched] =
    useState(false);

  useEffect(() => {
    if (!isImagesFieldNameFetched) {
      const employeesListWithoutBase64 =
        userProfileDataWithEmployeeList?.employeesListWithoutBase64;

      let existingProfileImagePath = employeesListWithoutBase64?.filter(
        (employee) => employee.userId === selectedEmployee?.userId
      )[0]?.salespersonDetails?.employeeImage;

      let existingIdCardImagePath = employeesListWithoutBase64?.filter(
        (employee) => employee.userId === selectedEmployee?.userId
      )[0]?.salespersonDetails?.employeeIdCardImage;

      // AADHAAR CARD

      let existingAadhaarcardFrontImagePath =
        employeesListWithoutBase64?.filter(
          (employee) => employee.userId === selectedEmployee?.userId
        )[0]?.salespersonDetails?.employeeAadhaarcardFrontImage;

      let existingAadhaarcardBackImagePath = employeesListWithoutBase64?.filter(
        (employee) => employee.userId === selectedEmployee?.userId
      )[0]?.salespersonDetails?.employeeAadhaarcardBackImage;

      // DRIVING LICENSE

      let existingDrivinglicenseFrontImagePath =
        employeesListWithoutBase64?.filter(
          (employee) => employee.userId === selectedEmployee?.userId
        )[0]?.salespersonDetails?.employeeDrivinglicenseFrontImage;

      let existingDrivinglicenseBackImagePath =
        employeesListWithoutBase64?.filter(
          (employee) => employee.userId === selectedEmployee?.userId
        )[0]?.salespersonDetails?.employeeDrivinglicenseBackImage;

      // PAN CARD

      let existingPancardImagePath = employeesListWithoutBase64?.filter(
        (employee) => employee.userId === selectedEmployee?.userId
      )[0]?.salespersonDetails?.employeePancardImage;

      // PASSPORT

      let existingPassportImagePath = employeesListWithoutBase64?.filter(
        (employee) => employee.userId === selectedEmployee?.userId
      )[0]?.salespersonDetails?.employeePassportImage;

      // PASSPORT

      let existingVoterIdFrontImagePath = employeesListWithoutBase64?.filter(
        (employee) => employee.userId === selectedEmployee?.userId
      )[0]?.salespersonDetails?.employeeVoterIdFrontImage;

      let existingVoterIdBackImagePath = employeesListWithoutBase64?.filter(
        (employee) => employee.userId === selectedEmployee?.userId
      )[0]?.salespersonDetails?.employeeVoterIdBackImage;

      let missingProfileImagePaths;
      let missingIdCardImagePaths;

      // AADHAAR CARD IMAGE PATHS
      let missingAadhaarcardFrontImagePaths;
      let missingAadhaarcardBackImagePaths;

      // DRIVING LICENSE IMAGE PATHS
      let missingDrivinglicenseFrontImagePaths;
      let missingDrivinglicenseBackImagePaths;

      // PANCARD IMAGE PATHS
      let missingPancardImagePaths;

      // PASSPORT IMAGE PATHS
      let missingPassportImagePaths;

      // VOTER ID IMAGE PATHS
      let missingVoterIdFrontImagePaths;
      let missingVoterIdBackImagePaths;

      if (
        !existingProfileImagePath ||
        !existingIdCardImagePath ||
        !existingAadhaarcardFrontImagePath ||
        !existingAadhaarcardBackImagePath ||
        !existingDrivinglicenseFrontImagePath ||
        !existingDrivinglicenseBackImagePath ||
        !existingPancardImagePath ||
        !existingPassportImagePath ||
        !existingVoterIdFrontImagePath ||
        !existingVoterIdBackImagePath
      ) {
        const Employees = userProfileDataWithEmployeeList?.employeesList;

        const profileImagePaths =
          Employees &&
          Employees.map(
            (employee) => employee.salespersonDetails.employeeImage
          ).filter((imagePath) => imagePath !== null);

        const IdCardImagePaths =
          Employees &&
          Employees.map(
            (employee) => employee.salespersonDetails.employeeIdCardImage
          ).filter((idCardImagePath) => idCardImagePath !== null);

        const aadharcardFrontImagePaths =
          Employees &&
          Employees.map(
            (employee) =>
              employee.salespersonDetails.employeeAadhaarcardFrontImage
          ).filter(
            (aadhaarcardFrontImagePath) => aadhaarcardFrontImagePath !== null
          );

        const aadharcardBackImagePaths =
          Employees &&
          Employees.map(
            (employee) =>
              employee.salespersonDetails.employeeAadhaarcardBackImage
          ).filter(
            (aadhaarcardBackImagePath) => aadhaarcardBackImagePath !== null
          );

        const drivinglicenseFrontImagePaths =
          Employees &&
          Employees.map(
            (employee) =>
              employee.salespersonDetails.employeeDrivinglicenseFrontImage
          ).filter(
            (drivinglicenseFrontImagePath) =>
              drivinglicenseFrontImagePath !== null
          );

        const drivinglicenseBackImagePaths =
          Employees &&
          Employees.map(
            (employee) =>
              employee.salespersonDetails.employeeDrivinglicenseBackImage
          ).filter(
            (drivinglicenseBackImagePath) =>
              drivinglicenseBackImagePath !== null
          );

        const pancardImagePaths =
          Employees &&
          Employees.map(
            (employee) => employee.salespersonDetails.employeePancardImage
          ).filter((pancardImagePath) => pancardImagePath !== null);

        const passportImagePaths =
          Employees &&
          Employees.map(
            (employee) => employee.salespersonDetails.employeePassportImage
          ).filter((passportImagePath) => passportImagePath !== null);

        const voterIdFrontImagePaths =
          Employees &&
          Employees.map(
            (employee) => employee.salespersonDetails.employeeVoterIdFrontImage
          ).filter((voterIdFrontImagePath) => voterIdFrontImagePath !== null);

        const voterIdBackImagePaths =
          Employees &&
          Employees.map(
            (employee) => employee.salespersonDetails.employeeVoterIdBackImage
          ).filter((voterIdBackImagePath) => voterIdBackImagePath !== null);

        const allPossibleProfileImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
              ? `agentEmployeeImage_${i}`
              : userProfileDataWithEmployeeList?.userDetails?.userType ===
                "Builder"
              ? `builderEmployeeImage_${i}`
              : null
        ).filter((path) => path !== null);

        const allPossibleIdCardImagePaths = Array.from({ length: 10 }, (_, i) =>
          userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
            ? `agentEmployeeIdCardImage_${i}`
            : userProfileDataWithEmployeeList?.userDetails?.userType ===
              "Builder"
            ? `builderEmployeeIdCardImage_${i}`
            : null
        ).filter((path) => path !== null);

        const allPossibleAadhaarcardFrontImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
              ? `agentEmployeeAadhaarcardFrontImage_${i}`
              : userProfileDataWithEmployeeList?.userDetails?.userType ===
                "Builder"
              ? `builderEmployeeAadhaarcardFrontImage_${i}`
              : null
        ).filter((path) => path !== null);

        const allPossibleAadhaarcardBackImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
              ? `agentEmployeeAadhaarcardBackImage_${i}`
              : userProfileDataWithEmployeeList?.userDetails?.userType ===
                "Builder"
              ? `builderEmployeeAadhaarcardBackImage_${i}`
              : null
        ).filter((path) => path !== null);

        const allPossibleDrivinglicenseFrontImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
              ? `agentEmployeeDrivinglicenseFrontImage_${i}`
              : userProfileDataWithEmployeeList?.userDetails?.userType ===
                "Builder"
              ? `builderEmployeeDrivinglicenseFrontImage_${i}`
              : null
        ).filter((path) => path !== null);

        const allPossibleDrivinglicenseBackImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
              ? `agentEmployeeDrivinglicenseBackImage_${i}`
              : userProfileDataWithEmployeeList?.userDetails?.userType ===
                "Builder"
              ? `builderEmployeeDrivinglicenseBackImage_${i}`
              : null
        ).filter((path) => path !== null);

        const allPossiblePancardImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
              ? `agentEmployeePancardImage_${i}`
              : userProfileDataWithEmployeeList?.userDetails?.userType ===
                "Builder"
              ? `builderEmployeePancardImage_${i}`
              : null
        ).filter((path) => path !== null);

        const allPossiblePassportImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
              ? `agentEmployeePassportImage_${i}`
              : userProfileDataWithEmployeeList?.userDetails?.userType ===
                "Builder"
              ? `builderEmployeePassportImage_${i}`
              : null
        ).filter((path) => path !== null);

        const allPossibleVoterIdFrontImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
              ? `agentEmployeeVoterIdFrontImage_${i}`
              : userProfileDataWithEmployeeList?.userDetails?.userType ===
                "Builder"
              ? `builderEmployeeVoterIdFrontImage_${i}`
              : null
        ).filter((path) => path !== null);

        const allPossibleVoterIdBackImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
              ? `agentEmployeeVoterIdBackImage_${i}`
              : userProfileDataWithEmployeeList?.userDetails?.userType ===
                "Builder"
              ? `builderEmployeeVoterIdBackImage_${i}`
              : null
        ).filter((path) => path !== null);

        missingProfileImagePaths = allPossibleProfileImagePaths.filter(
          (path) =>
            !(profileImagePaths || [])?.some((profilePath) =>
              profilePath?.includes(path)
            )
        );

        missingIdCardImagePaths = allPossibleIdCardImagePaths.filter(
          (path) =>
            !(IdCardImagePaths || [])?.some((idCardPath) =>
              idCardPath?.includes(path)
            )
        );

        missingAadhaarcardFrontImagePaths =
          allPossibleAadhaarcardFrontImagePaths.filter(
            (path) =>
              !(aadharcardFrontImagePaths || []).some(
                (aadharcardFrontImagePath) =>
                  aadharcardFrontImagePath?.includes(path)
              )
          );

        missingAadhaarcardBackImagePaths =
          allPossibleAadhaarcardBackImagePaths.filter(
            (path) =>
              !(aadharcardBackImagePaths || []).some(
                (aadharcardBackImagePath) =>
                  aadharcardBackImagePath?.includes(path)
              )
          );

        missingDrivinglicenseFrontImagePaths =
          allPossibleDrivinglicenseFrontImagePaths.filter(
            (path) =>
              !(drivinglicenseFrontImagePaths || []).some(
                (drivinglicenseFrontImagePath) =>
                  drivinglicenseFrontImagePath?.includes(path)
              )
          );

        missingDrivinglicenseBackImagePaths =
          allPossibleDrivinglicenseBackImagePaths.filter(
            (path) =>
              !(drivinglicenseBackImagePaths || []).some(
                (drivinglicenseBackImagePath) =>
                  drivinglicenseBackImagePath?.includes(path)
              )
          );

        missingPancardImagePaths = allPossiblePancardImagePaths.filter(
          (path) =>
            !(pancardImagePaths || []).some((pancardImagePath) =>
              pancardImagePath?.includes(path)
            )
        );

        missingPassportImagePaths = allPossiblePassportImagePaths.filter(
          (path) =>
            !(passportImagePaths || []).some((passportImagePath) =>
              passportImagePath?.includes(path)
            )
        );

        missingVoterIdFrontImagePaths =
          allPossibleVoterIdFrontImagePaths.filter(
            (path) =>
              !(voterIdFrontImagePaths || []).some((voterIdFrontImagePath) =>
                voterIdFrontImagePath?.includes(path)
              )
          );

        missingVoterIdBackImagePaths = allPossibleVoterIdBackImagePaths.filter(
          (path) =>
            !(voterIdBackImagePaths || []).some((voterIdBackImagePath) =>
              voterIdBackImagePath?.includes(path)
            )
        );
      }

      const profilePattern =
        userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
          ? /agentEmployeeImage_\d/
          : userProfileDataWithEmployeeList?.userDetails?.userType === "Builder"
          ? /builderEmployeeImage_\d/
          : null;

      const idCardPattern =
        userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
          ? /agentEmployeeIdCardImage_\d/
          : userProfileDataWithEmployeeList?.userDetails?.userType === "Builder"
          ? /builderEmployeeIdCardImage_\d/
          : null;

      const aadhaarcardFrontImagePattern =
        userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
          ? /agentEmployeeAadhaarcardFrontImage_\d/
          : userProfileDataWithEmployeeList?.userDetails?.userType === "Builder"
          ? /builderEmployeeAadhaarcardFrontImage_\d/
          : null;

      const aadhaarcardBackImagePattern =
        userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
          ? /agentEmployeeAadhaarcardBackImage_\d/
          : userProfileDataWithEmployeeList?.userDetails?.userType === "Builder"
          ? /builderEmployeeAadhaarcardBackImage_\d/
          : null;

      const drivinglicenseFrontImagePattern =
        userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
          ? /agentEmployeeDrivinglicenseFrontImage_\d/
          : userProfileDataWithEmployeeList?.userDetails?.userType === "Builder"
          ? /builderEmployeeDrivinglicenseFrontImage_\d/
          : null;

      const drivinglicenseBackImagePattern =
        userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
          ? /agentEmployeeDrivinglicenseBackImage_\d/
          : userProfileDataWithEmployeeList?.userDetails?.userType === "Builder"
          ? /builderEmployeeDrivinglicenseBackImage_\d/
          : null;

      const pancardImagePattern =
        userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
          ? /agentEmployeePancardImage_\d/
          : userProfileDataWithEmployeeList?.userDetails?.userType === "Builder"
          ? /builderEmployeePancardImage_\d/
          : null;

      const passportImagePattern =
        userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
          ? /agentEmployeePassportImage_\d/
          : userProfileDataWithEmployeeList?.userDetails?.userType === "Builder"
          ? /builderEmployeePassportImage_\d/
          : null;

      const voterIdFrontImagePattern =
        userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
          ? /agentEmployeeVoterIdFrontImage_\d/
          : userProfileDataWithEmployeeList?.userDetails?.userType === "Builder"
          ? /builderEmployeeVoterIdFrontImage_\d/
          : null;

      const voterIdBackImagePattern =
        userProfileDataWithEmployeeList?.userDetails?.userType === "Agent"
          ? /agentEmployeeVoterIdBackImage_\d/
          : userProfileDataWithEmployeeList?.userDetails?.userType === "Builder"
          ? /builderEmployeeVoterIdBackImage_\d/
          : null;

      const profilematch = existingProfileImagePath?.match(profilePattern);
      const idCardmatch = existingIdCardImagePath?.match(idCardPattern);
      const aadhaarCardFrontImagematch =
        existingAadhaarcardFrontImagePath?.match(aadhaarcardFrontImagePattern);
      const aadhaarCardBackImagematch = existingAadhaarcardBackImagePath?.match(
        aadhaarcardBackImagePattern
      );

      const drivingLicenseFrontImagematch =
        existingDrivinglicenseFrontImagePath?.match(
          drivinglicenseFrontImagePattern
        );
      const drivingLicenseBackImagematch =
        existingDrivinglicenseBackImagePath?.match(
          drivinglicenseBackImagePattern
        );

      const pancardImagematch =
        existingPancardImagePath?.match(pancardImagePattern);

      const passportImagematch =
        existingPassportImagePath?.match(passportImagePattern);

      const voterIdFrontImagematch = existingVoterIdFrontImagePath?.match(
        voterIdFrontImagePattern
      );
      const voterIdBackImagematch = existingVoterIdBackImagePath?.match(
        voterIdBackImagePattern
      );

      if (
        profileImageFieldName &&
        idCardImageFieldName &&
        aadhaarcardFrontImageFieldName &&
        aadhaarcardBackImageFieldName &&
        drivinglicenseFrontImageFieldName &&
        drivinglicenseBackImageFieldName &&
        pancardImageFieldName &&
        passportImageFieldName &&
        voterIdFrontImageFieldName &&
        voterIdBackImageFieldName
      ) {
        setIsImagesFieldNameFetched(true);
      }

      setProfileImageFieldName(
        profilematch
          ? profilematch[0]
          : missingProfileImagePaths
          ? missingProfileImagePaths[0]
          : null
      );
      setIdCardImageFieldName(
        idCardmatch
          ? idCardmatch[0]
          : missingIdCardImagePaths
          ? missingIdCardImagePaths[0]
          : null
      );
      setAadhaarcardFrontImageFieldName(
        aadhaarCardFrontImagematch
          ? aadhaarCardFrontImagematch[0]
          : missingAadhaarcardFrontImagePaths
          ? missingAadhaarcardFrontImagePaths[0]
          : null
      );
      setAadhaarcardBackImageFieldName(
        aadhaarCardBackImagematch
          ? aadhaarCardBackImagematch[0]
          : missingAadhaarcardBackImagePaths
          ? missingAadhaarcardBackImagePaths[0]
          : null
      );
      setDrivinglicenseFrontImageFieldName(
        drivingLicenseFrontImagematch
          ? drivingLicenseFrontImagematch[0]
          : missingDrivinglicenseFrontImagePaths
          ? missingDrivinglicenseFrontImagePaths[0]
          : null
      );
      setDrivinglicenseBackImageFieldName(
        drivingLicenseBackImagematch
          ? drivingLicenseBackImagematch[0]
          : missingDrivinglicenseBackImagePaths
          ? missingDrivinglicenseBackImagePaths[0]
          : null
      );
      setPancardImageFieldName(
        pancardImagematch
          ? pancardImagematch[0]
          : missingPancardImagePaths
          ? missingPancardImagePaths[0]
          : null
      );
      setPassportImageFieldName(
        passportImagematch
          ? passportImagematch[0]
          : missingPassportImagePaths
          ? missingPassportImagePaths[0]
          : null
      );
      setVoterIdFrontImageFieldName(
        voterIdFrontImagematch
          ? voterIdFrontImagematch[0]
          : missingVoterIdFrontImagePaths
          ? missingVoterIdFrontImagePaths[0]
          : null
      );
      setVoterIdBackImageFieldName(
        voterIdBackImagematch
          ? voterIdBackImagematch[0]
          : missingVoterIdBackImagePaths
          ? missingVoterIdBackImagePaths[0]
          : null
      );
    }
  }, [
    userProfileDataWithEmployeeList,
    selectedEmployee,
    // agentEmployeeData,
    isImagesFieldNameFetched,
  ]);

  useMemo(async () => {
    setUserData(userDetails?.user);
    setUserProfileData(userDetails?.userProfile);
  }, [userDetails]);

  useMemo(() => {
    if (userData && userType === "Owner") {
      if (userData.name) {
        setFullName(userData?.name);
      }
      if (userData.email && !userData.email.includes("@tempmail.com")) {
        setEmail(userData?.email);
      }
      if (userData.mobileNumber && userData.mobileNumber !== "undefined") {
        setMobile(userData.mobileNumber);
      }
      if (userData.whatsappNumber && userData.whatsappNumber !== "undefined") {
        setWhatsappMobile(userData.whatsappNumber);
      }
      if (
        userProfileData &&
        IsBase64Image(userProfileData?.ownerDetails?.ownerProfilePhoto)
      ) {
        setProfileImage(userProfileData?.ownerDetails?.ownerProfilePhoto);
      }
    }
    if (userData && userType === "Builder") {
      if (userData.name) {
        setFullName(userData?.name);
      }
      if (userData.email && !userData.email.includes("@tempmail.com")) {
        setEmail(userData?.email);
      }
      if (userData.mobileNumber && userData.mobileNumber !== "undefined") {
        setMobile(userData.mobileNumber);
      }
      if (userData.whatsappNumber && userData.whatsappNumber !== "undefined") {
        setWhatsappMobile(userData.whatsappNumber);
      }
      if (
        userProfileData &&
        IsBase64Image(userProfileData?.builderDetails?.builderProfilePhoto)
      ) {
        setProfileImage(userProfileData?.builderDetails?.builderProfilePhoto);
      }
      if (
        userProfileData &&
        IsBase64Image(userProfileData?.builderDetails?.builderFirmLogo)
      ) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderFirmLogo: userProfileData?.builderDetails?.builderFirmLogo,
        }));
      }
      if (userProfileData && userProfileData?.builderDetails?.builderFirmName) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderFirmName: userProfileData?.builderDetails?.builderFirmName,
        }));
      }
      if (userProfileData && userProfileData?.builderDetails?.builderFirmLogo) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderFirmLogo: userProfileData?.builderDetails?.builderFirmLogo,
        }));
      }

      if (userProfileData && userProfileData?.builderDetails?.builderReraNo) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderReraNo: userProfileData?.builderDetails?.builderReraNo,
        }));
      }

      if (
        userProfileData &&
        userProfileData?.builderDetails?.builderReraNoType
      ) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderReraNoType: userProfileData?.builderDetails?.builderReraNoType,
        }));
      }

      if (
        userProfileData &&
        userProfileData?.builderDetails?.builderOfficeAddress
      ) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderAddress: userProfileData?.builderDetails?.builderOfficeAddress,
        }));
      }

      if (
        userProfileData &&
        userProfileData?.builderDetails?.builderOfficeName
      ) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderOfficeName: userProfileData?.builderDetails?.builderOfficeName,
        }));
      }

      if (
        userProfileData &&
        userProfileData?.builderDetails?.builderOfficeCity
      ) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderOfficeCity: userProfileData?.builderDetails?.builderOfficeCity,
        }));
      }

      if (
        userProfileData &&
        userProfileData?.builderDetails?.builderOfficeWebsite
      ) {
        setBuilderDetails((prev) => ({
          ...prev,
          builderOfficeWebsite:
            userProfileData?.builderDetails?.builderOfficeWebsite,
        }));
      }
    }
    if (userData && userType === "Agent") {
      if (userData.name) {
        setFullName(userData?.name);
      }
      if (userData.email && !userData.email.includes("@tempmail.com")) {
        setEmail(userData?.email);
      }
      if (userData.mobileNumber && userData.mobileNumber !== "undefined") {
        setMobile(userData.mobileNumber);
      }
      if (userData.whatsappNumber && userData.whatsappNumber !== "undefined") {
        setWhatsappMobile(userData.whatsappNumber);
      }
      if (
        userProfileData &&
        IsBase64Image(userProfileData?.agentDetails?.agentProfilePhoto)
      ) {
        setProfileImage(userProfileData?.agentDetails?.agentProfilePhoto);
      }
      if (
        userProfileData &&
        IsBase64Image(userProfileData?.agentDetails?.agentFirmLogo)
      ) {
        setAgentDetails((prev) => ({
          ...prev,
          agentFirmLogo: userProfileData?.agentDetails?.agentFirmLogo,
        }));
      }
      if (userProfileData && userProfileData?.agentDetails?.agentFirmName) {
        setAgentDetails((prev) => ({
          ...prev,
          agentFirmName: userProfileData?.agentDetails?.agentFirmName,
        }));
      }
      if (userProfileData && userProfileData?.agentDetails?.agentFirmLogo) {
        setAgentDetails((prev) => ({
          ...prev,
          agentFirmLogo: userProfileData?.agentDetails?.agentFirmLogo,
        }));
      }

      if (userProfileData && userProfileData?.agentDetails?.agentReraNo) {
        setAgentDetails((prev) => ({
          ...prev,
          agentReraNo: userProfileData?.agentDetails?.agentReraNo,
        }));
      }

      if (userProfileData && userProfileData?.agentDetails?.agentReraNoType) {
        setAgentDetails((prev) => ({
          ...prev,
          agentReraNoType: userProfileData?.agentDetails?.agentReraNoType,
        }));
      }

      if (
        userProfileData &&
        userProfileData?.agentDetails?.agentOfficeAddress
      ) {
        setAgentDetails((prev) => ({
          ...prev,
          agentAddress: userProfileData?.agentDetails?.agentOfficeAddress,
        }));
      }

      if (userProfileData && userProfileData?.agentDetails?.agentOfficeName) {
        setAgentDetails((prev) => ({
          ...prev,
          agentOfficeName: userProfileData?.agentDetails?.agentOfficeName,
        }));
      }

      if (userProfileData && userProfileData?.agentDetails?.agentOfficeCity) {
        setAgentDetails((prev) => ({
          ...prev,
          agentOfficeCity: userProfileData?.agentDetails?.agentOfficeCity,
        }));
      }

      if (
        userProfileData &&
        userProfileData?.agentDetails?.agentOfficeWebsite
      ) {
        setAgentDetails((prev) => ({
          ...prev,
          agentOfficeWebsite: userProfileData?.agentDetails?.agentOfficeWebsite,
        }));
      }
    }
    if (userData && userType === "Salesperson") {
      if (userData.name) {
        setFullName(userData?.name);
      }
      if (userData.email && !userData.email.includes("@tempmail.com")) {
        setEmail(userData?.email);
      }
      if (userData.mobileNumber && userData.mobileNumber !== "undefined") {
        setMobile(userData.mobileNumber);
      }
      if (userData.whatsappNumber && userData.whatsappNumber !== "undefined") {
        setWhatsappMobile(userData.whatsappNumber);
      }
      if (
        userProfileData &&
        IsBase64Image(userProfileData?.salespersonDetails?.employeeImage)
      ) {
        setProfileImage(userProfileData?.salespersonDetails?.employeeImage);
      }
    }
  }, [
    userData,
    userProfileData,
    userType,
    setFullName,
    setEmail,
    setMobile,
    setWhatsappMobile,
  ]);

  useMemo(() => {
    if (
      fullname &&
      mobile &&
      email &&
      userData &&
      (fullname !== userData?.name ||
        email !== userData?.email ||
        mobile !== userData?.mobileNumber ||
        whatsappMobile !== userData?.whatsappNumber)
    ) {
      setShowSaveUserDetailsBtn(true);
    } else {
      setShowSaveUserDetailsBtn(false);
    }

    if (
      userType === "Builder" &&
      ((builderDetails?.builderFirmName &&
        builderDetails?.builderFirmName !==
          userProfileData?.builderDetails?.builderFirmName) ||
        (builderDetails?.builderReraNo &&
          builderDetails?.builderReraNo !==
            userProfileData?.builderDetails?.builderReraNo) ||
        (builderDetails?.builderReraNoType &&
          builderDetails?.builderReraNoType !==
            userProfileData?.builderDetails?.builderReraNoType) ||
        (builderDetails?.builderAddress &&
          builderDetails?.builderAddress !==
            userProfileData?.builderDetails?.builderOfficeAddress) ||
        (builderDetails?.builderFirmLogo &&
          !IsBase64Image(builderDetails?.builderFirmLogo)) ||
        builderDetails?.builderOfficeName !==
          userProfileData?.builderDetails?.builderOfficeName ||
        builderDetails?.builderOfficeCity !==
          userProfileData?.builderDetails?.builderOfficeCity ||
        builderDetails?.builderOfficeWebsite !==
          userProfileData?.builderDetails?.builderOfficeWebsite)
    ) {
      setShowSaveUserDetailsBtn(true);
    }

    if (
      userType === "Agent" &&
      ((agentDetails?.agentFirmName &&
        agentDetails?.agentFirmName !==
          userProfileData?.agentDetails?.agentFirmName) ||
        (agentDetails?.agentReraNo &&
          agentDetails?.agentReraNo !==
            userProfileData?.agentDetails?.agentReraNo) ||
        (agentDetails?.agentReraNoType &&
          agentDetails?.agentReraNoType !==
            userProfileData?.agentDetails?.agentReraNoType) ||
        (agentDetails?.agentAddress &&
          agentDetails?.agentAddress !==
            userProfileData?.agentDetails?.agentOfficeAddress) ||
        (agentDetails?.agentFirmLogo &&
          !IsBase64Image(agentDetails?.agentFirmLogo)) ||
        agentDetails?.agentOfficeName !==
          userProfileData?.agentDetails?.agentOfficeName ||
        agentDetails?.agentOfficeCity !==
          userProfileData?.agentDetails?.agentOfficeCity ||
        agentDetails?.agentOfficeWebsite !==
          userProfileData?.agentDetails?.agentOfficeWebsite)
    ) {
      setShowSaveUserDetailsBtn(true);
    }

    if (whatsappMobile && mobile && mobile === whatsappMobile) {
      onSameWhatsappMobileAsMobileChange(true);
    }
  }, [
    fullname,
    mobile,
    email,
    whatsappMobile,
    userData,
    userType,
    builderDetails,
    agentDetails,
    userProfileData,
    onSameWhatsappMobileAsMobileChange,
  ]);

  useEffect(() => {
    setLoading(true);
    if (userData) {
      setLoading(false);
    }
  }, [userData]);

  const updateProfile = async () => {
    try {
      if (
        userType === "Builder" &&
        builderDetails.builderOfficeWebsite &&
        updateProfileSchema.safeParse({
          officeWebsite: builderDetails.builderOfficeWebsite,
        }).success === false
      ) {
        const errorMessage = updateProfileSchema.safeParse({
          officeWebsite: builderDetails.builderOfficeWebsite,
        }).error.issues[0].message;
        message.error(errorMessage);
        return;
      } else if (
        userType === "Agent" &&
        agentDetails.agentOfficeWebsite &&
        updateProfileSchema.safeParse({
          officeWebsite: agentDetails.agentOfficeWebsite,
        }).success === false
      ) {
        const errorMessage = updateProfileSchema.safeParse({
          officeWebsite: agentDetails.agentOfficeWebsite,
        }).error.issues[0].message;
        message.error(errorMessage);
        return;
      }

      const formdata = new FormData();

      formdata.append("userId", userId);
      formdata.append("userType", userType);

      formdata.append("name", fullname);
      formdata.append("email", email);
      formdata.append("mobileNumber", mobile);
      formdata.append("whatsappNumber", whatsappMobile);

      if (userType === "Builder") {
        if (typeof profileImage !== String) {
          formdata.append("builderProfilePhoto", profileImage);
        }
        formdata.append("builderFirmName", builderDetails.builderFirmName);
        formdata.append("builderReraNo", builderDetails.builderReraNo);
        formdata.append("builderReraNoType", builderDetails.builderReraNoType);
        formdata.append("builderOfficeAddress", builderDetails.builderAddress);
        if (typeof builderDetails.builderFirmLogo !== String) {
          formdata.append("builderFirmLogo", builderDetails.builderFirmLogo);
        }
        formdata.append("builderOfficeName", builderDetails.builderOfficeName);
        formdata.append("builderOfficeCity", builderDetails.builderOfficeCity);
        formdata.append(
          "builderOfficeWebsite",
          builderDetails.builderOfficeWebsite
        );
      }

      if (userType === "Agent") {
        if (typeof profileImage !== String) {
          formdata.append("agentProfilePhoto", profileImage);
        }
        formdata.append("agentFirmName", agentDetails.agentFirmName);
        formdata.append("agentReraNo", agentDetails.agentReraNo);
        formdata.append("agentReraNoType", agentDetails.agentReraNoType);
        formdata.append("agentOfficeAddress", agentDetails.agentAddress);
        if (typeof agentDetails.agentFirmLogo !== String) {
          formdata.append("agentFirmLogo", agentDetails.agentFirmLogo);
        }
        formdata.append("agentOfficeName", agentDetails.agentOfficeName);
        formdata.append("agentOfficeCity", agentDetails.agentOfficeCity);
        formdata.append("agentOfficeWebsite", agentDetails.agentOfficeWebsite);
      }

      const response = await axios.patch(
        `${baseURL}/api/profile/updateProfile`,
        formdata,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "multipart/formdata",
          },
        }
      );
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response.data.message
      ) {
        message.success(response.data.message);
        setShowSaveUserDetailsBtn(false);
        setFetchLatestUserData(true);
      }
    } catch (error) {
      if (
        error.response.data.message ||
        error.response.status === 409 ||
        error.response.statusText === "Conflict"
      ) {
        return message.error(error.response.data.message);
      }
      message.error("Error updating profile, try again!");
    }
  };

  return (
    <div className="utr">
      {loading && <LoadingOverlay />}
      <div className="utr-sub">
        <div className="utr-container">
          <div className="utr-header">
            <div className="utr-header-sub">
              <GoArrowLeft
                className="utr-header-sub-icon"
                onClick={() => navigate(-1)}
              />
              <h3>{userType} - Profile</h3>
              <Progress
                type="circle"
                percent={45}
                size={40}
                strokeColor="#ff2d2d"
                trailColor="#cccccc"
                strokeWidth={9}
              />
            </div>
          </div>
          <div className="utr-footer-profile">
            <div className="utr-footer-profile-sub">
              <div
                className={`utr-footer-profile-option ${
                  selectedSection === "Business Info" ? "selected" : ""
                }`}
                onClick={() => handleSectionClick("Business Info")}
              >
                <LuUser2 className="utr-footer-profile-option-icon" />
                <h5>Business Info</h5>
              </div>
              {userType !== "Owner" && (
                <>
                  <div
                    className={`utr-footer-profile-option ${
                      selectedSection === "Firm Details" ? "selected" : ""
                    }`}
                    onClick={() => handleSectionClick("Firm Details")}
                  >
                    <AiOutlineSafetyCertificate className="utr-footer-profile-option-icon" />
                    <h5>Firm Details</h5>
                  </div>
                  <div
                    className={`utr-footer-profile-option ${
                      selectedSection === "Office Details" ? "selected" : ""
                    }`}
                    onClick={() => handleSectionClick("Office Details")}
                  >
                    <RiHomeOfficeLine className="utr-footer-profile-option-icon" />
                    <h5>Office Details</h5>
                  </div>
                  <div
                    className={`utr-footer-profile-option ${
                      selectedSection === "Employees" ? "selected" : ""
                    }`}
                    onClick={() => handleSectionClick("Employees")}
                  >
                    <LuUsers2 className="utr-footer-profile-option-icon" />
                    <h5>Employees</h5>
                  </div>
                </>
              )}
              <div
                className={`utr-footer-profile-option ${
                  selectedSection === "Preferences" ? "selected" : ""
                }`}
                onClick={() => handleSectionClick("Preferences")}
              >
                <TbSettings2 className="utr-footer-profile-option-icon" />
                <h5>Preferences</h5>
              </div>
            </div>
          </div>
          <div className="utr-form">
            {selectedSection === "Business Info" && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h4>Profile Details</h4>
                  <div className="utr-form-inputs">
                    <FormInput
                      icon={MdDriveFileRenameOutline}
                      placeholder="Full Name"
                      value={fullname}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    <FormInput
                      icon={MdOutlineEmail}
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        if (!userData?.email?.includes("@tempmail.com")) {
                          setEmail(e.target.value);
                        }
                      }}
                    />
                    <FormInput
                      icon={MdOutlinePhoneEnabled}
                      placeholder="Mobile No."
                      value={formatMobileDisplay(mobile)}
                      onChange={!userData?.mobile && handleMobileChange}
                      maxLength="14"
                    />
                    <FormInput
                      icon={IoLogoWhatsapp}
                      placeholder="Whatsapp No."
                      value={
                        checked
                          ? formatMobileDisplay(mobile)
                          : formatWhatsappMobileDisplay(whatsappMobile)
                      }
                      onChange={
                        !userData?.whatsappMobile && handleWhatsappMobileChange
                      }
                      readOnly={checked}
                      maxLength="14"
                    />
                  </div>
                  <h6>
                    Whatsapp Mobile number same as Mobile number?
                    <Switch
                      checked={checked}
                      onChange={onSameWhatsappMobileAsMobileChange}
                      style={{ backgroundColor: switchBackgroundColor }}
                      className="utr-form-category-h6-switch"
                    />
                  </h6>
                </div>
                <div className="utr-form-category utr-form-category-last">
                  <h4>Profile Photo</h4>
                  <div className="utr-form-images">
                    <ImageUpload
                      image={profileImage}
                      onImageChange={(e) => {
                        setProfileImage(e.target.files[0]);
                        if (typeof profileImage !== String) {
                          setShowSaveUserDetailsBtn(true);
                        }
                      }}
                      onRemove={() => {
                        setProfileImage(null);
                        setShowSaveUserDetailsBtn(false);
                      }}
                      label=""
                    />
                  </div>
                </div>
              </div>
            )}
            {selectedSection === "Firm Details" && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h4>{userType} Firm Details</h4>
                  <div className="utr-form-inputs">
                    <FormInput
                      icon={TbBuildingEstate}
                      placeholder="Firm Name"
                      value={
                        userType === "Builder"
                          ? builderDetails.builderFirmName
                          : userType === "Agent"
                          ? agentDetails.agentFirmName
                          : null
                      }
                      onChange={(e) =>
                        userType === "Builder"
                          ? setBuilderDetails((prev) => ({
                              ...prev,
                              builderFirmName: e.target.value,
                            }))
                          : userType === "Agent"
                          ? setAgentDetails((prev) => ({
                              ...prev,
                              agentFirmName: e.target.value,
                            }))
                          : null
                      }
                    />
                    <FormInput
                      icon={TbInputCheck}
                      placeholder="RERA No."
                      value={
                        userType === "Builder"
                          ? builderDetails.builderReraNo
                          : userType === "Agent"
                          ? agentDetails.agentReraNo
                          : null
                      }
                      onChange={(e) =>
                        userType === "Builder"
                          ? setBuilderDetails((prev) => ({
                              ...prev,
                              builderReraNo: e.target.value,
                            }))
                          : userType === "Agent"
                          ? setAgentDetails((prev) => ({
                              ...prev,
                              agentReraNo: e.target.value,
                            }))
                          : null
                      }
                    />
                    <div className="utr-form-input">
                      <LuTextCursorInput className="utr-form-input-icon" />
                      <Select
                        showSearch
                        name="builderReraNoType"
                        className="utr-form-input-select"
                        placeholder="Select Rera Number Type"
                        value={
                          userType === "Builder"
                            ? builderDetails.builderReraNoType || undefined
                            : userType === "Agent"
                            ? agentDetails.agentReraNoType || undefined
                            : null
                        }
                        optionFilterProp="label"
                        onChange={(value) =>
                          setBuilderDetails((prev) => ({
                            ...prev,
                            builderReraNoType: value,
                          }))
                        }
                        options={[
                          {
                            value:
                              "(ANRA) - Andaman and Nicobar Islands Real Estate Regulatory Authority",
                            label:
                              "(ANRA) - Andaman and Nicobar Islands Real Estate Regulatory Authority",
                          },
                          {
                            value:
                              "(APRERA) - Arunachal Pradesh Real Estate Regulatory Authority",
                            label:
                              "(APRERA) - Arunachal Pradesh Real Estate Regulatory Authority",
                          },
                          {
                            value:
                              "(APTIDCO) - Andhra Pradesh Township and Infrastructure Development Corporation",
                            label:
                              "(APTIDCO) - Andhra Pradesh Township and Infrastructure Development Corporation",
                          },
                          {
                            value:
                              "(AUIIP) - Assam Urban Infrastructure Investment Program",
                            label:
                              "(AUIIP) - Assam Urban Infrastructure Investment Program",
                          },
                          {
                            value:
                              "(BUIDCO) - Bihar Urban Infrastructure Development Corporation",
                            label:
                              "(BUIDCO) - Bihar Urban Infrastructure Development Corporation",
                          },
                          {
                            value:
                              "(Bihar RERA) - Bihar Real Estate Regulatory Authority",
                            label:
                              "(Bihar RERA) - Bihar Real Estate Regulatory Authority",
                          },
                          {
                            value: "(CGHB) - Chhattisgarh Housing Board",
                            label: "(CGHB) - Chhattisgarh Housing Board",
                          },
                          {
                            value: "(CHB) - Chandigarh Housing Board",
                            label: "(CHB) - Chandigarh Housing Board",
                          },
                          {
                            value: "(DDA) - Delhi Development Authority",
                            label: "(DDA) - Delhi Development Authority",
                          },
                          {
                            value:
                              "(DNHDD RERA) - Dadra and Nagar Haveli and Daman and Diu Real Estate Regulatory Authority",
                            label:
                              "(DNHDD RERA) - Dadra and Nagar Haveli and Daman and Diu Real Estate Regulatory Authority",
                          },
                          {
                            value:
                              "(HUDA) - Haryana Urban Development Authority",
                            label:
                              "(HUDA) - Haryana Urban Development Authority",
                          },
                          {
                            value:
                              "(HIMUDA) - Himachal Pradesh Housing and Urban Development Authority",
                            label:
                              "(HIMUDA) - Himachal Pradesh Housing and Urban Development Authority",
                          },
                          {
                            value:
                              "(MHADA) - Maharashtra Housing and Area Development Authority",
                            label:
                              "(MHADA) - Maharashtra Housing and Area Development Authority",
                          },
                          {
                            value:
                              "(MUDA) - Meghalaya Urban Development Authority",
                            label:
                              "(MUDA) - Meghalaya Urban Development Authority",
                          },
                          {
                            value:
                              "(PUDA) - Punjab Urban Planning and Development Authority",
                            label:
                              "(PUDA) - Punjab Urban Planning and Development Authority",
                          },
                          {
                            value:
                              "(UHUDA) - Uttarakhand Housing and Urban Development Authority",
                            label:
                              "(UHUDA) - Uttarakhand Housing and Urban Development Authority",
                          },
                          {
                            value:
                              "(WBHIDCO) - West Bengal Housing Infrastructure Development Corporation",
                            label:
                              "(WBHIDCO) - West Bengal Housing Infrastructure Development Corporation",
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="utr-form-category">
                  <h4>Where your firm is located?</h4>
                  <div className="utr-form-inputs">
                    <div className="utr-form-input utr-form-input-flex">
                      <textarea
                        type="text"
                        placeholder="Builder Firm Address"
                        value={
                          userType === "Builder"
                            ? builderDetails.builderAddress
                            : userType === "Agent"
                            ? agentDetails.agentAddress
                            : null
                        }
                        onChange={
                          userType === "Builder"
                            ? handleBuilderAddressChange
                            : userType === "Agent"
                            ? handleagentAddressChange
                            : null
                        }
                        className={
                          userType === "Builder"
                            ? builderAddressCharacterCount ||
                              builderDetails?.builderAddress?.length === 100
                              ? "utr-form-input-max-limit-reached"
                              : builderDetails.builderAddress.trim() !== ""
                              ? "utr-form-input-selected"
                              : ""
                            : userType === "Agent"
                            ? agentAddressCharacterCount ||
                              agentDetails?.agentAddress?.length === 100
                              ? "utr-form-input-max-limit-reached"
                              : agentDetails.agentAddress.trim() !== ""
                              ? "utr-form-input-selected"
                              : ""
                            : ""
                        }
                      />
                      <div
                        className={
                          userType === "Builder"
                            ? builderAddressCharacterCount ||
                              builderDetails?.builderAddress?.length === 100
                              ? "utr-form-input-sub-max-limit-reached"
                              : "utr-form-input-sub"
                            : userType === "Agent"
                            ? agentAddressCharacterCount ||
                              agentDetails?.agentAddress?.length === 100
                              ? "utr-form-input-sub-max-limit-reached"
                              : "utr-form-input-sub"
                            : "utr-form-input-sub"
                        }
                      >
                        {
                          <span>
                            {userType === "Builder"
                              ? builderAddressSpanContent
                              : userType === "Agent"
                              ? agentAddressSpanContent
                              : null}
                          </span>
                        }
                        {
                          <span>
                            {userType === "Builder"
                              ? builderAddressCharacterCount ||
                                builderDetails?.builderAddress?.length
                              : userType === "Agent"
                              ? agentAddressCharacterCount ||
                                agentDetails?.agentAddress?.length
                              : null}{" "}
                            / 100
                          </span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="utr-form-category">
                  <h4>Builder Photos</h4>
                  <div className="utr-form-images">
                    <ImageUpload
                      image={
                        userType === "Builder"
                          ? builderDetails.builderFirmLogo
                          : userType === "Agent"
                          ? agentDetails.agentFirmLogo
                          : null
                      }
                      onImageChange={(e) => {
                        if (userType === "Builder") {
                          setBuilderDetails((prev) => ({
                            ...prev,
                            builderFirmLogo: e.target.files[0],
                          }));

                          if (
                            typeof builderDetails.builderFirmLogo !== "string"
                          ) {
                            setShowSaveUserDetailsBtn(true);
                          }
                        } else if (userType === "Agent") {
                          setAgentDetails((prev) => ({
                            ...prev,
                            agentFirmLogo: e.target.files[0],
                          }));

                          if (typeof agentDetails.agentFirmLogo !== "string") {
                            setShowSaveUserDetailsBtn(true);
                          }
                        }
                      }}
                      onRemove={() => {
                        if (userType === "Builder") {
                          setBuilderDetails((prev) => ({
                            ...prev,
                            builderFirmLogo: null,
                          }));
                          setShowSaveUserDetailsBtn(false);
                        } else if (userType === "Agent") {
                          setAgentDetails((prev) => ({
                            ...prev,
                            agentFirmLogo: null,
                          }));
                          setShowSaveUserDetailsBtn(false);
                        }
                      }}
                      label="Firm Logo"
                    />
                  </div>
                </div>
              </div>
            )}
            {selectedSection === "Office Details" && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h4>{userType} Office Details</h4>
                  <div className="utr-form-inputs">
                    <FormInput
                      icon={TbBuildingEstate}
                      placeholder="Office Name"
                      value={
                        userType === "Builder"
                          ? builderDetails.builderOfficeName
                          : userType === "Agent"
                          ? agentDetails.agentOfficeName
                          : null
                      }
                      onChange={(e) =>
                        userType === "Builder"
                          ? setBuilderDetails((prev) => ({
                              ...prev,
                              builderOfficeName: e.target.value,
                            }))
                          : userType === "Agent"
                          ? setAgentDetails((prev) => ({
                              ...prev,
                              agentOfficeName: e.target.value,
                            }))
                          : null
                      }
                    />
                    <div className="utr-form-input">
                      <PiCity className="utr-form-input-icon" />
                      <Select
                        showSearch
                        className="utr-form-input-select"
                        placeholder="Select a city"
                        value={
                          userType === "Builder"
                            ? builderDetails.builderOfficeCity || undefined
                            : userType === "Agent"
                            ? agentDetails.agentOfficeCity || undefined
                            : null
                        }
                        optionFilterProp="label"
                        onChange={(value) =>
                          userType === "Builder"
                            ? setBuilderDetails((prev) => ({
                                ...prev,
                                builderOfficeCity: value,
                              }))
                            : userType === "Agent"
                            ? setAgentDetails((prev) => ({
                                ...prev,
                                agentOfficeCity: value,
                              }))
                            : null
                        }
                        options={cityOptions.map((option) => ({
                          value: option.cityName,
                          label: option.cityName,
                        }))}
                      />
                    </div>
                    <FormInput
                      icon={PiBracketsCurlyBold}
                      placeholder="Website Url (ex. https://proptzo.com/)"
                      value={
                        userType === "Builder"
                          ? builderDetails.builderOfficeWebsite
                          : userType === "Agent"
                          ? agentDetails.agentOfficeWebsite
                          : null
                      }
                      onChange={(e) =>
                        userType === "Builder"
                          ? setBuilderDetails((prev) => ({
                              ...prev,
                              builderOfficeWebsite: e.target.value,
                            }))
                          : userType === "Agent"
                          ? setAgentDetails((prev) => ({
                              ...prev,
                              agentOfficeWebsite: e.target.value,
                            }))
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {selectedSection === "Employees" && (
              <>
                {userType !== "Agent" && (
                  <div className="utr-form-sub">
                    {builderAddedEmployeesList.length === 0 && (
                      <div className="utr-form-category">
                        <h4>0 employees added.</h4>
                      </div>
                    )}
                    {builderAddedEmployeesList.length > 0 && (
                      <>
                        <div className="utr-form-category">
                          <h4>
                            {builderAddedEmployeesList.length === 1
                              ? "Added Employee"
                              : "Added Employees"}{" "}
                            ({builderAddedEmployeesList.length})
                          </h4>
                          <List
                            itemLayout="horizontal"
                            dataSource={builderAddedEmployeesList}
                            renderItem={(item, index) => (
                              <List.Item
                                key={index}
                                actions={[
                                  <h5
                                    className="utr-employees-remove"
                                    onClick={() => removeBuilderEmployee(index)}
                                  >
                                    edit
                                  </h5>,
                                  <h5
                                    className="utr-employees-remove red"
                                    onClick={() => removeBuilderEmployee(index)}
                                  >
                                    remove
                                  </h5>,
                                ]}
                              >
                                <List.Item.Meta
                                  avatar={
                                    <img
                                      src={renderBuilderEmployeeImage(
                                        item.employeeImage,
                                        item.employeeName.charAt(0)
                                      )}
                                      alt="Profile"
                                      className="utr-employees-img"
                                    />
                                  }
                                  title={
                                    <div className="utr-employees-name">
                                      {item.employeeName}
                                    </div>
                                  }
                                  description={
                                    <div>
                                      <div className="utr-employees-description">
                                        {item.employeeDesignation} /{" "}
                                        {item.employeeEmail} /{" "}
                                        {item.employeeMobileNo} /{" "}
                                        {item.employeeID}
                                      </div>
                                      {item.employeementLetterImage ? (
                                        <img
                                          src={renderBuilderEmployeeImage(
                                            item.employeementLetterImage,
                                            "EL"
                                          )}
                                          alt="Employment Letter"
                                          className="utr-employees-img-docs"
                                        />
                                      ) : (
                                        <img
                                          src={`https://placehold.co/30x30?text=EL`}
                                          alt="Employment Letter Placeholder"
                                          className="utr-employees-img-docs"
                                        />
                                      )}
                                      {item.employeeIdCardImage ? (
                                        <img
                                          src={renderBuilderEmployeeImage(
                                            item.employeeIdCardImage,
                                            "ID"
                                          )}
                                          alt="ID Card"
                                          className="utr-employees-img-docs"
                                        />
                                      ) : (
                                        <img
                                          src={`https://placehold.co/30x30?text=ID`}
                                          alt="ID Card Placeholder"
                                          className="utr-employees-img-docs"
                                        />
                                      )}
                                    </div>
                                  }
                                />
                              </List.Item>
                            )}
                          />
                        </div>
                        <div className="utr-form-category">
                          <h4>Note</h4>
                          <h6>
                            The added employee profiles are automatically
                            created with these credentials. Review carefully, as
                            employees will see these details upon login and will
                            not be able to change them.
                          </h6>
                        </div>
                      </>
                    )}
                    <div className="utr-form-category utr-form-category-last">
                      <h4>Add New Employee</h4>
                      <Collapse
                        // ghost
                        items={builderEmployeesCollapse}
                        bordered={true}
                      />
                    </div>
                  </div>
                )}
                {userType !== "Builder" && (
                  <div className="utr-form-sub">
                    {agentAddedEmployeesList.length === 0 && (
                      <div className="utr-form-category">
                        <h4>0 employees added.</h4>
                      </div>
                    )}
                    {agentAddedEmployeesList.length > 0 && (
                      <>
                        <div className="utr-form-category">
                          <h4>
                            {agentAddedEmployeesList.length === 1
                              ? "Added Employee"
                              : "Added Employees"}{" "}
                            ({agentAddedEmployeesList.length})
                          </h4>
                          <List
                            itemLayout="horizontal"
                            dataSource={agentAddedEmployeesList}
                            renderItem={(item, index) => (
                              <List.Item
                                key={index}
                                actions={[
                                  <h5
                                    className="utr-employees-remove"
                                    onClick={() => removeAgentEmployee(index)}
                                  >
                                    edit
                                  </h5>,
                                  <h5
                                    className="utr-employees-remove red"
                                    onClick={() => removeAgentEmployee(index)}
                                  >
                                    remove
                                  </h5>,
                                ]}
                              >
                                <List.Item.Meta
                                  avatar={
                                    <img
                                      src={renderAgentEmployeeImage(
                                        item.employeeImage,
                                        item.employeeName.charAt(0)
                                      )}
                                      alt="Profile"
                                      className="utr-employees-img"
                                    />
                                  }
                                  title={
                                    <div className="utr-employees-name">
                                      {item.employeeName}
                                    </div>
                                  }
                                  description={
                                    <div>
                                      <div className="utr-employees-description">
                                        {item.employeeDesignation} /{" "}
                                        {item.employeeEmail} /{" "}
                                        {item.employeeMobileNo} /{" "}
                                        {item.employeeID}
                                      </div>
                                      {item.employeementLetterImage ? (
                                        <img
                                          src={renderAgentEmployeeImage(
                                            item.employeementLetterImage,
                                            "EL"
                                          )}
                                          alt="Employment Letter"
                                          className="utr-employees-img-docs"
                                        />
                                      ) : (
                                        <img
                                          src={`https://placehold.co/30x30?text=EL`}
                                          alt="Employment Letter Placeholder"
                                          className="utr-employees-img-docs"
                                        />
                                      )}
                                      {item.employeeIdCardImage ? (
                                        <img
                                          src={renderAgentEmployeeImage(
                                            item.employeeIdCardImage,
                                            "ID"
                                          )}
                                          alt="ID Card"
                                          className="utr-employees-img-docs"
                                        />
                                      ) : (
                                        <img
                                          src={`https://placehold.co/30x30?text=ID`}
                                          alt="ID Card Placeholder"
                                          className="utr-employees-img-docs"
                                        />
                                      )}
                                    </div>
                                  }
                                />
                              </List.Item>
                            )}
                          />
                        </div>
                        <div className="utr-form-category">
                          <h4>Note</h4>
                          <h6>
                            The added employee profiles are automatically
                            created with these credentials. Review carefully, as
                            employees will see these details upon login and will
                            not be able to change them.
                          </h6>
                        </div>
                      </>
                    )}
                    <div className="utr-form-category utr-form-category-last">
                      <h4>Add New Employee</h4>
                      <Collapse
                        // ghost
                        items={agentEmployeesCollapse}
                        bordered={true}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            {selectedSection === "Preferences" && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h4>Preferences Coming Soon! Check Back Later.</h4>
                </div>
              </div>
            )}
          </div>
          {showSaveUserDetailsBtn && (
            <div className="utr-footer">
              <button
                className="button"
                onClick={updateProfile}
                disabled={buttonLoading || loading}
              >
                {buttonLoading ? <LoadingOutlined /> : "Save"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
