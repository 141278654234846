import React from "react";
import "./AdminSettings.css";

function AdminSettings() {
  return (
    <div className="adashboard">
      <div className="adashboard-sub">Admin Settings</div>
    </div>
  );
}

export default AdminSettings;
