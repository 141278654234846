const citiesData = [
  {
    city: "Agra",
    geoCode: [27.181218354714616, 78.01417350469912],
    state: "Uttar Pradesh",
  },
  {
    city: "Ahmadnagar",
    geoCode: [19.145235595125218, 74.69214092751217],
    state: "Maharashtra",
  },
  {
    city: "Ahmedabad",
    geoCode: [23.0637199785994, 72.54933934111328],
    state: "Gujarat",
  },
  {
    city: "Aluva",
    geoCode: [10.047880690198609, 76.24842097495936],
    state: "Kerela",
  },
  {
    city: "Amritsar",
    geoCode: [31.657074173843544, 74.87309038838885],
    state: "Punjab",
  },
  {
    city: "Aurangabad",
    geoCode: [19.82983291974715, 75.28976662228227],
    state: "Maharashtra",
  },
  {
    city: "Badlapur",
    geoCode: [19.207316535146873, 73.18697723671666],
    state: "Maharashtra",
  },
  {
    city: "Bengaluru",
    geoCode: [12.905383868013015, 77.41602475885742],
    state: "Karnataka",
  },
  {
    city: "Bareilly",
    geoCode: [28.380444606080616, 79.38367931595273],
    state: "Uttar Pradesh",
  },
  {
    city: "Belagavi",
    geoCode: [15.849710565935245, 74.49909698078147],
    state: "Karnataka",
  },
  {
    city: "Bhiwadi",
    geoCode: [28.190164643467753, 76.83129871987792],
    state: "Rajasthan",
  },
  {
    city: "Bhopal",
    geoCode: [23.21551680937394, 77.39638508185446],
    state: "Madhya Pradesh",
  },
  {
    city: "Bhubaneswar",
    geoCode: [20.290346610657064, 85.63255020262491],
    state: "Odisha",
  },
  {
    city: "Bokaro Steel City",
    geoCode: [23.674235603546336, 86.1433642762864],
  },
  {
    city: "Chandigarh",
    geoCode: [30.73388025043733, 76.7717382502207],
    state: "Chandigarh",
  },
  {
    city: "Chengalpattu",
    geoCode: [12.768642153950609, 80.03576273178324],
    state: "Tamil Nadu",
  },
  {
    city: "Chennai",
    geoCode: [13.016650245601442, 80.23358097805438],
    state: "Tamil Nadu",
  },
  {
    city: "Coimbatore",
    geoCode: [11.019417831107779, 76.94881304783631],
    state: "Tamil Nadu",
  },
  {
    city: "Dehradun",
    geoCode: [30.48758725894378, 77.89587102540172],
    state: "Uttarakhand",
  },
  {
    city: "Delhi",
    geoCode: [28.722297910452117, 77.08382440525793],
    state: "Delhi",
  },
  {
    city: "Durgapur",
    geoCode: [23.487174074588992, 87.31706706172736],
    state: "West Bengal",
  },
  {
    city: "Ernakulam",
    geoCode: [10.153094432295012, 76.08226289533545],
    state: "Kerela",
  },
  {
    city: "Erode",
    geoCode: [11.457534337251497, 77.6562135746097],
    state: "Tamil Nadu",
  },
  {
    city: "Faridabad",
    geoCode: [28.414571890053185, 77.29591078944728],
    state: "Haryana",
  },
  {
    city: "Ghaziabad",
    geoCode: [28.660406930264127, 77.45053043024289],
    state: "Uttar Pardesh",
  },
  {
    city: "Goa",
    geoCode: [15.29847620526536, 74.12683670953972],
    state: "Goa",
  },
  {
    city: "Gorakhpur",
    geoCode: [26.799746772042703, 83.29830347333301],
    state: "Uttar Pradesh",
  },
  {
    city: "Greater Noida",
    geoCode: [28.474528471569368, 77.5040324558658],
    state: "Uttar Pradesh",
  },
  {
    city: "Guntur",
    geoCode: [16.32032692602733, 80.36671002089152],
    state: "Andhra Pradesh",
  },
  {
    city: "Guwahati",
    geoCode: [26.13159230805564, 91.51367278073633],
    state: "Assam",
  },
  {
    city: "Gurgaon",
    geoCode: [28.45206659562221, 77.0108715024326],
    state: "Haryana",
  },
  {
    city: "Gwalior",
    geoCode: [26.179057398533995, 78.13973754112638],
    state: "Madhya Pradesh",
  },
  {
    city: "Haridwar",
    geoCode: [29.947947317983683, 78.13741793850045],
    state: "Uttarakhand",
  },
  {
    city: "Hosur",
    geoCode: [12.734215556209332, 77.83132535385086],
    state: "Tamil Nadu",
  },
  {
    city: "Hubballi",
    geoCode: [15.332629039936204, 75.17899081724194],
    state: "Karnatka",
  },
  {
    city: "Hyderabad",
    geoCode: [17.35265730236181, 78.49078673827908],
    state: "Telangana",
  },
  {
    city: "Indore",
    geoCode: [22.74617644623904, 75.84790884143077],
    state: "Madhya Pradesh",
  },
  {
    city: "Jaipur",
    geoCode: [26.928283786519888, 75.78674122363391],
    state: "Rajasthan",
  },
  {
    city: "Jabalpur",
    geoCode: [23.183888525052826, 79.92626360571101],
    state: "Madhya Pradesh",
  },
  {
    city: "Jalandhar",
    geoCode: [31.31650081463251, 75.57566697825474],
    state: "Punjab",
  },
  {
    city: "Jammu",
    geoCode: [32.74728313284166, 74.85029352745806],
    state: "Jammu",
  },
  {
    city: "Jamshedpur",
    geoCode: [22.91610741981519, 86.1871266052691],
    state: "Jharkhand",
  },
  {
    city: "Jodhpur",
    geoCode: [26.233154184658655, 73.02656085494239],
    state: "Rajasthan",
  },
  {
    city: "Kalyan",
    geoCode: [19.27828293224415, 73.16202025015805],
    state: "Maharashtra",
  },
  {
    city: "Kannur",
    geoCode: [11.95619948109536, 75.4130961760963],
    state: "Kerela",
  },
  {
    city: "Kanpur",
    geoCode: [26.47735009573074, 80.32864003915925],
    state: "Uttar Pradesh",
  },
  {
    city: "Khopoli",
    geoCode: [18.785112713968736, 73.33227635653911],
    state: "Maharashtra",
  },
  {
    city: "Kochi",
    geoCode: [10.039263592321452, 76.38981450368152],
    state: "Kerela",
  },
  {
    city: "Kodaikanal",
    geoCode: [10.242090050803808, 77.48022878156208],
    state: "Tamil Nadu",
  },
  {
    city: "Kolkata",
    geoCode: [22.73633497383968, 88.37359401533247],
    state: "West Bengal",
  },
  {
    city: "Kottayam",
    geoCode: [9.481460934502682, 76.37962617839857],
    state: "Kerela",
  },
  {
    city: "Kozhikode",
    geoCode: [11.28862986461277, 75.85068317033993],
    state: "Kerela",
  },
  {
    city: "Lucknow",
    geoCode: [26.963274386112516, 80.98868528910386],
    state: "Uttar Pradesh",
  },
  {
    city: "Lonavala",
    geoCode: [18.777983455393134, 73.42942284751994],
    state: "Maharashtra",
  },
  {
    city: "Ludhiana",
    geoCode: [30.90484857065382, 75.84827545552373],
    state: "Punjab",
  },
  {
    city: "Madurai",
    geoCode: [9.781008968633227, 78.09389887279819],
    state: "Tamil nadu",
  },
  {
    city: "Mangaluru",
    geoCode: [13.042929335408404, 74.89528906854139],
    state: "Karnatka",
  },
  {
    city: "Mohali (SAS Nagar)",
    geoCode: [30.704817116254226, 76.71729309832551],
    state: "Punjab",
  },
  {
    city: "Mumbai",
    geoCode: [19.0493139235991, 72.93089724318715],
    state: "Maharashtra",
  },
  {
    city: "Mysuru",
    geoCode: [12.390534341504049, 76.64672506887945],
    state: "Karnatka",
  },
  {
    city: "Nagpur",
    geoCode: [21.14619846967737, 79.10680364289365],
    state: "Maharashtra",
  },
  {
    city: "Nainital",
    geoCode: [29.387696153291465, 79.50504031685675],
    state: "Uttarakhand",
  },
  {
    city: "Nanded",
    geoCode: [19.13186791712154, 77.36495594767257],
    state: "Maharashtra",
  },
  {
    city: "Nashik",
    geoCode: [20.01229842492509, 73.71572975693812],
    state: "Maharashtra",
  },
  {
    city: "Navsari",
    geoCode: [20.960865109477663, 72.94462583491429],
    state: "Gujarat",
  },
  {
    city: "Navi Mumbai",
    geoCode: [19.039699236920555, 73.07408391785725],
    state: "Maharashtra",
  },
  {
    city: "Nellore",
    geoCode: [14.416584716560164, 80.01850349558656],
    state: "Andhra Pradesh",
  },
  {
    city: "New Delhi",
    geoCode: [28.5958016949633, 77.20703876429873],
    state: "Delhi",
  },
  {
    city: "Newtown",
    geoCode: [22.567517125684287, 88.46781758799702],
    state: "Kolkata, West Bengal",
  },
  {
    city: "Noida",
    geoCode: [28.54317305134126, 77.38036839830735],
    state: "Uttar Pradesh",
  },
  {
    city: "Ooty",
    geoCode: [11.49013951675598, 76.6015002263411],
    state: "Tamil Nadu",
  },
  {
    city: "Palakkad",
    geoCode: [10.73495508338662, 76.6545426119493],
    state: "Kerela",
  },
  {
    city: "Palghar",
    geoCode: [19.632311385820024, 72.76661338332036],
    state: "Maharashtra",
  },
  {
    city: "Panchkula",
    geoCode: [30.694326313616664, 76.86020341415112],
    state: "Haryana",
  },
  {
    city: "Patiala",
    geoCode: [30.3432130561659, 76.39003993636443],
    state: "Punjab",
  },
  {
    city: "Patna",
    geoCode: [25.617576691164878, 84.99882650544001],
    state: "Bihar",
  },
  {
    city: "Puducherry",
    geoCode: [12.011282682570274, 80.04436666053367],
    state: "Puducherry",
  },
  {
    city: "Pune",
    geoCode: [18.540096336981183, 73.83440216233274],
    state: "Maharashtra",
  },
  {
    city: "Prayagraj",
    geoCode: [25.427830892726245, 81.80950622207642],
    state: "Uttar Pradesh",
  },
  {
    city: "Raipur",
    geoCode: [21.256706154129073, 81.60623906568091],
    state: "Chhattisgarh",
  },
  {
    city: "Rajamahendravaram",
    geoCode: [17.085082663040158, 81.8454144833329],
    state: "Andhra Pradesh",
  },
  {
    city: "Rajkot",
    geoCode: [22.369996094325995, 70.77681256851193],
    state: "Gujarat",
  },
  {
    city: "Ranchi",
    geoCode: [23.448507334781677, 85.46225348236166],
    state: "Jharkhand",
  },
  {
    city: "Salem",
    geoCode: [11.678073128221262, 78.15019547144199],
    state: "Tamil nadu",
  },
  {
    city: "Satara",
    geoCode: [17.707609570415286, 74.0553896219469],
    state: "Maharashtra",
  },
  {
    city: "Shimla",
    geoCode: [31.104463224938637, 77.1710627836215],
    state: "Himachal Pradesh",
  },
  {
    city: "Siliguri",
    geoCode: [26.752498673703233, 88.4308873684317],
    state: "West Bengal",
  },
  {
    city: "Solapur",
    geoCode: [17.701409165695264, 75.83442832081967],
    state: "Maharashtra",
  },
  {
    city: "Sonipat",
    geoCode: [28.997030900746765, 77.00983562818953],
    state: "Haryana",
  },
  {
    city: "Srinagar",
    geoCode: [34.09843742322892, 74.80186489580556],
    state: "Srinagar",
  },

  {
    city: "Surat",
    geoCode: [21.135094972463165, 72.82226019627922],
    state: "Gujarat",
  },
  {
    city: "Thane",
    geoCode: [19.199412029367185, 73.04077074616809],
    state: "Maharashtra",
  },
  {
    city: "Thanjavur",
    geoCode: [10.915460771653029, 79.024202867989],
    state: "Tamil Nadu",
  },
  {
    city: "Thrissur",
    geoCode: [10.564209157902663, 76.18814708115144],
    state: "Kerela",
  },
  {
    city: "Tirunelveli",
    geoCode: [8.724317687307995, 77.71824752010235],
    state: "Tamil Nadu",
  },
  {
    city: "Tirupati",
    geoCode: [13.692062125057793, 79.35220747784406],
    state: "Andhra Pradesh",
  },
  {
    city: "Tirupur",
    geoCode: [11.124171942269475, 77.31414711477724],
    state: "Tamil nadu",
  },
  {
    city: "Tiruchirappalli",
    geoCode: [10.876510431261602, 78.68064235960722],
    state: "Tamil Nadu",
  },
  {
    city: "Thiruvananthapuram",
    geoCode: [8.533448118901665, 77.06608042555764],
    state: "Kerela",
  },
  {
    city: "Tumakuru",
    geoCode: [13.339296352211598, 77.1133519408619],
    state: "Karnataka",
  },
  {
    city: "Udaipur",
    geoCode: [24.585429126281547, 73.71399983769052],
    state: "Rajasthan",
  },
  {
    city: "Udupi",
    geoCode: [13.435370080667841, 74.7648583533291],
    state: "Karnataka",
  },
  {
    city: "Varanasi",
    geoCode: [25.340741000461644, 82.96653652737585],
    state: "Uttar Pradesh",
  },
  {
    city: "Vadodara",
    geoCode: [22.293858077633537, 73.18024229806608],
    state: "Gujarat",
  },
  {
    city: "Vapi",
    geoCode: [20.312386418807073, 72.93338549843692],
    state: "Gujarat",
  },
  {
    city: "Vijayawada",
    geoCode: [16.508304492218624, 80.67414912205018],
    state: "Andhra Pradesh",
  },
  {
    city: "Visakhapatnam",
    geoCode: [17.70893740238761, 83.22772374341348],
    state: "Andhra Pradesh",
  },
  {
    city: "Vrindavan",
    geoCode: [27.575306091117273, 77.66491978576727],
    state: "Uttar Pradesh",
  },
  {
    city: "Zirakpur",
    geoCode: [30.64298026155063, 76.8163319746589],
    state: "Punjab",
  },
];

export default citiesData;
