import z from 'zod';

export const addProjectSchema = z.object({
    projectName: z.string().min(1).optional(),
    projectReraNo: z.string().min(1).optional(),
    projectDescription: z.string().min(200).optional(),
    projectStatus: z.string().min(1).optional(),
    projectCompletionYear: z.string().min(1).optional(),
    projectOwnershipType: z.string().min(1).optional(),
    projectArea: z.string().min(1).optional(),
    projectAreaUnit: z.string().min(1).optional(),
    projectPriceRangePriceFrom: z.string().min(1).optional(),
    projectPriceRangePriceTo: z.string().min(1).optional(),
    projectCurrencyType: z.string().min(1).optional(),
    projectCity: z.string().min(1).optional(),
    projectLocality: z.string().min(1).optional(),
  });