import {atom} from "recoil";

export const selectedPropertyFilters = atom({
    key:"selectedPropertyFilters",
    default:{
        status: "",
        floorPlan: "",
        default:"All"
    }
})

export const filteredPropertiesCount = atom({
    key:"filteredPropertiesCount",
    default:0
})