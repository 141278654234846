import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import axios from "axios";

// STYLING IMPORT
import "./ViewProperty.css";
import "../ViewProjects/ViewProjects.css";

// SVGS ASSETS IMPORT
import logo from "../../assets/svgs/main-logo.svg";
import WhatsappSvg from "../../assets/svgs/socials/whatsapp.svg";
import FacebookSvg from "../../assets/svgs/socials/facebook.svg";
import InstagramSvg from "../../assets/svgs/socials/instagram.svg";
import LinkedinSvg from "../../assets/svgs/socials/linkedin.svg";

// ANT DESIGN IMPORTS
import { Flex, Rate, Modal, Radio, Checkbox, message } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  CloseOutlined,
  EnvironmentOutlined,
  ShareAltOutlined,
  HeartOutlined,
  PrinterOutlined,
  MailOutlined,
  ExpandAltOutlined,
  FilePdfOutlined,
  VerticalAlignBottomOutlined,
  PushpinOutlined,
  HeartFilled,
  LinkOutlined,
  SafetyCertificateOutlined,
  EyeOutlined,
  TrademarkOutlined,
} from "@ant-design/icons";

// REACT ICONS
import { PiBedDuotone, PiBathtubDuotone, PiHandTapLight } from "react-icons/pi";
import { IoLockClosedOutline } from "react-icons/io5";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { AiOutlineCustomerService } from "react-icons/ai";
import { MdBalcony, MdEdit } from "react-icons/md";
import { FaKitchenSet } from "react-icons/fa6";
import { LuBuilding, LuBedDouble } from "react-icons/lu";
import { CiCalendarDate } from "react-icons/ci";
import { BsBuildingCheck } from "react-icons/bs";
import {
  FaCar,
  FaBatteryFull,
  FaShieldAlt,
  FaSwimmingPool,
  FaLeaf,
  FaDumbbell,
  FaChild,
  FaBuilding,
  FaCoffee,
  FaGolfBall,
  FaUsers,
  FaFutbol,
  FaTint,
  FaPhoneAlt,
  FaRunning,
  FaChess,
  FaTools,
  FaHome,
  FaCompass,
  FaShoppingCart,
  FaSchool,
  FaHospital,
  FaMoneyCheckAlt,
  FaPray,
  FaBook,
  FaWarehouse,
  FaBroom,
} from "react-icons/fa";

// PRICE FORMATTER
import PriceFormatter from "../../components/PriceFormatter/PriceFormatter";

// LEAFLET MAP INTEGRATION
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import locationPin from "../../assets/svgs/locpin.svg";
import L from "leaflet";

// RECOIL INTEGRATION
import { useSetRecoilState } from "recoil";
import { fetchWishlistAtom } from "../../store/atoms/updateWIshlist";

function ViewProperty() {
  useEffect(() => {
    document.title = "Proptzo - View Property Details";
  }, []);

  const baseURL = process.env.REACT_APP_base_URL;
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const { postId } = useParams();

  // const [isLoading, setIsLoading] = useState(true);

  // Image Modals
  const [currentGroup, setCurrentGroup] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");
  const [modalGroup, setModalGroup] = useState(0);
  const [modalImageIndex, setModalImageIndex] = useState(0);

  // Data Fetching States & Api's
  const [fetchedPosts, setFetchedPosts] = useState([]);
  const [fetchedUserData, setFetchedUserData] = useState(null);
  const [fetchedUserProfileData, setFetchedUserProfileData] = useState(null);
  const [isPostdataFethced, setIsPostdataFethced] = useState(false);
  const [propertyViews, setPropertyViews] = useState(0);

  const [base64PropertyImages, setBase64PropertyImages] = useState(null);
  // Function to distribute image paths into sub-arrays of 3
  function distributeImages(imagePaths, subArraySize = 3) {
    const distributedImages = [];
    // Handle edge case where there are fewer than subArraySize elements
    if (imagePaths?.length < subArraySize) {
      distributedImages.push(imagePaths); // Add the remaining images as a single sub-array
    } else {
      for (let i = 0; i < imagePaths?.length; i += subArraySize) {
        const subArray = imagePaths?.slice(i, i + subArraySize);
        distributedImages.push(subArray);
      }
    }
    return setBase64PropertyImages(distributedImages);
  }

  useMemo(() => {
    distributeImages(fetchedPosts?.propertyImages);
  }, [fetchedPosts?.propertyImages]);

  const totalImages = base64PropertyImages?.flat()?.length;
  const handlePrevClick = () => {
    setCurrentGroup((prevGroup) =>
      prevGroup === 0 ? base64PropertyImages.length - 1 : prevGroup - 1
    );
  };
  const handleNextClick = () => {
    setCurrentGroup((prevGroup) =>
      prevGroup === base64PropertyImages.length - 1 ? 0 : prevGroup + 1
    );
  };
  const openModal = (src, group, index) => {
    setModalImageSrc(src);
    setModalGroup(group);
    setModalImageIndex(index);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setModalImageSrc("");
    setModalImageIndex(0);
  };
  const handleModalImageChange = (direction) => {
    if (direction === "prev") {
      setModalGroup((prevGroup) =>
        prevGroup === 0 ? base64PropertyImages.length - 1 : prevGroup - 1
      );
    } else if (direction === "next") {
      setModalGroup((prevGroup) =>
        prevGroup === base64PropertyImages.length - 1 ? 0 : prevGroup + 1
      );
    }
  };

  const renderImages = () => {
    const currentImages = base64PropertyImages?.[currentGroup];
    if (currentImages?.length === 1) {
      return (
        <div className="vp-images-sub vp-images-sub-onlyOne">
          <img
            src={`data:image/jpeg;base64,${currentImages[0]}`}
            alt="onlyoneimg"
            className="vp-image-center"
            onClick={() => openModal(currentImages[0], currentGroup, 0)}
          />
        </div>
      );
    } else if (currentImages?.length === 2) {
      return (
        <div className="vp-images-sub">
          <img
            src={`data:image/jpeg;base64,${currentImages[0]}`}
            alt="onlyoneimg"
            className="vp-image-center"
            onClick={() => openModal(currentImages[0], currentGroup, 0)}
          />
          <img
            src={`data:image/jpeg;base64,${currentImages[1]}`}
            alt="onlyoneimg"
            className="vp-image-center"
            onClick={() => openModal(currentImages[1], currentGroup, 1)}
          />
        </div>
      );
    } else {
      return (
        <div className="vp-images-sub">
          {currentImages?.map((src, index) => (
            <img
              key={index}
              src={`data:image/jpeg;base64,${src}`}
              alt={`propertyImg${currentGroup * 3 + index + 1}`}
              className={`vp-image-${
                index === 1 ? "center" : index === 0 ? "left" : "right"
              }`}
              onClick={() => openModal(src, currentGroup, index)}
            />
          ))}
        </div>
      );
    }
  };

  useEffect(() => {
    const fetchUserPosts = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/post/publicposts/${postId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        if (response.data) {
          setFetchedPosts(response.data);
          setIsPostdataFethced(true);
        }
      } catch (error) {
        if (
          error.response.data ||
          error.response.data.message === "Post not found"
        ) {
          navigate("/property-not-found");
        }
        console.error("Error fetching user posts:", error);
      }
    };

    if (!isPostdataFethced) {
      fetchUserPosts();
    }
  }, [postId, isPostdataFethced, baseURL]);

  useEffect(() => {
    const fetchUserData = async (_userId) => {
      try {
        const response = await axios.get(
          `${baseURL}/api/auth/getUserData/${_userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        setFetchedUserData(response.data.user);
        setFetchedUserProfileData(response.data.userProfile);
      } catch (error) {
        console.error("Error fetching user posts:", error);
      }
    };

    if (isPostdataFethced && fetchedPosts?.userId) {
      fetchUserData(fetchedPosts?.userId);
    }
  }, [baseURL, fetchedPosts?.userId, isPostdataFethced]);

  // UPDATING PROPERTY VIEW FUNCTIONALITY
  const updatePropertyViewCount = useCallback(
    async (postId, currentViewCount) => {
      try {
        const response = await axios.patch(
          `${baseURL}/api/post/updatePropertyViews/${postId}`,
          {
            currentViewCount,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        // Handle successful response (if needed)
        if (
          response.status === 200 ||
          response.statusText === "OK" ||
          response.data.message === "Property view incremented successfully"
        ) {
          let postIdArray = [];
          try {
            const history = localStorage.getItem("history");
            if (history) {
              postIdArray = JSON.parse(history);
            }
          } catch (error) {
            console.error("Error parsing history from localStorage:", error);
          }

          if (!postIdArray.includes(postId)) {
            if (postIdArray.length === 8) {
              postIdArray.shift();
            }
            postIdArray.push(postId);
          }

          try {
            localStorage.setItem("history", JSON.stringify(postIdArray));
          } catch (error) {
            console.error("Error storing history in localStorage:", error);
          }
        }
      } catch (error) {
        console.error("Error updating property view count:", error);
      }
    },
    [baseURL]
  );

  const getPropertyViewCount = useCallback(
    async (postId) => {
      try {
        const response = await axios.patch(
          `${baseURL}/api/post/getPropertyViews/${postId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        // Handle successful response (if needed)
        return response.data.propertyViews;
      } catch (error) {
        console.error("Error updating property view count:", error);
      }
    },
    [baseURL]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedViews = await getPropertyViewCount(postId);
        setPropertyViews(fetchedViews);
      } catch (error) {
        console.error("Error fetching property views:", error);
      }
    };
    fetchData();

    updatePropertyViewCount(postId, propertyViews);
  }, [getPropertyViewCount, updatePropertyViewCount, postId]);

  // Additional Rooms
  function conditionalAdditionalRooms(additionalRoom) {
    let icon;
    switch (additionalRoom) {
      case "Prayer Room":
        icon = <FaPray className="vp-pd-sub-icon" />;
        break;
      case "Study Room":
        icon = <FaBook className="vp-pd-sub-icon" />;
        break;
      case "Store Room":
        icon = <FaWarehouse className="vp-pd-sub-icon" />;
        break;
      case "Servant Room":
        icon = <FaBroom className="vp-pd-sub-icon" />;
        break;
      default:
        icon = null;
        break;
    }
    return (
      <div className="vp-pd-sub">
        {icon}
        <h5 className="vp-pd-sub-amenity">{additionalRoom}</h5>
      </div>
    );
  }

  // Amenities
  function conditionalAmenities(amenity) {
    let icon;
    switch (amenity) {
      case "Car Parking":
        icon = <FaCar className="vp-pd-sub-icon" />;
        break;
      case "Power Backup":
        icon = <FaBatteryFull className="vp-pd-sub-icon" />;
        break;
      case "24*7 Security":
        icon = <FaShieldAlt className="vp-pd-sub-icon" />;
        break;
      case "Swimming Pool":
        icon = <FaSwimmingPool className="vp-pd-sub-icon" />;
        break;
      case "Landscaped Gardens":
        icon = <FaLeaf className="vp-pd-sub-icon" />;
        break;
      case "Gym":
        icon = <FaDumbbell className="vp-pd-sub-icon" />;
        break;
      case "Children's Playarea":
        icon = <FaChild className="vp-pd-sub-icon" />;
        break;
      case "Club House":
        icon = <FaBuilding className="vp-pd-sub-icon" />;
        break;
      case "Cafeteria":
        icon = <FaCoffee className="vp-pd-sub-icon" />;
        break;
      case "Golf Course":
        icon = <FaGolfBall className="vp-pd-sub-icon" />;
        break;
      case "Multipurpose Room":
        icon = <FaUsers className="vp-pd-sub-icon" />;
        break;
      case "Sports Facility":
        icon = <FaFutbol className="vp-pd-sub-icon" />;
        break;
      case "Rainwater Harvesting":
        icon = <FaTint className="vp-pd-sub-icon" />;
        break;
      case "Intercom":
        icon = <FaPhoneAlt className="vp-pd-sub-icon" />;
        break;
      case "Jogging Track":
        icon = <FaRunning className="vp-pd-sub-icon" />;
        break;
      case "Indoor Games":
        icon = <FaChess className="vp-pd-sub-icon" />;
        break;
      case "Maintenance Staff":
        icon = <FaTools className="vp-pd-sub-icon" />;
        break;
      case "Staff Quarter":
        icon = <FaHome className="vp-pd-sub-icon" />;
        break;
      case "Vastu Compliant":
        icon = <FaCompass className="vp-pd-sub-icon" />;
        break;
      case "Shopping Mall":
        icon = <FaShoppingCart className="vp-pd-sub-icon" />;
        break;
      case "School":
        icon = <FaSchool className="vp-pd-sub-icon" />;
        break;
      case "Hospital":
        icon = <FaHospital className="vp-pd-sub-icon" />;
        break;
      case "Atm":
        icon = <FaMoneyCheckAlt className="vp-pd-sub-icon" />;
        break;
      default:
        icon = <PushpinOutlined className="vp-pd-sub-icon" />;
        break;
    }
    return (
      <div className="vp-pd-sub">
        {icon}
        <h5 className="vp-pd-sub-amenity">{amenity}</h5>
      </div>
    );
  }

  // Last Updated Date - Format - Month Date, Year
  const updatedDate = useMemo(() => {
    const formattedDate = new Date(fetchedPosts?.updatedAt).toLocaleDateString(
      "en-US",
      {
        year: "numeric",
        month: "long",
        day: "numeric",
      }
    );
    return formattedDate;
  }, [fetchedPosts?.updatedAt]);

  //Map Function & States
  // States for navigating the map to the selected city/state
  const [selectedCityCoordinates, setSelectedCityCoordinates] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [zoomLevel, setZoomLevel] = useState(16);
  const customIcon = L.icon({
    iconUrl: locationPin,
    iconSize: [40, 40], // Size of the icon
    iconAnchor: [16, 32], // Anchor point of the icon
  });
  // Base 64 Image Function
  const isBase64Image = (str) => {
    // Regular expression to match base64 image format
    const base64Regex = /^([A-Za-z0-9+/])+={0,2}$/;
    if(str){
      return base64Regex.test(str);
    }
  };
  const propertyImage =
    fetchedPosts?.propertyImages?.length > 0
      ? fetchedPosts.propertyImages[0]
      : "https://placehold.co/250x250?text=No Images ...";
  const propertyTitle = fetchedPosts?.propertyTitle;
  const propertyCurrencyType = fetchedPosts?.propertyCurrencyType;
  const propertyPrice = fetchedPosts?.propertyPrice;
  // useEffect to update the map view when selectedCityCoordinates changes
  useEffect(() => {
    if (
      selectedCityCoordinates.latitude === 0 &&
      selectedCityCoordinates.longitude === 0 &&
      fetchedPosts?.propertyLocation
    ) {
      const location = fetchedPosts?.propertyLocation;
      if (location && fetchedPosts) {
        setSelectedCityCoordinates({
          latitude: location?.latitude,
          longitude: location?.longitude,
        });
        // setIsLoading(false);
      }
    }
  }, [selectedCityCoordinates, fetchedPosts, fetchedPosts?.propertyLocation]);
  function ClickHandler() {
    const map = useMapEvents({});
    // Add a new useEffect to update zoom level when map zoom changes
    useEffect(() => {
      const handleZoomEnd = () => {
        if (map) {
          setZoomLevel(map.getZoom());
        }
      };
      if (map) {
        map.on("zoomend", handleZoomEnd);
      }
      // Cleanup function to remove the event listeners when component unmounts
      return () => {
        if (map) {
          map.off("zoomend", handleZoomEnd);
        }
      };
    }, [map]);
    return null;
  }
  // Reference for Leaflet Map Functionality
  const mapRef = useRef(null);
  useEffect(() => {
    // Access the map instance from the MapContainer component (assuming it's a parent)
    // Function to center the map on user's location (if available)
    const centerOnUserLocation = () => {
      if (mapRef.current) {
        // Check if mapInstance exists
        if (fetchedPosts?.propertyLocation) {
          mapRef.current.setView(
            [
              fetchedPosts?.propertyLocation.latitude,
              fetchedPosts?.propertyLocation.longitude,
            ],
            13
          );
        }
      } else {
        console.error("Map instance not available yet"); // Handle the case where map is not ready
      }
    };
    // Call the centering function on component mount and when userCurrentLocation changes
    centerOnUserLocation();
  }, [fetchedPosts?.propertyLocation, selectedCityCoordinates, mapRef]);

  const [associatedProject, setAssociatedProject] = useState(null);
  const [associatedProjectLister, setAssociatedProjectListener] =
    useState(null);

  const fetchAssociatedProject = useCallback(
    async (associatedProjectId) => {
      try {
        const response = await axios.get(
          `${baseURL}/api/project/getproject/${associatedProjectId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        // setFetchedProject(response.data);
        return response.data;
      } catch (err) {
        console.log(err);
      }
    },
    [baseURL]
  );

  const fetchAssociatedProjectListener = useCallback(
    async (associatedProjectUserId) => {
      try {
        const response = await axios.get(
          `${baseURL}/api/auth/getUserData/${associatedProjectUserId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        // setFetchedProject(response.data);
        return response.data.user;
      } catch (err) {
        console.log(err);
      }
    },
    [baseURL]
  );

  useMemo(async () => {
    if (fetchedPosts && fetchedPosts?.associatedProjectId) {
      return setAssociatedProject(
        await fetchAssociatedProject(fetchedPosts?.associatedProjectId)
      );
    }
  }, [fetchAssociatedProject, fetchedPosts]);
  useMemo(async () => {
    if (fetchedPosts && associatedProject && associatedProject?.userId) {
      return setAssociatedProjectListener(
        await fetchAssociatedProjectListener(associatedProject?.userId)
      );
    }
  }, [fetchAssociatedProjectListener, associatedProject, fetchedPosts]);

  // Price Format function
  const formattedPriceFrom = PriceFormatter({
    amount: associatedProject?.projectPriceRange.priceFrom,
    currencyType: associatedProject?.projectCurrencyType,
  });
  const formattedPriceTo = PriceFormatter({
    amount: associatedProject?.projectPriceRange.priceTo,
    currencyType: associatedProject?.projectCurrencyType,
  });

  const renderProject = () => {
    return (
      associatedProject && (
        <div className="trending-prop-sub">
          <div className="trending-prop-img">
            <img
              src={`data:image/jpeg;base64,${associatedProject?.projectLogo}`}
              alt="project"
            />
            <div className="trending-for">
              <h5>{associatedProject?.projectStatus?.toUpperCase()}</h5>
            </div>
            <div className="trending-type">
              <h5>{associatedProject?.projectOwnershipType?.toUpperCase()}</h5>
            </div>
            <div className="trending-rera">
              <SafetyCertificateOutlined className="trending-rera-icon" />
              <h5>RERA CERTIFIED</h5>
            </div>
            <div className="trending-prop-img-icons">
              <ShareAltOutlined className="trending-prop-img-icons-icon" />
              {associatedProject.userId === userId && (
                <MdEdit
                  className="trending-prop-img-icons-icon"
                  onClick={() =>
                    navigate(`/projectupdate/${associatedProject._id}`)
                  }
                />
              )}
              <EyeOutlined
                className="trending-prop-img-icons-icon"
                onClick={() => {
                  navigate(`/viewprojects/${associatedProject?._id}`);
                }}
              />
            </div>
          </div>
          <h5>{associatedProject?.projectName}</h5>
          <div className="trending-prop-loc">
            <EnvironmentOutlined className="trending-prop-loc-icon" />
            <p>
              {associatedProject?.projectLocality},{""}
              {associatedProject?.projectCity}
            </p>
          </div>
          <div className="trending-prop-data">
            <ExpandAltOutlined className="trending-prop-data-icon" />
            <h5>
              {associatedProject?.projectArea}{" "}
              {associatedProject?.projectAreaUnit}
            </h5>
          </div>
          <div className="trending-prop-footer">
            <h5 className="trending-prop-footer-name">
              {associatedProjectLister?.name}
            </h5>
            <h5 className="trending-prop-footer-price">
              {formattedPriceFrom} - {formattedPriceTo}
            </h5>
          </div>
        </div>
      )
    );
  };

  // Wishlist Toggle & States
  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/wishlist/${userId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        setWishlist(response.data.wishlist);
      } catch (error) {
        console.error("Error fetching wishlist:", error);
      }
    };
    fetchWishlist();
  }, [userId]);
  const [wishlist, setWishlist] = useState([]);
  const setCheckLatestWishlistCount = useSetRecoilState(fetchWishlistAtom);
  const handleWishlistToggle = async (postId) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/wishlist/toggle`,
        {
          userId,
          postId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json", // Set content type for file upload
          },
        }
      );
      if (response.data.isAdded) {
        setWishlist([...wishlist, { userId, postId }]);
      } else {
        setWishlist(wishlist.filter((item) => item.postId !== postId));
      }
    } catch (error) {
      console.error("Error toggling wishlist:", error);
    }
  };

  // Contact Info
  const [contactInfo, setContactInfo] = useState({
    fullName: "",
    mobile: "",
    email: "",
    senderMessage: "",
  });
  const [validationMessage, setValidationMessage] = useState("");

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    const { fullName, mobile, email, senderMessage } = contactInfo;
    if (!fullName) {
      setValidationMessage("Please enter your full name.");
      return;
    }
    if (!mobile || mobile.length !== 12) {
      setValidationMessage("Please enter a valid 10-digit mobile number.");
      return;
    }
    if (!email || !validateContactInfoEmail(email)) {
      setValidationMessage("Please enter a valid email address.");
      return;
    }
    if (!senderMessage) {
      setValidationMessage("Please enter your message.");
      return;
    }

    const formData = new FormData();

    const notificationType = "Requested Contact Info";
    formData.append("senderUserId", userId);
    formData.append("recieverUserId", fetchedPosts?.userId);
    formData.append("name", fullName);
    formData.append("mobile", mobile);
    formData.append("email", email);
    formData.append("senderMessage", senderMessage);
    formData.append("notificationType", notificationType);
    formData.append("propertyId", postId);
    try {
      const response = await axios.post(
        `${baseURL}/api/notification/sendNotification`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "multipart/form-data", // Set content type for file upload
          },
        }
      );
      if (
        response.data.message === "notification sent" ||
        response.status === 200 ||
        response.statusText === "OK" ||
        response.data.notification
      ) {
        message.success("Message sent successfully!");
        setIsContactNotificationAlreadySent(true);
        setValidationMessage("");
        // Clear form fields
        setContactInfo({
          fullName: "",
          mobile: "",
          email: "",
          senderMessage: "",
        });
      }
    } catch (err) {
      message.error(`${err.response.data.message} ...`);
      setValidationMessage("");
      // Clear form fields
      setContactInfo({
        fullName: "",
        mobile: "",
        email: "",
        senderMessage: "",
      });
    }
  };
  const handleContactInfoMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setContactInfo((prevValues) => ({
        ...prevValues,
        mobile: value,
      }));
    }
  };
  const formatContactInfoMobileDisplay = (mobile) => {
    const formatted = mobile.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };
  const validateContactInfoEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  // Render Seller Info according to UserType
  const renderSellerInfo = () => {
    const userType = fetchedUserProfileData && fetchedUserProfileData?.userType;
    const userName =
      userType === "Builder" || userType === "Agent" || userType === "Owner"
        ? fetchedUserData?.name
        : userType === "Salesperson"
        ? fetchedUserProfileData?.salespersonDetails?.employeeName
        : "Username";
    const initialLetter = userName?.charAt(0);
    const profileImageString =
      userType === "Builder"
        ? fetchedUserProfileData?.builderDetails?.builderProfilePhoto
        : userType === "Agent"
        ? fetchedUserProfileData?.agentDetails?.agentProfilePhoto
        : userType === "Owner"
        ? fetchedUserProfileData?.ownerDetails?.ownerProfilePhoto
        : userType === "Salesperson"
        ? fetchedUserProfileData?.salespersonDetails?.employeeImage
        : null;
    const profilePhoto = isBase64Image(profileImageString)
      ? `data:image/jpeg;base64,${profileImageString}`
      : `https://placehold.co/100x100?text=${initialLetter}`;
    return (
      <div className="vp-content-right-container">
        <h5>Listed By</h5>
        <div className="vp-seller-details">
          <div className="vp-seller-details-image">
            <img src={profilePhoto} alt="sellerimg" />
          </div>
          <div className="vp-seller-details-data">
            <h5>
              {userName} {fetchedPosts?.userId === userId && "(You)"}{" "}
              <TrademarkOutlined />
            </h5>
            <span>Listing {userType}</span>
          </div>
        </div>
        {fetchedPosts?.userId !== userId &&
          !isContactNotificationAlreadySent && (
            <>
              <h4>Full Name:</h4>
              <input
                type="text"
                name="fullName"
                value={contactInfo.fullName}
                onChange={handleInputChange}
              />
              <h4>Mobile:</h4>
              <input
                type="text"
                name="mobile"
                value={formatContactInfoMobileDisplay(contactInfo.mobile)}
                onChange={handleContactInfoMobileChange}
                maxLength="14"
              />
              <h4>Email:</h4>
              <input
                type="text"
                name="email"
                value={contactInfo.email}
                onChange={handleInputChange}
              />
              <h4>Your Message:</h4>
              <input
                type="text"
                name="senderMessage"
                value={contactInfo.senderMessage}
                onChange={handleInputChange}
              />
              <button onClick={handleSubmit}>Send Message</button>
              {validationMessage && <p className="red">{validationMessage}</p>}
            </>
          )}
        {isContactNotificationAlreadySent && (
          <p className="red">contact notification already sent</p>
        )}
      </div>
    );
  };

  // Download Brochure States & Modal
  const [isBrochureModalVisible, setIsBrochureModalVisible] = useState(false);
  const [reasonToBuy, setReasonToBuy] = useState("");
  const [isPropertyDealer, setIsPropertyDealer] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("91");
  const [email, setEmail] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [interestedInHomeLoan, setInterestedInHomeLoan] = useState(false);
  const [interestedInSiteVisits, setInterestedInSiteVisits] = useState(false);
  const [planningToBuy, setPlanningToBuy] = useState("");

  // IS NOTIFICATION SENT ?

  const [isBrochureAlreadyDownloaded, setIsBrochureAlreadyDownloaded] =
    useState(null);

  const [
    isContactNotificationAlreadySent,
    setIsContactNotificationAlreadySent,
  ] = useState(null);

  const isBrochureNotificationPresent = useCallback(async () => {
    if (userId && fetchedPosts?.userId && postId) {
      const resp = await axios.get(
        `${baseURL}/api/notification/isNotificationPresent`,
        {
          params: {
            senderUserId: userId,
            recieverUserId: fetchedPosts?.userId,
            propertyId: postId,
            notificationType: "Brochure Download",
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      return await resp.data.exists;
    } else {
      return console.log("missing notification parameters");
    }
  }, [baseURL, postId, userId, fetchedPosts?.userId]);

  const isContactNotificationPresent = useCallback(async () => {
    if (userId && fetchedPosts?.userId && postId) {
      const resp = await axios.get(
        `${baseURL}/api/notification/isNotificationPresent`,
        {
          params: {
            senderUserId: userId,
            recieverUserId: fetchedPosts?.userId,
            propertyId: postId,
            notificationType: "Requested Contact Info",
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      return await resp.data.exists;
    } else {
      return console.log("missing notification parameters");
    }
  }, [baseURL, postId, userId, fetchedPosts?.userId]);

  useMemo(async () => {
    if (userId && fetchedPosts?.userId && postId) {
      setIsBrochureAlreadyDownloaded(await isBrochureNotificationPresent());
    }
  }, [userId, fetchedPosts?.userId, postId, isBrochureNotificationPresent]);

  useMemo(async () => {
    if (userId && fetchedPosts?.userId && postId) {
      setIsContactNotificationAlreadySent(await isContactNotificationPresent());
    }
  }, [userId, fetchedPosts?.userId, postId, isContactNotificationPresent]);

  const showBrochureModal = () => {
    setIsBrochureModalVisible(true);
  };
  const handleBrochureModalOk = () => {
    setIsBrochureModalVisible(false);
  };
  const handleBrochureModalCancel = () => {
    setIsBrochureModalVisible(false);
  };
  const onChangeReasonToBuy = (e) => {
    setReasonToBuy(e.target.value);
  };
  const onChangeIsPropertyDealer = (e) => {
    setIsPropertyDealer(e.target.value);
  };
  const onChangeAgreedToTerms = (e) => {
    setAgreedToTerms(e.target.checked);
  };
  const onChangePlanningToBuy = (e) => {
    setPlanningToBuy(e.target.value);
  };
  const onChangeInterestedInHomeLoan = (e) => {
    setInterestedInHomeLoan(e.target.checked);
  };
  const onChangeInterestedInSiteVisits = (e) => {
    setInterestedInSiteVisits(e.target.checked);
  };
  const handleDownloadBrochure = async () => {
    if (!reasonToBuy) {
      message.error("Please select your reason to buy.");
      return;
    }
    if (!isPropertyDealer) {
      message.error("Please indicate if you are a property dealer.");
      return;
    }
    if (!name) {
      message.error("Please enter your name.");
      return;
    }
    if (!mobile || mobile.length !== 12) {
      message.error("Please enter a valid 10-digit mobile number.");
      return;
    }
    if (!email || !validateEmail(email)) {
      message.error("Please enter a valid email address.");
      return;
    }
    if (!agreedToTerms) {
      message.error("You must agree to the terms and conditions.");
      return;
    }
    if (!fetchedPosts?.propertyBrochure) {
      message.error("Brochure information is missing.");
      return;
    }

    const formData = new FormData();
    const notificationType = "Brochure Download";

    const _isPropertyDealer = isPropertyDealer === "Yes" ? true : false;
    formData.append("senderUserId", userId);
    formData.append("recieverUserId", fetchedPosts?.userId);
    formData.append("notificationType", notificationType);
    formData.append("propertyId", postId);

    formData.append("reasonToBuy", reasonToBuy);
    formData.append("isPropertyDealer", _isPropertyDealer);
    formData.append("name", name);
    formData.append("mobile", mobile);
    formData.append("email", email);
    formData.append("interestedInHomeLoan", interestedInHomeLoan);
    formData.append("interestedInSiteVisits", interestedInSiteVisits);
    formData.append("planningToBuy", planningToBuy);
    try {
      const response = await axios.post(
        `${baseURL}/api/notification/sendNotification`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "multipart/form-data", // Set content type for file upload
          },
        }
      );
      if (
        response.data.message === "notification sent" ||
        response.status === 200 ||
        response.statusText === "OK" ||
        response.data.notification
      ) {
        setIsBrochureAlreadyDownloaded(true);
      }
      downloadBrochure(fetchedPosts?.propertyBrochure);
      message.success("Brochure download started ...");
      setIsBrochureModalVisible(false);
      resetBrochureForm();
    } catch (err) {
      message.error(`${err.response.data.message} ...`);
      setIsBrochureModalVisible(false);
      resetBrochureForm();
    }
  };
  const handleMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setMobile(value);
    }
  };
  const formatMobileDisplay = (mobile) => {
    const formatted = mobile.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  const downloadBrochure = (brochureUrl) => {
    const link = document.createElement("a");
    link.href = brochureUrl;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
    }, 100);
  };
  const downloadBrochureAgain = (brochureUrl) => {
    if (!brochureUrl) {
      message.error("Brochure information is missing.");
      return;
    }
    const link = document.createElement("a");
    link.href = brochureUrl;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
    }, 100);
    message.success("Brochure download started ...");
  };
  const resetBrochureForm = () => {
    setReasonToBuy("");
    setIsPropertyDealer("");
    setName("");
    setMobile("91");
    setEmail("");
    setAgreedToTerms(false);
    setInterestedInHomeLoan(false);
    setInterestedInSiteVisits(false);
    setPlanningToBuy("");
  };

  // Share Project Functionality
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const showShareModal = () => {
    setIsShareModalVisible(true);
  };
  const handleShareModalOk = () => {
    setIsShareModalVisible(false);
  };
  const handleShareModalCancel = () => {
    setIsShareModalVisible(false);
    setCopyButtonText("Copy");
  };
  const [copyButtonText, setCopyButtonText] = useState("Copy");
  const currentUrl = window.location.href;
  const handleCopyUrl = () => {
    copy(currentUrl);
    setCopyButtonText("Copied!");
    setTimeout(() => {
      setCopyButtonText("Copy");
    }, 10000);
  };
  const copyButtonClass =
    copyButtonText === "Copied!"
      ? "vp-share-url-copybtn vp-share-url-copybtn-green"
      : "vp-share-url-copybtn";
  const shareMessage =
    `${fetchedPosts?.propertyTitle || "Property Title Unavailable"}\n\n` +
    `Checkout this property in ${
      fetchedPosts?.propertyLocality || "Location Unavailable"
    }, ${fetchedPosts?.propertyCity || ""}.\n\n` +
    `👉 Price: ${fetchedPosts?.propertyCurrencyType} ${fetchedPosts?.propertyPrice}\n` +
    `👉 Area: ${fetchedPosts?.propertyArea || "Property Area not mentioned"} ${
      fetchedPosts?.propertyAreaUnit
    }\n` +
    `👉 For: ${fetchedPosts?.propertyAction}\n` +
    `👉 Type: ${fetchedPosts?.propertyType}\n` +
    `👉 Sub-type: ${fetchedPosts?.propertySubType}\n` +
    `👉 Ownership: ${
      fetchedPosts?.propertyOwnershipType || "Ownership not mentioned"
    }\n` +
    `👉 Availability: ${
      fetchedPosts?.propertyStatus || "Status not updated"
    }\n\n` +
    `👉 More Details: ${currentUrl}`;
  const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(shareMessage)}`;
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    currentUrl
  )}&quote=${encodeURIComponent(shareMessage)}`;
  const instagramUrl = `https://www.instagram.com/share?url=${encodeURIComponent(
    currentUrl
  )}&title=${encodeURIComponent(shareMessage)}`;
  const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    currentUrl
  )}`;

  // // Print Functionality
  // const printRef = useRef();
  // const handlePrint = () => {
  //   const printContents = printRef.current.innerHTML;
  //   const originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //   document.body.innerHTML = originalContents;
  // };

  // Ratings Functionality
  const desc = ["terrible", "bad", "normal", "good", "wonderful"];
  const [rating, setRating] = useState(3);

  return (
    <div className="vp">
      <div className="vp-sub">
        {totalImages > 0 ? (
          <div className="vp-images">
            {totalImages > 3 && (
              <LeftOutlined
                className="vp-images-icon vp-images-icon-left-icon"
                onClick={handlePrevClick}
              />
            )}
            {renderImages()}
            {totalImages > 3 && (
              <RightOutlined
                className="vp-images-icon vp-images-icon-right-icon"
                onClick={handleNextClick}
              />
            )}
            {isModalOpen && (
              <div className="vp-modal-overlay" onClick={closeModal}>
                <CloseOutlined
                  className="vp-modal-close-icon"
                  onClick={closeModal}
                />
                <div
                  className="vp-modal-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={`data:image/jpeg;base64,${modalImageSrc}`}
                    alt="modalImg"
                    className="vp-modal-image"
                  />
                  <div className="vp-modal-allimages">
                    {base64PropertyImages?.map((group, groupIndex) => (
                      <div
                        key={groupIndex}
                        className={`vp-modal-allimages-group ${
                          groupIndex === modalGroup ? "active" : ""
                        }`}
                      >
                        {group.map((src, index) => (
                          <div
                            key={index}
                            className={`vp-modal-allimages-sub ${
                              groupIndex === modalGroup &&
                              index === modalImageIndex
                                ? "selected"
                                : ""
                            }`}
                            onClick={() => openModal(src, groupIndex, index)}
                          >
                            <img
                              src={`data:image/jpeg;base64,${src}`}
                              alt={`propertyImg${groupIndex * 3 + index + 1}`}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="vp-images">
            <div className="vp-images-sub vp-images-sub-onlyOne">
              <img
                src="https://placehold.co/1920x1080?text=proptzo"
                alt="proptzo"
                // className="vp-image-center"
              />
            </div>
          </div>
        )}
        <div className="vp-container">
          <div className="vp-header">
            <div className="vp-header-left">
              <span>FOR {fetchedPosts?.propertyAction?.toUpperCase()}</span>
              <h1>{fetchedPosts?.propertyTitle}</h1>
            </div>
            <div className="vp-header-right">
              <h1>
                <PriceFormatter
                  amount={fetchedPosts?.propertyPrice}
                  currencyType={fetchedPosts?.propertyCurrencyType}
                />
              </h1>
            </div>
          </div>
          <div className="vp-hero">
            <div className="vp-hero-sub">
              <h5>FEATURES:</h5>
              <div className="vp-hero-options">
                <div className="vp-hero-options-sub">
                  <PiBedDuotone className="vp-hero-options-sub-icon" />
                  <span>{fetchedPosts?.propertyBedroomCount} Bedroom</span>
                </div>
                <div className="vp-hero-options-sub">
                  <PiBathtubDuotone className="vp-hero-options-sub-icon" />
                  <span>{fetchedPosts?.propertyBathroomCount} Bathroom</span>
                </div>
              </div>
            </div>
            <div className="vp-hero-sub">
              <h5>LOCATION:</h5>
              <div className="vp-hero-options">
                <div className="vp-hero-options-sub">
                  <EnvironmentOutlined className="vp-hero-options-sub-icon" />
                  <span>
                    {fetchedPosts?.propertyLocality},{" "}
                    {fetchedPosts?.propertyCity}
                  </span>
                </div>
              </div>
            </div>
            <div className="vp-hero-sub">
              <div className="vp-hero-icons">
                {/* <MailOutlined className="vp-hero-icons-sub" />
                <PrinterOutlined
                  className="vp-hero-icons-sub"
                  onClick={handlePrint}
                />
                <div ref={printRef} className="vp-print-page">
                  <div className="watermark">
                    <img src={logo} alt="proptzo" />
                  </div>
                  <div className="vp-print-page-sub">
                    <div className="vp-print-page-category">
                      <h1>{fetchedPosts?.propertyTitle}</h1>
                      <span>
                        {fetchedPosts?.propertyLocality},{" "}
                        {fetchedPosts?.propertyCity}
                      </span>
                    </div>
                    <div className="vp-print-page-category">
                      <h2>Description:</h2>
                      <span>{fetchedPosts?.propertyDescription}</span>
                    </div>
                  </div>
                </div> */}
                <ShareAltOutlined
                  className="vp-hero-icons-sub"
                  onClick={showShareModal}
                />
                <Modal
                  title="Share Property Details"
                  visible={isShareModalVisible}
                  onOk={handleShareModalOk}
                  onCancel={handleShareModalCancel}
                  footer={null}
                  centered
                  className="vp-share-modal"
                >
                  <div className="vp-share-url">
                    <div className="vp-share-url-element">
                      <LinkOutlined className="vp-share-url-element-icon" />
                    </div>
                    <div className="vp-share-url-link">
                      <span onClick={handleCopyUrl}>{currentUrl}</span>
                    </div>
                    <div className={copyButtonClass} onClick={handleCopyUrl}>
                      <span>{copyButtonText}</span>
                    </div>
                  </div>
                  <div className="vp-share-modal-sub">
                    <div className="vp-share-category">
                      <a
                        href={whatsappUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={WhatsappSvg} alt="WhatsApp" />
                      </a>
                    </div>
                    <div className="vp-share-category">
                      <a
                        href={facebookUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={FacebookSvg} alt="Facebook" />
                      </a>
                    </div>
                    <div className="vp-share-category">
                      <a
                        href={instagramUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={InstagramSvg} alt="Facebook" />
                      </a>
                    </div>
                    <div className="vp-share-category">
                      <a
                        href={linkedinUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={LinkedinSvg} alt="Facebook" />
                      </a>
                    </div>
                  </div>
                </Modal>
                {fetchedPosts?.userId === userId ? (
                  <MdEdit
                    className="vp-hero-icons-sub"
                    onClick={() => navigate(`/postupdate/${fetchedPosts?._id}`)}
                  />
                ) : wishlist.some(
                    (item) => item.postId === fetchedPosts?._id
                  ) ? (
                  <HeartFilled
                    className="vp-hero-icons-sub red"
                    onClick={() => {
                      handleWishlistToggle(fetchedPosts?._id);
                      setCheckLatestWishlistCount(true);
                    }}
                  />
                ) : (
                  <HeartOutlined
                    className="vp-hero-icons-sub"
                    onClick={() => {
                      handleWishlistToggle(fetchedPosts?._id);
                      setCheckLatestWishlistCount(true);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="vp-content">
            <div className="vp-content-left">
              <div className="vp-content-left-container vp-content-left-container-one">
                <h5>Description</h5>
                <p>{fetchedPosts?.propertyDescription}</p>
                <span>View More</span>
              </div>
              <div className="vp-content-left-container">
                <h5>Overview</h5>
                <div className="vp-left-overview">
                  <div className="vp-left-overview-sub">
                    <CiCalendarDate className="vp-left-overview-sub-icon" />
                    <div className="vp-left-overview-sub-data">
                      <h5>Last Updated:</h5>
                      <span>{updatedDate}</span>
                    </div>
                  </div>
                  {fetchedPosts?.propertyArea &&
                    fetchedPosts?.propertyAreaUnit && (
                      <div className="vp-left-overview-sub">
                        <ExpandAltOutlined className="vp-left-overview-sub-icon" />
                        <div className="vp-left-overview-sub-data">
                          <h5>Area:</h5>
                          <span>
                            {fetchedPosts?.propertyArea}{" "}
                            {fetchedPosts?.propertyAreaUnit}
                          </span>
                        </div>
                      </div>
                    )}
                  {fetchedPosts?.propertyBedroomCount && (
                    <div className="vp-left-overview-sub">
                      <PiBedDuotone className="vp-left-overview-sub-icon" />
                      <div className="vp-left-overview-sub-data">
                        <h5>Bedrooms:</h5>
                        <span>{fetchedPosts?.propertyBedroomCount} Rooms</span>
                      </div>
                    </div>
                  )}
                  {fetchedPosts?.propertyBathroomCount && (
                    <div className="vp-left-overview-sub">
                      <PiBathtubDuotone className="vp-left-overview-sub-icon" />
                      <div className="vp-left-overview-sub-data">
                        <h5>Bathrooms:</h5>
                        <span>{fetchedPosts?.propertyBathroomCount} Rooms</span>
                      </div>
                    </div>
                  )}
                  {fetchedPosts?.propertyBalconiesCount && (
                    <div className="vp-left-overview-sub">
                      <MdBalcony className="vp-left-overview-sub-icon" />
                      <div className="vp-left-overview-sub-data">
                        <h5>Balconies:</h5>
                        <span>
                          {fetchedPosts?.propertyBalconiesCount} Balcony
                        </span>
                      </div>
                    </div>
                  )}
                  {fetchedPosts?.propertyTotalKitchen && (
                    <div className="vp-left-overview-sub">
                      <FaKitchenSet className="vp-left-overview-sub-icon" />
                      <div className="vp-left-overview-sub-data">
                        <h5>Total Kitchens:</h5>
                        <span>
                          {fetchedPosts?.propertyTotalKitchen} Kitchens
                        </span>
                      </div>
                    </div>
                  )}
                  {fetchedPosts?.propertyOnFloor && (
                    <div className="vp-left-overview-sub">
                      <BsBuildingCheck className="vp-left-overview-sub-icon" />
                      <div className="vp-left-overview-sub-data">
                        <h5>Property Floor:</h5>
                        <span>{fetchedPosts?.propertyOnFloor}th floor</span>
                      </div>
                    </div>
                  )}
                  {fetchedPosts?.propertyTotalFloor && (
                    <div className="vp-left-overview-sub">
                      <LuBuilding className="vp-left-overview-sub-icon" />
                      <div className="vp-left-overview-sub-data">
                        <h5>Total Floors:</h5>
                        <span>{fetchedPosts?.propertyTotalFloor} Floors</span>
                      </div>
                    </div>
                  )}
                  {fetchedPosts?.propertyTotalMasterBedroom && (
                    <div className="vp-left-overview-sub">
                      <LuBedDouble className="vp-left-overview-sub-icon" />
                      <div className="vp-left-overview-sub-data">
                        <h5>Total Master Bedrooms:</h5>
                        <span>
                          {fetchedPosts?.propertyTotalMasterBedroom} Rooms
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="vp-content-left-container">
                <h5>Property Details</h5>
                <div className="vp-pd">
                  {fetchedPosts?.propertyPrice &&
                    fetchedPosts?.propertyCurrencyType && (
                      <div className="vp-pd-sub">
                        <h5>Price:</h5>
                        <span>
                          <PriceFormatter
                            amount={fetchedPosts?.propertyPrice}
                            currencyType={fetchedPosts?.propertyCurrencyType}
                          />
                        </span>
                      </div>
                    )}
                  {fetchedPosts?.propertyFloorPlan && (
                    <div className="vp-pd-sub">
                      <h5>Floor Plan:</h5>
                      <span>{fetchedPosts?.propertyFloorPlan}</span>
                    </div>
                  )}
                  {fetchedPosts?.propertyArea &&
                    fetchedPosts?.propertyAreaUnit && (
                      <div className="vp-pd-sub">
                        <h5>Property Area:</h5>
                        <span>
                          {fetchedPosts?.propertyArea}{" "}
                          {fetchedPosts?.propertyAreaUnit}
                        </span>
                      </div>
                    )}
                  {fetchedPosts?.propertyStatus && (
                    <div className="vp-pd-sub">
                      <h5>Status:</h5>
                      <span>{fetchedPosts?.propertyStatus}</span>
                    </div>
                  )}
                  {fetchedPosts?.propertyCarpetArea &&
                    fetchedPosts?.propertyAreaUnit && (
                      <div className="vp-pd-sub">
                        <h5>Carpet Area:</h5>
                        <span>
                          {fetchedPosts?.propertyCarpetArea}{" "}
                          {fetchedPosts?.propertyAreaUnit}
                        </span>
                      </div>
                    )}
                  {fetchedPosts?.propertyAction && (
                    <div className="vp-pd-sub">
                      <h5>For:</h5>
                      <span>{fetchedPosts?.propertyAction}</span>
                    </div>
                  )}
                  {fetchedPosts?.propertyBuiltupArea &&
                    fetchedPosts?.propertyAreaUnit && (
                      <div className="vp-pd-sub">
                        <h5>Builtup Area:</h5>
                        <span>
                          {fetchedPosts?.propertyBuiltupArea}{" "}
                          {fetchedPosts?.propertyAreaUnit}
                        </span>
                      </div>
                    )}
                  {fetchedPosts?.propertyType && (
                    <div className="vp-pd-sub">
                      <h5>Property Type:</h5>
                      <span>{fetchedPosts?.propertyType}</span>
                    </div>
                  )}
                  {fetchedPosts?.propertySubType && (
                    <div className="vp-pd-sub">
                      <h5>Property Sub Type:</h5>
                      <span>{fetchedPosts?.propertySubType}</span>
                    </div>
                  )}
                  {fetchedPosts?.propertyDealType && (
                    <div className="vp-pd-sub">
                      <h5>Deal Type:</h5>
                      <span>{fetchedPosts?.propertyDealType}</span>
                    </div>
                  )}
                  {fetchedPosts?.propertyOwnershipType && (
                    <div className="vp-pd-sub">
                      <h5>Ownership Type:</h5>
                      <span>{fetchedPosts?.propertyOwnershipType}</span>
                    </div>
                  )}
                  {fetchedPosts?.propertyFurnishedType && (
                    <div className="vp-pd-sub">
                      <h5>Furnishing Status:</h5>
                      <span>{fetchedPosts?.propertyFurnishedType}</span>
                    </div>
                  )}
                </div>
              </div>
              {fetchedPosts?.propertyAdditionalRooms &&
                fetchedPosts?.propertyAdditionalRooms.length > 0 && (
                  <div className="vp-content-left-container">
                    <h5>Additional Rooms</h5>
                    <div className="vp-pd">
                      {fetchedPosts?.propertyAdditionalRooms.map(
                        (additionalRoom) =>
                          conditionalAdditionalRooms(additionalRoom)
                      )}
                    </div>
                  </div>
                )}
              {fetchedPosts?.propertyAmenities &&
                fetchedPosts?.propertyAmenities.length > 0 && (
                  <div className="vp-content-left-container">
                    <h5>Amenities & Features</h5>
                    <div className="vp-pd">
                      {fetchedPosts?.propertyAmenities.map((amenity) =>
                        conditionalAmenities(amenity)
                      )}
                    </div>
                  </div>
                )}
              {fetchedPosts?.propertyLocation && (
                <div className="vp-content-left-container">
                  <h5>Map</h5>
                  <div className="vp-map">
                    <MapContainer
                      center={[
                        selectedCityCoordinates.latitude,
                        selectedCityCoordinates.longitude,
                      ]}
                      zoom={zoomLevel}
                      className="vp-map-sub"
                      ref={mapRef}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />

                      <Marker
                        position={[
                          selectedCityCoordinates.latitude,
                          selectedCityCoordinates.longitude,
                        ]}
                        icon={customIcon}
                      >
                        <Popup
                          position={[
                            selectedCityCoordinates.latitude,
                            selectedCityCoordinates.longitude,
                          ]}
                        >
                          <div className="custom-marker-popup">
                            <div className="custom-popup-image">
                              {isBase64Image(propertyImage) ? (
                                <img
                                  src={`data:image/jpeg;base64,${propertyImage}`}
                                  alt="propertyimage"
                                />
                              ) : (
                                <img src={propertyImage} alt="propertyimage" />
                              )}
                            </div>
                            <div className="custom-popup-content">
                              <h5>{propertyTitle}</h5>
                              <h4>
                                <PriceFormatter
                                  amount={propertyPrice}
                                  currencyType={propertyCurrencyType}
                                />
                              </h4>
                            </div>
                          </div>
                        </Popup>
                      </Marker>

                      <ClickHandler />
                    </MapContainer>
                  </div>
                  <div className="vp-map-address">
                    <h5>Address</h5>
                    <p>
                      {fetchedPosts?.propertyLocality},{" "}
                      {fetchedPosts?.propertyCity}
                    </p>
                  </div>
                </div>
              )}
              {fetchedPosts?.propertyLayout && (
                <div className="vp-content-left-container">
                  <h5>Floor Plan</h5>
                  <div className="vp-map">
                    <img
                      src={`data:image/jpeg;base64,${fetchedPosts?.propertyLayout}`}
                      className="vp-property-layout-img"
                      alt=""
                    />
                  </div>
                  <div className="vp-map-address">
                    <h5>Note:</h5>
                    <p>
                      This floor plan is for illustrative purposes only. Please
                      do not rely on this as an exact representation.
                    </p>
                  </div>
                </div>
              )}
              {associatedProject && (
                <div className="vp-content-left-container">
                  <h5>Property Registered in Project</h5>
                  <div className="vp-pip-category vp-pip-category-ml">
                    {renderProject()}
                  </div>
                </div>
              )}
              {fetchedPosts?.userId !== userId &&
                fetchedPosts?.propertyBrochure && (
                  <div className="vp-content-left-container">
                    <h5>File Attachments</h5>
                    <div className="vp-documents">
                      {isBrochureAlreadyDownloaded ? (
                        <>
                          <div className="vp-documents-sub">
                            <FilePdfOutlined
                              className="vp-documents-sub-icon"
                              onClick={() =>
                                downloadBrochureAgain(
                                  fetchedPosts?.propertyBrochure
                                )
                              }
                            />
                            <span
                              onClick={() =>
                                downloadBrochureAgain(
                                  fetchedPosts?.propertyBrochure
                                )
                              }
                            >
                              Download Brochure Again
                            </span>
                            <VerticalAlignBottomOutlined
                              className="vp-documents-sub-icon-download"
                              onClick={() =>
                                downloadBrochureAgain(
                                  fetchedPosts?.propertyBrochure
                                )
                              }
                            />
                          </div>
                        </>
                      ) : (
                        <div className="vp-documents-sub">
                          <FilePdfOutlined
                            className="vp-documents-sub-icon"
                            onClick={showBrochureModal}
                          />
                          <span onClick={showBrochureModal}>
                            Download Brochure
                          </span>
                          <VerticalAlignBottomOutlined
                            className="vp-documents-sub-icon-download"
                            onClick={showBrochureModal}
                          />
                        </div>
                      )}
                      <Modal
                        title="File Attachment (Brochure)"
                        visible={isBrochureModalVisible}
                        onOk={handleBrochureModalOk}
                        onCancel={handleBrochureModalCancel}
                        footer={null}
                        centered
                        className="vp-brochure-modal"
                      >
                        <div className="vp-brochure-modal-sub">
                          <h5>BASIC INFORMATION</h5>
                          <p>Your reason to buy is</p>
                          <div className="vp-brochure-modal-category">
                            <Radio.Group
                              onChange={onChangeReasonToBuy}
                              value={reasonToBuy}
                              className="vp-brochure-modal-category-radio"
                            >
                              <Radio value="Investment">Investment</Radio>
                              <Radio value="Self Use">Self Use</Radio>
                            </Radio.Group>
                            <p>Are you a property Dealer?</p>
                            <div className="vp-brochure-modal-category">
                              <Radio.Group
                                onChange={onChangeIsPropertyDealer}
                                value={isPropertyDealer}
                                className="vp-brochure-modal-category-radio"
                              >
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                              </Radio.Group>
                            </div>
                          </div>
                        </div>
                        <div className="vp-brochure-modal-info">
                          <input
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          <input
                            type="text"
                            placeholder="Mobile"
                            value={formatMobileDisplay(mobile)}
                            onChange={handleMobileChange}
                            maxLength="14"
                          />
                          <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="vp-brochure-modal-sub">
                          <h5>OPTIONAL INFORMATION</h5>
                          <p>By when you are planning to buy the property?</p>
                          <div className="vp-brochure-modal-category">
                            <Radio.Group
                              onChange={onChangePlanningToBuy}
                              value={planningToBuy}
                            >
                              <Radio value="3 months">3 months</Radio>
                              <Radio value="6 months">6 months</Radio>
                              <Radio value="More than 6 months">
                                More than 6 months
                              </Radio>
                            </Radio.Group>
                          </div>
                          <Checkbox
                            className="vp-brochure-modal-sub-checkbox"
                            checked={interestedInHomeLoan}
                            onChange={onChangeInterestedInHomeLoan}
                          >
                            I am interested in home loan.
                          </Checkbox>
                          <Checkbox
                            className="vp-brochure-modal-sub-checkbox"
                            checked={interestedInSiteVisits}
                            onChange={onChangeInterestedInSiteVisits}
                          >
                            I am interested in site visits.
                          </Checkbox>
                          <Checkbox
                            className="vp-brochure-modal-sub-checkbox"
                            checked={agreedToTerms}
                            onChange={onChangeAgreedToTerms}
                          >
                            I agree to the{" "}
                            <span className="vp-brochure-modal-sub-checkbox-span">
                              Terms & Conditions
                            </span>{" "}
                            and{" "}
                            <span className="vp-brochure-modal-sub-checkbox-span">
                              Privacy Policy
                            </span>
                            .
                          </Checkbox>
                        </div>
                        <button
                          className="vp-brochure-modal-button"
                          onClick={handleDownloadBrochure}
                        >
                          Download Brochure
                        </button>
                      </Modal>
                    </div>
                  </div>
                )}
              {/* <div className="vp-content-left-container">
                <h5>Loan Calculator</h5>
              </div> */}
              <div className="vp-content-left-container">
                <h5>What's Nearby</h5>
                <p className="vp-nearby-p">
                  Discover the convenience of local amenities and attractions
                  just moments away from your new home.
                </p>
                <div className="vp-pd">
                  <div className="vp-pd-sub">
                    <h5>School:</h5>
                    <span>0.7 Km</span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>Hospital:</h5>
                    <span>0.4 Km</span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>University:</h5>
                    <span>0.8 Km</span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>Metro Station:</h5>
                    <span>0.1 Km</span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>Grocery Center:</h5>
                    <span>0.3 Km</span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>Gym, Wellness:</h5>
                    <span>0.2 Km</span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>Market:</h5>
                    <span>0.9 Km</span>
                  </div>
                  <div className="vp-pd-sub">
                    <h5>River:</h5>
                    <span>0.5 Km</span>
                  </div>
                </div>
              </div>
              <div className="vp-content-left-container">
                <h5>Property Reviews</h5>
                <div className="vp-reviews">
                  {[...Array(3)].map((_, index) => (
                    <div className="vp-reviews-sub">
                      <img src="https://placehold.co/100x100?text=U" alt="" />
                      <div className="vp-review-main">
                        <h5>Anil Kumar</h5>
                        <span>August 13, 2024</span>
                        <Flex
                          gap="middle"
                          vertical
                          className="vp-reviews-rating"
                        >
                          <Rate
                            tooltips={desc}
                            onChange={setRating}
                            value={rating}
                          />
                        </Flex>
                        <p>
                          I recently moved into this property and I couldn't be
                          happier with my decision. The amenities are top-notch,
                          from the beautifully maintained landscaped gardens to
                          the fully-equipped gym. The location is also
                          incredibly convenient, with great cafes, parks, and
                          public transport options just a stone's throw away.
                          The staff is friendly and always willing to help.
                          Overall, it's a fantastic place to live and I highly
                          recommend it to anyone looking for a new home.
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <span>See all reviews (19)</span>
              </div>
              <div className="vp-content-left-container vp-content-left-container-postreview">
                <h5>Leave a review</h5>
                <Flex gap="middle" vertical className="vp-reviews-rating">
                  <Rate tooltips={desc} onChange={setRating} />
                </Flex>
                <div className="vp-postreview">
                  <div className="vp-postreview-sub">
                    <h5>Review</h5>
                    <input type="text"></input>
                    <button>Post Review</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="vp-content-right">
              {renderSellerInfo()}
              <div className="vp-content-right-container">
                <h5>Why Choose Us?</h5>
                <div className="vp-content-right-wcu">
                  <IoLockClosedOutline className="vp-content-right-wcu-icon" />
                  <span>Secure Booking</span>
                </div>
                <div className="vp-content-right-wcu">
                  <IoIosCheckmarkCircleOutline className="vp-content-right-wcu-icon" />
                  <span>Best Price Guarantee</span>
                </div>
                <div className="vp-content-right-wcu">
                  <PiHandTapLight className="vp-content-right-wcu-icon" />
                  <span>Easy Booking Process</span>
                </div>
                <div className="vp-content-right-wcu">
                  <AiOutlineCustomerService className="vp-content-right-wcu-icon" />
                  <span>Customer Support Ease</span>
                </div>
              </div>
            </div>
          </div>
          <div className="vp-featured-properties"></div>
        </div>
      </div>
    </div>
  );
}

export default ViewProperty;
