import z from "zod";

export const addPropertySchema = z.object({
  floorPlan: z.string().min(1).optional(),
  action: z.string().min(1).optional(),
  type: z.string().min(1).optional(),
  subType: z.string().min(1).optional(),

  title: z.string().min(50).optional(),
  description: z.string().min(200).optional(),

  city: z.string().min(1).optional(),
  locality: z.string().min(1).optional(),

  area: z.string().min(1).optional(),
  areaUnit: z.string().min(1).optional(),
  price: z.string().min(1).optional(),
  currencyType: z.string().min(1).optional(),

  customAmenity: z.string().min(1).optional(),
});
