import React from "react";

import "./Pricing.css";
import "../Blogs/Blogs.css";

// React Icons
import { FaCheckCircle } from "react-icons/fa";
import { FaRegCircle } from "react-icons/fa6";

// Ant Design
import { Collapse } from "antd";

function Pricing() {
  const faqs = [
    {
      key: "1",
      label: "Why Should I Use Your Services?",
      children: (
        <p>
          Our services are designed to provide you with top-notch solutions that
          meet your needs efficiently and effectively. With a focus on quality,
          reliability, and customer satisfaction, we ensure that you receive the
          best possible service.
        </p>
      ),
    },
    {
      key: "2",
      label: "How Do I Get Started With Your Services?",
      children: (
        <p>
          Getting started with our services is easy. Simply sign up on our
          website, fill out the necessary information, and you'll be on your way
          to enjoying the benefits of our offerings. If you need any assistance,
          our support team is always here to help.
        </p>
      ),
    },
    {
      key: "3",
      label: "How Secure Are Your Services?",
      children: (
        <p>
          Security is our top priority. We use advanced encryption and security
          protocols to protect your data and ensure that your information is
          safe with us. Our team continuously monitors and updates our systems
          to guard against any potential threats.
        </p>
      ),
    },
    {
      key: "4",
      label: "Is There Customer Support Available?",
      children: (
        <p>
          Yes, we offer comprehensive customer support to assist you with any
          questions or issues you may have. Our support team is available 24/7
          through various channels, including phone, email, and live chat, to
          ensure you receive the help you need.
        </p>
      ),
    },
    {
      key: "5",
      label: "How Can I Update My Account Information?",
      children: (
        <p>
          Updating your account information is straightforward. Log in to your
          account, navigate to the account settings section, and make the
          necessary changes. If you encounter any difficulties, our support team
          is ready to assist you.
        </p>
      ),
    },
  ];

  return (
    <div className="blogs">
      <div className="blogs-sub">
        <h1>Pricing Plans</h1>
        <h5>Choose the best plan that fits your needs</h5>
      </div>
      <div className="blogs-container">
        <div className="trending-sub">
          <h5>PRICING</h5>
          <h1>Our Subscription</h1>
        </div>
        <div className="blogs-container-sub">
          <div className="pricing">
            <div className="pricing-sub">
              <h1>₹ 299 /month</h1>
            </div>
            <div className="pricing-sub">
              <h2>Intro</h2>
            </div>
            <div className="pricing-sub">
              <p>Join us every month for a very reasonable price</p>
            </div>
            <div className="pricing-sub">
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Transportation for you</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>A hotel or homestay</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>Free meals and drinks</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>24/7 Support</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>Unlimited Users</h5>
              </span>
            </div>
            <div className="pricing-sub">
              <button>Choose package</button>
            </div>
          </div>
          <div className="pricing selected">
            <div className="pricing-sub">
              <div className="pricing-popular-tag">Most Popular</div>
            </div>
            <div className="pricing-sub">
              <h1>₹ 499 /month</h1>
            </div>
            <div className="pricing-sub">
              <h2>Intro</h2>
            </div>
            <div className="pricing-sub">
              <p>Join us every month for a very reasonable price</p>
            </div>
            <div className="pricing-sub">
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Transportation for you</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>A hotel or homestay</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Free meals and drinks</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>24/7 Support</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>Unlimited Users</h5>
              </span>
            </div>
            <div className="pricing-sub">
              <button>Choose package</button>
            </div>
          </div>
          <div className="pricing">
            <div className="pricing-sub">
              <h1>₹ 699 /month</h1>
            </div>
            <div className="pricing-sub">
              <h2>Intro</h2>
            </div>
            <div className="pricing-sub">
              <p>Join us every month for a very reasonable price</p>
            </div>
            <div className="pricing-sub">
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Transportation for you</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>A hotel or homestay</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Free meals and drinks</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>24/7 Support</h5>
              </span>
              <span>
                <FaRegCircle className="pricing-sub-span-icon" />
                <h5>Unlimited Users</h5>
              </span>
            </div>
            <div className="pricing-sub">
              <button>Choose package</button>
            </div>
          </div>
          <div className="pricing">
            <div className="pricing-sub">
              <h1>₹ 899 /month</h1>
            </div>
            <div className="pricing-sub">
              <h2>Intro</h2>
            </div>
            <div className="pricing-sub">
              <p>Join us every month for a very reasonable price</p>
            </div>
            <div className="pricing-sub">
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Transportation for you</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>A hotel or homestay</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Free meals and drinks</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>24/7 Support</h5>
              </span>
              <span>
                <FaCheckCircle className="pricing-sub-span-icon-red" />
                <h5>Unlimited Users</h5>
              </span>
            </div>
            <div className="pricing-sub">
              <button>Choose package</button>
            </div>
          </div>
        </div>
      </div>
      <div className="blogs-container">
        <div className="trending-sub">
          <h5>FAQS</h5>
          <h1>Quick Answers To Questions</h1>
        </div>
        <div className="pricing-container-faqs">
          <Collapse
            items={faqs}
            defaultActiveKey={["1"]}
            expandIconPosition="end"
          />
        </div>
      </div>
    </div>
  );
}

export default Pricing;
