import React, { useState, useEffect, Suspense, lazy, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import LoadingOverlay from "../components/LoadingOverlay/LoadingOverlay";
import { userDataAtom } from "../store/atoms/userDataAtom";
import { useRecoilValue } from "recoil";

const SignUp = lazy(() => import("../pages/SignUp/SignUp"));

function UserTypeRedirectGaurd() {
  const navigate = useNavigate();
  const [userPresent, setUserPresent] = useState(null); // null indicates loading state

  const userDetails = useRecoilValue(userDataAtom);

  useMemo(() => {
    if (userDetails?.user) {
      setUserPresent(userDetails?.user || false);
    } else {
      setUserPresent(false);
    }
  }, [userDetails]);

  useEffect(() => {
    if (userPresent === false) {
      navigate("/signup");
    }
  }, [userPresent, navigate]);

  if (userPresent === null) {
    return <LoadingOverlay />;
  }

  return (
    <Suspense fallback={<LoadingOverlay />}>
      {userPresent ? <Outlet /> : <SignUp />}
    </Suspense>
  );
}

export default UserTypeRedirectGaurd;
