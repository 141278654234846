import React from "react";
import "./AdminHeader.css";

//ANT DESIGN
import { Badge, Tooltip } from "antd";

// ANT DESIGN ICONS
import {
  LoginOutlined,
  LogoutOutlined,
  NotificationOutlined,
  BugOutlined,
  HomeOutlined,
  ReconciliationOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";

function AdminHeader() {
  return (
    <div className="aheader">
      <div className="aheader-sub">
        <div className="aheader-user">
          {/* <Tooltip
            placement="right"
            title="Log-in"
            className="aheader-user-tooltip"
          >
            <div className="aheader-user-image"></div>
            <div className="aheader-user-detail">
              <h4>Welcome Admin!</h4>
              <h6>Log-in</h6>
            </div>
            <div className="aheader-user-detail">
              <LoginOutlined className="aheader-user-icon" />
            </div>
          </Tooltip> */}
          <Tooltip
            placement="right"
            title="Log-out"
            className="aheader-user-tooltip"
          >
            <div className="aheader-user-image"></div>
            <div className="aheader-user-detail">
              <h4>Username</h4>
              <h6>Super Admin / Sub-admin</h6>
            </div>
            <div className="aheader-user-detail">
              <LogoutOutlined className="aheader-user-icon" />
            </div>
          </Tooltip>
        </div>
        <div className="aheader-user">
          <Tooltip placement="bottom" title="Properties">
            <Badge count={1} overflowCount={99} className="aheader-user-icon">
              <HomeOutlined />
            </Badge>
          </Tooltip>
          <Tooltip placement="bottom" title="Projects">
            <Badge count={7} overflowCount={99} className="aheader-user-icon">
              <ReconciliationOutlined />
            </Badge>
          </Tooltip>
          <Tooltip placement="bottom" title="Notifications">
            <Badge
              count={5}
              overflowCount={99}
              color="#3a86ff"
              className="aheader-user-icon"
            >
              <NotificationOutlined />
            </Badge>
          </Tooltip>
          <Tooltip placement="bottom" title="Customer care">
            <Badge count={5} overflowCount={99} className="aheader-user-icon">
              <CustomerServiceOutlined />
            </Badge>
          </Tooltip>
          <Tooltip placement="bottom" title="Bugs">
            <Badge
              count={351}
              overflowCount={99}
              color="#faad14"
              className="aheader-user-icon"
            >
              <BugOutlined />
            </Badge>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default AdminHeader;
