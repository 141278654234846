import React, { useState, useEffect, useRef } from "react";
import "./MyDashboard.css";
import { Link } from "react-router-dom";
import axios from "axios";
import PriceFormatter from "../../components/PriceFormatter/PriceFormatter";

import { Empty } from "antd";

import { MdVerified } from "react-icons/md";
import { FiHeart } from "react-icons/fi";
import { FiShare } from "react-icons/fi";
import { MdErrorOutline } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { IoImageOutline } from "react-icons/io5";

function MyDashboard() {
  const baseURL = process.env.REACT_APP_base_URL;
  const userId = localStorage.getItem("userId");

  const [selectUserType, setSelectUserType] = useState(null);
  useEffect(() => {
    document.title = "Proptzo Dashboard - Manage Your Properties Efficiently";
  }, []);

  const [fetchedPosts, setFetchedPosts] = useState([]);
  const [fetchedProjects, setFetchedProjects] = useState([]);

  useEffect(() => {
    const fetchUserTypeFromAPI = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/profile/getUserType/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status >= 200 && response.status < 300) {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            const userType = response.data.userType;

            setSelectUserType(userType);
          } else {
            throw new Error("Response is not in JSON format");
          }
        } else {
          throw new Error("Failed to fetch userType from API");
        }
      } catch (error) {
        console.error("Error fetching userType:", error);
      }
    };

    fetchUserTypeFromAPI();
    const fetchUserPosts = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/post/getpost/${userId}`
        );
        setFetchedPosts(response.data.posts);
        setFetchedProjects(response.data.projects);
      } catch (error) {
        console.error("Error fetching user posts:", error);
      }
    };
    fetchUserPosts();
  }, [userId]);

  const [postIdToDelete, setPostIdToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [projectIdToDelete, setProjectIdToDelete] = useState(null);
  const [showProjectDeleteModal, setShowProjectDeleteModal] = useState(false);

  const openDeleteModal = (postId) => {
    setPostIdToDelete(postId);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const openProjectDeleteModal = (projectId) => {
    setProjectIdToDelete(projectId);
    setShowProjectDeleteModal(true);
  };

  const closeProjectDeleteModal = () => {
    setShowProjectDeleteModal(false);
  };

  const retrivedHistory = localStorage.getItem("history");
  const StoredHistory = retrivedHistory ? JSON.parse(retrivedHistory) : [];
  const handleDelete = async () => {
    try {
      setLoadingDelete(true);
      await axios.delete(`${baseURL}/api/post/delete/${postIdToDelete}`);
      setFetchedPosts(
        fetchedPosts.filter((post) => post._id !== postIdToDelete)
      );
      if (StoredHistory && StoredHistory.length) {
        const filteredHistory = StoredHistory?.filter(
          (historyId) => historyId !== postIdToDelete
        );

        localStorage.setItem("history", JSON.stringify(filteredHistory));
      }
      setLoadingDelete(false);
      closeDeleteModal();
    } catch (error) {
      console.error("Error deleting post:", error);
      setLoadingDelete(false);
    }
  };

  const handleProjectDelete = async () => {
    try {
      setLoadingDelete(true);
      await axios.delete(`${baseURL}/api/project/delete/${projectIdToDelete}`);
      setFetchedProjects(
        fetchedProjects.filter((project) => project._id !== projectIdToDelete)
      );
      setLoadingDelete(false);
      closeProjectDeleteModal();
    } catch (error) {
      console.error("Error deleting post:", error);
      setLoadingDelete(false);
    }
  };

  return (
    <div className="md">
      <div className="md-sub">
        {selectUserType === "Owner" ||
        selectUserType === "Salesperson" ? null : fetchedProjects.length ===
          0 ? (
          <div className="md-properties">
            <Empty description="No Projects listed" />
            <Link className="md-properties-link" to="/postproject">
              Add a New Project
            </Link>
          </div>
        ) : (
          <>
            {/* <div className="md-header">
              <div className="md-inputs">
                <input type="search" placeholder="Search..." />
                <div className="md-inputs-sub">
                  <span>From Date:</span>
                  <input type="date" className="md-date" placeholder="From" />
                </div>
                <div className="md-inputs-sub">
                  <span>To Date:</span>
                  <input type="date" className="md-date" placeholder="To" />
                </div>
              </div>
              <Link className="md-header-link" to="/postproject">
                Create New Project
              </Link>
            </div> */}
            <div className="md-properties">
              <h2>My Projects</h2>
              {fetchedProjects &&
                fetchedProjects.map((project) => (
                  <div className="property" key={project._id}>
                    <div className="property-images">
                      {project.projectLogo ? (
                        <div className="property-images-sub-onlyOne">
                          <img
                            src={`data:image/jpeg;base64,${project.projectLogo}`}
                            alt={`Project logo`}
                            className="property-images-sub-img-onlyOne"
                          />
                        </div>
                      ) : (
                        // Render placeholder image only if no images are present
                        <img
                          src="https://placehold.co/250x250?text=No\nImage Available ..."
                          alt=""
                        ></img>
                      )}
                    </div>
                    <div className="property-container-one">
                      <div className="property-header">
                        <div className="property-header-sub">
                          {/* <div className="property-reraCertification">
                            <MdVerified className="property-reraCertification-icon" />
                            <h6>Rera Certified</h6>
                          </div> */}
                          <h5>{project.projectName}</h5>
                        </div>
                        <div className="property-shareOrWishlist">
                          {/* <FiHeart className="property-shareOrWishlist-icon" /> */}
                          <FiShare className="property-shareOrWishlist-icon" />
                          <MdErrorOutline className="property-shareOrWishlist-icon" />
                        </div>
                      </div>
                      <div className="property-highlights">
                        {project.projectStatus && (
                          <div className="property-highlights-sub">
                            <h5>Status</h5>
                            <h6>{project.projectStatus}</h6>
                          </div>
                        )}

                        {project.projectArea && project.projectAreaUnit && (
                          <div className="property-highlights-sub">
                            <h5>Area</h5>
                            <h6>
                              {project.projectArea} {project.projectAreaUnit}
                            </h6>
                          </div>
                        )}

                        {project.projectFloorPlans && (
                          <div className="property-highlights-sub">
                            <h5>Floor plans</h5>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                                width: "100%",
                                margin: "1px",
                              }}
                            >
                              {project.projectFloorPlans
                                .slice()
                                .sort((a, b) => {
                                  const numericA = parseInt(a);
                                  const numericB = parseInt(b);
                                  return numericA - numericB;
                                })
                                .map((floorPlan, index) =>
                                  project.projectFloorPlans.length >
                                  index + 1 ? (
                                    <h6
                                      key={index}
                                      style={{ marginRight: "5px" }}
                                    >{`${floorPlan},`}</h6>
                                  ) : (
                                    <h6 key={index}>{floorPlan}</h6>
                                  )
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="property-location">
                        <h6>{project.projectLocality}</h6>
                        <h5>{project.projectCity}</h5>
                      </div>
                    </div>
                    <div className="property-container-two">
                      {project.projectPriceRange && (
                        <div className="property-pricing">
                          <h5>
                            <PriceFormatter
                              amount={project.projectPriceRange.priceFrom}
                              currencyType={project.projectCurrencyType}
                            />
                            {" - "}
                            <PriceFormatter
                              amount={project.projectPriceRange.priceTo}
                              currencyType={project.projectCurrencyType}
                            />
                          </h5>
                        </div>
                      )}
                      <div className="property-buttons">
                        <Link
                          to={`/viewprojects/${project._id}`}
                          className="property-buttons-btn1-link"
                        >
                          View Deal
                        </Link>
                        <button className="property-buttons-btn2">
                          Contact {project.userType}
                        </button>
                      </div>
                      <div className="property-buttons-dashboard">
                        <Link
                          to={`/projectupdate/${project._id}`}
                          className="property-buttons-dashboard-link"
                        >
                          <MdEdit className="property-buttons-dashboard-icon" />
                        </Link>
                        <MdDelete
                          className="property-buttons-dashboard-icon"
                          onClick={() => openProjectDeleteModal(project._id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
        {fetchedPosts.length === 0 ? (
          <div className="md-properties">
            <Empty description="No Properties listed" />
            <Link className="md-properties-link" to="/postproperty">
              Create a New Listing
            </Link>
          </div>
        ) : (
          <>
            {/* <div className="md-header">
              <div className="md-inputs">
                <input type="search" placeholder="Search..." />
                <div className="md-inputs-sub">
                  <span>From Date:</span>
                  <input type="date" className="md-date" placeholder="From" />
                </div>
                <div className="md-inputs-sub">
                  <span>To Date:</span>
                  <input type="date" className="md-date" placeholder="To" />
                </div>
              </div>
              <Link className="md-header-link" to="/postproperty">
                Create New Listing
              </Link>
            </div> */}
            <div className="md-properties">
              <h2>My Listings</h2>
              {fetchedPosts &&
                fetchedPosts.map((post, index) => (
                  <div className="property" key={post._id}>
                    <div className="property-images">
                      {post.propertyImages.length === 1 ? (
                        <div className="property-images-sub-onlyOne">
                          <img
                            src={`data:image/jpeg;base64,${post.propertyImages[0]}`}
                            alt={`Property ${index + 1}`}
                            className="property-images-sub-img-onlyOne"
                          />
                        </div>
                      ) : post.propertyImages.length > 1 ? (
                        <>
                          {post.propertyImages.length === 2 ? (
                            <>
                              <div className="property-images-sub-onlyTwo">
                                <img
                                  src={`data:image/jpeg;base64,${post.propertyImages[0]}`}
                                  alt={`Property ${index + 1}`}
                                  className="property-images-sub-img-onlyTwo"
                                />
                              </div>
                              <div className="property-images-sub-onlyTwo">
                                <img
                                  src={`data:image/jpeg;base64,${post.propertyImages[1]}`}
                                  alt={`Property ${index + 1}`}
                                  className="property-images-sub-img-onlyTwo"
                                />
                              </div>
                            </>
                          ) : post.propertyImages.length === 3 ? (
                            <>
                              <div className="property-images-sub-onlyThree">
                                <img
                                  src={`data:image/jpeg;base64,${post.propertyImages[0]}`}
                                  alt={`Property ${index + 1}`}
                                  className="property-images-sub-img-onlyThree"
                                />
                              </div>
                              <div className="property-images-sub-onlyThree">
                                <img
                                  src={`data:image/jpeg;base64,${post.propertyImages[1]}`}
                                  alt={`Property ${index + 1}`}
                                  className="property-images-sub-img-onlyThree"
                                />
                              </div>
                              <div className="property-images-sub-onlyThree-img3">
                                <img
                                  src={`data:image/jpeg;base64,${post.propertyImages[2]}`}
                                  alt={`Property ${index + 1}`}
                                  className="property-images-sub-img-onlyThree-img3"
                                />
                              </div>
                            </>
                          ) : (
                            post.propertyImages
                              .slice(0, 3)
                              .map((imageData, i) => (
                                <div className="property-images-sub" key={i}>
                                  <img
                                    src={`data:image/jpeg;base64,${imageData}`}
                                    alt={`Property ${index + 1}`}
                                  />
                                </div>
                              ))
                          )}
                          {post.propertyImages.length > 3 && (
                            <div className="property-images-sub">
                              <div className="image-overlay"></div>
                              <img
                                src={`data:image/jpeg;base64,${post.propertyImages[3]}`}
                                alt={`Property ${index + 1}`}
                              />
                              <span>
                                <IoImageOutline className="property-images-sub-icon" />
                                more ...
                              </span>
                            </div>
                          )}
                        </>
                      ) : (
                        // Render placeholder image only if no images are present
                        <img
                          src="https://placehold.co/250x250?text=No\nImage Available ..."
                          alt=""
                        ></img>
                      )}
                    </div>
                    <div className="property-container-one">
                      <div className="property-header">
                        <div className="property-header-sub">
                          {/* <div className="property-reraCertification">
                            <MdVerified className="property-reraCertification-icon" />
                            <h6>Rera Certified</h6>
                          </div> */}
                          <h5>{post.propertyTitle}</h5>
                        </div>
                        <div className="property-shareOrWishlist">
                          {/* <FiHeart className="property-shareOrWishlist-icon" /> */}
                          <FiShare className="property-shareOrWishlist-icon" />
                          <MdErrorOutline className="property-shareOrWishlist-icon" />
                        </div>
                      </div>
                      <div className="property-highlights">
                        {post.propertyAction && (
                          <div className="property-highlights-sub">
                            <h5>For</h5>
                            <h6>{post.propertyAction}</h6>
                          </div>
                        )}
                        {post.propertyType && (
                          <div className="property-highlights-sub">
                            <h5>Type</h5>
                            <h6>{post.propertyType}</h6>
                          </div>
                        )}
                        {post.propertySubType && (
                          <div className="property-highlights-sub">
                            <h5>Sub Type</h5>
                            <h6>{post.propertySubType}</h6>
                          </div>
                        )}
                        {post.propertyStatus && (
                          <div className="property-highlights-sub">
                            <h5>Status</h5>
                            <h6>{post.propertyStatus}</h6>
                          </div>
                        )}
                        {post.propertyDealType && (
                          <div className="property-highlights-sub">
                            <h5>Deal Type</h5>
                            <h6>{post.propertyDealType}</h6>
                          </div>
                        )}
                        {post.propertyArea && post.propertyAreaUnit && (
                          <div className="property-highlights-sub">
                            <h5>Area</h5>
                            <h6>
                              {post.propertyArea} {post.propertyAreaUnit}
                            </h6>
                          </div>
                        )}
                        {post.propertyBuiltupArea && (
                          <div className="property-highlights-sub">
                            <h5>Builtup Area</h5>
                            <h6>
                              {post.propertyBuiltupArea} {post.propertyAreaUnit}
                            </h6>
                          </div>
                        )}
                        {post.propertyCarpetArea && (
                          <div className="property-highlights-sub">
                            <h5>Carpet Area</h5>
                            <h6>
                              {post.propertyCarpetArea} {post.propertyAreaUnit}
                            </h6>
                          </div>
                        )}
                        {post.propertyOwnershipType && (
                          <div className="property-highlights-sub">
                            <h5>Ownership</h5>
                            <h6>{post.propertyOwnershipType}</h6>
                          </div>
                        )}
                        {post.propertyFurnishedType && (
                          <div className="property-highlights-sub">
                            <h5>Furnishing</h5>
                            <h6>{post.propertyFurnishedType}</h6>
                          </div>
                        )}
                        {post.propertyBedroomCount && (
                          <div className="property-highlights-sub">
                            <h5>Bedrooms</h5>
                            <h6>{post.propertyBedroomCount}</h6>
                          </div>
                        )}
                        {post.propertyTotalMasterBedroom && (
                          <div className="property-highlights-sub">
                            <h5>Master Bedrooms</h5>
                            <h6>{post.propertyTotalMasterBedroom}</h6>
                          </div>
                        )}
                        {post.propertyBathroomCount && (
                          <div className="property-highlights-sub">
                            <h5>Bathrooms</h5>
                            <h6>{post.propertyBathroomCount}</h6>
                          </div>
                        )}
                        {post.propertyBalconiesCount && (
                          <div className="property-highlights-sub">
                            <h5>Balconies</h5>
                            <h6>{post.propertyBalconiesCount}</h6>
                          </div>
                        )}
                        {post.propertyTotalKitchen && (
                          <div className="property-highlights-sub">
                            <h5>Kitchens</h5>
                            <h6>{post.propertyTotalKitchen}</h6>
                          </div>
                        )}
                        {post.propertyTotalFloor && (
                          <div className="property-highlights-sub">
                            <h5>Floors</h5>
                            <h6>{post.propertyTotalFloor}</h6>
                          </div>
                        )}
                      </div>
                      <div className="property-location">
                        <h6>{post.propertyLocality}</h6>
                        <h5>{post.propertyCity}</h5>
                      </div>
                    </div>
                    <div className="property-container-two">
                      <div className="property-pricing">
                        <h5>
                          <PriceFormatter
                            amount={post.propertyPrice}
                            currencyType={post.propertyCurrencyType}
                          />
                        </h5>
                      </div>
                      <div className="property-buttons">
                        <Link
                          to={`/viewproperty/${post._id}`}
                          className="property-buttons-btn1-link"
                        >
                          View Deal
                        </Link>
                        <button className="property-buttons-btn2">
                          Contact {post.userType}
                        </button>
                      </div>
                      <div className="property-buttons-dashboard">
                        <Link
                          to={`/postupdate/${post._id}`}
                          className="property-buttons-dashboard-link"
                        >
                          <MdEdit className="property-buttons-dashboard-icon" />
                        </Link>
                        <MdDelete
                          className="property-buttons-dashboard-icon"
                          onClick={() => openDeleteModal(post._id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
      {/* DELETE POST MODAL */}
      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Confirm Delete</h2>
            <p>Are you sure you want to delete this post?</p>
            <p>This action cannot be undone.</p>
            <div className="modal-buttons">
              <div>
                <button
                  onClick={handleDelete}
                  disabled={loadingDelete}
                  className="modal-btn-delete"
                >
                  {loadingDelete ? "Deleting..." : "Delete"}
                </button>
                <button onClick={closeDeleteModal} className="modal-btn-cancel">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* DELETE PROJECT MODAL */}
      {showProjectDeleteModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Confirm Delete</h2>
            <p>Are you sure you want to delete this project?</p>
            <p>This action cannot be undone.</p>
            <div className="modal-buttons">
              <div>
                <button
                  onClick={handleProjectDelete}
                  disabled={loadingDelete}
                  className="modal-btn-delete"
                >
                  {loadingDelete ? "Deleting..." : "Delete"}
                </button>
                <button
                  onClick={closeProjectDeleteModal}
                  className="modal-btn-cancel"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyDashboard;
