import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// STYLINGS IMPORT
import "./FooterMobile.css";

//ANT DESIGN
import { Badge } from "antd";

// ANT DESIGN ICONS
import {
  RiseOutlined,
  UserOutlined,
  HomeFilled,
  PlusCircleFilled,
  HeartFilled,
} from "@ant-design/icons";

function FooterMobile() {
  const navigate = useNavigate();

  return (
    <div className="footerm">
      <div className="footerm-sub">
        <div className="footerm-item" onClick={() => navigate(`/`)}>
          <HomeFilled className="footerm-item-icon" />
          <h5>Home</h5>
        </div>
        <div className="footerm-item">
          <RiseOutlined className="footerm-item-icon" />
          <h5>Trending</h5>
        </div>
        <div className="footerm-item">
          <PlusCircleFilled className="footerm-item-icon" />
          <h5>Sell/Rent</h5>
        </div>
        <div className="footerm-item">
          <Badge
            count={2}
            overflowCount={99}
            color="#3a86ff"
            className="footerm-item-icon"
          >
            <HeartFilled />
          </Badge>
          <h5>Wishlist</h5>
        </div>
        <div className="footerm-item">
          <UserOutlined className="footerm-item-icon" />
          <h5>Profile</h5>
        </div>
      </div>
    </div>
  );
}

export default FooterMobile;
