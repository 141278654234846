export const formatPrice = (num, currencyType) => {
  if (currencyType === "₹") {
    if (num >= 1_00_00_000) {
      return `${(num / 1_00_00_000).toFixed(2)} Cr`; // Crores
    } else if (num >= 1_00_000) {
      return `${(num / 1_00_000).toFixed(2)} Lac`; // Lakhs
    } else if (num >= 1_000) {
      return `${(num / 1_000).toFixed(2)} K`; // Thousands
    } else {
      return num.toString();
    }
  } else if (currencyType === "$") {
    if (num >= 1_000_000_000) {
      return `${(num / 1_000_000_000).toFixed(2)} B`; // Billions
    } else if (num >= 1_000_000) {
      return `${(num / 1_000_000).toFixed(2)} M`; // Millions
    } else if (num >= 1_000) {
      return `${(num / 1_000).toFixed(2)} K`; // Thousands
    } else {
      return num.toString();
    }
  } else {
    // Default to no formatting
    return num?.toString();
  }
};
