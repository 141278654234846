import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Hero.css";
import { Modal, Checkbox, Col, Row, AutoComplete } from "antd";
import { RightOutlined } from "@ant-design/icons";

import CountUp from "react-countup";

function Hero() {
  const baseURL = process.env.REACT_APP_base_URL;
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [searchCategory, setSearchCategory] = useState("Buy");
  const [searchValue, setSearchValue] = useState("");
  const [foundCount, setFoundCount] = useState(0);

  const showModal = () => {
    if (searchCategory !== "New Launch") {
      console.log("Opening modal");
      setIsModalOpen(true);
    }
  };

  const handleOk = () => {
    console.log("Clicked OK");
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    console.log("Clicked Cancel");
    setIsModalOpen(false);
  };

  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    filterProperties(value, searchCategory);
  };

  const handleSelect = (value) => {
    let selectedItem;
    if (searchCategory === "Projects") {
      selectedItem = filteredProperties.find(
        (project) => project.projectName === value
      );
      if (selectedItem) {
        navigate(`/viewprojects/${selectedItem._id}`);
      }
    } else {
      selectedItem = filteredProperties.find(
        (property) => property.propertyTitle === value
      );
      if (selectedItem) {
        navigate(`/viewproperty/${selectedItem._id}`);
      }
    }
  };

  const filterProperties = (searchTerm, category) => {
    if (!searchTerm) {
      setFilteredProperties([]);
      setFoundCount(0);
      return;
    }

    let filtered;
    if (category === "Projects") {
      filtered = projectList.filter((project) =>
        project.projectName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      filtered = propertyList.filter((property) => {
        const titleMatch = property.propertyTitle
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        switch (category) {
          case "Buy":
            return titleMatch && property.propertyAction === "Sale";
          case "Rent":
            return titleMatch && property.propertyAction === "Rent/Lease";
          case "New Launch":
            return titleMatch && property.propertyAction === "Sale";
          case "PG / Co-living":
            return titleMatch && property.propertyAction === "PG/Hostel";
          case "Commercial":
            return titleMatch && property.propertyType === "Commercial";
          case "Plots / Land":
            return (
              titleMatch &&
              (property.propertySubType === "Plot" ||
                property.propertySubType === "Industrial plot")
            );
          default:
            return false;
        }
      });
    }

    setFilteredProperties(filtered);
    setFoundCount(filtered.length);
  };

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/post/publicposts`);
        setPropertyList(response.data);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/project/publicprojects`
        );
        setProjectList(response.data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    if (searchCategory === "Projects") {
      fetchProjects();
    } else {
      fetchProperties();
    }
  }, [baseURL, searchCategory]);

  const renderCheckboxGroup = () => {
    switch (searchCategory) {
      case "Buy":
        return (
          <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
            <Row>
              <Col span={10}>
                <Checkbox value="Flat/Apartment">Flat/Apartment</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Independent/Builder Floor">
                  Independent House
                </Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Independent House/Villa">Penthouse</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Residential Land">Builder Floor</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Studio Apartment">Villa</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Farm House">Plot</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        );
      case "Rent":
        return (
          <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
            <Row>
              <Col span={10}>
                <Checkbox value="PG">PG</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Flat/Apartment">Flat/Apartment</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Independent/Builder Floor">
                  Independent House
                </Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Independent House/Villa">Penthouse</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Residential Land">Builder Floor</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Studio Apartment">Villa</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Farm House">Plot</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        );
      case "PG / Co-living":
        return (
          <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
            <Row>
              <Col span={10}>
                <Checkbox value="PG">PG</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Flat/Apartment">Flat/Apartment</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Independent/Builder Floor">
                  Independent House
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        );
      case "Commercial":
        return (
          <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
            <Row>
              <Col span={10}>
                <Checkbox value="Shop">Shop</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Showroom">Showroom</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Booth">Booth</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="SOHO">SOHO</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Industrial Shed">Industrial Shed</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Office Space">Office Space</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Co-Working Space">Co-Working Space</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        );
      case "Plots / Land":
        return (
          <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
            <Row>
              <Col span={10}>
                <Checkbox value="Plot">Plot</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Industrial Plot">Industrial Plot</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        );
      case "Projects":
        return (
          <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
            <Row>
              <Col span={10}>
                <Checkbox value="Ready to move">Ready to move</Checkbox>
              </Col>
              <Col span={10}>
                <Checkbox value="Under construction">
                  Under construction
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        );
      default:
        return null;
    }
  };

  const getSpanText = () => {
    switch (searchCategory) {
      case "New Launch":
        return "Residential";
      case "Commercial":
        return "All Commercial";
      case "Plots / Land":
        return "Residential";
      case "Projects":
        return "All Projects";
      default:
        return "All Residential";
    }
  };

  return (
    <div className="hero">
      <div className="hero-sub">
        <div className="hero-overlay"></div>
        <div className="hero-heading">
          <h3>Discover the best properties at the best prices</h3>
          <h1>
            Find Your <span>Dream</span> Home
          </h1>
        </div>
        <div className="hero-search">
          <div className="hero-category">
            <span
              className={`hero-category-sub ${
                searchCategory === "Buy" ? "selected" : ""
              }`}
              onClick={() => setSearchCategory("Buy")}
            >
              Buy
            </span>
            <span
              className={`hero-category-sub ${
                searchCategory === "Rent" ? "selected" : ""
              }`}
              onClick={() => setSearchCategory("Rent")}
            >
              Rent
            </span>
            <span
              className={`hero-category-sub ${
                searchCategory === "New Launch" ? "selected" : ""
              }`}
              onClick={() => setSearchCategory("New Launch")}
            >
              New Launch
            </span>
            <span
              className={`hero-category-sub ${
                searchCategory === "PG / Co-living" ? "selected" : ""
              }`}
              onClick={() => setSearchCategory("PG / Co-living")}
            >
              PG / Co-living
            </span>
            <span
              className={`hero-category-sub ${
                searchCategory === "Commercial" ? "selected" : ""
              }`}
              onClick={() => setSearchCategory("Commercial")}
            >
              Commercial
            </span>
            <span
              className={`hero-category-sub ${
                searchCategory === "Plots / Land" ? "selected" : ""
              }`}
              onClick={() => setSearchCategory("Plots / Land")}
            >
              Plots / Land
            </span>
            <span
              className={`hero-category-sub ${
                searchCategory === "Projects" ? "selected" : ""
              }`}
              onClick={() => setSearchCategory("Projects")}
            >
              Projects
            </span>
          </div>
          <div className="hero-main">
            <div className="hero-select" onClick={showModal}>
              <span>{getSpanText()}</span>
              {searchCategory !== "New Launch" && <RightOutlined />}
            </div>
            <AutoComplete
              className="hero-input"
              placeholder="Search anything here"
              variant="borderless"
              value={searchValue}
              onChange={handleSearch}
              onSelect={handleSelect}
              options={
                searchValue
                  ? filteredProperties.map((item) => ({
                      value:
                        searchCategory === "Projects"
                          ? item.projectName
                          : item.propertyTitle,
                      label:
                        searchCategory === "Projects"
                          ? item.projectName
                          : item.propertyTitle,
                      id: item._id,
                    }))
                  : []
              }
            />
            <span
              onClick={() => {
                navigate("/properties");
              }}
            >
              {searchValue
                ? foundCount === 1
                  ? `${foundCount} found`
                  : `${foundCount} founds`
                : "Search all"}
            </span>
          </div>
        </div>
        <div className="hero-usercount">
          <div className="hero-usercount-sub">
            <h1>
              <CountUp end={140000} duration={30} separator="," />
            </h1>
            <p>People have faith in our service</p>
          </div>
          <div className="hero-usercount-sub">
            <h1>
              <CountUp end={3400} duration={30} separator="," />
            </h1>
            <p>Property and house prepared for habitation</p>
          </div>
          <div className="hero-usercount-sub">
            <h1>
              <CountUp end={569} duration={30} separator="," />
            </h1>
            <p>Verified till date</p>
          </div>
        </div>
      </div>
      <Modal
        title="Property Types"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {renderCheckboxGroup()}
      </Modal>
    </div>
  );
}

export default Hero;
