import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// STYLING IMPORT
import "./HeaderMobile.css";

// ASSETS IMPORT
import logo from "../../../assets/svgs/main-logo.svg";

//ANT DESIGN
import { Badge, Drawer } from "antd";

// ANT DESIGN ICONS
import {
  PlusCircleOutlined,
  BellFilled,
  MenuOutlined,
  ShoppingOutlined,
  RetweetOutlined,
  ShopOutlined,
  AppstoreOutlined,
  RiseOutlined,
  DownloadOutlined,
  CrownOutlined,
  FileProtectOutlined,
  IssuesCloseOutlined,
  SlidersOutlined,
  CustomerServiceOutlined,
  EnvironmentOutlined,
  BellOutlined,
} from "@ant-design/icons";

function HeaderMobile() {
  const baseURL = process.env.REACT_APP_base_URL;
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const location = useLocation();

  const [openHam, setOpenHam] = useState(false);
  const showDrawer = () => {
    setOpenHam(true);
  };
  const onCloseHam = () => {
    setOpenHam(false);
  };

  const [userData, setUserData] = useState(null);
  const [userProfileData, setUserProfileData] = useState(null);

  const handleLogout = async () => {
    try {
      await axios.post(`${baseURL}/api/auth/logout`);
      localStorage.clear();
      navigate("/signup");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const [selectUserType, setSelectUserType] = useState(null);
  useEffect(() => {
    const fetchUserTypeFromAPI = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/profile/getUserType/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        if (response.status >= 200 && response.status < 300) {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            const userType = response.data.userType;
            setSelectUserType(userType);
          } else {
            throw new Error("Response is not in JSON format");
          }
        } else {
          throw new Error("Failed to fetch userType from API");
        }
      } catch (error) {
        console.error("Error fetching userType:", error);
      }
    };
    fetchUserTypeFromAPI();
    const fetchUserDataFromAPI = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/auth/getUserData/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        if (response.status >= 200 && response.status < 300) {
          setUserData(response.data.user);
          setUserProfileData(response.data.userProfile);
        } else {
          throw new Error("Failed to fetch userData from API");
        }
      } catch (error) {
        console.error("Error fetching userData:", error);
      }
    };
    fetchUserDataFromAPI();
  }, [userId]);

  const [isHomepage, setIsHomepage] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    setIsHomepage(location.pathname === "/");
  }, [location.pathname]);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const viewportHeight = window.innerHeight;
      setIsScrolled(isHomepage && scrollPosition >= viewportHeight * 0.1);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isHomepage]);

  return (
    <div>
      {isHomepage && !isScrolled ? (
        <div className="headerm">
          <div className="headerm-sub">
            <img src={logo} alt="proptzo" onClick={() => navigate("/")} />
            <div>
              <Badge count={7} overflowCount={99} className="headerm-sub-icon">
                <BellFilled />
              </Badge>
              <MenuOutlined className="headerm-sub-icon" onClick={showDrawer} />
            </div>
          </div>
        </div>
      ) : (
        <div className="headerm headerm-scrolled">
          <div className="headerm-sub">
            <input
              type="search"
              placeholder="search properties / projects"
            ></input>
            <MenuOutlined className="headerm-sub-icon" onClick={showDrawer} />
          </div>
        </div>
      )}
      <Drawer
        title={
          userData
            ? `${userData.userType || ""} ${userData.name || "Welcome Guest!"}`
            : "Welcome Guest!"
        }
        onClose={onCloseHam}
        open={openHam}
      >
        <div className="headerm-ham-category">
          <h5>Services</h5>
          <div
            className="headerm-ham-category-sub"
            onClick={() => {
              navigate("/properties");
              onCloseHam();
            }}
          >
            <ShoppingOutlined className="headerm-ham-category-sub-icon" />
            Buy
          </div>
          <div
            className="headerm-ham-category-sub"
            onClick={() => {
              navigate("/compare");
              onCloseHam();
            }}
          >
            <RetweetOutlined className="headerm-ham-category-sub-icon" />
            Compare
          </div>
        </div>
        <div className="headerm-ham-category">
          <h5>Property Management</h5>
          {userData &&
            selectUserType !== "Owner" &&
            selectUserType !== "Salesperson" && (
              <div
                className="headerm-ham-category-sub"
                onClick={() => {
                  navigate("/postproject");
                  onCloseHam();
                }}
              >
                <PlusCircleOutlined className="headerm-ham-category-sub-icon" />
                Add Project
              </div>
            )}
          <div
            className="headerm-ham-category-sub"
            onClick={() => {
              navigate("/postproperty");
              onCloseHam();
            }}
          >
            <ShopOutlined className="headerm-ham-category-sub-icon" />
            Post Property
          </div>
          {userData && (
            <div
              className="headerm-ham-category-sub"
              onClick={() => {
                navigate("/dashboard");
                onCloseHam();
              }}
            >
              <AppstoreOutlined className="headerm-ham-category-sub-icon" />
              My Dashboard
            </div>
          )}
        </div>
        <div className="headerm-ham-category">
          <h5>Features</h5>
          <div
            className="headerm-ham-category-sub"
            onClick={() => {
              navigate("/trending");
              onCloseHam();
            }}
          >
            <RiseOutlined className="headerm-ham-category-sub-icon" />
            Trending
          </div>
          <div
            className="headerm-ham-category-sub"
            onClick={() => {
              navigate("/download-app");
              onCloseHam();
            }}
          >
            <DownloadOutlined className="headerm-ham-category-sub-icon" />
            Download App
          </div>
        </div>
        <div className="headerm-ham-category">
          <h5>Membership</h5>
          <div
            className="headerm-ham-category-sub"
            onClick={() => {
              navigate("/proptzo-prime");
              onCloseHam();
            }}
          >
            <CrownOutlined className="headerm-ham-category-sub-icon" />
            Proptzo Prime
          </div>
          <div
            className="headerm-ham-category-sub"
            onClick={() => {
              navigate("/prime-benefits");
              onCloseHam();
            }}
          >
            <FileProtectOutlined className="headerm-ham-category-sub-icon" />
            Prime Benefits
          </div>
        </div>
        <div className="headerm-ham-category">
          <h5>About</h5>
          <div
            className="headerm-ham-category-sub"
            onClick={() => {
              navigate("/about");
              onCloseHam();
            }}
          >
            <IssuesCloseOutlined className="headerm-ham-category-sub-icon" />
            About
          </div>
          <div
            className="headerm-ham-category-sub"
            onClick={() => {
              navigate("/insights");
              onCloseHam();
            }}
          >
            <SlidersOutlined className="headerm-ham-category-sub-icon" />
            Insights
          </div>
          <div
            className="headerm-ham-category-sub"
            onClick={() => {
              navigate("/notifications");
              onCloseHam();
            }}
          >
            <BellOutlined className="headerm-ham-category-sub-icon" />
            Notifications
          </div>
        </div>
        <div className="headerm-ham-category">
          <h5>Help & Settings</h5>
          <div
            className="headerm-ham-category-sub"
            onClick={() => {
              navigate("/customer-care");
              onCloseHam();
            }}
          >
            <CustomerServiceOutlined className="headerm-ham-category-sub-icon" />
            Customer Care
          </div>
          <div
            className="headerm-ham-category-sub"
            onClick={() => {
              navigate("/office-location");
              onCloseHam();
            }}
          >
            <EnvironmentOutlined className="headerm-ham-category-sub-icon" />
            Office Location
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default HeaderMobile;
