import React from "react";
import "./HeroMobile.css";

function HeroMobile() {
  return (
    <div className="herom">
      <div className="herom-sub"></div>
      <div className="herom-search">
        <div className="herom-search-input"></div>
        <div className="herom-search-button">
          <h5>Search</h5>
        </div>
      </div>
    </div>
  );
}

export default HeroMobile;
