import axios from "axios";
import { atom } from "recoil";

export const userDataAtom = atom({
  key: "userDataAtom",
  default: {
    user: null,
    userProfile: null,
    isLoading: true,
    isError: false,
  },
});

export const fetchUserData = async () => {
  const baseURL = process.env.REACT_APP_base_URL;
  const userId = localStorage.getItem("userId");
  try {
    const res = await axios.get(`${baseURL}/api/auth/getUserData/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    if (res.data) {
      return res.data;
    } else {
      return {
        user: false,
        userProfile: false,
      };
    }
  } catch (err) {
    return {
      user: false,
      userProfile: false,
    };
  }
};
