import React, { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { Navigate, Outlet } from "react-router-dom";
import { userDataAtom } from "../store/atoms/userDataAtom";
import Loader from "../admin/components/Loader/AdminLoader";

function AdminRoute() {
  const userDetails = useRecoilValue(userDataAtom);

  if (userDetails?.isLoading) {
    return <Loader />;
  }

  const isAdmin = userDetails?.user && userDetails?.user?.isAdmin;

  return isAdmin ? (
    <Suspense fallback={<Loader />}>
      <Outlet />
    </Suspense>
  ) : (
    <Navigate to="/" />
  );
}

export default AdminRoute;
