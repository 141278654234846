import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Password.css";
import logo from "../../assets/svgs/main-logo.svg";
import { Input, Button, message } from "antd";
import { useSetRecoilState } from "recoil";
import { isAuthenticatedAtom } from "../../store/atoms/IsAuthenticatedAtom";

const Password = () => {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const storedPassword = process.env.REACT_APP_PASSWORD;
const setIsAuthenticated = useSetRecoilState(isAuthenticatedAtom);
  const handleLogin = () => {
    if (password.trim() === storedPassword.trim()) {
      sessionStorage.setItem("authenticated", "true");
      setIsAuthenticated(true);
     return navigate('/');
    } else {
      message.error("Incorrect password");
    }
  };

  return (
    <div className="password">
      <div className="password-sub">
        <img src={logo} alt="Logo" />
        <h1>Nice To See You!</h1>
        <h5>Enter your password</h5>
        <Input.Password
          placeholder="input password"
          className="password-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button className="password-button" onClick={handleLogin}>
          Access App
        </Button>
      </div>
    </div>
  );
};

export default Password;
