import React, { useRef } from "react";
import "./Searches.css";

// ANT DESIGN ICONS
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

// REACT ICONS
import {
  BsBagCheck,
  BsClipboard2Plus,
  BsColumnsGap,
  BsHouseCheck,
  BsHeart,
  BsClockHistory,
  BsHandThumbsUp,
  BsPersonGear,
} from "react-icons/bs";

function Searches() {
  const sliderRef = useRef(null);
  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -310,
        behavior: "smooth",
      });
    }
  };
  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 310,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="searches">
      <div className="searches-sub">
        <div className="searches-header">
          <h5>PROPTZO</h5>
          <div className="searches-header-sub">
            <h1>Services</h1>
            <span>
              View All Services
              <RightOutlined className="searches-header-sub-span-icon" />
            </span>
          </div>
        </div>
        <div className="searches-slider">
          <LeftOutlined className="searches-slider-icon" onClick={scrollLeft} />
          <div className="searches-slider-sub" ref={sliderRef}>
            <div className="searches-card">
              <BsBagCheck className="searches-card-icon" />
              <h5>Buy</h5>
            </div>
            <div className="searches-card">
              <BsClipboard2Plus className="searches-card-icon" />
              <h5>Sell</h5>
            </div>
            <div className="searches-card">
              <BsHouseCheck className="searches-card-icon" />
              <h5>Rent</h5>
            </div>
            <div className="searches-card">
              <BsColumnsGap className="searches-card-icon" />
              <h5>Dashboard</h5>
            </div>
            <div className="searches-card">
              <BsHeart className="searches-card-icon" />
              <h5>Wishlist</h5>
            </div>
            <div className="searches-card">
              <BsClockHistory className="searches-card-icon" />
              <h5>Avoid Brokers</h5>
            </div>
            <div className="searches-card">
              <BsHandThumbsUp className="searches-card-icon" />
              <h5>Reviews</h5>
            </div>
            <div className="searches-card">
              <BsPersonGear className="searches-card-icon" />
              <h5>Team Manage</h5>
            </div>
          </div>
          <RightOutlined
            className="searches-slider-icon"
            onClick={scrollRight}
          />
        </div>
      </div>
    </div>
  );
}

export default Searches;
