import React from "react";
import "./Footer.css";

// React Icons
import { IoIosArrowForward } from "react-icons/io";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { CiGlobe } from "react-icons/ci";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-sub">
        <div className="footer-options footer-options-hidden">
          <h5>Proptzo</h5>
          <ul>
            {/* <li>Mobile Apps</li> */}
            <li>Our Services</li>
            {/* <li>Price Trends</li> */}
            <li>Post Your Property</li>
            <li>Real Estate Investments</li>
            <li>Builders in India</li>
            {/* <li>Area Converter</li> */}
            <li>Articles</li>
            {/* <li>Rent Receipt</li> */}
            <li>Customer Service</li>
            <li>Sitemap</li>
          </ul>
        </div>
        <div className="footer-options footer-options-hidden">
          <h5>Company</h5>
          <ul>
            <li>About Us</li>
            <li>Contact Us</li>
            {/* <li>Careers with us</li> */}
            <li>Terms & Conditions</li>
            <li>Request Info</li>
            <li>Feedback</li>
            <li>Report a problem</li>
          </ul>
        </div>
        <div className="footer-options footer-options-hidden">
          <h5>VSGR Group Pvt Ltd</h5>
          <ul>
            <li>Developed by VupgradeU Infotech</li>
          </ul>
        </div>
        <div className="footer-options">
          <h5>Follow Proptzo</h5>
          <div className="footer-social">
            <FaFacebookSquare className="footer-social-icon" />
            <FaSquareXTwitter className="footer-social-icon" />
            <FaInstagramSquare className="footer-social-icon" />
          </div>
          <h5>Lets's stay in touch</h5>
          <div className="footer-email">
            <input type="email" placeholder="Enter email address" />
            <IoIosArrowForward className="footer-email-icon" />
          </div>
          <div className="footer-playstore"></div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-sub">
          <span>Copyright © 2024 - 2024 Proptzo. All Rights Reserved</span>
          <span className="footer-bottom-sub-span footer-bottom-sub-hidden">
            Sitemap
          </span>
        </div>
        <div className="footer-bottom-sub footer-bottom-sub-hidden">
          <span className="footer-bottom-sub-span">India / India</span>
          <CiGlobe className="footer-bottom-sub-icon" />
        </div>
      </div>
    </div>
  );
}

export default Footer;
