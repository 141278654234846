import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// STYLING IMPORT
import "./InitialProfile.css";

// SVG'S IMPORT
import OwnerIcon from "../../assets/svgs/Owner-Icon.svg";
import AgentIcon from "../../assets/svgs/Agent-Icon.svg";
import BuilderIcon from "../../assets/svgs/Builder-Icon.svg";
import SalespersonIcon from "../../assets/svgs/Salesperson-Icon.svg";

// LOADER IMPORT
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";

// REACT ICONS
import { GoArrowLeft } from "react-icons/go";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { GrPowerReset } from "react-icons/gr";
import { IoClose, IoLogoWhatsapp } from "react-icons/io5";
import {
  MdDriveFileRenameOutline,
  MdOutlineEmail,
  MdOutlinePhoneEnabled,
  MdOutlineCompareArrows,
} from "react-icons/md";
import { TbBuildingEstate, TbInputCheck } from "react-icons/tb";
import {
  LuFileImage,
  LuTextCursorInput,
  LuCalendarClock,
  LuFile,
} from "react-icons/lu";
import { PiCity, PiBracketsCurlyBold } from "react-icons/pi";
import { LiaAddressCardSolid } from "react-icons/lia";
import { LiaUserTagSolid } from "react-icons/lia";
import { FiKey } from "react-icons/fi";
import {
  BsBuildingLock,
  BsCurrencyDollar,
  BsCurrencyRupee,
} from "react-icons/bs";
import { AiOutlineAlert, AiOutlineExpandAlt } from "react-icons/ai";
import { FaRegCircleCheck } from "react-icons/fa6";
import { CgTag } from "react-icons/cg";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { GrCurrency } from "react-icons/gr";

// ANT DESIGN IMPORT
import { message, Popconfirm, Select, Switch, Collapse, List } from "antd";
import { AimOutlined, LoadingOutlined } from "@ant-design/icons";

// CITIES IMPORT
import citiesData from "../../utils/CitiesData";

// LEAFLET MAP INTEGRATION
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import locationPin from "../../assets/svgs/locpin.svg";
import L from "leaflet";

// ZOD
import {
  agentEmployeeSchema,
  agentSchema,
  builderEmployeeSchema,
  builderSchema,
  ownerSchema,
  projectSchema,
  salespersonSchema,
} from "../../schemas/userTypeRedirectSchema";

// REUSABLE FORM INPUT COMPONENT
const FormInput = ({
  icon: Icon,
  placeholder,
  value = "",
  onChange,
  type = "text",
}) => (
  <div className="utr-form-input">
    <Icon className="utr-form-input-icon" />
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={value.trim() !== "" ? "utr-form-input-selected" : ""}
    />
  </div>
);

// REUSABLE IMAGE UPLOAD COMPONENT
const ImageUpload = ({ image, onImageChange, onRemove, label }) => (
  <>
    {image ? (
      <div className="utr-form-images-sub">
        <img src={URL.createObjectURL(image)} alt={label} />
        <div className="utr-form-images-remove" onClick={onRemove}>
          <IoClose className="utr-form-images-remove-icon" />
        </div>
      </div>
    ) : (
      <div className="utr-form-images-sub">
        <label htmlFor={`utr-image-upload-${label}`}>
          <LuFileImage className="utr-form-images-sub-icon" />
          <h5>{label}</h5>
        </label>
        <input
          id={`utr-image-upload-${label}`}
          type="file"
          accept="image/*"
          onChange={onImageChange}
          style={{ display: "none" }}
        />
      </div>
    )}
  </>
);

// REUSABLE FILE UPLOAD COMPONENT
const FileUpload = ({ file, onFileChange, onRemove, label }) => (
  <>
    {file ? (
      <div className="utr-form-images-sub utr-form-images-sub-pdf">
        {file.type === "application/pdf" ? (
          <>
            {/* <iframe
              src={URL.createObjectURL(file)}
              title={label}
              className="utr-form-files-preview"
            /> */}
            <div className="utr-form-files-preview-doc">
              <LuFile className="utr-form-images-sub-icon" />
              <h5>{file.name}</h5>
            </div>
          </>
        ) : (
          <div className="utr-form-files-preview-doc">
            <LuFile className="utr-form-images-sub-icon" />
            <h5>{file.name}</h5>
          </div>
        )}
        <div className="utr-form-images-remove" onClick={onRemove}>
          <IoClose className="utr-form-images-remove-icon" />
        </div>
      </div>
    ) : (
      <div className="utr-form-images-sub">
        <label htmlFor={`utr-file-upload-${label}`}>
          <LuFileImage className="utr-form-images-sub-icon" />
          <h5>{label}</h5>
        </label>
        <input
          id={`utr-file-upload-${label}`}
          type="file"
          accept=".pdf,.doc,.docx"
          onChange={onFileChange}
          style={{ display: "none" }}
        />
      </div>
    )}
  </>
);

function InitialProfile() {
  const baseURL = process.env.REACT_APP_base_URL;
  const userId = localStorage.getItem("userId");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Proptzo - Create Your Profile";
  }, []);

  // Steps
  const [currentStep, setCurrentStep] = useState(1);
  const nextStep = () => {
    if (!userType) {
      message.warning("Please select your user type first.");
      return;
    }
    setLoading(true);
    setTimeout(() => {
      setCurrentStep((prevStep) => prevStep + 1);
      setLoading(false);
    }, 300);
  };
  const prevStep = () => {
    setLoading(true);
    setTimeout(() => {
      setCurrentStep((prevStep) => prevStep - 1);
      setLoading(false);
    }, 300);
  };

  // Form Reset
  const confirmFormReset = (e) => {
    message.success("Profile Data Reset success!");
    setUserType(null);
    //Owner Profile
    setChecked(false);
    setFullName("");
    setEmail("");
    setMobile("");
    setWhatsappMobile("");
    setProfileImage(null);
    // Builder Profile
    setBuilderDetails({
      builderFirmName: "",
      builderReraNo: "",
      builderReraNoType: "",
      builderProfilePhoto: null,
      builderFirmLogo: null,
      builderAddress: "",
      builderOfficeCity: "",
    });
    setBuilderEmployeeDetails({
      employeeName: "",
      employeeDesignation: "",
      employeeEmail: "",
      employeeMobileNo: "",
      employeeID: "",
      employeeImage: null,
      employeementLetterImage: null,
      employeeIdCardImage: null,
    });
    setBuilderAddedEmployeesList([]);
    setBuilderProjectDetails({
      projectName: "",
      projectReraNo: "",
      projectDescription: "",
      projectStatus: "",
      projectCompletionYear: "",
      projectOwnershipType: "",
      projectFloorPlans: [],
      projectArea: "",
      projectAreaUnit: "",
      projectPriceRange: {
        priceFrom: "",
        priceTo: "",
      },
      projectCurrencyType: "₹",
      projectCity: "",
      projectLocation: {
        latitude: 0,
        longitude: 0,
      },
      projectLocality: "",
      projectLogo: null,
      projectLayout: null,
      projectBrochure: null,
    });
    setBuilderProjectDescriptionCharacterCount(0);
    setBuilderProjectLocalityCharacterCount(0);
    // Reset Map States
    setClickedPosition(null);
    setPlaceName("");
    setPrevPlaceName("");
    setZoomLevel(12);
    setSelectedCityCoordinates({
      longitude: 0,
      latitude: 0,
    });
    setBuilderAddedProjectsList([]);
    // Agent Profile
    setAgentDetails({
      dealsIn: [],
      agentFirmName: "",
      agentReraNo: "",
      agentReraNoType: "",
      agentAddress: "",
      agentOfficeCity: "",
      agentProfilePhoto: null,
      agentFirmLogo: null,
    });
    setAgentEmployeeDetails({
      employeeName: "",
      employeeEmail: "",
      employeeMobileNo: "",
      employeeDesignation: "",
      employeeIdType: "",
      employeeID: "",
      employeeAadhaarCardNo: "",
      employeePanCardNo: "",
      employeeVoterCardNo: "",
      employeePassportNo: "",
      employeeDrivingLicenseNo: "",
      employeeImage: null,
      employeeIdCardImage: null,
      employeeAadhaarcardFrontImage: null,
      employeeAadhaarcardBackImage: null,
      employeePancardImage: null,
      employeeVoterIdFrontImage: null,
      employeeVoterIdBackImage: null,
      employeePassportImage: null,
      employeeDrivinglicenseFrontImage: null,
      employeeDrivinglicenseBackImage: null,
      employeementLetterImage: null,
    });
    setAgentAddedEmployeesList([]);
    // Salesperson Profile
    setSalespersonChecked(false);
    setSalespersonDetails({
      employeeName: "",
      employeeEmail: "",
      employeeMobileNo: "",
      employeeWhatsappMobile: "",
      employeeIdType: "",
      employeeDesignation: "",
      employeeID: "",
      employeeAadhaarCardNo: "",
      employeePanCardNo: "",
      employeeVoterCardNo: "",
      employeePassportNo: "",
      employeeDrivingLicenseNo: "",
      employeeImage: null,
      employeementLetterImage: null,
      employeeIdCardImage: null,
      employeeAadhaarcardFrontImage: null,
      employeeAadhaarcardBackImage: null,
      employeePancardImage: null,
      employeeVoterIdFrontImage: null,
      employeeVoterIdBackImage: null,
      employeePassportImage: null,
      employeeDrivinglicenseFrontImage: null,
      employeeDrivinglicenseBackImage: null,
    });
    setCurrentStep(1);
  };
  const cancelFormReset = (e) => {
    message.error("Reset cancelled!");
  };

  // User Type
  const [userType, setUserType] = useState(null);
  useEffect(() => {
    if (userType === "Owner") {
      // Reset Owner related states
      setChecked(false);
      setProfileImage(null);
      setFullName("");
      setEmail("");
      setMobile("");
      setWhatsappMobile("");
    } else if (userType === "Builder") {
      // Reset Builder related states
      setBuilderDetails({
        builderFirmName: "",
        builderReraNo: "",
        builderReraNoType: "",
        builderProfilePhoto: null,
        builderFirmLogo: null,
        builderAddress: "",
        builderOfficeCity: "",
      });
      setBuilderAddressCharacterCount(0);
      setBuilderEmployeeDetails({
        employeeName: "",
        employeeDesignation: "",
        employeeEmail: "",
        employeeMobileNo: "",
        employeeID: "",
        employeeImage: null,
        employeementLetterImage: null,
        employeeIdCardImage: null,
      });
      setBuilderAddedEmployeesList([]);
      setBuilderProjectDetails({
        projectName: "",
        projectReraNo: "",
        projectDescription: "",
        projectStatus: "",
        projectCompletionYear: "",
        projectOwnershipType: "",
        projectFloorPlans: [],
        projectArea: "",
        projectAreaUnit: "",
        projectPriceRange: {
          priceFrom: "",
          priceTo: "",
        },
        projectCurrencyType: "₹",
        projectCity: "",
        projectLocation: {
          latitude: 0,
          longitude: 0,
        },
        projectLocality: "",
        projectLogo: null,
        projectLayout: null,
        projectBrochure: null,
      });
      setBuilderProjectDescriptionCharacterCount(0);
      setBuilderProjectLocalityCharacterCount(0);
      // Reset Map States
      setClickedPosition(null);
      setPlaceName("");
      setPrevPlaceName("");
      setZoomLevel(12);
      setSelectedCityCoordinates({
        longitude: 0,
        latitude: 0,
      });
      setBuilderAddedProjectsList([]);
    } else if (userType === "Agent") {
      // Reset Agent related states
      setAgentDetails({
        dealsIn: [],
        agentFirmName: "",
        agentReraNo: "",
        agentReraNoType: "",
        agentAddress: "",
        agentOfficeCity: "",
        agentProfilePhoto: null,
        agentFirmLogo: null,
      });
      setAgentEmployeeDetails({
        employeeName: "",
        employeeEmail: "",
        employeeMobileNo: "",
        employeeDesignation: "",
        employeeIdType: "",
        employeeID: "",
        employeeAadhaarCardNo: "",
        employeePanCardNo: "",
        employeeVoterCardNo: "",
        employeePassportNo: "",
        employeeDrivingLicenseNo: "",
        employeeImage: null,
        employeeIdCardImage: null,
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,
        employeePancardImage: null,
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
        employeePassportImage: null,
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,
        employeementLetterImage: null,
      });
      setAgentAddedEmployeesList([]);
    } else if (userType === "Salesperson") {
      // Reset Salesperson related states
      setSalespersonChecked(false);
      setSalespersonDetails({
        employeeName: "",
        employeeEmail: "",
        employeeMobileNo: "",
        employeeWhatsappMobile: "",
        employeeIdType: "",
        employeeDesignation: "",
        employeeID: "",
        employeeAadhaarCardNo: "",
        employeePanCardNo: "",
        employeeVoterCardNo: "",
        employeePassportNo: "",
        employeeDrivingLicenseNo: "",
        employeeImage: null,
        employeementLetterImage: null,
        employeeIdCardImage: null,
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,
        employeePancardImage: null,
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
        employeePassportImage: null,
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,
      });
    }
  }, [userType]);

  // Cities with their GeoCodes
  const cityOptions = citiesData.map((city) => ({
    cityName: city.city,
    geocode: city.geoCode,
  }));

  //
  const filterCityData = (selectedCity) =>
    citiesData.filter((city) => city.city === selectedCity)[0];

  // Owner Profile Details
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const handleMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setMobile(value);
    }
  };
  const formatMobileDisplay = (mobile) => {
    const formatted = mobile.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };
  const [whatsappMobile, setWhatsappMobile] = useState("");
  const handleWhatsappMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setWhatsappMobile(value);
    }
  };
  const formatWhatsappMobileDisplay = (whatsappMobile) => {
    const formatted = whatsappMobile.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };
  const [checked, setChecked] = useState(false);
  const onSameWhatsappMobileAsMobileChange = (isChecked) => {
    setChecked(isChecked);
    if (isChecked) {
      setWhatsappMobile(mobile);
    } else {
      setWhatsappMobile("");
    }
  };
  const switchBackgroundColor = checked ? "var(--primary-color)" : "#ccc";
  const [profileImage, setProfileImage] = useState(null);

  // Builder Profile Details
  const [builderDetails, setBuilderDetails] = useState({
    builderFirmName: "",
    builderReraNo: "",
    builderReraNoType: "",
    builderOfficeCity: "",
    builderOfficeWebsite: "",
    builderAddress: "",
    builderProfilePhoto: null,
    builderFirmLogo: null,
  });
  // console.log(builderDetails);
  // Builder Address
  const [builderAddressCharacterCount, setBuilderAddressCharacterCount] =
    useState(0);
  const handleBuilderAddressChange = (e) => {
    const newBuilderAddress = e.target.value;
    if (newBuilderAddress.length <= 100) {
      setBuilderDetails({
        ...builderDetails,
        builderAddress: newBuilderAddress,
      });
      setBuilderAddressCharacterCount(newBuilderAddress.length);
    } else {
      setBuilderDetails({
        ...builderDetails,
        builderAddress: newBuilderAddress.slice(0, 100),
      });
      setBuilderAddressCharacterCount(100);
    }
  };
  const builderAddressSpanContent =
    builderAddressCharacterCount === 100
      ? "Max character limit reached"
      : "Provide complete address";
  // Builder Employees
  const [builderEmployeeDetails, setBuilderEmployeeDetails] = useState({
    employeeName: "",
    employeeEmail: "",
    employeeMobileNo: "",
    employeeDesignation: "",
    employeeID: "",
    employeeImage: null,
    employeementLetterImage: null,
    employeeIdCardImage: null,
  });
  // Builder Employee Mobile
  const handleBuilderEmployeeMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setBuilderEmployeeDetails((prev) => ({
        ...prev,
        employeeMobileNo: value,
      }));
    }
  };
  const formatBuilderEmployeeMobileDisplay = (employeeMobileNo) => {
    const formatted = employeeMobileNo.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };
  // Builder Added Employees List
  const [builderAddedEmployeesList, setBuilderAddedEmployeesList] = useState(
    []
  );

  // console.log(builderAddedEmployeesList);
  const addBuilderEmployee = async () => {
    // validate here in zod the builder employee data object ha no missing required fields

    if (
      builderEmployeeSchema.safeParse({
        employeeFullName: builderEmployeeDetails.employeeName,
      }).success === false
    ) {
      const errorMessage = builderEmployeeSchema.safeParse({
        employeeFullName: builderEmployeeDetails.employeeName,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Employee name");
      message.error(customMessage);
      return;
    }

    if (
      builderEmployeeSchema.safeParse({
        employeeDesignation: builderEmployeeDetails.employeeDesignation,
      }).success === false
    ) {
      const errorMessage = builderEmployeeSchema.safeParse({
        employeeDesignation: builderEmployeeDetails.employeeDesignation,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Employee designation"
      );
      message.error(customMessage);
      return;
    }

    if (
      builderEmployeeSchema.safeParse({
        employeeEmail: builderEmployeeDetails.employeeEmail,
      }).success === false
    ) {
      const errorMessage = builderEmployeeSchema.safeParse({
        employeeEmail: builderEmployeeDetails.employeeEmail,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Employee email");
      message.error(customMessage);
      return;
    }

    if (
      builderEmployeeSchema.safeParse({
        employeeMobileNo: builderEmployeeDetails.employeeMobileNo,
      }).success === false
    ) {
      const errorMessage = builderEmployeeSchema.safeParse({
        employeeMobileNo: builderEmployeeDetails.employeeMobileNo,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Employee mobile no"
      );
      message.error(customMessage);
      return;
    }

    if (
      builderEmployeeSchema.safeParse({
        employeeId: builderEmployeeDetails.employeeID,
      }).success === false
    ) {
      const errorMessage = builderEmployeeSchema.safeParse({
        employeeId: builderEmployeeDetails.employeeID,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Employee id");
      message.error(customMessage);
      return;
    }

    if (!builderEmployeeDetails.employeeImage) {
      message.error("Employee image required");
      return;
    }

    if (!builderEmployeeDetails.employeeIdCardImage) {
      message.error("Employee id image required");
      return;
    }

    if (
      builderAddedEmployeesList.length > 0 &&
      builderAddedEmployeesList.some(
        (employee) =>
          employee.employeeEmail === builderEmployeeDetails.employeeEmail ||
          employee.employeeMobileNo ===
            builderEmployeeDetails.employeeMobileNo.replace(
              /(\d{2})(\d{0,10})/,
              "+$1 $2"
            )
      )
    ) {
      const identifier = builderAddedEmployeesList.some(
        (employee) =>
          employee.employeeEmail === builderEmployeeDetails.employeeEmail
      )
        ? "email"
        : builderAddedEmployeesList.some(
            (employee) =>
              employee.employeeMobileNo ===
              builderEmployeeDetails.employeeMobileNo.replace(
                /(\d{2})(\d{0,10})/,
                "+$1 $2"
              )
          )
        ? "mobile no"
        : "credentials";
      message.error(
        `Employee with this ${identifier} already exists in the employee list`
      );
      return;
    }

    try {
      const response = await axios.get(
        `${baseURL}/api/profile/checkDuplicateCredentials`,
        {
          params: {
            email: builderEmployeeDetails.employeeEmail,
            mobileNo: builderEmployeeDetails.employeeMobileNo,
          },
        }
      );
      if (
        response.data.message === "User added successfully" ||
        response.status === 200 ||
        response.statusText === "OK"
      ) {
        message.success(response.data.message);
        setBuilderAddedEmployeesList((prev) => [
          ...prev,
          {
            ...builderEmployeeDetails,
            employeeMobileNo: formatBuilderEmployeeMobileDisplay(
              builderEmployeeDetails.employeeMobileNo
            ),
          },
        ]);
        setBuilderEmployeeDetails({
          employeeName: "",
          employeeEmail: "",
          employeeMobileNo: "",
          employeeDesignation: "",
          employeeID: "",
          employeeImage: null,
          employeementLetterImage: null,
          employeeIdCardImage: null,
        });
      }
    } catch (err) {
      message.error(err.response.data.message);
      return;
    }
  };

  const removeBuilderEmployee = (index) => {
    setBuilderAddedEmployeesList((prev) => prev.filter((_, i) => i !== index));
  };
  const renderBuilderEmployeeImage = (image, placeholderText) => {
    if (image) {
      return URL.createObjectURL(image);
    }
    return `https://placehold.co/50x50?text=${placeholderText}`;
  };

  const builderEmployeesForm = useMemo(
    () => (
      <>
        <div className="utr-form-category">
          <h4>Employee Details</h4>
          <div className="utr-form-inputs">
            <FormInput
              icon={MdDriveFileRenameOutline}
              placeholder="Employee Full Name"
              value={builderEmployeeDetails.employeeName}
              onChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeName: e.target.value,
                }))
              }
            />
            <FormInput
              icon={LiaUserTagSolid}
              placeholder="Employee Designation"
              value={builderEmployeeDetails.employeeDesignation}
              onChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeDesignation: e.target.value,
                }))
              }
            />
            <FormInput
              icon={MdOutlineEmail}
              placeholder="Employee Email"
              value={builderEmployeeDetails.employeeEmail}
              onChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeEmail: e.target.value,
                }))
              }
            />
            <FormInput
              icon={MdOutlinePhoneEnabled}
              placeholder="Employee Mobile No."
              value={formatBuilderEmployeeMobileDisplay(
                builderEmployeeDetails.employeeMobileNo
              )}
              onChange={handleBuilderEmployeeMobileChange}
              maxLength="14"
            />
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Employee ID"
              value={builderEmployeeDetails.employeeID}
              onChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeID: e.target.value,
                }))
              }
            />
          </div>
        </div>
        <div className="utr-form-category">
          <h4>Employee Photos</h4>
          <div className="utr-form-images">
            <ImageUpload
              image={builderEmployeeDetails.employeeImage}
              onImageChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeImage: null,
                }))
              }
              label="Profile"
            />
            <ImageUpload
              image={builderEmployeeDetails.employeementLetterImage}
              onImageChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeementLetterImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeementLetterImage: null,
                }))
              }
              label="Employment Letter"
            />
            <ImageUpload
              image={builderEmployeeDetails.employeeIdCardImage}
              onImageChange={(e) =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeIdCardImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setBuilderEmployeeDetails((prev) => ({
                  ...prev,
                  employeeIdCardImage: null,
                }))
              }
              label="ID Card"
            />
          </div>
          <button
            className="utr-add-project-employee-add"
            onClick={addBuilderEmployee}
          >
            Add Employee
          </button>
        </div>
        {builderAddedEmployeesList.length > 0 && (
          <>
            <div className="utr-form-category">
              <h4>
                {builderAddedEmployeesList.length === 1
                  ? "Added Employee"
                  : "Added Employees"}{" "}
                ({builderAddedEmployeesList.length})
              </h4>
              <List
                itemLayout="horizontal"
                dataSource={builderAddedEmployeesList}
                renderItem={(item, index) => (
                  <List.Item
                    key={index}
                    actions={[
                      <h5
                        className="utr-employees-remove red"
                        onClick={() => removeBuilderEmployee(index)}
                      >
                        remove
                      </h5>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <img
                          src={renderBuilderEmployeeImage(
                            item.employeeImage,
                            item.employeeName.charAt(0)
                          )}
                          alt="Profile"
                          className="utr-employees-img"
                        />
                      }
                      title={
                        <div className="utr-employees-name">
                          {item.employeeName}
                        </div>
                      }
                      description={
                        <div>
                          <div className="utr-employees-description">
                            {item.employeeDesignation} / {item.employeeEmail} /{" "}
                            {item.employeeMobileNo} / {item.employeeID}
                          </div>
                          {item.employeementLetterImage ? (
                            <img
                              src={renderBuilderEmployeeImage(
                                item.employeementLetterImage,
                                "EL"
                              )}
                              alt="Employment Letter"
                              className="utr-employees-img-docs"
                            />
                          ) : (
                            <img
                              src={`https://placehold.co/30x30?text=EL`}
                              alt="Employment Letter Placeholder"
                              className="utr-employees-img-docs"
                            />
                          )}
                          {item.employeeIdCardImage ? (
                            <img
                              src={renderBuilderEmployeeImage(
                                item.employeeIdCardImage,
                                "ID"
                              )}
                              alt="ID Card"
                              className="utr-employees-img-docs"
                            />
                          ) : (
                            <img
                              src={`https://placehold.co/30x30?text=ID`}
                              alt="ID Card Placeholder"
                              className="utr-employees-img-docs"
                            />
                          )}
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
            <div className="utr-form-category">
              <h4>Note</h4>
              <h6>
                (You can also add these employees later on the profile page
                under the "{userType} Employees" section.)
              </h6>
            </div>
          </>
        )}
      </>
    ),
    [builderEmployeeDetails, builderAddedEmployeesList]
  );
  // Builder Employees Reset
  const confirmBuilderEmployeesReset = (e) => {
    message.success("Employees Data Reset success!");
    setBuilderEmployeeDetails({
      employeeName: "",
      employeeDesignation: "",
      employeeEmail: "",
      employeeMobileNo: "",
      employeeID: "",
      employeeImage: null,
      employeementLetterImage: null,
      employeeIdCardImage: null,
    });
    setBuilderAddedEmployeesList([]);
  };
  const cancelBuilderEmployeesReset = (e) => {
    message.error("Employess reset cancelled!");
  };
  const builderEmployeesCollapse = useMemo(
    () => [
      {
        key: "1",
        label: `${builderAddedEmployeesList.length} ${
          builderAddedEmployeesList.length === 1 ? "Employee" : "Employees"
        } added`,
        children: builderEmployeesForm,
        extra: (
          <Popconfirm
            title="Reset Employee Data"
            description="Are you sure to reset all employee data?"
            onConfirm={confirmBuilderEmployeesReset}
            onCancel={cancelBuilderEmployeesReset}
            okText="Reset"
            cancelText="Cancel"
          >
            <GrPowerReset className="utr-collapse-icon" />
          </Popconfirm>
        ),
      },
    ],
    [builderEmployeesForm, builderAddedEmployeesList.length]
  );

  // Builder Projects
  const [builderProjectDetails, setBuilderProjectDetails] = useState({
    projectName: "",
    projectReraNo: "",
    projectDescription: "",
    projectStatus: "",
    projectCompletionYear: "",
    projectOwnershipType: "",
    projectFloorPlans: [],
    projectArea: "",
    projectAreaUnit: "",
    projectPriceRange: {
      priceFrom: "",
      priceTo: "",
    },
    projectCurrencyType: "₹",
    projectCity: "",
    projectLocation: {
      latitude: 0,
      longitude: 0,
    },
    projectLocality: "",
    projectLogo: null,
    projectLayout: null,
    projectBrochure: null,
  });
  // Builder Project Description
  const [
    builderProjectDescriptionCharacterCount,
    setBuilderProjectDescriptionCharacterCount,
  ] = useState(0);
  const handleBuilderProjectDescriptionChange = (e) => {
    const newBuilderProjectDescription = e.target.value;
    if (newBuilderProjectDescription.length <= 2500) {
      setBuilderProjectDetails((prev) => ({
        ...prev,
        projectDescription: newBuilderProjectDescription,
      }));
      setBuilderProjectDescriptionCharacterCount(
        newBuilderProjectDescription.length
      );
    } else {
      setBuilderProjectDetails((prev) => ({
        ...prev,
        projectDescription: newBuilderProjectDescription.slice(0, 2500),
      }));
      setBuilderProjectDescriptionCharacterCount(2500);
    }
  };
  const builderProjectDescriptionSpanContent =
    builderProjectDescriptionCharacterCount === 2500
      ? "Max character limit reached"
      : "Provide project description";
  // Builder Project Status
  const handleBuilderProjectStatusChange = (status) => {
    setBuilderProjectDetails((prev) => ({
      ...prev,
      projectStatus: status,
    }));
  };
  const isReadyToMoveSelected =
    builderProjectDetails.projectStatus === "Ready to Move";
  const isUnderConstructionSelected =
    builderProjectDetails.projectStatus === "Under Construction";
  // Builder Project Completion Year
  const handleBuilderProjectCompletionYearYearChange = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 4);
    setBuilderProjectDetails((prev) => ({
      ...prev,
      projectCompletionYear: numericValue,
    }));
  };
  // Builder Ownership Type
  const handleBuilderProjectOwnershipTypeChange = (status) => {
    setBuilderProjectDetails((prev) => ({
      ...prev,
      projectOwnershipType: status,
    }));
  };
  const isFreeholdSelected =
    builderProjectDetails.projectOwnershipType === "Freehold";
  const isLeaseholdSelected =
    builderProjectDetails.projectOwnershipType === "Leasehold";
  // Builder Project Floor Plans
  const handleBuilderProjectFloorPlansChange = (floorPlan) => {
    setBuilderProjectDetails((prev) => {
      const isSelected = prev.projectFloorPlans.includes(floorPlan);
      const newFloorPlans = isSelected
        ? prev.projectFloorPlans.filter((plan) => plan !== floorPlan)
        : [...prev.projectFloorPlans, floorPlan];
      return {
        ...prev,
        projectFloorPlans: newFloorPlans,
      };
    });
  };
  const handleClearBuilderProjectFloorPlansSelection = () => {
    setBuilderProjectDetails((prev) => ({
      ...prev,
      projectFloorPlans: [],
    }));
  };
  const isBuilderFloorPlanSelected = (floorPlan) =>
    builderProjectDetails.projectFloorPlans.includes(floorPlan);
  // Builder Price Range
  const formatPrice = (price, currencyType) => {
    const numericPrice = parseFloat(price);
    if (!isNaN(numericPrice)) {
      let formattedPrice = "";
      if (currencyType === "₹") {
        if (numericPrice >= 10000000) {
          formattedPrice = `₹ ${numericPrice / 10000000} Cr`;
        } else if (numericPrice >= 100000) {
          formattedPrice = `₹ ${numericPrice / 100000} Lakh`;
        } else if (numericPrice >= 1000) {
          formattedPrice = `₹ ${numericPrice / 1000} K`;
        } else {
          formattedPrice = `₹ ${numericPrice}`;
        }
      } else if (currencyType === "$") {
        if (numericPrice >= 1000000) {
          formattedPrice = `$ ${numericPrice / 1000000} M`;
        } else if (numericPrice >= 1000) {
          formattedPrice = `$ ${numericPrice / 1000} K`;
        } else {
          formattedPrice = `$ ${numericPrice}`;
        }
      }
      return formattedPrice;
    } else {
      return "";
    }
  };
  const [isPriceRangeTyping, setIsPriceRangeTyping] = useState(false);
  const handleProjectPriceRangeChange = (field, value) => {
    setIsPriceRangeTyping(!!value);
    setBuilderProjectDetails((prev) => ({
      ...prev,
      projectPriceRange: {
        ...prev.projectPriceRange,
        [field]: value,
      },
    }));
  };
  // Builder Project Locality
  const [
    builderProjectLocalityCharacterCount,
    setBuilderProjectLocalityCharacterCount,
  ] = useState(0);
  const handleBuilderProjectLocalityChange = (e) => {
    const newBuilderProjectLocality = e.target.value;
    if (newBuilderProjectLocality.length <= 500) {
      setBuilderProjectDetails((prev) => ({
        ...prev,
        projectLocality: newBuilderProjectLocality,
      }));
      setBuilderProjectLocalityCharacterCount(newBuilderProjectLocality.length);
    } else {
      setBuilderProjectDetails((prev) => ({
        ...prev,
        projectLocality: newBuilderProjectLocality.slice(0, 500),
      }));
      setBuilderProjectLocalityCharacterCount(500);
    }
  };
  const builderProjectLocalitySpanContent =
    builderProjectLocalityCharacterCount === 500
      ? "Max character limit reached"
      : "Provide project locality";
  // Builder Project location
  const [clickedPosition, setClickedPosition] = useState(null);
  const [placeName, setPlaceName] = useState("");
  const [prevPlaceName, setPrevPlaceName] = useState("");
  const [zoomLevel, setZoomLevel] = useState(12);
  const [selectedCityCoordinates, setSelectedCityCoordinates] = useState({
    longitude: 0,
    latitude: 0,
  });
  function ClickHandler() {
    const map = useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        setClickedPosition([lat, lng]);
        setBuilderProjectDetails((prev) => ({
          ...prev,
          projectLocation: { latitude: lat, longitude: lng },
        }));
        const fetchLocality = async () =>
          await axios
            .get(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&addressdetails=${1}`
            )
            .then((response) => {
              const { display_name } = response.data;
              setPlaceName(display_name);

              setBuilderProjectDetails((prev) => ({
                ...prev,
                projectLocality: display_name,
              }));
            })
            .catch((error) => {
              console.error("Error fetching place name:", error);
              setPlaceName(null);
            });
        fetchLocality();
      },
    });
    useEffect(() => {
      if (placeName !== "" && placeName !== prevPlaceName) {
        setBuilderProjectDetails((prev) => ({
          ...prev,
          projectLocality: placeName,
        }));
        setPrevPlaceName(placeName);
      }
    }, [placeName]);
    useEffect(() => {
      if (
        selectedCityCoordinates.latitude !== 0 &&
        selectedCityCoordinates.longitude !== 0 &&
        map
      ) {
        map.setView(
          [selectedCityCoordinates.latitude, selectedCityCoordinates.longitude],
          zoomLevel
        );
      }
    }, [selectedCityCoordinates, zoomLevel, map]);

    // Add a new useEffect to update zoom level when map zoom changes
    useEffect(() => {
      const handleZoomEnd = () => {
        if (map) {
          setZoomLevel(map.getZoom());
        }
      };
      const handleMoveEnd = () => {
        if (map) {
          const { lng, lat } = map.getCenter();
          setSelectedCityCoordinates((prevCityCoordinates) => ({
            ...prevCityCoordinates,
            latitude: lat,
            longitude: lng,
          }));
        }
      };
      if (map) {
        map.on("zoomend", handleZoomEnd);
        map.on("dragend", handleMoveEnd);
      }
      return () => {
        if (map) {
          map.off("zoomend", handleZoomEnd);
          map.off("dragend", handleMoveEnd);
        }
      };
    }, [map]);
    return null;
  }
  const customIcon = L.icon({
    iconUrl: locationPin,
    iconSize: [40, 40],
    iconAnchor: [16, 32],
  });
  const getLiveLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          setSelectedCityCoordinates({
            latitude: latitude,
            longitude: longitude,
          });
          setClickedPosition([latitude, longitude]);
          setBuilderProjectDetails((prev) => ({
            ...prev,
            projectLocation: { latitude, longitude },
          }));
          setZoomLevel(18);

          (async function fetchLocationAddress() {
            await axios
              .get(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=${1}`
              )
              .then((response) => {
                const { display_name } = response.data;
                setPlaceName(display_name);

                setBuilderProjectDetails((prev) => ({
                  ...prev,
                  projectLocality: display_name,
                }));
              })
              .catch((error) => {
                console.error("Error fetching place name:", error);
                setPlaceName(null);
              });
          })();
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser");
    }
  };
  // Builder Added Employees List
  const [builderAddedProjectsList, setBuilderAddedProjectsList] = useState([]);
  // console.log(builderAddedEmployeesList);
  const addBuilderProject = () => {
    if (
      projectSchema.safeParse({
        projectName: builderProjectDetails.projectName,
      }).success === false
    ) {
      const errorMessage = projectSchema.safeParse({
        projectName: builderProjectDetails.projectName,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Project name");
      message.error(customMessage);
      return;
    }

    if (
      projectSchema.safeParse({
        projectReraNo: builderProjectDetails.projectReraNo,
      }).success === false
    ) {
      const errorMessage = projectSchema.safeParse({
        projectReraNo: builderProjectDetails.projectReraNo,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Project rera no");
      message.error(customMessage);
      return;
    }

    if (
      projectSchema.safeParse({
        projectDescription: builderProjectDetails.projectDescription,
      }).success === false
    ) {
      const errorMessage = projectSchema.safeParse({
        projectDescription: builderProjectDetails.projectDescription,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Project description"
      );
      message.error(customMessage);
      return;
    }

    if (
      projectSchema.safeParse({
        projectStatus: builderProjectDetails.projectStatus,
      }).success === false
    ) {
      message.error("Select project status type");
      return;
    }

    if (
      builderProjectDetails.projectStatus === "Under Construction" &&
      projectSchema.safeParse({
        projectCompletionYear: builderProjectDetails.projectCompletionYear,
      }).success === false
    ) {
      message.error("provide estimated completion year");
      return;
    }

    if (
      projectSchema.safeParse({
        projectOwnershipType: builderProjectDetails.projectOwnershipType,
      }).success === false
    ) {
      message.error("Select project ownership type");
      return;
    }

    if (builderProjectDetails.projectFloorPlans?.length <= 0) {
      message.error("Project floor plans must contain atleast 1 plan");
      return;
    }

    if (
      projectSchema.safeParse({
        projectArea: builderProjectDetails.projectArea,
      }).success === false
    ) {
      const errorMessage = projectSchema.safeParse({
        projectArea: builderProjectDetails.projectArea,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Project area");
      message.error(customMessage);
      return;
    }

    if (
      projectSchema.safeParse({
        projectAreaUnit: builderProjectDetails.projectAreaUnit,
      }).success === false
    ) {
      message.error("Select project area unit");
      return;
    }

    if (
      projectSchema.safeParse({
        projectPriceRangePriceFrom:
          builderProjectDetails.projectPriceRange.priceFrom,
      }).success === false
    ) {
      message.error("Price range required");
      return;
    }

    if (
      projectSchema.safeParse({
        projectPriceRangePriceTo:
          builderProjectDetails.projectPriceRange.priceTo,
      }).success === false
    ) {
      message.error("Price range required");
      return;
    }

    if (
      projectSchema.safeParse({
        projectCurrencyType: builderProjectDetails.projectCurrencyType,
      }).success === false
    ) {
      const errorMessage = projectSchema.safeParse({
        projectCurrencyType: builderProjectDetails.projectCurrencyType,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Project currency type"
      );
      message.error(customMessage);
      return;
    }

    if (
      projectSchema.safeParse({
        projectCity: builderProjectDetails.projectCity,
      }).success === false
    ) {
      message.error("Select project city.");
      return;
    }

    if (
      builderProjectDetails.projectLocation.latitude === 0 ||
      builderProjectDetails.projectLocation.longitude === 0
    ) {
      message.error("Project location required");
      return;
    }

    if (
      projectSchema.safeParse({
        projectLocality: builderProjectDetails.projectLocality,
      }).success === false
    ) {
      const errorMessage = projectSchema.safeParse({
        projectLocality: builderProjectDetails.projectLocality,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Project locality");
      message.error(customMessage);
      return;
    }

    if (!builderProjectDetails.projectLogo) {
      message.error("Project logo required.");
      return;
    }

    if (!builderProjectDetails.projectLayout) {
      message.error("Project layout required.");
      return;
    }

    if (!builderProjectDetails.projectBrochure) {
      message.error("Project brochure required.");
      return;
    }

    setBuilderAddedProjectsList((prev) => [
      ...prev,
      {
        ...builderProjectDetails,
        projectPriceRange: {
          ...builderProjectDetails.projectPriceRange,
          priceFrom: builderProjectDetails.projectPriceRange.priceFrom,
          priceTo: builderProjectDetails.projectPriceRange.priceTo,
        },
      },
    ]);
    setBuilderProjectDetails({
      projectName: "",
      projectReraNo: "",
      projectDescription: "",
      projectStatus: "",
      projectCompletionYear: "",
      projectOwnershipType: "",
      projectFloorPlans: [],
      projectArea: "",
      projectAreaUnit: "",
      projectPriceRange: {
        priceFrom: "",
        priceTo: "",
      },
      projectCurrencyType: "₹",
      projectCity: "",
      projectLocation: {
        latitude: 0,
        longitude: 0,
      },
      projectLocality: "",
      projectLogo: null,
      projectLayout: null,
      projectBrochure: null,
    });
  };
  const removeBuilderProject = (index) => {
    setBuilderAddedProjectsList((prev) => prev.filter((_, i) => i !== index));
  };
  const renderBuilderProjectImage = (image, placeholderText) => {
    if (image) {
      return URL.createObjectURL(image);
    }
    return `https://placehold.co/50x50?text=${placeholderText}`;
  };

  const builderProjectsForm = useMemo(
    () => (
      <>
        <div className="utr-form-category">
          <h4>Project Details</h4>
          <div className="utr-form-inputs">
            <FormInput
              icon={MdDriveFileRenameOutline}
              placeholder="Project Name"
              value={builderProjectDetails.projectName}
              onChange={(e) =>
                setBuilderProjectDetails((prev) => ({
                  ...prev,
                  projectName: e.target.value,
                }))
              }
            />
            <FormInput
              icon={TbInputCheck}
              placeholder="Project Rera No."
              value={builderProjectDetails.projectReraNo}
              onChange={(e) =>
                setBuilderProjectDetails((prev) => ({
                  ...prev,
                  projectReraNo: e.target.value,
                }))
              }
            />
            <div className="utr-form-input utr-form-input-flex">
              <textarea
                placeholder="Project Description"
                value={builderProjectDetails.projectDescription}
                onChange={handleBuilderProjectDescriptionChange}
                className={
                  builderProjectDescriptionCharacterCount === 2500
                    ? "utr-form-input-max-limit-reached"
                    : builderProjectDetails.projectDescription.trim() !== ""
                    ? "utr-form-input-selected"
                    : ""
                }
                rows={5}
              />
              <div
                className={
                  builderProjectDescriptionCharacterCount === 2500
                    ? "utr-form-input-sub-max-limit-reached"
                    : "utr-form-input-sub"
                }
              >
                <span>{builderProjectDescriptionSpanContent}</span>
                <span>{builderProjectDescriptionCharacterCount} / 2500</span>
              </div>
            </div>
          </div>
        </div>
        <div className="utr-form-category">
          <h4>Project Status</h4>
          <div className="utr-form-types">
            <div
              className={`utr-form-types-sub ${
                isReadyToMoveSelected ? "selected" : ""
              }`}
              onClick={() => handleBuilderProjectStatusChange("Ready to Move")}
            >
              <FaRegCircleCheck className="utr-form-types-sub-icon" />
              <h5>Ready to Move</h5>
            </div>
            <div
              className={`utr-form-types-sub ${
                isUnderConstructionSelected ? "selected" : ""
              }`}
              onClick={() =>
                handleBuilderProjectStatusChange("Under Construction")
              }
            >
              <AiOutlineAlert className="utr-form-types-sub-icon" />
              <h5>Under Construction</h5>
            </div>
          </div>
        </div>
        {builderProjectDetails.projectStatus === "Under Construction" && (
          <div className="utr-form-category">
            <h4>Estimated Year of Completion</h4>
            <div className="utr-form-inputs">
              <FormInput
                icon={LuCalendarClock}
                placeholder="Year"
                value={builderProjectDetails.projectCompletionYear}
                onChange={handleBuilderProjectCompletionYearYearChange}
                maxLength="4"
              />
            </div>
          </div>
        )}
        <div className="utr-form-category">
          <h4>Project Ownership Type</h4>
          <div className="utr-form-types">
            <div
              className={`utr-form-types-sub ${
                isFreeholdSelected ? "selected" : ""
              }`}
              onClick={() =>
                handleBuilderProjectOwnershipTypeChange("Freehold")
              }
            >
              <BsBuildingLock className="utr-form-types-sub-icon" />
              <h5>Freehold</h5>
            </div>
            <div
              className={`utr-form-types-sub ${
                isLeaseholdSelected ? "selected" : ""
              }`}
              onClick={() =>
                handleBuilderProjectOwnershipTypeChange("Leasehold")
              }
            >
              <FiKey className="utr-form-types-sub-icon" />
              <h5>Leasehold</h5>
            </div>
          </div>
        </div>
        <div className="utr-form-category">
          <h4>Project Floor Plans</h4>
          <div className="utr-form-types">
            {[
              "1 BHK",
              "2 BHK",
              "3 BHK",
              "4 BHK",
              "5 BHK",
              "6 BHK",
              "SOHO",
              "Studio Apartment",
            ].map((floorPlan) => (
              <div
                key={floorPlan}
                className={`utr-form-types-sub ${
                  isBuilderFloorPlanSelected(floorPlan) ? "selected" : ""
                }`}
                onClick={() => handleBuilderProjectFloorPlansChange(floorPlan)}
              >
                <CgTag className="utr-form-types-sub-icon" />
                <h5>{floorPlan}</h5>
              </div>
            ))}
            {builderProjectDetails.projectFloorPlans.length > 0 && (
              <div className="utr-form-types-clearselection">
                <span onClick={handleClearBuilderProjectFloorPlansSelection}>
                  - Clear Selection
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="utr-form-category">
          <h4>Project Area</h4>
          <div className="utr-form-inputs">
            <FormInput
              type="number"
              icon={AiOutlineExpandAlt}
              placeholder="Project Area"
              value={builderProjectDetails.projectArea}
              onChange={(e) =>
                setBuilderProjectDetails((prev) => ({
                  ...prev,
                  projectArea: e.target.value,
                }))
              }
            />
            <div className="utr-form-input">
              <MdOutlineCompareArrows className="utr-form-input-icon" />
              <Select
                showSearch
                name="builderProjectAreaUnit"
                className="utr-form-input-select"
                placeholder="Select Area unit"
                value={builderProjectDetails.projectAreaUnit || undefined}
                optionFilterProp="label"
                onChange={(value) =>
                  setBuilderProjectDetails((prev) => ({
                    ...prev,
                    projectAreaUnit: value,
                  }))
                }
                options={[
                  {
                    value: "acres",
                    label: "Acres",
                  },
                  {
                    value: "sq.meters",
                    label: "Sq Meters",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="utr-form-category">
          <h4>
            Project Price Range{" "}
            {isPriceRangeTyping && (
              <>
                - (
                {formatPrice(
                  builderProjectDetails.projectPriceRange.priceFrom,
                  builderProjectDetails.projectCurrencyType
                )}{" "}
                -{" "}
                {formatPrice(
                  builderProjectDetails.projectPriceRange.priceTo,
                  builderProjectDetails.projectCurrencyType
                )}
                )
              </>
            )}
          </h4>
          <div className="utr-form-inputs">
            <FormInput
              type="number"
              icon={
                builderProjectDetails.projectCurrencyType === "₹"
                  ? BsCurrencyRupee
                  : BsCurrencyDollar
              }
              placeholder="Price From"
              value={builderProjectDetails.projectPriceRange.priceFrom}
              onChange={(e) =>
                handleProjectPriceRangeChange("priceFrom", e.target.value)
              }
            />
            <FormInput
              type="number"
              icon={
                builderProjectDetails.projectCurrencyType === "₹"
                  ? BsCurrencyRupee
                  : BsCurrencyDollar
              }
              placeholder="Price To"
              value={builderProjectDetails.projectPriceRange.priceTo}
              onChange={(e) =>
                handleProjectPriceRangeChange("priceTo", e.target.value)
              }
            />
            <div className="utr-form-input">
              <GrCurrency className="utr-form-input-icon" />
              <Select
                showSearch
                name="builderProjectCurrencyType"
                className="utr-form-input-select"
                placeholder="Select Currency Type"
                value={builderProjectDetails.projectCurrencyType || undefined}
                defaultValue="₹"
                optionFilterProp="label"
                onChange={(value) =>
                  setBuilderProjectDetails((prev) => ({
                    ...prev,
                    projectCurrencyType: value,
                  }))
                }
                options={[
                  {
                    value: "₹",
                    label: "INR - Rupees (₹)",
                  },
                  {
                    value: "$",
                    label: "USD - Dollars ($)",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="utr-form-category">
          <h4>Project Location Details</h4>
          <div className="utr-form-inputs">
            <div className="utr-form-input">
              <PiCity className="utr-form-input-icon" />
              <Select
                showSearch
                className="utr-form-input-select"
                placeholder="Select a city"
                value={builderProjectDetails.projectCity || undefined}
                optionFilterProp="label"
                onChange={(value) => {
                  setBuilderProjectDetails((prev) => ({
                    ...prev,
                    projectCity: value,
                  }));
                  const cityData = filterCityData(value);
                  setSelectedCityCoordinates({
                    latitude: cityData.geoCode[0],
                    longitude: cityData.geoCode[1],
                  });
                }}
                options={cityOptions.map((option) => ({
                  value: option.cityName,
                  label: option.cityName,
                }))}
              />
            </div>
            <div className="utr-form-input"></div>
            <div className="utr-form-input utr-form-input-flex utr-form-input-mtmap">
              <textarea
                placeholder="Project Locality"
                value={builderProjectDetails.projectLocality}
                onChange={handleBuilderProjectLocalityChange}
                className={
                  builderProjectLocalityCharacterCount === 500
                    ? "utr-form-input-max-limit-reached"
                    : builderProjectDetails?.projectLocality?.trim() !== ""
                    ? "utr-form-input-selected"
                    : ""
                }
                rows={5}
              />
              <div
                className={
                  builderProjectLocalityCharacterCount === 500
                    ? "utr-form-input-sub-max-limit-reached"
                    : "utr-form-input-sub"
                }
              >
                <span>{builderProjectLocalitySpanContent}</span>
                <span>
                  {builderProjectLocalityCharacterCount ||
                    builderProjectDetails?.projectLocality?.length}{" "}
                  / 500
                </span>
              </div>
            </div>
            <div className="utr-form-input">
              <div className="utr-form-input-map">
                <AimOutlined
                  className="utr-form-map-live-Loc"
                  onClick={getLiveLocation}
                />
                <MapContainer
                  center={[20.5937, 78.9629]}
                  zoom={3}
                  className="pp-current-location-onMap"
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <ClickHandler />
                  {clickedPosition && (
                    <Marker position={[...clickedPosition]} icon={customIcon}>
                      {/* <Popup>{projectLocality}</Popup> */}
                    </Marker>
                  )}
                </MapContainer>
              </div>
            </div>
          </div>
        </div>
        <div className="utr-form-category">
          <h4>Project Photos</h4>
          <div className="utr-form-images">
            <ImageUpload
              image={builderProjectDetails.projectLogo}
              onImageChange={(e) =>
                setBuilderProjectDetails((prev) => ({
                  ...prev,
                  projectLogo: e.target.files[0],
                }))
              }
              onRemove={() =>
                setBuilderProjectDetails((prev) => ({
                  ...prev,
                  projectLogo: null,
                }))
              }
              label="Project Logo"
            />
            <ImageUpload
              image={builderProjectDetails.projectLayout}
              onImageChange={(e) =>
                setBuilderProjectDetails((prev) => ({
                  ...prev,
                  projectLayout: e.target.files[0],
                }))
              }
              onRemove={() =>
                setBuilderProjectDetails((prev) => ({
                  ...prev,
                  projectLayout: null,
                }))
              }
              label="Project Layout"
            />
          </div>
        </div>
        <div className="utr-form-category">
          <h4>Project Brcohure (Accepted Formats: .pdf, .doc, .docx!)</h4>
          <FileUpload
            file={builderProjectDetails.projectBrochure}
            onFileChange={(e) =>
              setBuilderProjectDetails((prev) => ({
                ...prev,
                projectBrochure: e.target.files[0],
              }))
            }
            onRemove={() =>
              setBuilderProjectDetails((prev) => ({
                ...prev,
                projectBrochure: null,
              }))
            }
            label="Project Brochure"
          />
          <button
            className="utr-add-project-employee-add"
            onClick={addBuilderProject}
          >
            Add Project
          </button>
        </div>
        {builderAddedProjectsList.length > 0 && (
          <>
            <div className="utr-form-category">
              <h4>
                {builderAddedProjectsList.length === 1
                  ? "Added Project"
                  : "Added Projectss"}{" "}
                ({builderAddedProjectsList.length})
              </h4>
              <List
                itemLayout="horizontal"
                dataSource={builderAddedProjectsList}
                renderItem={(item, index) => (
                  <List.Item
                    key={index}
                    actions={[
                      <h5
                        className="utr-employees-remove red"
                        onClick={() => removeBuilderProject(index)}
                      >
                        remove
                      </h5>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <img
                          src={renderBuilderProjectImage(
                            item.projectLogo,
                            item.projectName.charAt(0)
                          )}
                          alt="Project"
                          className="utr-employees-img"
                        />
                      }
                      title={
                        <div className="utr-employees-name">
                          {item.projectName} (
                          {formatPrice(
                            item.projectPriceRange.priceFrom,
                            item.projectCurrencyType
                          )}{" "}
                          -{" "}
                          {formatPrice(
                            item.projectPriceRange.priceTo,
                            item.projectCurrencyType
                          )}
                          )
                        </div>
                      }
                      description={
                        <div>
                          <div className="utr-employees-description">
                            {item.projectReraNo} / {item.projectStatus}{" "}
                            {item.projectStatus === "Under Construction" &&
                              `(${item.projectCompletionYear})`}{" "}
                            / {item.projectOwnershipType} / {item.projectArea}{" "}
                            {item.projectAreaUnit}
                          </div>
                          {item.projectLayout ? (
                            <img
                              src={renderBuilderProjectImage(
                                item.projectLayout,
                                "PL"
                              )}
                              alt="Project Layout"
                              className="utr-employees-img-docs"
                            />
                          ) : (
                            <img
                              src={`https://placehold.co/30x30?text=PL`}
                              alt="Project Layout Placeholder"
                              className="utr-employees-img-docs"
                            />
                          )}
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
            <div className="utr-form-category">
              <h4>Note</h4>
              <h6>
                (You can also add these projects later on the dashboard page
                under the "Add Projects" section.)
              </h6>
            </div>
          </>
        )}
      </>
    ),
    [builderProjectDetails, builderAddedProjectsList]
  );
  // Builder Projects Reset
  const confirmBuilderProjectsReset = (e) => {
    message.success("Projects Data Reset success!");
    setBuilderProjectDetails({
      projectName: "",
      projectReraNo: "",
      projectDescription: "",
      projectStatus: "",
      projectCompletionYear: "",
      projectOwnershipType: "",
      projectFloorPlans: [],
      projectArea: "",
      projectAreaUnit: "",
      projectPriceRange: {
        priceFrom: "",
        priceTo: "",
      },
      projectCurrencyType: "₹",
      projectCity: "",
      projectLocation: {
        latitude: 0,
        longitude: 0,
      },
      projectLocality: "",
      projectLogo: null,
      projectLayout: null,
      projectBrochure: null,
    });
    setBuilderProjectDescriptionCharacterCount(0);
    setBuilderProjectLocalityCharacterCount(0);
    // Reset Map States
    setClickedPosition(null);
    setPlaceName("");
    setPrevPlaceName("");
    setZoomLevel(12);
    setSelectedCityCoordinates({
      longitude: 0,
      latitude: 0,
    });
  };
  const cancelBuilderProjectsReset = (e) => {
    message.error("Projectss reset cancelled!");
  };
  const builderProjectsCollapse = useMemo(
    () => [
      {
        key: "1",
        label: `${builderAddedProjectsList.length} ${
          builderAddedProjectsList.length === 1 ? "Project" : "Projects"
        } added`,
        children: builderProjectsForm,
        extra: (
          <Popconfirm
            title="Reset Project Data"
            description="Are you sure to reset the project?"
            onConfirm={confirmBuilderProjectsReset}
            onCancel={cancelBuilderProjectsReset}
            okText="Reset"
            cancelText="Cancel"
          >
            <GrPowerReset className="utr-collapse-icon" />
          </Popconfirm>
        ),
      },
    ],
    [builderProjectsForm, builderAddedProjectsList.length]
  );

  // Agent Profile Details
  const [agentDetails, setAgentDetails] = useState({
    dealsIn: [],
    agentFirmName: "",
    agentReraNo: "",
    agentReraNoType: "",
    agentOfficeWebsite: "",
    agentAddress: "",
    agentOfficeCity: "",
    agentProfilePhoto: null,
    agentFirmLogo: null,
  });
  // Agent Deals In
  const handleAgentDealsInChange = (deal) => {
    setAgentDetails((prev) => {
      const isSelected = prev.dealsIn.includes(deal);
      const newDealsIn = isSelected
        ? prev.dealsIn.filter((d) => d !== deal)
        : [...prev.dealsIn, deal];
      return {
        ...prev,
        dealsIn: newDealsIn,
      };
    });
  };
  const handleClearAgentDealsInSelection = () => {
    setAgentDetails((prev) => ({
      ...prev,
      dealsIn: [],
    }));
  };
  const isAgentDealSelected = (deal) => agentDetails.dealsIn.includes(deal);
  // Agent Address
  const [agentAddressCharacterCount, setAgentAddressCharacterCount] =
    useState(0);
  const handleAgentAddressChange = (e) => {
    const newAgentAddress = e.target.value;
    if (newAgentAddress.length <= 100) {
      setAgentDetails({
        ...agentDetails,
        agentAddress: newAgentAddress,
      });
      setAgentAddressCharacterCount(newAgentAddress.length);
    } else {
      setAgentDetails({
        ...agentDetails,
        agentAddress: newAgentAddress.slice(0, 100),
      });
      setAgentAddressCharacterCount(100);
    }
  };
  const agentAddressSpanContent =
    agentAddressCharacterCount === 100
      ? "Max character limit reached"
      : "Provide complete address";
  // Agent Employees
  const [agentEmployeeDetails, setAgentEmployeeDetails] = useState({
    employeeName: "",
    employeeEmail: "",
    employeeMobileNo: "",
    employeeDesignation: "",
    employeeIdType: "",
    employeeID: "",
    employeeAadhaarCardNo: "",
    employeePanCardNo: "",
    employeeVoterCardNo: "",
    employeePassportNo: "",
    employeeDrivingLicenseNo: "",
    employeeImage: null,
    employeementLetterImage: null,
    employeeIdCardImage: null,
    employeeAadhaarcardFrontImage: null,
    employeeAadhaarcardBackImage: null,
    employeePancardImage: null,
    employeeVoterIdFrontImage: null,
    employeeVoterIdBackImage: null,
    employeePassportImage: null,
    employeeDrivinglicenseFrontImage: null,
    employeeDrivinglicenseBackImage: null,
  });
  // console.log(agentEmployeeDetails);
  // Agent Employee Mobile
  const handleAgentEmployeeMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setAgentEmployeeDetails((prev) => ({
        ...prev,
        employeeMobileNo: value,
      }));
    }
  };
  const formatAgentEmployeeMobileDisplay = (employeeMobileNo) => {
    const formatted = employeeMobileNo.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };
  // Reset Employee Id Type (Related Fields) OnChnage - Id Proofs Reset
  const resetEmployeeIdProofDetails = () => ({
    employeeID: "",
    employeeAadhaarCardNo: "",
    employeePanCardNo: "",
    employeeVoterCardNo: "",
    employeePassportNo: "",
    employeeDrivingLicenseNo: "",
    employeeIdCardImage: null,
    employeeAadhaarcardFrontImage: null,
    employeeAadhaarcardBackImage: null,
    employeePancardImage: null,
    employeeVoterIdFrontImage: null,
    employeeVoterIdBackImage: null,
    employeePassportImage: null,
    employeeDrivinglicenseFrontImage: null,
    employeeDrivinglicenseBackImage: null,
  });
  const handleAgentEmployeeIdTypeChange = (value) => {
    setAgentEmployeeDetails((prev) => ({
      ...prev,
      employeeIdType: value,
      ...resetEmployeeIdProofDetails(),
    }));
  };
  // Agent Employee Aadhaar Card
  const handleAgentEmployeeAadhaarCardNo = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 4);
    setAgentEmployeeDetails((prev) => ({
      ...prev,
      employeeAadhaarCardNo: numericValue,
    }));
  };
  // Agent Added Employees List
  const [agentAddedEmployeesList, setAgentAddedEmployeesList] = useState([]);
  // console.log(agentAddedEmployeesList);
  const addAgentEmployee = async () => {
    if (
      agentEmployeeSchema.safeParse({
        employeeFullName: agentEmployeeDetails.employeeName,
      }).success === false
    ) {
      const errorMessage = agentEmployeeSchema.safeParse({
        employeeFullName: agentEmployeeDetails.employeeName,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Employee name");
      message.error(customMessage);
      return;
    }

    if (
      agentEmployeeSchema.safeParse({
        employeeDesignation: agentEmployeeDetails.employeeDesignation,
      }).success === false
    ) {
      const errorMessage = agentEmployeeSchema.safeParse({
        employeeDesignation: agentEmployeeDetails.employeeDesignation,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Employee designation"
      );
      message.error(customMessage);
      return;
    }

    if (
      agentEmployeeSchema.safeParse({
        employeeEmail: agentEmployeeDetails.employeeEmail,
      }).success === false
    ) {
      const errorMessage = agentEmployeeSchema.safeParse({
        employeeEmail: agentEmployeeDetails.employeeEmail,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Employee email");
      message.error(customMessage);
      return;
    }

    if (
      agentEmployeeSchema.safeParse({
        employeeMobileNo: agentEmployeeDetails.employeeMobileNo,
      }).success === false
    ) {
      const errorMessage = agentEmployeeSchema.safeParse({
        employeeMobileNo: agentEmployeeDetails.employeeMobileNo,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Employee mobile no"
      );
      message.error(customMessage);
      return;
    }

    if (
      agentEmployeeSchema.safeParse({
        employeeIdType: agentEmployeeDetails.employeeIdType,
      }).success === false
    ) {
      message.error("Select atleast one employee id type");
      return;
    }

    if (agentEmployeeDetails.employeeIdType === "Employee ID Card") {
      if (
        agentEmployeeSchema.safeParse({
          employeeID: agentEmployeeDetails.employeeID,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeeID: agentEmployeeDetails.employeeID,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Employee ID");
        message.error(customMessage);
        return;
      }
      if (!agentEmployeeDetails.employeeIdCardImage) {
        message.error("Employee ID card Image required");
        return;
      }
    }

    if (agentEmployeeDetails.employeeIdType === "Aadhaar Card") {
      if (
        agentEmployeeSchema.safeParse({
          employeeAadhaarCardNo: agentEmployeeDetails.employeeAadhaarCardNo,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeeAadhaarCardNo: agentEmployeeDetails.employeeAadhaarCardNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(
          /^String/,
          "Aadhaar card no."
        );
        message.error(customMessage);
        return;
      }
      if (!agentEmployeeDetails.employeeAadhaarcardFrontImage) {
        message.error("Employee aadhaar card front image required");
        return;
      }

      if (!agentEmployeeDetails.employeeAadhaarcardBackImage) {
        message.error("Employee aadhaar card back image required");
        return;
      }
    }

    if (agentEmployeeDetails.employeeIdType === "Pan Card") {
      if (
        agentEmployeeSchema.safeParse({
          employeePanCardNo: agentEmployeeDetails.employeePanCardNo,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeePanCardNo: agentEmployeeDetails.employeePanCardNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Pan card no.");
        message.error(customMessage);
        return;
      }

      if (!agentEmployeeDetails.employeePancardImage) {
        message.error("Employee pancard image required");
        return;
      }
    }

    if (agentEmployeeDetails.employeeIdType === "Voter Card") {
      if (
        agentEmployeeSchema.safeParse({
          employeeVoterCardNo: agentEmployeeDetails.employeeVoterCardNo,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeeVoterCardNo: agentEmployeeDetails.employeeVoterCardNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Voter card no.");
        message.error(customMessage);
        return;
      }
      if (!agentEmployeeDetails.employeeVoterIdFrontImage) {
        message.error("Employee voter card front image required");
        return;
      }

      if (!agentEmployeeDetails.employeeVoterIdBackImage) {
        message.error("Employee voter card back image required");
        return;
      }
    }

    if (agentEmployeeDetails.employeeIdType === "Passport") {
      if (
        agentEmployeeSchema.safeParse({
          employeePassportNo: agentEmployeeDetails.employeePassportNo,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeePassportNo: agentEmployeeDetails.employeePassportNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Passport no.");
        message.error(customMessage);
        return;
      }

      if (!agentEmployeeDetails.employeePassportImage) {
        message.error("Employee passport image required");
        return;
      }
    }

    if (agentEmployeeDetails.employeeIdType === "Driving License") {
      if (
        agentEmployeeSchema.safeParse({
          employeeDrivingLicenseNo:
            agentEmployeeDetails.employeeDrivingLicenseNo,
        }).success === false
      ) {
        const errorMessage = agentEmployeeSchema.safeParse({
          employeeDrivingLicenseNo:
            agentEmployeeDetails.employeeDrivingLicenseNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(
          /^String/,
          "Driving license no."
        );
        message.error(customMessage);
        return;
      }
      if (!agentEmployeeDetails.employeeDrivinglicenseFrontImage) {
        message.error("Employee driving license front image required");
        return;
      }

      if (!agentEmployeeDetails.employeeDrivinglicenseBackImage) {
        message.error("Employee driving license back image required");
        return;
      }
    }

    if (!agentEmployeeDetails.employeeImage) {
      message.error("Employee image required");
      return;
    }

    if (
      agentAddedEmployeesList.length > 0 &&
      agentAddedEmployeesList.some(
        (employee) =>
          employee.employeeEmail === agentEmployeeDetails.employeeEmail ||
          employee.employeeMobileNo ===
            agentEmployeeDetails.employeeMobileNo.replace(
              /(\d{2})(\d{0,10})/,
              "+$1 $2"
            )
      )
    ) {
      const identifier = agentAddedEmployeesList.some(
        (employee) =>
          employee.employeeEmail === agentEmployeeDetails.employeeEmail
      )
        ? "email"
        : agentAddedEmployeesList.some(
            (employee) =>
              employee.employeeMobileNo ===
              agentEmployeeDetails.employeeMobileNo.replace(
                /(\d{2})(\d{0,10})/,
                "+$1 $2"
              )
          )
        ? "mobile no"
        : "credentials";
      message.error(
        `Employee with this ${identifier} already exists in the employee list`
      );
      return;
    }

    try {
      const response = await axios.get(
        `${baseURL}/api/profile/checkDuplicateCredentials`,
        {
          params: {
            email: agentEmployeeDetails.employeeEmail,
            mobileNo: agentEmployeeDetails.employeeMobileNo,
          },
        }
      );
      if (
        response.data.message === "User added successfully" ||
        response.status === 200 ||
        response.statusText === "OK"
      ) {
        message.success(response.data.message);
        setAgentAddedEmployeesList((prev) => [
          ...prev,
          {
            ...agentEmployeeDetails,
            employeeMobileNo: formatAgentEmployeeMobileDisplay(
              agentEmployeeDetails.employeeMobileNo
            ),
          },
        ]);
        setAgentEmployeeDetails({
          employeeName: "",
          employeeEmail: "",
          employeeMobileNo: "",
          employeeDesignation: "",
          employeeIdType: "",
          employeeID: "",
          employeeAadhaarCardNo: "",
          employeePanCardNo: "",
          employeeVoterCardNo: "",
          employeePassportNo: "",
          employeeDrivingLicenseNo: "",
          employeeImage: null,
          employeementLetterImage: null,
          employeeIdCardImage: null,
          employeeAadhaarcardFrontImage: null,
          employeeAadhaarcardBackImage: null,
          employeePancardImage: null,
          employeeVoterIdFrontImage: null,
          employeeVoterIdBackImage: null,
          employeePassportImage: null,
          employeeDrivinglicenseFrontImage: null,
          employeeDrivinglicenseBackImage: null,
        });
      }
    } catch (err) {
      message.error(err.response.data.message);
      return;
    }
  };

  const removeAgentEmployee = (index) => {
    setAgentAddedEmployeesList((prev) => prev.filter((_, i) => i !== index));
  };
  const renderAgentEmployeeImage = (image, placeholderText) => {
    if (image) {
      return URL.createObjectURL(image);
    }
    return `https://placehold.co/50x50?text=${placeholderText}`;
  };
  const agentEmployeesForm = (
    <>
      <div className="utr-form-category">
        <h4> Employee Details</h4>
        <div className="utr-form-inputs">
          <FormInput
            icon={MdDriveFileRenameOutline}
            placeholder="Employee Full Name"
            value={agentEmployeeDetails.employeeName}
            onChange={(e) =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeeName: e.target.value,
              }))
            }
          />
          <FormInput
            icon={LiaUserTagSolid}
            placeholder="Employee Designation"
            value={agentEmployeeDetails.employeeDesignation}
            onChange={(e) =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeeDesignation: e.target.value,
              }))
            }
          />
          <FormInput
            icon={MdOutlineEmail}
            placeholder="Employee Email"
            value={agentEmployeeDetails.employeeEmail}
            onChange={(e) =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeeEmail: e.target.value,
              }))
            }
          />
          <FormInput
            icon={MdOutlinePhoneEnabled}
            placeholder="Employee Mobile No."
            value={formatAgentEmployeeMobileDisplay(
              agentEmployeeDetails.employeeMobileNo
            )}
            onChange={handleAgentEmployeeMobileChange}
            maxLength="14"
          />
          <div className="utr-form-input">
            <HiOutlineDocumentReport className="utr-form-input-icon" />
            <Select
              showSearch
              name="agentEmployeeIdType"
              className="utr-form-input-select"
              placeholder="Select ID Type"
              value={agentEmployeeDetails.employeeIdType || undefined}
              optionFilterProp="label"
              onChange={handleAgentEmployeeIdTypeChange}
              options={[
                {
                  value: "Employee ID Card",
                  label: "Employee ID Card",
                },
                {
                  value: "Aadhaar Card",
                  label: "Employee Aadhaar Card",
                },
                {
                  value: "Pan Card",
                  label: "Employee Pan Card",
                },
                {
                  value: "Voter Card",
                  label: "Employee Voter Card",
                },
                {
                  value: "Passport",
                  label: "Employee Passport",
                },
                {
                  value: "Driving License",
                  label: "Employee Driving License",
                },
              ]}
            />
          </div>
          {agentEmployeeDetails.employeeIdType === "Employee ID Card" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Employee ID"
              value={agentEmployeeDetails.employeeID}
              onChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeeID: e.target.value,
                }))
              }
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Aadhaar Card" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Aadhaar Card No. (last 4 digits)"
              value={agentEmployeeDetails.employeeAadhaarCardNo}
              onChange={handleAgentEmployeeAadhaarCardNo}
              maxLength="4"
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Pan Card" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Pan Card No."
              value={agentEmployeeDetails.employeePanCardNo}
              onChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePanCardNo: e.target.value,
                }))
              }
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Voter Card" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Voter Card No."
              value={agentEmployeeDetails.employeeVoterCardNo}
              onChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeeVoterCardNo: e.target.value,
                }))
              }
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Passport" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Passport No."
              value={agentEmployeeDetails.employeePassportNo}
              onChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePassportNo: e.target.value,
                }))
              }
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Driving License" && (
            <FormInput
              icon={LiaAddressCardSolid}
              placeholder="Driving License No."
              value={agentEmployeeDetails.employeeDrivingLicenseNo}
              onChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeeDrivingLicenseNo: e.target.value,
                }))
              }
            />
          )}
        </div>
      </div>
      <div className="utr-form-category">
        <h4>Employee Photos</h4>
        <div className="utr-form-images">
          <ImageUpload
            image={agentEmployeeDetails.employeeImage}
            onImageChange={(e) =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeeImage: e.target.files[0],
              }))
            }
            onRemove={() =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeeImage: null,
              }))
            }
            label="Employee Photo"
          />
          <ImageUpload
            image={agentEmployeeDetails.employeementLetterImage}
            onImageChange={(e) =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeementLetterImage: e.target.files[0],
              }))
            }
            onRemove={() =>
              setAgentEmployeeDetails((prev) => ({
                ...prev,
                employeementLetterImage: null,
              }))
            }
            label="Employeement Letter"
          />
          {agentEmployeeDetails.employeeIdType === "Employee ID Card" && (
            <ImageUpload
              image={agentEmployeeDetails.employeeIdCardImage}
              onImageChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeeIdCardImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeeIdCardImage: null,
                }))
              }
              label="ID Card"
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Aadhaar Card" && (
            <>
              <ImageUpload
                image={agentEmployeeDetails.employeeAadhaarcardFrontImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeAadhaarcardFrontImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeAadhaarcardFrontImage: null,
                  }))
                }
                label="Aadhaar Front"
              />
              <ImageUpload
                image={agentEmployeeDetails.employeeAadhaarcardBackImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeAadhaarcardBackImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeAadhaarcardBackImage: null,
                  }))
                }
                label="Aadhaar Back"
              />
            </>
          )}
          {agentEmployeeDetails.employeeIdType === "Pan Card" && (
            <ImageUpload
              image={agentEmployeeDetails.employeePancardImage}
              onImageChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePancardImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePancardImage: null,
                }))
              }
              label="Pan Card"
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Voter Card" && (
            <>
              <ImageUpload
                image={agentEmployeeDetails.employeeVoterIdFrontImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeVoterIdFrontImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeVoterIdFrontImage: null,
                  }))
                }
                label="Voter Front"
              />
              <ImageUpload
                image={agentEmployeeDetails.employeeVoterIdBackImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeVoterIdBackImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeVoterIdBackImage: null,
                  }))
                }
                label="Voter Back"
              />
            </>
          )}
          {agentEmployeeDetails.employeeIdType === "Passport" && (
            <ImageUpload
              image={agentEmployeeDetails.employeePassportImage}
              onImageChange={(e) =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePassportImage: e.target.files[0],
                }))
              }
              onRemove={() =>
                setAgentEmployeeDetails((prev) => ({
                  ...prev,
                  employeePassportImage: null,
                }))
              }
              label="Passport"
            />
          )}
          {agentEmployeeDetails.employeeIdType === "Driving License" && (
            <>
              <ImageUpload
                image={agentEmployeeDetails.employeeDrivinglicenseFrontImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeDrivinglicenseFrontImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeDrivinglicenseFrontImage: null,
                  }))
                }
                label="Driving License Front"
              />
              <ImageUpload
                image={agentEmployeeDetails.employeeDrivinglicenseBackImage}
                onImageChange={(e) =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeDrivinglicenseBackImage: e.target.files[0],
                  }))
                }
                onRemove={() =>
                  setAgentEmployeeDetails((prev) => ({
                    ...prev,
                    employeeDrivinglicenseBackImage: null,
                  }))
                }
                label="Driving License Back"
              />
            </>
          )}
        </div>
        <button
          className="utr-add-project-employee-add"
          onClick={addAgentEmployee}
        >
          Add Employee
        </button>
      </div>
      {agentAddedEmployeesList.length > 0 && (
        <>
          <div className="utr-form-category">
            <h4>
              {agentAddedEmployeesList.length === 1
                ? "Added Employee"
                : "Added Employees"}{" "}
              ({agentAddedEmployeesList.length})
            </h4>
            <List
              itemLayout="horizontal"
              dataSource={agentAddedEmployeesList}
              renderItem={(item, index) => (
                <List.Item
                  key={index}
                  actions={[
                    <h5
                      className="utr-employees-remove red"
                      onClick={() => removeAgentEmployee(index)}
                    >
                      remove
                    </h5>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <img
                        src={renderAgentEmployeeImage(
                          item.employeeImage,
                          item.employeeName.charAt(0)
                        )}
                        alt="Profile"
                        className="utr-employees-img"
                      />
                    }
                    title={
                      <div className="utr-employees-name">
                        {item.employeeName}
                      </div>
                    }
                    description={
                      <div>
                        <div className="utr-employees-description">
                          {item.employeeDesignation} / {item.employeeEmail} /{" "}
                          {item.employeeMobileNo} / {item.employeeID}
                        </div>
                        {item.employeementLetterImage ? (
                          <img
                            src={renderAgentEmployeeImage(
                              item.employeementLetterImage,
                              "EL"
                            )}
                            alt="Employment Letter"
                            className="utr-employees-img-docs"
                          />
                        ) : (
                          <img
                            src={`https://placehold.co/30x30?text=EL`}
                            alt="Employment Letter Placeholder"
                            className="utr-employees-img-docs"
                          />
                        )}
                        {item.employeeIdType === "Employee ID Card" &&
                        item.employeeIdCardImage ? (
                          <img
                            src={renderAgentEmployeeImage(
                              item.employeeIdCardImage,
                              "ID"
                            )}
                            alt="ID Card"
                            className="utr-employees-img-docs"
                          />
                        ) : item.employeeIdType === "Aadhaar Card" &&
                          item.employeeAadhaarcardFrontImage &&
                          item.employeeAadhaarcardBackImage ? (
                          <>
                            <img
                              src={renderAgentEmployeeImage(
                                item.employeeAadhaarcardFrontImage,
                                "Aadhaar Front"
                              )}
                              alt="Aadhaar Front"
                              className="utr-employees-img-docs"
                            />
                            <img
                              src={renderAgentEmployeeImage(
                                item.employeeAadhaarcardBackImage,
                                "Aadhaar Back"
                              )}
                              alt="Aadhaar Back"
                              className="utr-employees-img-docs"
                            />
                          </>
                        ) : item.employeeIdType === "Pan Card" &&
                          item.employeePancardImage ? (
                          <img
                            src={renderAgentEmployeeImage(
                              item.employeePancardImage,
                              "PAN"
                            )}
                            alt="PAN Card"
                            className="utr-employees-img-docs"
                          />
                        ) : item.employeeIdType === "Voter Card" &&
                          item.employeeVoterIdFrontImage &&
                          item.employeeVoterIdBackImage ? (
                          <>
                            <img
                              src={renderAgentEmployeeImage(
                                item.employeeVoterIdFrontImage,
                                "Voter Front"
                              )}
                              alt="Voter Front"
                              className="utr-employees-img-docs"
                            />
                            <img
                              src={renderAgentEmployeeImage(
                                item.employeeVoterIdBackImage,
                                "Voter Back"
                              )}
                              alt="Voter Back"
                              className="utr-employees-img-docs"
                            />
                          </>
                        ) : item.employeeIdType === "Passport" &&
                          item.employeePassportImage ? (
                          <img
                            src={renderAgentEmployeeImage(
                              item.employeePassportImage,
                              "Passport"
                            )}
                            alt="Passport"
                            className="utr-employees-img-docs"
                          />
                        ) : item.employeeIdType === "Driving License" &&
                          item.employeeDrivinglicenseFrontImage &&
                          item.employeeDrivinglicenseBackImage ? (
                          <>
                            <img
                              src={renderAgentEmployeeImage(
                                item.employeeDrivinglicenseFrontImage,
                                "Driving Front"
                              )}
                              alt="Driving Front"
                              className="utr-employees-img-docs"
                            />
                            <img
                              src={renderAgentEmployeeImage(
                                item.employeeDrivinglicenseBackImage,
                                "Driving Back"
                              )}
                              alt="Driving Back"
                              className="utr-employees-img-docs"
                            />
                          </>
                        ) : (
                          <img
                            src={`https://placehold.co/30x30?text=${item.employeeIdType.charAt(
                              0
                            )}`}
                            alt={`${item.employeeIdType} Placeholder`}
                            className="utr-employees-img-docs"
                          />
                        )}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
          <div className="utr-form-category">
            <h4>Note</h4>
            <h6>
              (You can also add these employees later on the profile page under
              the "{userType} Employees" section.)
            </h6>
          </div>
        </>
      )}
    </>
  );

  // Agent Employees Reset
  const confirmAgentEmployeesReset = (e) => {
    message.success("Employees Data Reset success!");
    setAgentEmployeeDetails({
      employeeName: "",
      employeeEmail: "",
      employeeMobileNo: "",
      employeeDesignation: "",
      employeeIdType: "",
      employeeID: "",
      employeeAadhaarCardNo: "",
      employeePanCardNo: "",
      employeeVoterCardNo: "",
      employeePassportNo: "",
      employeeDrivingLicenseNo: "",
      employeeImage: null,
      employeementLetterImage: null,
      employeeIdCardImage: null,
      employeeAadhaarcardFrontImage: null,
      employeeAadhaarcardBackImage: null,
      employeePancardImage: null,
      employeeVoterIdFrontImage: null,
      employeeVoterIdBackImage: null,
      employeePassportImage: null,
      employeeDrivinglicenseFrontImage: null,
      employeeDrivinglicenseBackImage: null,
    });
  };
  const cancelAgentEmployeesReset = (e) => {
    message.error("Employees reset cancelled!");
  };

  const agentEmployeesCollapse = [
    {
      key: "1",
      label: `${agentAddedEmployeesList.length} ${
        agentAddedEmployeesList.length === 1 ? "Employee" : "Employees"
      } added`,
      children: agentEmployeesForm,
      extra: (
        <Popconfirm
          title="Reset Employee Data"
          description="Are you sure to reset all employee data?"
          onConfirm={confirmAgentEmployeesReset}
          onCancel={cancelAgentEmployeesReset}
          okText="Reset"
          cancelText="Cancel"
        >
          <GrPowerReset className="utr-collapse-icon" />
        </Popconfirm>
      ),
    },
  ];

  // Salesperson Profile
  const [salespersonDetails, setSalespersonDetails] = useState({
    employeeName: "",
    employeeEmail: "",
    employeeMobileNo: "",
    employeeWhatsappMobile: "",
    employeeIdType: "",
    employeeDesignation: "",
    employeeID: "",
    employeeAadhaarCardNo: "",
    employeePanCardNo: "",
    employeeVoterCardNo: "",
    employeePassportNo: "",
    employeeDrivingLicenseNo: "",
    employeeImage: null,
    employeementLetterImage: null,
    employeeIdCardImage: null,
    employeeAadhaarcardFrontImage: null,
    employeeAadhaarcardBackImage: null,
    employeePancardImage: null,
    employeeVoterIdFrontImage: null,
    employeeVoterIdBackImage: null,
    employeePassportImage: null,
    employeeDrivinglicenseFrontImage: null,
    employeeDrivinglicenseBackImage: null,
  });
  // console.log(salespersonDetails);
  // Salesperson Mobile
  const handleSalespersonMobileChange = (e) => {
    const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
    if (/^\d{0,12}$/.test(value)) {
      setSalespersonDetails((prev) => ({
        ...prev,
        employeeMobileNo: value,
      }));
      if (salespersonChecked) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeWhatsappMobile: value,
        }));
      }
    }
  };
  const formatSalespersonMobileDisplay = (employeeMobileNo) => {
    const formatted = employeeMobileNo.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };
  //Salesperson Whatsapp Mobile
  const handleSalespersonWhatsappMobileChange = (e) => {
    if (!salespersonChecked) {
      const value = e.target.value.replace(/\s/g, "").replace(/^\+/, "");
      if (/^\d{0,12}$/.test(value)) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeWhatsappMobile: value,
        }));
      }
    }
  };
  const [salespersonChecked, setSalespersonChecked] = useState(false);
  const onSalespersonSameWhatsappMobileAsMobileChange = (isChecked) => {
    setSalespersonChecked(isChecked);
    if (isChecked) {
      setSalespersonDetails((prev) => ({
        ...prev,
        employeeWhatsappMobile: prev.employeeMobileNo,
      }));
    } else {
      setSalespersonDetails((prev) => ({
        ...prev,
        employeeWhatsappMobile: "",
      }));
    }
  };
  const salespersonSwitchBackgroundColor = salespersonChecked
    ? "var(--primary-color)"
    : "#ccc";
  // Reset Salesperson Id Type (Related Fields) OnChnage - Id Proofs Reset
  const resetSalespersonIdProofDetails = () => ({
    employeeID: "",
    employeeAadhaarCardNo: "",
    employeePanCardNo: "",
    employeeVoterCardNo: "",
    employeePassportNo: "",
    employeeDrivingLicenseNo: "",
    employeeIdCardImage: null,
    employeeAadhaarcardFrontImage: null,
    employeeAadhaarcardBackImage: null,
    employeePancardImage: null,
    employeeVoterIdFrontImage: null,
    employeeVoterIdBackImage: null,
    employeePassportImage: null,
    employeeDrivinglicenseFrontImage: null,
    employeeDrivinglicenseBackImage: null,
  });
  const handleSalespersonIdTypeChange = (value) => {
    setSalespersonDetails((prev) => ({
      ...prev,
      employeeIdType: value,
      ...resetSalespersonIdProofDetails(),
    }));
  };
  // Agent Employee Aadhaar Card
  const handleSalespersonAadhaarCardNo = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 4);
    setSalespersonDetails((prev) => ({
      ...prev,
      employeeAadhaarCardNo: numericValue,
    }));
  };

  const [userData, setUserData] = useState(null);

  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await axios.get(
        `${baseURL}/api/auth/getUserData/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      return response.data.user;
    } catch (err) {
      console.log(err);
    }
  }, [baseURL, userId]);

  useMemo(async () => {
    if (userType === "Owner" || userType === "Salesperson") {
      setUserData(await fetchUserDetails());
    }
  }, [userType, fetchUserDetails]);

  useMemo(() => {
    if (userData && userType === "Owner") {
      if (userData.name) {
        setFullName(userData?.name);
      }

      if (userData.email && !userData.email.includes("@tempmail.com")) {
        setEmail(userData?.email);
      }

      if (userData.mobileNumber && userData.mobileNumber !== "undefined") {
        setMobile(userData.mobileNumber);
      }

      // Check if userData has a valid whatsappNumber
      if (userData.whatsappNumber && userData.whatsappNumber !== "undefined") {
        setWhatsappMobile(userData.whatsappNumber);
      }
    }

    if (userData && userType === "Salesperson") {
      if (userData.name) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeName: userData?.name,
        }));
      }

      if (userData.email && !userData.email.includes("@tempmail.com")) {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeEmail: userData?.email,
        }));
      }

      if (userData.mobileNumber && userData.mobileNumber !== "undefined") {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeMobileNo: userData.mobileNumber,
        }));
      }

      // Check if userData has a valid whatsappNumber
      if (userData.whatsappNumber && userData.whatsappNumber !== "undefined") {
        setSalespersonDetails((prev) => ({
          ...prev,
          employeeWhatsappMobile: userData.whatsappNumber,
        }));
      }
    }
  }, [userData, userType, setFullName, setEmail, setMobile, setWhatsappMobile]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation checks
    if (userType === "Owner") {
      if (ownerSchema.safeParse({ fullname }).success === false) {
        const errorMessage = ownerSchema.safeParse({ fullname }).error.issues[0]
          .message;
        const customMessage = errorMessage.replace(/^String/, "Full name");
        message.error(customMessage);
        return;
      }
      if (ownerSchema.safeParse({ email }).success === false) {
        if (
          ownerSchema.safeParse({ email }).error.issues[0] &&
          ownerSchema.safeParse({ email }).error.issues[0].code ===
            "invalid_string"
        ) {
          message.error(
            ownerSchema.safeParse({ email }).error.issues[0].message
          );
        }
        return;
      }
      if (ownerSchema.safeParse({ mobile }).success === false) {
        const errorMessage = ownerSchema.safeParse({ mobile }).error.issues[0]
          .message;
        const customMessage = errorMessage.replace(/^String/, "Mobile No.");
        message.error(customMessage);
        return;
      }
      if (
        whatsappMobile?.trim() &&
        ownerSchema.safeParse({ whatsappMobile }).success === false
      ) {
        const errorMessage = ownerSchema.safeParse({ whatsappMobile }).error
          .issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Whatsapp No.");
        message.error(customMessage);
        return;
      }
    } else if (userType === "Builder") {
      if (
        builderSchema.safeParse({
          builderFirmName: builderDetails.builderFirmName,
        }).success === false
      ) {
        const errorMessage = builderSchema.safeParse({
          builderFirmName: builderDetails.builderFirmName,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Firm name");
        message.error(customMessage);
        return;
      }

      if (
        builderSchema.safeParse({ builderReraNo: builderDetails.builderReraNo })
          .success === false
      ) {
        const errorMessage = builderSchema.safeParse({
          builderReraNo: builderDetails.builderReraNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Rera no");
        message.error(customMessage);
        return;
      }

      if (
        builderSchema.safeParse({
          builderReraNoType: builderDetails.builderReraNoType,
        }).success === false
      ) {
        const errorMessage = builderSchema.safeParse({
          builderReraNoType: builderDetails.builderReraNoType,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Rera no type");
        message.error(customMessage);
        return;
      }

      if (
        builderSchema.safeParse({
          builderOfficeCity: builderDetails.builderOfficeCity,
        }).success === false
      ) {
        const errorMessage = builderSchema.safeParse({
          builderOfficeCity: builderDetails.builderOfficeCity,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Office City");
        message.error(customMessage);
        return;
      }

      if (
        builderDetails.builderOfficeWebsite &&
        builderSchema.safeParse({
          builderOfficeWebsite: builderDetails.builderOfficeWebsite,
        }).success === false
      ) {
        const errorMessage = builderSchema.safeParse({
          builderOfficeWebsite: builderDetails.builderOfficeWebsite,
        }).error.issues[0].message;
        message.error(errorMessage);
        return;
      }

      if (
        builderSchema.safeParse({
          builderAddress: builderDetails.builderAddress,
        }).success === false
      ) {
        const errorMessage = builderSchema.safeParse({
          builderAddress: builderDetails.builderAddress,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Office address");
        message.error(customMessage);
        return;
      }

      if (!builderDetails.builderProfilePhoto) {
        message.error("profile photo required");
        return;
      }

      if (!builderDetails.builderFirmLogo) {
        message.error("Firm logo required");
        return;
      }
    } else if (userType === "Agent") {
      if (agentDetails.dealsIn.length <= 0) {
        message.error("Deals in must contain atleast 1 catagory");
        return;
      }

      if (
        agentSchema.safeParse({
          agentFirmName: agentDetails.agentFirmName,
        }).success === false
      ) {
        const errorMessage = agentSchema.safeParse({
          agentFirmName: agentDetails.agentFirmName,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Firm name");
        message.error(customMessage);
        return;
      }

      if (
        agentSchema.safeParse({ agentReraNo: agentDetails.agentReraNo })
          .success === false
      ) {
        const errorMessage = agentSchema.safeParse({
          agentReraNo: agentDetails.agentReraNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Rera no");
        message.error(customMessage);
        return;
      }

      if (
        agentSchema.safeParse({ agentReraNoType: agentDetails.agentReraNoType })
          .success === false
      ) {
        const errorMessage = agentSchema.safeParse({
          agentReraNoType: agentDetails.agentReraNoType,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Rera no type");
        message.error(customMessage);
        return;
      }

      if (
        agentSchema.safeParse({ agentOfficeCity: agentDetails.agentOfficeCity })
          .success === false
      ) {
        const errorMessage = agentSchema.safeParse({
          agentOfficeCity: agentDetails.agentOfficeCity,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Office City");
        message.error(customMessage);
        return;
      }

      if (
        agentDetails.agentOfficeWebsite &&
        agentSchema.safeParse({
          agentOfficeWebsite: agentDetails.agentOfficeWebsite,
        }).success === false
      ) {
        const errorMessage = agentSchema.safeParse({
          agentOfficeWebsite: agentDetails.agentOfficeWebsite,
        }).error.issues[0].message;
        message.error(errorMessage);
        return;
      }

      if (
        agentSchema.safeParse({ agentAddress: agentDetails.agentAddress })
          .success === false
      ) {
        const errorMessage = agentSchema.safeParse({
          agentAddress: agentDetails.agentAddress,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Office address");
        message.error(customMessage);
        return;
      }

      if (!agentDetails.agentProfilePhoto) {
        message.error("profile photo required");
        return;
      }

      if (!agentDetails.agentFirmLogo) {
        message.error("Firm logo required");
        return;
      }
    } else if (userType === "Salesperson") {
      if (
        salespersonSchema.safeParse({
          employeeFullName: salespersonDetails.employeeName,
        }).success === false
      ) {
        const errorMessage = salespersonSchema.safeParse({
          employeeFullName: salespersonDetails.employeeName,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Employee name");
        message.error(customMessage);
        return;
      }

      if (
        salespersonSchema.safeParse({
          employeeEmail: salespersonDetails.employeeEmail,
        }).success === false
      ) {
        const errorMessage = salespersonSchema.safeParse({
          employeeEmail: salespersonDetails.employeeEmail,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(/^String/, "Employee email");
        message.error(customMessage);
        return;
      }

      if (
        salespersonSchema.safeParse({
          employeeMobileNo: salespersonDetails.employeeMobileNo,
        }).success === false
      ) {
        const errorMessage = salespersonSchema.safeParse({
          employeeMobileNo: salespersonDetails.employeeMobileNo,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(
          /^String/,
          "Employee mobile no"
        );
        message.error(customMessage);
        return;
      }

      if (
        salespersonDetails.employeeWhatsappMobile &&
        salespersonSchema.safeParse({
          employeeWhatsappMobile: salespersonDetails.employeeWhatsappMobile,
        }).success === false
      ) {
        const errorMessage = salespersonSchema.safeParse({
          employeeWhatsappMobile: salespersonDetails.employeeWhatsappMobile,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(
          /^String/,
          "Employee whatsapp no"
        );
        message.error(customMessage);
        return;
      }

      if (
        salespersonSchema.safeParse({
          employeeDesignation: salespersonDetails.employeeDesignation,
        }).success === false
      ) {
        const errorMessage = salespersonSchema.safeParse({
          employeeDesignation: salespersonDetails.employeeDesignation,
        }).error.issues[0].message;
        const customMessage = errorMessage.replace(
          /^String/,
          "Employee designation"
        );
        message.error(customMessage);
        return;
      }

      if (
        salespersonSchema.safeParse({
          employeeIdType: salespersonDetails.employeeIdType,
        }).success === false
      ) {
        message.error("Select atleast one employee id type");
        return;
      }

      if (salespersonDetails.employeeIdType === "Employee ID Card") {
        if (
          salespersonSchema.safeParse({
            employeeID: salespersonDetails.employeeID,
          }).success === false
        ) {
          const errorMessage = salespersonSchema.safeParse({
            employeeID: salespersonDetails.employeeID,
          }).error.issues[0].message;
          const customMessage = errorMessage.replace(/^String/, "Employee ID");
          message.error(customMessage);
          return;
        }
        if (!salespersonDetails.employeeIdCardImage) {
          message.error("Employee ID card Image required");
          return;
        }
      }

      if (salespersonDetails.employeeIdType === "Aadhaar Card") {
        if (
          salespersonSchema.safeParse({
            employeeAadhaarCardNo: salespersonDetails.employeeAadhaarCardNo,
          }).success === false
        ) {
          message.error(`Aadhaar card no last 4 digits required.`);
          return;
        }
        if (!salespersonDetails.employeeAadhaarcardFrontImage) {
          message.error("Employee aadhaar card front image required");
          return;
        }

        if (!salespersonDetails.employeeAadhaarcardBackImage) {
          message.error("Employee aadhaar card back image required");
          return;
        }
      }

      if (salespersonDetails.employeeIdType === "Pan Card") {
        if (
          salespersonSchema.safeParse({
            employeePanCardNo: salespersonDetails.employeePanCardNo,
          }).success === false
        ) {
          const errorMessage = salespersonSchema.safeParse({
            employeePanCardNo: salespersonDetails.employeePanCardNo,
          }).error.issues[0].message;
          const customMessage = errorMessage.replace(/^String/, "Pan card no.");
          message.error(customMessage);
          return;
        }

        if (!salespersonDetails.employeePancardImage) {
          message.error("Employee pancard image required");
          return;
        }
      }

      if (salespersonDetails.employeeIdType === "Voter Card") {
        if (
          salespersonSchema.safeParse({
            employeeVoterCardNo: salespersonDetails.employeeVoterCardNo,
          }).success === false
        ) {
          const errorMessage = salespersonSchema.safeParse({
            employeeVoterCardNo: salespersonDetails.employeeVoterCardNo,
          }).error.issues[0].message;
          const customMessage = errorMessage.replace(
            /^String/,
            "Voter card no."
          );
          message.error(customMessage);
          return;
        }
        if (!salespersonDetails.employeeVoterIdFrontImage) {
          message.error("Employee voter card front image required");
          return;
        }

        if (!salespersonDetails.employeeVoterIdBackImage) {
          message.error("Employee voter card back image required");
          return;
        }
      }

      if (salespersonDetails.employeeIdType === "Passport") {
        if (
          salespersonSchema.safeParse({
            employeePassportNo: salespersonDetails.employeePassportNo,
          }).success === false
        ) {
          const errorMessage = salespersonSchema.safeParse({
            employeePassportNo: salespersonDetails.employeePassportNo,
          }).error.issues[0].message;
          const customMessage = errorMessage.replace(/^String/, "Passport no.");
          message.error(customMessage);
          return;
        }

        if (!salespersonDetails.employeePassportImage) {
          message.error("Employee passport image required");
          return;
        }
      }

      if (salespersonDetails.employeeIdType === "Driving License") {
        if (
          salespersonSchema.safeParse({
            employeeDrivingLicenseNo:
              salespersonDetails.employeeDrivingLicenseNo,
          }).success === false
        ) {
          const errorMessage = salespersonSchema.safeParse({
            employeeDrivingLicenseNo:
              salespersonDetails.employeeDrivingLicenseNo,
          }).error.issues[0].message;
          const customMessage = errorMessage.replace(
            /^String/,
            "Driving license no."
          );
          message.error(customMessage);
          return;
        }
        if (!salespersonDetails.employeeDrivinglicenseFrontImage) {
          message.error("Employee driving license front image required");
          return;
        }

        if (!salespersonDetails.employeeDrivinglicenseBackImage) {
          message.error("Employee driving license back image required");
          return;
        }
      }

      if (!salespersonDetails.employeeImage) {
        message.error("Employee image required");
        return;
      }
    }

    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("userType", userType);
    // Owner Form Data
    if (userType === "Owner") {
      formData.append("ownerName", fullname);
      formData.append("ownerEmail", email);
      formData.append("ownerMobile", mobile);
      formData.append("ownerWhatsappMobile", whatsappMobile);
      formData.append("ownerProfilePhoto", profileImage);
    }

    // Builder Form Data
    if (userType === "Builder") {
      formData.append("builderFirmName", builderDetails.builderFirmName);
      formData.append("builderReraNo", builderDetails.builderReraNo);
      formData.append("builderReraNoType", builderDetails.builderReraNoType);
      formData.append("builderOfficeCity", builderDetails.builderOfficeCity);
      formData.append("builderOfficeAddress", builderDetails.builderAddress);
      formData.append(
        "builderProfilePhoto",
        builderDetails.builderProfilePhoto
      );
      formData.append("builderFirmLogo", builderDetails.builderFirmLogo);
      // Builder Employees
      builderAddedEmployeesList.forEach((employee, index) => {
        formData.append(
          `builderEmployeeList[${index}][employeeName]`,
          employee.employeeName
        );
        formData.append(
          `builderEmployeeList[${index}][employeeDesignation]`,
          employee.employeeDesignation
        );
        formData.append(
          `builderEmployeeList[${index}][employeeEmail]`,
          employee.employeeEmail
        );
        formData.append(
          `builderEmployeeList[${index}][employeeMobileNo]`,
          employee.employeeMobileNo
        );
        formData.append(
          `builderEmployeeList[${index}][employeeID]`,
          employee.employeeID
        );
        formData.append(
          `builderEmployeeImage_${index}`,
          employee.employeeImage
        );
        formData.append(
          `builder_Employee_Employeement_Letter_Image_${index}`,
          employee.employeementLetterImage
        );
        formData.append(
          `builderEmployeeIdCardImage_${index}`,
          employee.employeeIdCardImage
        );
      });
      // Builder Projects
      builderAddedProjectsList.forEach((project, index) => {
        formData.append(
          `builderProjectList[${index}][projectName]`,
          project.projectName
        );
        formData.append(
          `builderProjectList[${index}][projectReraNo]`,
          project.projectReraNo
        );
        formData.append(
          `builderProjectList[${index}][projectDescription]`,
          project.projectDescription
        );
        formData.append(
          `builderProjectList[${index}][projectStatus]`,
          project.projectStatus
        );
        formData.append(
          `builderProjectList[${index}][projectCompletionYear]`,
          project.projectCompletionYear
        );
        formData.append(
          `builderProjectList[${index}][projectOwnershipType]`,
          project.projectOwnershipType
        );
        formData.append(
          `builderProjectList[${index}][projectFloorPlans]`,
          JSON.stringify(project.projectFloorPlans)
        );
        formData.append(
          `builderProjectList[${index}][projectArea]`,
          project.projectArea
        );
        formData.append(
          `builderProjectList[${index}][projectAreaUnit]`,
          project.projectAreaUnit
        );
        formData.append(
          `builderProjectList[${index}][projectPriceRange]`,
          JSON.stringify(project.projectPriceRange)
        );
        formData.append(
          `builderProjectList[${index}][projectCurrencyType]`,
          project.projectCurrencyType
        );
        formData.append(
          `builderProjectList[${index}][projectCity]`,
          project.projectCity
        );
        formData.append(
          `builderProjectList[${index}][projectLocation]`,
          JSON.stringify(project.projectLocation)
        );
        formData.append(
          `builderProjectList[${index}][projectLocality]`,
          project.projectLocality
        );

        formData.append(`builderProjectLogo_${index}`, project.projectLogo);

        formData.append(`builderProjectLayout_${index}`, project.projectLayout);
        formData.append(
          `builderProjectBrochure_${index}`,
          project.projectBrochure
        );
      });
    }

    // Agent Form Data
    if (userType === "Agent") {
      formData.append("agentDealsIn", JSON.stringify(agentDetails.dealsIn));
      formData.append("agentFirmName", agentDetails.agentFirmName);
      formData.append("agentReraNo", agentDetails.agentReraNo);
      formData.append("agentReraNoType", agentDetails.agentReraNoType);
      formData.append("agentOfficeCity", agentDetails.agentOfficeCity);
      formData.append("agentOfficeAddress", agentDetails.agentAddress);
      formData.append("agentProfilePhoto", agentDetails.agentProfilePhoto);
      formData.append("agentFirmLogo", agentDetails.agentFirmLogo);
      // Agent Emplyees
      agentAddedEmployeesList.forEach((employee, index) => {
        formData.append(
          `agentEmployeeList[${index}][employeeName]`,
          employee.employeeName
        );
        formData.append(
          `agentEmployeeList[${index}][employeeDesignation]`,
          employee.employeeDesignation
        );
        formData.append(
          `agentEmployeeList[${index}][employeeEmail]`,
          employee.employeeEmail
        );
        formData.append(
          `agentEmployeeList[${index}][employeeMobileNo]`,
          employee.employeeMobileNo
        );
        formData.append(
          `agentEmployeeList[${index}][employeeIdType]`,
          employee.employeeIdType
        );
        formData.append(
          `agentEmployeeList[${index}][employeeID]`,
          employee.employeeID
        );
        formData.append(`agentEmployeeImage_${index}`, employee.employeeImage);
        formData.append(
          `agent_Employee_Employeement_Letter_Image_${index}`,
          employee.employeementLetterImage
        );
        formData.append(
          `agentEmployeeIdCardImage_${index}`,
          employee.employeeIdCardImage
        );
        formData.append(
          `agentEmployeeList[${index}][employeeAadhaarCardNo]`,
          employee.employeeAadhaarCardNo
        );
        formData.append(
          `agentEmployeeList[${index}][employeePanCardNo]`,
          employee.employeePanCardNo
        );
        formData.append(
          `agentEmployeeList[${index}][employeeVoterCardNo]`,
          employee.employeeVoterCardNo
        );
        formData.append(
          `agentEmployeeList[${index}][employeePassportNo]`,
          employee.employeePassportNo
        );
        formData.append(
          `agentEmployeeList[${index}][employeeDrivingLicenseNo]`,
          employee.employeeDrivingLicenseNo
        );
        formData.append(
          `agentEmployeeAadhaarcardFrontImage_${index}`,
          employee.employeeAadhaarcardFrontImage
        );
        formData.append(
          `agentEmployeeAadhaarcardBackImage_${index}`,
          employee.employeeAadhaarcardBackImage
        );
        formData.append(
          `agentEmployeePancardImage_${index}`,
          employee.employeePancardImage
        );
        formData.append(
          `agentEmployeeVoterIdFrontImage_${index}`,
          employee.employeeVoterIdFrontImage
        );
        formData.append(
          `agentEmployeeVoterIdBackImage_${index}`,
          employee.employeeVoterIdBackImage
        );
        formData.append(
          `agentEmployeePassportImage_${index}`,
          employee.employeePassportImage
        );
        formData.append(
          `agentEmployeeDrivinglicenseFrontImage_${index}`,
          employee.employeeDrivinglicenseFrontImage
        );
        formData.append(
          `agentEmployeeDrivinglicenseBackImage_${index}`,
          employee.employeeDrivinglicenseBackImage
        );
      });
    }

    // Salesperson Form Data
    if (userType === "Salesperson") {
      formData.append("salespersonName", salespersonDetails.employeeName);
      formData.append("salespersonEmail", salespersonDetails.employeeEmail);
      formData.append(
        "salespersonMobileNo",
        salespersonDetails.employeeMobileNo
      );
      formData.append(
        "salespersonWhatsappMobile",
        salespersonDetails.employeeWhatsappMobile
      );
      formData.append(
        "salespersonDesignation",
        salespersonDetails.employeeDesignation
      );
      formData.append(
        "selectedSalespersonEmployeeIdType",
        salespersonDetails.employeeIdType
      );
      formData.append("salespersonID", salespersonDetails.employeeID);
      formData.append(
        "salespersonAadhaarcardNo",
        salespersonDetails.employeeAadhaarCardNo
      );
      formData.append(
        "salespersonPancardNo",
        salespersonDetails.employeePanCardNo
      );
      formData.append(
        "salespersonVoterIdNo",
        salespersonDetails.employeeVoterCardNo
      );
      formData.append(
        "salespersonPassportNo",
        salespersonDetails.employeePassportNo
      );
      formData.append(
        "salespersonDrivinglicenseNo",
        salespersonDetails.employeeDrivingLicenseNo
      );
      formData.append("salespersonImage", salespersonDetails.employeeImage);
      formData.append(
        "salespersonEmployeementLetter",
        salespersonDetails.employeementLetterImage
      );
      formData.append(
        "salespersonIdCardImage",
        salespersonDetails.employeeIdCardImage
      );
      formData.append(
        "salespersonAadhaarcardFrontImage",
        salespersonDetails.employeeAadhaarcardFrontImage
      );
      formData.append(
        "salespersonAadhaarcardBackImage",
        salespersonDetails.employeeAadhaarcardBackImage
      );
      formData.append(
        "salespersonPancardImage",
        salespersonDetails.employeePancardImage
      );
      formData.append(
        "salespersonVoterIdFrontImage",
        salespersonDetails.employeeVoterIdFrontImage
      );
      formData.append(
        "salespersonVoterIdBackImage",
        salespersonDetails.employeeVoterIdBackImage
      );
      formData.append(
        "salespersonPassportImage",
        salespersonDetails.employeePassportImage
      );
      formData.append(
        "salespersonDrivinglicenseFrontImage",
        salespersonDetails.employeeDrivinglicenseFrontImage
      );
      formData.append(
        "salespersonDrivinglicenseBackImage",
        salespersonDetails.employeeDrivinglicenseBackImage
      );
    }

    if (buttonLoading || loading) {
      return;
    }

    try {
      const response = await axios.post(
        `${baseURL}/api/profile/saveUserDetails`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.info(response.data.message);
      if (
        response.data.message === "Profile created successfully" ||
        response.status === 201 ||
        response.statusText === "Created"
      ) {
        setButtonLoading(true);
        setTimeout(() => {
          setButtonLoading(false);
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
            navigate("/");
          }, 500);
        }, 2000);
      }
    } catch (error) {
      message.error(error.response.data.message);
      setButtonLoading(false);
      console.error("Error saving profile:", error);
    }
  };

  return (
    <div className="utr">
      {loading && <LoadingOverlay />}
      <div className="utr-sub">
        <div className="utr-container">
          <div className="utr-header">
            <div className="utr-header-sub">
              <GoArrowLeft
                className="utr-header-sub-icon"
                onClick={() => (currentStep === 1 ? navigate("/") : prevStep())}
              />
              {currentStep === 1 ? (
                <h3>Set-up your Profile</h3>
              ) : (
                <h3>User Type - {userType}</h3>
              )}
              <Popconfirm
                title="Reset the data"
                description="Are you sure to rest this form?"
                onConfirm={confirmFormReset}
                onCancel={cancelFormReset}
                okText="Reset"
                cancelText="Cancel"
              >
                <GrPowerReset className="utr-header-sub-icon-reset" />
              </Popconfirm>
            </div>
          </div>
          <div className="utr-form">
            {currentStep === 1 && (
              <div className="utr-form-sub">
                <div className="utr-form-userType">
                  <div className="utr-form-userType-heading">
                    <h4>Select your User Type</h4>
                  </div>
                  <div className="utr-form-userType-sub">
                    <div
                      className={`utr-form-usertype-category ${
                        userType === "Owner" ? "selected" : ""
                      }`}
                      onClick={() => setUserType("Owner")}
                    >
                      <img src={OwnerIcon} alt="Owner" />
                      <h5>Owner</h5>
                    </div>
                    <div
                      className={`utr-form-usertype-category ${
                        userType === "Builder" ? "selected" : ""
                      }`}
                      onClick={() => setUserType("Builder")}
                    >
                      <img src={BuilderIcon} alt="Builder" />
                      <h5>Builder</h5>
                    </div>
                    <div
                      className={`utr-form-usertype-category ${
                        userType === "Agent" ? "selected" : ""
                      }`}
                      onClick={() => setUserType("Agent")}
                    >
                      <img src={AgentIcon} alt="Agent" />
                      <h5>Agent</h5>
                    </div>
                    <div
                      className={`utr-form-usertype-category ${
                        userType === "Salesperson" ? "selected" : ""
                      }`}
                      onClick={() => setUserType("Salesperson")}
                    >
                      <img src={SalespersonIcon} alt="Salesperson" />
                      <h5>Salesperson</h5>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div className="utr-form-sub">
                {userType === "Owner" && (
                  <>
                    <div className="utr-form-category">
                      <h4>Profile Details</h4>
                      <div className="utr-form-inputs">
                        <FormInput
                          icon={MdDriveFileRenameOutline}
                          placeholder="Full Name"
                          value={fullname}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                        <FormInput
                          icon={MdOutlineEmail}
                          placeholder="Email"
                          value={email}
                          onChange={(e) => {
                            if (
                              !userData?.email ||
                              userData.email.includes("@tempmail.com")
                            ) {
                              setEmail(e.target.value);
                            }
                          }}
                        />
                        <FormInput
                          icon={MdOutlinePhoneEnabled}
                          placeholder="Mobile No."
                          value={formatMobileDisplay(mobile)}
                          onChange={!userData?.mobile && handleMobileChange}
                          maxLength="14"
                        />
                        <FormInput
                          icon={IoLogoWhatsapp}
                          placeholder="Whatsapp No."
                          value={
                            checked
                              ? formatMobileDisplay(mobile)
                              : formatWhatsappMobileDisplay(whatsappMobile)
                          }
                          onChange={
                            !userData?.whatsappMobile &&
                            handleWhatsappMobileChange
                          }
                          readOnly={checked}
                          maxLength="14"
                        />
                      </div>
                      <h6>
                        Whatsapp Mobile number same as Mobile number?
                        <Switch
                          checked={checked}
                          onChange={onSameWhatsappMobileAsMobileChange}
                          style={{ backgroundColor: switchBackgroundColor }}
                          className="utr-form-category-h6-switch"
                        />
                      </h6>
                    </div>
                    <div className="utr-form-category">
                      <h4>Profile Photo</h4>
                      <div className="utr-form-images">
                        <ImageUpload
                          image={profileImage}
                          onImageChange={(e) =>
                            setProfileImage(e.target.files[0])
                          }
                          onRemove={() => setProfileImage(null)}
                          label=""
                        />
                      </div>
                    </div>
                  </>
                )}
                {userType === "Builder" && (
                  <>
                    <div className="utr-form-category">
                      <h4>Builder Firm Details</h4>
                      <div className="utr-form-inputs">
                        <FormInput
                          icon={TbBuildingEstate}
                          placeholder="Firm Name"
                          value={builderDetails.builderFirmName}
                          onChange={(e) =>
                            setBuilderDetails((prev) => ({
                              ...prev,
                              builderFirmName: e.target.value,
                            }))
                          }
                        />
                        <FormInput
                          icon={TbInputCheck}
                          placeholder="RERA No."
                          value={builderDetails.builderReraNo}
                          onChange={(e) =>
                            setBuilderDetails((prev) => ({
                              ...prev,
                              builderReraNo: e.target.value,
                            }))
                          }
                        />
                        <div className="utr-form-input">
                          <LuTextCursorInput className="utr-form-input-icon" />
                          <Select
                            showSearch
                            name="builderReraNoType"
                            className="utr-form-input-select"
                            placeholder="Select Rera Number Type"
                            value={
                              builderDetails.builderReraNoType || undefined
                            }
                            optionFilterProp="label"
                            onChange={(value) =>
                              setBuilderDetails((prev) => ({
                                ...prev,
                                builderReraNoType: value,
                              }))
                            }
                            options={[
                              {
                                value:
                                  "(ANRA) - Andaman and Nicobar Islands Real Estate Regulatory Authority",
                                label:
                                  "(ANRA) - Andaman and Nicobar Islands Real Estate Regulatory Authority",
                              },
                              {
                                value:
                                  "(APRERA) - Arunachal Pradesh Real Estate Regulatory Authority",
                                label:
                                  "(APRERA) - Arunachal Pradesh Real Estate Regulatory Authority",
                              },
                              {
                                value:
                                  "(APTIDCO) - Andhra Pradesh Township and Infrastructure Development Corporation",
                                label:
                                  "(APTIDCO) - Andhra Pradesh Township and Infrastructure Development Corporation",
                              },
                              {
                                value:
                                  "(AUIIP) - Assam Urban Infrastructure Investment Program",
                                label:
                                  "(AUIIP) - Assam Urban Infrastructure Investment Program",
                              },
                              {
                                value:
                                  "(BUIDCO) - Bihar Urban Infrastructure Development Corporation",
                                label:
                                  "(BUIDCO) - Bihar Urban Infrastructure Development Corporation",
                              },
                              {
                                value:
                                  "(Bihar RERA) - Bihar Real Estate Regulatory Authority",
                                label:
                                  "(Bihar RERA) - Bihar Real Estate Regulatory Authority",
                              },
                              {
                                value: "(CGHB) - Chhattisgarh Housing Board",
                                label: "(CGHB) - Chhattisgarh Housing Board",
                              },
                              {
                                value: "(CHB) - Chandigarh Housing Board",
                                label: "(CHB) - Chandigarh Housing Board",
                              },
                              {
                                value: "(DDA) - Delhi Development Authority",
                                label: "(DDA) - Delhi Development Authority",
                              },
                              {
                                value:
                                  "(DNHDD RERA) - Dadra and Nagar Haveli and Daman and Diu Real Estate Regulatory Authority",
                                label:
                                  "(DNHDD RERA) - Dadra and Nagar Haveli and Daman and Diu Real Estate Regulatory Authority",
                              },
                              {
                                value:
                                  "(HUDA) - Haryana Urban Development Authority",
                                label:
                                  "(HUDA) - Haryana Urban Development Authority",
                              },
                              {
                                value:
                                  "(HIMUDA) - Himachal Pradesh Housing and Urban Development Authority",
                                label:
                                  "(HIMUDA) - Himachal Pradesh Housing and Urban Development Authority",
                              },
                              {
                                value:
                                  "(MHADA) - Maharashtra Housing and Area Development Authority",
                                label:
                                  "(MHADA) - Maharashtra Housing and Area Development Authority",
                              },
                              {
                                value:
                                  "(MUDA) - Meghalaya Urban Development Authority",
                                label:
                                  "(MUDA) - Meghalaya Urban Development Authority",
                              },
                              {
                                value:
                                  "(PUDA) - Punjab Urban Planning and Development Authority",
                                label:
                                  "(PUDA) - Punjab Urban Planning and Development Authority",
                              },
                              {
                                value:
                                  "(UHUDA) - Uttarakhand Housing and Urban Development Authority",
                                label:
                                  "(UHUDA) - Uttarakhand Housing and Urban Development Authority",
                              },
                              {
                                value:
                                  "(WBHIDCO) - West Bengal Housing Infrastructure Development Corporation",
                                label:
                                  "(WBHIDCO) - West Bengal Housing Infrastructure Development Corporation",
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="utr-form-category">
                      <h4>Where your firm is located?</h4>
                      <div className="utr-form-inputs">
                        <div className="utr-form-input">
                          <PiCity className="utr-form-input-icon" />
                          <Select
                            showSearch
                            className="utr-form-input-select"
                            placeholder="Select a city"
                            value={
                              builderDetails.builderOfficeCity || undefined
                            }
                            optionFilterProp="label"
                            onChange={(value) =>
                              setBuilderDetails((prev) => ({
                                ...prev,
                                builderOfficeCity: value,
                              }))
                            }
                            options={cityOptions.map((option) => ({
                              value: option.cityName,
                              label: option.cityName,
                            }))}
                          />
                        </div>
                        <FormInput
                          icon={PiBracketsCurlyBold}
                          placeholder="Website Url (ex. https://proptzo.com/)"
                          value={builderDetails.builderOfficeWebsite}
                          onChange={(e) =>
                            setBuilderDetails((prev) => ({
                              ...prev,
                              builderOfficeWebsite: e.target.value,
                            }))
                          }
                        />
                        <div className="utr-form-input utr-form-input-flex">
                          <textarea
                            type="text"
                            placeholder="Builder Firm Address"
                            value={builderDetails.builderAddress}
                            onChange={handleBuilderAddressChange}
                            className={
                              builderAddressCharacterCount === 100
                                ? "utr-form-input-max-limit-reached"
                                : builderDetails.builderAddress.trim() !== ""
                                ? "utr-form-input-selected"
                                : ""
                            }
                          />
                          <div
                            className={
                              builderAddressCharacterCount === 100
                                ? "utr-form-input-sub-max-limit-reached"
                                : "utr-form-input-sub"
                            }
                          >
                            <span>{builderAddressSpanContent}</span>
                            <span>{builderAddressCharacterCount} / 100</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="utr-form-category">
                      <h4>Builder Photos</h4>
                      <div className="utr-form-images">
                        <ImageUpload
                          image={builderDetails.builderProfilePhoto}
                          onImageChange={(e) =>
                            setBuilderDetails((prev) => ({
                              ...prev,
                              builderProfilePhoto: e.target.files[0],
                            }))
                          }
                          onRemove={() =>
                            setBuilderDetails((prev) => ({
                              ...prev,
                              builderProfilePhoto: null,
                            }))
                          }
                          label="Profile Photo"
                        />
                        <ImageUpload
                          image={builderDetails.builderFirmLogo}
                          onImageChange={(e) =>
                            setBuilderDetails((prev) => ({
                              ...prev,
                              builderFirmLogo: e.target.files[0],
                            }))
                          }
                          onRemove={() =>
                            setBuilderDetails((prev) => ({
                              ...prev,
                              builderFirmLogo: null,
                            }))
                          }
                          label="Firm Logo"
                        />
                      </div>
                    </div>
                    <div className="utr-form-category">
                      <h4>Add your Employees</h4>
                      <Collapse
                        // ghost
                        items={builderEmployeesCollapse}
                        bordered={true}
                      />
                    </div>
                    <div className="utr-form-category utr-form-category-last">
                      <h4>Add your Projects</h4>
                      <Collapse
                        // ghost
                        items={builderProjectsCollapse}
                        bordered={true}
                      />
                    </div>
                  </>
                )}
                {userType === "Agent" && (
                  <>
                    <div className="utr-form-category">
                      <h4>Deals In</h4>
                      <div className="utr-form-types">
                        {["Sale", "Rent / Lease", "PG / Hostel"].map((deal) => (
                          <div
                            key={deal}
                            className={`utr-form-types-sub ${
                              isAgentDealSelected(deal) ? "selected" : ""
                            }`}
                            onClick={() => handleAgentDealsInChange(deal)}
                          >
                            <CgTag className="utr-form-types-sub-icon" />
                            <h5>{deal}</h5>
                          </div>
                        ))}
                        {agentDetails.dealsIn.length > 0 && (
                          <div className="utr-form-types-clearselection">
                            <span onClick={handleClearAgentDealsInSelection}>
                              - Clear Selection
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="utr-form-category">
                      <h4>Agent Firm Details</h4>
                      <div className="utr-form-inputs">
                        <FormInput
                          icon={TbBuildingEstate}
                          placeholder="Firm Name"
                          value={agentDetails.agentFirmName}
                          onChange={(e) =>
                            setAgentDetails((prev) => ({
                              ...prev,
                              agentFirmName: e.target.value,
                            }))
                          }
                        />
                        <FormInput
                          icon={TbBuildingEstate}
                          placeholder="Rera No."
                          value={agentDetails.agentReraNo}
                          onChange={(e) =>
                            setAgentDetails((prev) => ({
                              ...prev,
                              agentReraNo: e.target.value,
                            }))
                          }
                        />
                        <div className="utr-form-input">
                          <LuTextCursorInput className="utr-form-input-icon" />
                          <Select
                            showSearch
                            name="agentReraNoType"
                            className="utr-form-input-select"
                            placeholder="Select Rera Number Type"
                            value={agentDetails.agentReraNoType || undefined}
                            optionFilterProp="label"
                            onChange={(value) =>
                              setAgentDetails((prev) => ({
                                ...prev,
                                agentReraNoType: value,
                              }))
                            }
                            options={[
                              {
                                value:
                                  "(ANRA) - Andaman and Nicobar Islands Real Estate Regulatory Authority",
                                label:
                                  "(ANRA) - Andaman and Nicobar Islands Real Estate Regulatory Authority",
                              },
                              {
                                value:
                                  "(APRERA) - Arunachal Pradesh Real Estate Regulatory Authority",
                                label:
                                  "(APRERA) - Arunachal Pradesh Real Estate Regulatory Authority",
                              },
                              {
                                value:
                                  "(APTIDCO) - Andhra Pradesh Township and Infrastructure Development Corporation",
                                label:
                                  "(APTIDCO) - Andhra Pradesh Township and Infrastructure Development Corporation",
                              },
                              {
                                value:
                                  "(AUIIP) - Assam Urban Infrastructure Investment Program",
                                label:
                                  "(AUIIP) - Assam Urban Infrastructure Investment Program",
                              },
                              {
                                value:
                                  "(BUIDCO) - Bihar Urban Infrastructure Development Corporation",
                                label:
                                  "(BUIDCO) - Bihar Urban Infrastructure Development Corporation",
                              },
                              {
                                value:
                                  "(Bihar RERA) - Bihar Real Estate Regulatory Authority",
                                label:
                                  "(Bihar RERA) - Bihar Real Estate Regulatory Authority",
                              },
                              {
                                value: "(CGHB) - Chhattisgarh Housing Board",
                                label: "(CGHB) - Chhattisgarh Housing Board",
                              },
                              {
                                value: "(CHB) - Chandigarh Housing Board",
                                label: "(CHB) - Chandigarh Housing Board",
                              },
                              {
                                value: "(DDA) - Delhi Development Authority",
                                label: "(DDA) - Delhi Development Authority",
                              },
                              {
                                value:
                                  "(DNHDD RERA) - Dadra and Nagar Haveli and Daman and Diu Real Estate Regulatory Authority",
                                label:
                                  "(DNHDD RERA) - Dadra and Nagar Haveli and Daman and Diu Real Estate Regulatory Authority",
                              },
                              {
                                value:
                                  "(HUDA) - Haryana Urban Development Authority",
                                label:
                                  "(HUDA) - Haryana Urban Development Authority",
                              },
                              {
                                value:
                                  "(HIMUDA) - Himachal Pradesh Housing and Urban Development Authority",
                                label:
                                  "(HIMUDA) - Himachal Pradesh Housing and Urban Development Authority",
                              },
                              {
                                value:
                                  "(MHADA) - Maharashtra Housing and Area Development Authority",
                                label:
                                  "(MHADA) - Maharashtra Housing and Area Development Authority",
                              },
                              {
                                value:
                                  "(MUDA) - Meghalaya Urban Development Authority",
                                label:
                                  "(MUDA) - Meghalaya Urban Development Authority",
                              },
                              {
                                value:
                                  "(PUDA) - Punjab Urban Planning and Development Authority",
                                label:
                                  "(PUDA) - Punjab Urban Planning and Development Authority",
                              },
                              {
                                value:
                                  "(UHUDA) - Uttarakhand Housing and Urban Development Authority",
                                label:
                                  "(UHUDA) - Uttarakhand Housing and Urban Development Authority",
                              },
                              {
                                value:
                                  "(WBHIDCO) - West Bengal Housing Infrastructure Development Corporation",
                                label:
                                  "(WBHIDCO) - West Bengal Housing Infrastructure Development Corporation",
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="utr-form-category">
                      <h4>Where your firm is located?</h4>
                      <div className="utr-form-inputs">
                        <div className="utr-form-input">
                          <PiCity className="utr-form-input-icon" />
                          <Select
                            showSearch
                            className="utr-form-input-select"
                            placeholder="Select a city"
                            value={agentDetails.agentOfficeCity || undefined}
                            optionFilterProp="label"
                            onChange={(value) =>
                              setAgentDetails((prev) => ({
                                ...prev,
                                agentOfficeCity: value,
                              }))
                            }
                            options={cityOptions.map((option) => ({
                              value: option.cityName,
                              label: option.cityName,
                            }))}
                          />
                        </div>
                        <FormInput
                          icon={PiBracketsCurlyBold}
                          placeholder="Website Url (ex. https://proptzo.com/)"
                          value={agentDetails.agentOfficeWebsite}
                          onChange={(e) =>
                            setAgentDetails((prev) => ({
                              ...prev,
                              agentOfficeWebsite: e.target.value,
                            }))
                          }
                        />
                        <div className="utr-form-input utr-form-input-flex">
                          <textarea
                            type="text"
                            placeholder="Agent Firm Address"
                            value={agentDetails.agentAddress}
                            onChange={handleAgentAddressChange}
                            className={
                              agentAddressCharacterCount === 100
                                ? "utr-form-input-max-limit-reached"
                                : agentDetails.agentAddress.trim() !== ""
                                ? "utr-form-input-selected"
                                : ""
                            }
                          />
                          <div
                            className={
                              agentAddressCharacterCount === 100
                                ? "utr-form-input-sub-max-limit-reached"
                                : "utr-form-input-sub"
                            }
                          >
                            <span>{agentAddressSpanContent}</span>
                            <span>{agentAddressCharacterCount} / 100</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="utr-form-category">
                      <h4>Agent Photos</h4>
                      <div className="utr-form-images">
                        <ImageUpload
                          image={agentDetails.agentProfilePhoto}
                          onImageChange={(e) =>
                            setAgentDetails((prev) => ({
                              ...prev,
                              agentProfilePhoto: e.target.files[0],
                            }))
                          }
                          onRemove={() =>
                            setAgentDetails((prev) => ({
                              ...prev,
                              agentProfilePhoto: null,
                            }))
                          }
                          label="Profile Photo"
                        />
                        <ImageUpload
                          image={agentDetails.agentFirmLogo}
                          onImageChange={(e) =>
                            setAgentDetails((prev) => ({
                              ...prev,
                              agentFirmLogo: e.target.files[0],
                            }))
                          }
                          onRemove={() =>
                            setAgentDetails((prev) => ({
                              ...prev,
                              agentFirmLogo: null,
                            }))
                          }
                          label="Firm Logo"
                        />
                      </div>
                    </div>
                    <div className="utr-form-category utr-form-category-last">
                      <h4>Add your Employees</h4>
                      <Collapse
                        // ghost
                        items={agentEmployeesCollapse}
                        bordered={true}
                      />
                    </div>
                  </>
                )}
                {userType === "Salesperson" && (
                  <>
                    <div className="utr-form-category">
                      <h4>Profile Details</h4>
                      <div className="utr-form-inputs">
                        <FormInput
                          icon={MdDriveFileRenameOutline}
                          placeholder="Full Name"
                          value={salespersonDetails.employeeName}
                          onChange={(e) =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeName: e.target.value,
                            }))
                          }
                        />
                        <FormInput
                          icon={MdOutlineEmail}
                          placeholder="Email"
                          value={salespersonDetails.employeeEmail}
                          onChange={(e) => {
                            if (
                              !userData?.email ||
                              userData.email.includes("@tempmail.com")
                            ) {
                              setSalespersonDetails((prev) => ({
                                ...prev,
                                employeeEmail: e.target.value,
                              }));
                            }
                          }}
                        />
                        <FormInput
                          icon={MdOutlinePhoneEnabled}
                          placeholder="Mobile No."
                          value={formatSalespersonMobileDisplay(
                            salespersonDetails.employeeMobileNo
                          )}
                          onChange={(e) => {
                            if (
                              !userData?.mobileNumber ||
                              userData?.mobileNumber === "undefined"
                            ) {
                              handleSalespersonMobileChange(e);
                            }
                          }}
                          maxLength="14"
                        />
                        <FormInput
                          icon={IoLogoWhatsapp}
                          placeholder="Whatsapp No."
                          value={
                            salespersonChecked
                              ? formatSalespersonMobileDisplay(
                                  salespersonDetails.employeeMobileNo
                                )
                              : formatWhatsappMobileDisplay(
                                  salespersonDetails.employeeWhatsappMobile
                                )
                          }
                          onChange={(e) => {
                            if (
                              !userData?.whatsappNumber ||
                              userData?.whatsappNumber === "undefined"
                            ) {
                              handleSalespersonWhatsappMobileChange(e);
                            }
                          }}
                          readOnly={salespersonChecked}
                          maxLength="14"
                        />
                      </div>
                      <h6>
                        Whatsapp Mobile number same as Mobile number?
                        <Switch
                          checked={salespersonChecked}
                          onChange={
                            onSalespersonSameWhatsappMobileAsMobileChange
                          }
                          style={{
                            backgroundColor: salespersonSwitchBackgroundColor,
                          }}
                          className="utr-form-category-h6-switch"
                        />
                      </h6>
                    </div>
                    <div className="utr-form-category">
                      <h4>For Verification</h4>
                      <div className="utr-form-inputs">
                        <FormInput
                          icon={LiaUserTagSolid}
                          placeholder="Employee Designation"
                          value={salespersonDetails.employeeDesignation}
                          onChange={(e) =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeDesignation: e.target.value,
                            }))
                          }
                        />
                        <div className="utr-form-input">
                          <HiOutlineDocumentReport className="utr-form-input-icon" />
                          <Select
                            showSearch
                            name="salespersonEmployeeIdType"
                            className="utr-form-input-select"
                            placeholder="Select ID Type"
                            value={
                              salespersonDetails.employeeIdType || undefined
                            }
                            optionFilterProp="label"
                            onChange={handleSalespersonIdTypeChange}
                            options={[
                              {
                                value: "Employee ID Card",
                                label: "Employee ID Card",
                              },
                              {
                                value: "Aadhaar Card",
                                label: "Aadhaar Card",
                              },
                              {
                                value: "Pan Card",
                                label: "Pan Card",
                              },
                              {
                                value: "Voter Card",
                                label: "Voter Card",
                              },
                              {
                                value: "Passport",
                                label: "Passport",
                              },
                              {
                                value: "Driving License",
                                label: "Driving License",
                              },
                            ]}
                          />
                        </div>
                        {salespersonDetails.employeeIdType ===
                          "Employee ID Card" && (
                          <FormInput
                            icon={LiaAddressCardSolid}
                            placeholder="Employee ID"
                            value={salespersonDetails.employeeID}
                            onChange={(e) =>
                              setSalespersonDetails((prev) => ({
                                ...prev,
                                employeeID: e.target.value,
                              }))
                            }
                          />
                        )}
                        {salespersonDetails.employeeIdType ===
                          "Aadhaar Card" && (
                          <FormInput
                            icon={LiaAddressCardSolid}
                            placeholder="Aadhaar Card No. (last 4 digits)"
                            value={salespersonDetails.employeeAadhaarCardNo}
                            onChange={handleSalespersonAadhaarCardNo}
                            maxLength="4"
                          />
                        )}
                        {salespersonDetails.employeeIdType === "Pan Card" && (
                          <FormInput
                            icon={LiaAddressCardSolid}
                            placeholder="Pan Card No."
                            value={salespersonDetails.employeePanCardNo}
                            onChange={(e) =>
                              setSalespersonDetails((prev) => ({
                                ...prev,
                                employeePanCardNo: e.target.value,
                              }))
                            }
                          />
                        )}
                        {salespersonDetails.employeeIdType === "Voter Card" && (
                          <FormInput
                            icon={LiaAddressCardSolid}
                            placeholder="Voter Card No."
                            value={salespersonDetails.employeeVoterCardNo}
                            onChange={(e) =>
                              setSalespersonDetails((prev) => ({
                                ...prev,
                                employeeVoterCardNo: e.target.value,
                              }))
                            }
                          />
                        )}
                        {salespersonDetails.employeeIdType === "Passport" && (
                          <FormInput
                            icon={LiaAddressCardSolid}
                            placeholder="Passport No."
                            value={salespersonDetails.employeePassportNo}
                            onChange={(e) =>
                              setSalespersonDetails((prev) => ({
                                ...prev,
                                employeePassportNo: e.target.value,
                              }))
                            }
                          />
                        )}
                        {salespersonDetails.employeeIdType ===
                          "Driving License" && (
                          <FormInput
                            icon={LiaAddressCardSolid}
                            placeholder="Driving License No."
                            value={salespersonDetails.employeeDrivingLicenseNo}
                            onChange={(e) =>
                              setSalespersonDetails((prev) => ({
                                ...prev,
                                employeeDrivingLicenseNo: e.target.value,
                              }))
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="utr-form-category">
                      <h4>Salesperson Photos</h4>
                      <div className="utr-form-images">
                        <ImageUpload
                          image={salespersonDetails.employeeImage}
                          onImageChange={(e) =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeImage: e.target.files[0],
                            }))
                          }
                          onRemove={() =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeeImage: null,
                            }))
                          }
                          label="Employee Photo"
                        />
                        <ImageUpload
                          image={salespersonDetails.employeementLetterImage}
                          onImageChange={(e) =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeementLetterImage: e.target.files[0],
                            }))
                          }
                          onRemove={() =>
                            setSalespersonDetails((prev) => ({
                              ...prev,
                              employeementLetterImage: null,
                            }))
                          }
                          label="Employee Letter"
                        />
                        {salespersonDetails.employeeIdType ===
                          "Employee ID Card" && (
                          <ImageUpload
                            image={salespersonDetails.employeeIdCardImage}
                            onImageChange={(e) =>
                              setSalespersonDetails((prev) => ({
                                ...prev,
                                employeeIdCardImage: e.target.files[0],
                              }))
                            }
                            onRemove={() =>
                              setSalespersonDetails((prev) => ({
                                ...prev,
                                employeeIdCardImage: null,
                              }))
                            }
                            label="ID Card"
                          />
                        )}
                        {salespersonDetails.employeeIdType ===
                          "Aadhaar Card" && (
                          <>
                            <ImageUpload
                              image={
                                salespersonDetails.employeeAadhaarcardFrontImage
                              }
                              onImageChange={(e) =>
                                setSalespersonDetails((prev) => ({
                                  ...prev,
                                  employeeAadhaarcardFrontImage:
                                    e.target.files[0],
                                }))
                              }
                              onRemove={() =>
                                setSalespersonDetails((prev) => ({
                                  ...prev,
                                  employeeAadhaarcardFrontImage: null,
                                }))
                              }
                              label="Aadhaar Front"
                            />
                            <ImageUpload
                              image={
                                salespersonDetails.employeeAadhaarcardBackImage
                              }
                              onImageChange={(e) =>
                                setSalespersonDetails((prev) => ({
                                  ...prev,
                                  employeeAadhaarcardBackImage:
                                    e.target.files[0],
                                }))
                              }
                              onRemove={() =>
                                setSalespersonDetails((prev) => ({
                                  ...prev,
                                  employeeAadhaarcardBackImage: null,
                                }))
                              }
                              label="Aadhaar Back"
                            />
                          </>
                        )}
                        {salespersonDetails.employeeIdType === "Pan Card" && (
                          <ImageUpload
                            image={salespersonDetails.employeePancardImage}
                            onImageChange={(e) =>
                              setSalespersonDetails((prev) => ({
                                ...prev,
                                employeePancardImage: e.target.files[0],
                              }))
                            }
                            onRemove={() =>
                              setSalespersonDetails((prev) => ({
                                ...prev,
                                employeePancardImage: null,
                              }))
                            }
                            label="Pan Card"
                          />
                        )}
                        {salespersonDetails.employeeIdType === "Voter Card" && (
                          <>
                            <ImageUpload
                              image={
                                salespersonDetails.employeeVoterIdFrontImage
                              }
                              onImageChange={(e) =>
                                setSalespersonDetails((prev) => ({
                                  ...prev,
                                  employeeVoterIdFrontImage: e.target.files[0],
                                }))
                              }
                              onRemove={() =>
                                setSalespersonDetails((prev) => ({
                                  ...prev,
                                  employeeVoterIdFrontImage: null,
                                }))
                              }
                              label="Voter Front"
                            />
                            <ImageUpload
                              image={
                                salespersonDetails.employeeVoterIdBackImage
                              }
                              onImageChange={(e) =>
                                setSalespersonDetails((prev) => ({
                                  ...prev,
                                  employeeVoterIdBackImage: e.target.files[0],
                                }))
                              }
                              onRemove={() =>
                                setSalespersonDetails((prev) => ({
                                  ...prev,
                                  employeeVoterIdBackImage: null,
                                }))
                              }
                              label="Voter Back"
                            />
                          </>
                        )}
                        {salespersonDetails.employeeIdType === "Passport" && (
                          <ImageUpload
                            image={salespersonDetails.employeePassportImage}
                            onImageChange={(e) =>
                              setSalespersonDetails((prev) => ({
                                ...prev,
                                employeePassportImage: e.target.files[0],
                              }))
                            }
                            onRemove={() =>
                              setSalespersonDetails((prev) => ({
                                ...prev,
                                employeePassportImage: null,
                              }))
                            }
                            label="Passport"
                          />
                        )}
                        {salespersonDetails.employeeIdType ===
                          "Driving License" && (
                          <>
                            <ImageUpload
                              image={
                                salespersonDetails.employeeDrivinglicenseFrontImage
                              }
                              onImageChange={(e) =>
                                setSalespersonDetails((prev) => ({
                                  ...prev,
                                  employeeDrivinglicenseFrontImage:
                                    e.target.files[0],
                                }))
                              }
                              onRemove={() =>
                                setSalespersonDetails((prev) => ({
                                  ...prev,
                                  employeeDrivinglicenseFrontImage: null,
                                }))
                              }
                              label="Driving License Front"
                            />
                            <ImageUpload
                              image={
                                salespersonDetails.employeeDrivinglicenseBackImage
                              }
                              onImageChange={(e) =>
                                setSalespersonDetails((prev) => ({
                                  ...prev,
                                  employeeDrivinglicenseBackImage:
                                    e.target.files[0],
                                }))
                              }
                              onRemove={() =>
                                setSalespersonDetails((prev) => ({
                                  ...prev,
                                  employeeDrivinglicenseBackImage: null,
                                }))
                              }
                              label="Driving License Back"
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="utr-footer">
            {currentStep > 1 && (
              <RiArrowLeftSLine
                className="utr-form-footer-icon"
                onClick={prevStep}
              />
            )}
            {currentStep < 2 ? (
              <RiArrowRightSLine
                className="utr-form-footer-icon"
                onClick={nextStep}
              />
            ) : (
              <button
                className="button"
                onClick={handleSubmit}
                disabled={buttonLoading || loading}
              >
                {buttonLoading ? <LoadingOutlined /> : "Save Profile"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InitialProfile;
