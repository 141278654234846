import React from "react";
import "./TopCities.css";

function TopCities() {
  return (
    <div className="trending">
      <div className="trending-sub">
        <h5>TOP CITIES</h5>
        <h1>Explore Today's Hottest Urban Destinations</h1>
        <div className="topcities">
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
          <div className="topcities-sub">
            <h5>
              Properties in <span>Chandigarh</span>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopCities;
