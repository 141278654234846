import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// STYLING IMPORT
import "./AdminSidebar.css";

// LOADER IMPORT
import Loader from "../../components/Loader/AdminLoader";

// ANT DESIGN ICONS
import {
  AppstoreOutlined,
  TeamOutlined,
  HomeOutlined,
  ReconciliationOutlined,
  LikeOutlined,
  SettingOutlined,
  NotificationOutlined,
  BugOutlined,
  CustomerServiceOutlined,
  UserOutlined,
} from "@ant-design/icons";

// ANT DESIGN IMPORT
import { Tooltip, Badge } from "antd";

const SidebarItem = ({ path, title, Icon, setLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = location.pathname === path;

  const handleClick = () => {
    setLoading(true);
    navigate(path);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return (
    <Tooltip placement="right" title={title}>
      <div
        className={`asidebar-category ${isActive ? "hovered" : ""}`}
        onClick={handleClick}
      >
        <Icon className="asidebar-category-icon" />
      </div>
    </Tooltip>
  );
};

function AdminSidebar() {
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading && <Loader />}
      <div className="asidebar">
        <div className="asidebar-sub">
          <SidebarItem
            path="/admin"
            title="Dashboard"
            Icon={AppstoreOutlined}
            setLoading={setLoading}
          />
          <SidebarItem
            path="/admin/users"
            title="Admin Users"
            Icon={TeamOutlined}
            setLoading={setLoading}
          />
          <SidebarItem
            path="/admin/profiles"
            title="Profiles"
            Icon={UserOutlined}
            setLoading={setLoading}
          />
          <SidebarItem
            path="/admin/properties"
            title="Properties"
            Icon={HomeOutlined}
            setLoading={setLoading}
          />
          <SidebarItem
            path="/admin/projects"
            title="Projects"
            Icon={ReconciliationOutlined}
            setLoading={setLoading}
          />
          <SidebarItem
            path="/admin/notifications"
            title="Notifications"
            Icon={NotificationOutlined}
            setLoading={setLoading}
          />
          <SidebarItem
            path="/admin/bugs"
            title="Bugs"
            Icon={BugOutlined}
            setLoading={setLoading}
          />
        </div>
        <div className="asidebar-sub">
          <SidebarItem
            path="/admin/customer-care"
            title="Customer care"
            Icon={CustomerServiceOutlined}
            setLoading={setLoading}
          />
          <SidebarItem
            path="/admin/feedbacks"
            title="Feedbacks"
            Icon={LikeOutlined}
            setLoading={setLoading}
          />
          <SidebarItem
            path="/admin/settings"
            title="Settings"
            Icon={SettingOutlined}
            setLoading={setLoading}
          />
        </div>
      </div>
    </>
  );
}

export default AdminSidebar;
