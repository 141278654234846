import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Profile.css";

import { IoHelpCircleOutline } from "react-icons/io5";
import { TbEdit, TbUser } from "react-icons/tb";
import {
  MdDriveFileRenameOutline,
  MdEdit,
  MdOutlineEmail,
  MdOutlinePhoneEnabled,
} from "react-icons/md";
import { MdLocationPin } from "react-icons/md";
import { IoCheckmarkCircle } from "react-icons/io5";
import { FaUsersRectangle } from "react-icons/fa6";
import { FiUser } from "react-icons/fi";
import { LiaAddressCardSolid } from "react-icons/lia";
import { MdOutlinePermIdentity } from "react-icons/md";

// REACT ICONS
import { GoArrowLeft } from "react-icons/go";
import { CiImageOn } from "react-icons/ci";
import { IoIosClose, IoMdAdd, IoMdClose } from "react-icons/io";

// REACT TOASTIFY
import { Bounce, ToastContainer, toast } from "react-toastify";

// WATERMARK IMAGE
import watermarkImage from "../../assets/svgs/main-logo.svg";

// UTILS
import { IsBase64Image } from "../../utils/IsBase64";
import { Modal, message } from "antd";

function Profile() {
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_base_URL;
  const userId = localStorage.getItem("userId");

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    document.title = "Proptzo - User Profile";
  }, []);

  const [deleteAccountModalVisible, setDeleteAccountModalVisible] =
    useState(false);
  const handleDeleteAccount = () => {
    setDeleteAccountModalVisible(true);
  };
  const handleOk = async () => {
    // Make the request to the backend to delete the account
    try {
      const response = await fetch(`${baseURL}/api/auth/users/${userId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        // Account deletion requested successfully
        setDeleteAccountModalVisible(false);
        // Optionally, you can show a success message or redirect the user
      } else {
        // Handle error response
        console.error("Error deleting account:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const handleCancel = () => {
    setDeleteAccountModalVisible(false);
  };

  // State variables for user data
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    mobileNumber: "", // Changed from 'mobile' to 'mobileNumber'
  });

  // State variables for storing prev user data
  const [prevUserData, setPrevUserData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    whatsappNumber: "",
  });

  const [userProfileData, setUserProfileData] = useState(null);

  // State variable for editing mode

  const [editingName, setEditingName] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);
  const [editingMobile, setEditingMobile] = useState(false);
  const [editingWhatsappNumber, setEditingWhatsaapNumber] = useState(false);
  const [lastLoginDate, setLastLoginDate] = useState("");

  const [updationResp, setUpdationResp] = useState(null);

  // OWNER PROFILE
  const handleSaveOwnerProfileImage = async () => {
    try {
      const ownerProfilePhoto =
        userProfileData.userDetails.ownerDetails.ownerProfilePhoto;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentFirmName
      const formdata = new FormData();

      formdata.append("userId", userData._id);
      formdata.append("userType", userType);
      formdata.append("ownerProfilePhoto", ownerProfilePhoto);
      formdata.append("sendingOwnerProfileImage", true);
      await updateProfile(formdata);
      setProfilePhotoModalVisible(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };

  const handleOwnerProfileImageChange = (e) => {
    const file = e.target.files[0];
    setUserProfileData((prev) => ({
      ...prev,
      userDetails: {
        ...prev.userDetails,
        ownerDetails: {
          ...prev.userDetails.ownerDetails,
          ownerProfilePhoto: file,
        },
      },
    }));
    setUpdationResp(null);
  };

  // AGENT PROFILE

  const handleSaveAgentProfileImage = async () => {
    try {
      const agentProfilePhoto =
        userProfileData.userDetails.agentDetails.agentProfilePhoto;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentFirmName
      const formdata = new FormData();

      formdata.append("userId", userData._id);
      formdata.append("userType", userType);
      formdata.append("agentProfilePhoto", agentProfilePhoto);
      formdata.append("sendingAgentProfilePhoto", true);
      await updateProfile(formdata);
      setProfilePhotoModalVisible(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };

  const handleAgentProfileImageChange = (e) => {
    const file = e.target.files[0];
    setUserProfileData((prev) => ({
      ...prev,
      userDetails: {
        ...prev.userDetails,
        agentDetails: {
          ...prev.userDetails.agentDetails,
          agentProfilePhoto: file,
        },
      },
    }));
    setUpdationResp(null);
  };

  const [editingFirmName, setEditingFirmName] = useState(false);
  const handleEditAgentFirmName = () => {
    setEditingFirmName(true);
  };
  const handleSaveAgentFirmName = async () => {
    try {
      const agentFirmName =
        userProfileData.userDetails.agentDetails.agentFirmName;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentFirmName
      await updateProfile({
        userId: userData._id,
        userType,
        agentFirmName,
      });
      setEditingFirmName(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeAgentFirmName = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        agentDetails: {
          ...prev.userDetails.agentDetails,
          agentFirmName: value,
        },
      },
    }));
  };

  const [editingOfficeAddress, setEditingOfficeAddress] = useState(false);
  const handleEditAgentOfficeAddress = () => {
    setEditingOfficeAddress(true);
  };
  const handleSaveAgentOfficeAddress = async () => {
    try {
      const agentOfficeAddress =
        userProfileData.userDetails.agentDetails.agentOfficeAddress;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentOfficeAddress
      await updateProfile({
        userId: userData._id,
        userType,
        agentOfficeAddress,
      });
      setEditingOfficeAddress(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeAgentOfficeAddress = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        agentDetails: {
          ...prev.userDetails.agentDetails,
          agentOfficeAddress: value,
        },
      },
    }));
  };

  const [editingReraNo, setEditingReraNo] = useState(false);
  const handleEditAgentReraNo = () => {
    setEditingReraNo(true);
  };
  const handleSaveAgentReraNo = async () => {
    try {
      const agentReraNo = userProfileData.userDetails.agentDetails.agentReraNo;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentOfficeAddress
      await updateProfile({
        userId: userData._id,
        userType,
        agentReraNo,
      });
      setEditingReraNo(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeAgentReraNo = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        agentDetails: {
          ...prev.userDetails.agentDetails,
          agentReraNo: value,
        },
      },
    }));
  };

  // AGENT OFFICE DETAILS
  const [editingOfficeName, setEditingOfficeName] = useState(false);
  const handleEditAgentOfficeName = () => {
    setEditingOfficeName(true);
  };
  const handleSaveAgentOfficeName = async () => {
    try {
      const agentOfficeName =
        userProfileData.userDetails.agentDetails.agentOfficeName;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentOfficeName
      await updateProfile({
        userId: userData._id,
        userType,
        agentOfficeName,
      });
      setEditingOfficeName(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeAgentOfficeName = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        agentDetails: {
          ...prev.userDetails.agentDetails,
          agentOfficeName: value,
        },
      },
    }));
  };

  const [editingOfficeCity, setEditingOfficeCity] = useState(false);
  const handleEditAgentOfficeCity = () => {
    setEditingOfficeCity(true);
  };
  const handleSaveAgentOfficeCity = async () => {
    try {
      const agentOfficeCity =
        userProfileData.userDetails.agentDetails.agentOfficeCity;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentOfficeCity
      await updateProfile({
        userId: userData._id,
        userType,
        agentOfficeCity,
      });
      setEditingOfficeCity(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeAgentOfficeCity = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        agentDetails: {
          ...prev.userDetails.agentDetails,
          agentOfficeCity: value,
        },
      },
    }));
  };

  const [editingOfficeLocality, setEditingOfficeLocality] = useState(false);
  const handleEditAgentOfficeLocality = () => {
    setEditingOfficeLocality(true);
  };
  const handleSaveAgentOfficeLocality = async () => {
    try {
      const agentOfficeLocality =
        userProfileData.userDetails.agentDetails.agentOfficeLocality;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentOfficeLocality
      await updateProfile({
        userId: userData._id,
        userType,
        agentOfficeLocality,
      });
      setEditingOfficeLocality(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeAgentOfficeLocality = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        agentDetails: {
          ...prev.userDetails.agentDetails,
          agentOfficeLocality: value,
        },
      },
    }));
  };

  const [editingOfficeWebsiteLink, setEditingOfficeWebsiteLink] =
    useState(false);
  const handleEditAgentOfficeWebsiteLink = () => {
    setEditingOfficeWebsiteLink(true);
  };
  const handleSaveAgentOfficeWebsiteLink = async () => {
    try {
      const agentOfficeWebsite =
        userProfileData.userDetails.agentDetails.agentOfficeWebsite;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentOfficeLocality
      await updateProfile({
        userId: userData._id,
        userType,
        agentOfficeWebsite,
      });
      setEditingOfficeWebsiteLink(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeAgentOfficeWebsiteLink = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        agentDetails: {
          ...prev.userDetails.agentDetails,
          agentOfficeWebsite: value,
        },
      },
    }));
  };

  // ----------------------------------------------------------------------
  // BUILDER PROFILE

  const handleSaveBuilderProfileImage = async () => {
    try {
      const builderProfilePhoto =
        userProfileData.userDetails.builderDetails.builderProfilePhoto;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentFirmName
      const formdata = new FormData();

      formdata.append("userId", userData._id);
      formdata.append("userType", userType);
      formdata.append("builderProfilePhoto", builderProfilePhoto);
      await updateProfile(formdata);
      setProfilePhotoModalVisible(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };

  const handleBuilderProfileImageChange = (e) => {
    const file = e.target.files[0];
    setUserProfileData((prev) => ({
      ...prev,
      userDetails: {
        ...prev.userDetails,
        builderDetails: {
          ...prev.userDetails.builderDetails,
          builderProfilePhoto: file,
        },
      },
    }));
    setUpdationResp(null);
  };

  const handleEditBuilderFirmName = () => {
    setEditingFirmName(true);
  };
  const handleSaveBuilderFirmName = async () => {
    try {
      const builderFirmName =
        userProfileData.userDetails.builderDetails.builderFirmName;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing builderFirmName
      await updateProfile({
        userId: userData._id,
        userType,
        builderFirmName,
      });
      setEditingFirmName(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeBuilderFirmName = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        builderDetails: {
          ...prev.userDetails.builderDetails,
          builderFirmName: value,
        },
      },
    }));
  };

  const handleEditBuilderOfficeAddress = () => {
    setEditingOfficeAddress(true);
  };
  const handleSaveBuilderOfficeAddress = async () => {
    try {
      const builderOfficeAddress =
        userProfileData.userDetails.builderDetails.builderOfficeAddress;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing builderOfficeAddress
      await updateProfile({
        userId: userData._id,
        userType,
        builderOfficeAddress,
      });
      setEditingOfficeAddress(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeBuilderOfficeAddress = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        builderDetails: {
          ...prev.userDetails.builderDetails,
          builderOfficeAddress: value,
        },
      },
    }));
  };

  const handleEditBuilderReraNo = () => {
    setEditingReraNo(true);
  };
  const handleSaveBuilderReraNo = async () => {
    try {
      const builderReraNo =
        userProfileData.userDetails.builderDetails.builderReraNo;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentOfficeAddress
      await updateProfile({
        userId: userData._id,
        userType,
        builderReraNo,
      });
      setEditingReraNo(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeBuilderReraNo = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        builderDetails: {
          ...prev.userDetails.builderDetails,
          builderReraNo: value,
        },
      },
    }));
  };

  // BUILDER OFFICE DETAILS
  const handleEditBuilderOfficeName = () => {
    setEditingOfficeName(true);
  };
  const handleSaveBuilderOfficeName = async () => {
    try {
      const builderOfficeName =
        userProfileData.userDetails.builderDetails.builderOfficeName;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentOfficeName
      await updateProfile({
        userId: userData._id,
        userType,
        builderOfficeName,
      });
      setEditingOfficeName(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeBuilderOfficeName = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        builderDetails: {
          ...prev.userDetails.builderDetails,
          builderOfficeName: value,
        },
      },
    }));
  };

  const handleEditBuilderOfficeCity = () => {
    setEditingOfficeCity(true);
  };
  const handleSaveBuilderOfficeCity = async () => {
    try {
      const builderOfficeCity =
        userProfileData.userDetails.builderDetails.builderOfficeCity;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentOfficeCity
      await updateProfile({
        userId: userData._id,
        userType,
        builderOfficeCity,
      });
      setEditingOfficeCity(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeBuilderOfficeCity = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        builderDetails: {
          ...prev.userDetails.builderDetails,
          builderOfficeCity: value,
        },
      },
    }));
  };

  const handleEditBuilderOfficeLocality = () => {
    setEditingOfficeLocality(true);
  };
  const handleSaveBuilderOfficeLocality = async () => {
    try {
      const builderOfficeLocality =
        userProfileData.userDetails.builderDetails.builderOfficeLocality;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentOfficeLocality
      await updateProfile({
        userId: userData._id,
        userType,
        builderOfficeLocality,
      });
      setEditingOfficeLocality(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeBuilderOfficeLocality = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        builderDetails: {
          ...prev.userDetails.builderDetails,
          builderOfficeLocality: value,
        },
      },
    }));
  };

  const handleEditBuilderOfficeWebsiteLink = () => {
    setEditingOfficeWebsiteLink(true);
  };
  const handleSaveBuilderOfficeWebsiteLink = async () => {
    try {
      const builderOfficeWebsite =
        userProfileData.userDetails.builderDetails.builderOfficeWebsite;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentOfficeLocality
      await updateProfile({
        userId: userData._id,
        userType,
        builderOfficeWebsite,
      });
      setEditingOfficeWebsiteLink(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeBuilderOfficeWebsiteLink = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        builderDetails: {
          ...prev.userDetails.builderDetails,
          builderOfficeWebsite: value,
        },
      },
    }));
  };

  // -------------------------------------------------------------
  // SALESPERSON PROFILE

  const handleSaveSalespersonProfileImage = async () => {
    try {
      const salespersonProfileImage =
        userProfileData.userDetails.salespersonDetails.employeeImage;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentFirmName
      const formdata = new FormData();

      formdata.append("userId", userData._id);
      formdata.append("userType", userType);
      formdata.append("salespersonImage", salespersonProfileImage);
      await updateProfile(formdata);
      setProfilePhotoModalVisible(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };

  const handleSalespersonProfileImageChange = (e) => {
    const file = e.target.files[0];
    setUserProfileData((prev) => ({
      ...prev,
      userDetails: {
        ...prev.userDetails,
        salespersonDetails: {
          ...prev.userDetails.salespersonDetails,
          employeeImage: file,
        },
      },
    }));
    setUpdationResp(null);
  };

  const handleEditSalespersonCompanyFirmName = () => {
    setEditingFirmName(true);
  };
  const handleSaveSalespersonCompanyFirmName = async () => {
    try {
      const salespersonCompanyFirmName =
        userProfileData.userDetails.salespersonDetails
          .salespersonCompanyFirmName;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing salespersonFirmName
      await updateProfile({
        userId: userData._id,
        userType,
        salespersonCompanyFirmName,
      });
      setEditingFirmName(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeSalespersonCompanyFirmName = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        salespersonDetails: {
          ...prev.userDetails.salespersonDetails,
          salespersonCompanyFirmName: value,
        },
      },
    }));
  };

  const handleEditSalespersonCompanyOfficeAddress = () => {
    setEditingOfficeAddress(true);
  };
  const handleSaveSalespersonCompanyOfficeAddress = async () => {
    try {
      const salespersonCompanyOfficeAddress =
        userProfileData.userDetails.salespersonDetails
          .salespersonCompanyOfficeAddress;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing builderOfficeAddress
      await updateProfile({
        userId: userData._id,
        userType,
        salespersonCompanyOfficeAddress,
      });
      setEditingOfficeAddress(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeSalespersonCompanyOfficeAddress = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        salespersonDetails: {
          ...prev.userDetails.salespersonDetails,
          salespersonCompanyOfficeAddress: value,
        },
      },
    }));
  };

  const handleEditSalespersonCompanyReraNo = () => {
    setEditingReraNo(true);
  };
  const handleSaveSalespersonCompanyReraNo = async () => {
    try {
      const salespersonCompanyReraNo =
        userProfileData.userDetails.salespersonDetails.salespersonCompanyReraNo;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentOfficeAddress
      await updateProfile({
        userId: userData._id,
        userType,
        salespersonCompanyReraNo,
      });
      setEditingReraNo(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeSalespersonCompanyReraNo = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        salespersonDetails: {
          ...prev.userDetails.salespersonDetails,
          salespersonCompanyReraNo: value,
        },
      },
    }));
  };

  // // BUILDER OFFICE DETAILS
  const handleEditSalespersonCompanyOfficeName = () => {
    setEditingOfficeName(true);
  };
  const handleSaveSalespersonCompanyOfficeName = async () => {
    try {
      const salespersonCompanyOfficeName =
        userProfileData.userDetails.salespersonDetails
          .salespersonCompanyOfficeName;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentOfficeName
      await updateProfile({
        userId: userData._id,
        userType,
        salespersonCompanyOfficeName,
      });
      setEditingOfficeName(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeSalespersonCompanyOfficeName = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        salespersonDetails: {
          ...prev.userDetails.salespersonDetails,
          salespersonCompanyOfficeName: value,
        },
      },
    }));
  };

  const handleEditSalespersonCompanyOfficeCity = () => {
    setEditingOfficeCity(true);
  };
  const handleSaveSalespersonCompanyOfficeCity = async () => {
    try {
      const salespersonCompanyOfficeCity =
        userProfileData.userDetails.salespersonDetails
          .salespersonCompanyOfficeCity;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentOfficeCity
      await updateProfile({
        userId: userData._id,
        userType,
        salespersonCompanyOfficeCity,
      });
      setEditingOfficeCity(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeSalespersonCompanyOfficeCity = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        salespersonDetails: {
          ...prev.userDetails.salespersonDetails,
          salespersonCompanyOfficeCity: value,
        },
      },
    }));
  };

  const handleEditSalespersonCompanyOfficeLocality = () => {
    setEditingOfficeLocality(true);
  };
  const handleSaveSalespersonCompanyOfficeLocality = async () => {
    try {
      const salespersonCompanyOfficeLocality =
        userProfileData.userDetails.salespersonDetails
          .salespersonCompanyOfficeLocality;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentOfficeLocality
      await updateProfile({
        userId: userData._id,
        userType,
        salespersonCompanyOfficeLocality,
      });
      setEditingOfficeLocality(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeSalespersonCompanyOfficeLocality = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        salespersonDetails: {
          ...prev.userDetails.salespersonDetails,
          salespersonCompanyOfficeLocality: value,
        },
      },
    }));
  };

  const handleEditSalespersonCompanyOfficeWebsiteLink = () => {
    setEditingOfficeWebsiteLink(true);
  };
  const handleSaveSalespersonCompanyOfficeWebsiteLink = async () => {
    try {
      const salespersonCompanyOfficeWebsite =
        userProfileData.userDetails.salespersonDetails
          .salespersonCompanyOfficeWebsite;
      const userType = userProfileData.userDetails.userType;
      // Call the updateProfile function here passing agentOfficeLocality
      await updateProfile({
        userId: userData._id,
        userType,
        salespersonCompanyOfficeWebsite,
      });
      setEditingOfficeWebsiteLink(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeSalespersonCompanyOfficeWebsiteLink = (e) => {
    const { value } = e.target;
    setUserProfileData((prev) => ({
      userDetails: {
        ...prev.userDetails,
        salespersonDetails: {
          ...prev.userDetails.salespersonDetails,
          salespersonCompanyOfficeWebsite: value,
        },
      },
    }));
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const _userProfileData = await axios.get(
          `${baseURL}/api/profile/getUserDetailsById/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        setUserProfileData(_userProfileData.data);
        localStorage.setItem(
          "userType",
          _userProfileData.data.userDetails.userType
        );
        // // Format the last login date and time to Indian Standard Time (IST)
        // const lastLoggedInUTC = new Date(_userProfileData.data.lastLoggedIn);
        // const lastLoggedInIST = new Date(
        //   lastLoggedInUTC.getTime() +
        //     (330 + lastLoggedInUTC.getTimezoneOffset()) * 60000
        // ).toLocaleString("en-US", {
        //   timeZone: "Asia/Kolkata",
        //   year: "numeric",
        //   month: "long",
        //   day: "numeric",
        //   hour: "numeric",
        //   minute: "numeric",
        // });
        // setLastLoginDate(lastLoggedInIST);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, [baseURL, userId]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/auth/getUserData/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );

        setUserData(response.data.user);
        setPrevUserData(response.data.user);
        // Format the last login date and time to Indian Standard Time (IST)
        const lastLoggedInUTC = new Date(response.data.user.lastLoggedIn);
        const lastLoggedInIST = new Date(
          lastLoggedInUTC.getTime() +
            (330 + lastLoggedInUTC.getTimezoneOffset()) * 60000
        ).toLocaleString("en-US", {
          timeZone: "Asia/Kolkata",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });
        setLastLoginDate(lastLoggedInIST);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, [baseURL, userId]);
  const handleEditName = () => {
    setEditingName(true);
  };

  const handleSaveName = async () => {
    try {
      const userType = userProfileData.userDetails.userType;
      await updateProfile({
        userId: userData._id,
        name: userData.name,
        userType,
      });
      setEditingName(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeName = (e) => {
    const { value } = e.target;

    setUserData((prev) => ({
      ...prev,
      name: value,
    }));
  };
  const handleEditEmail = () => {
    setEditingEmail(true);
  };
  const handleSaveEmail = async () => {
    try {
      const userType = userProfileData.userDetails.userType;
      await updateProfile({
        userId: userData._id,
        email: userData.email,
        userType,
      });
      setEditingEmail(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeEmail = (e) => {
    const { value } = e.target;

    setUserData((prev) => ({
      ...prev,
      email: value,
    }));
  };
  const handleEditMobile = () => {
    setEditingMobile(true);
  };
  const handleSaveMobile = async () => {
    try {
      const userType = userProfileData.userDetails.userType;
      await updateProfile({
        userId: userData._id,
        mobileNumber: userData.mobileNumber,
        userType,
      }); // Changed from 'mobile' to 'mobileNumber'
      setEditingMobile(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeMobile = (e) => {
    const { value } = e.target;

    setUserData((prev) => ({
      ...prev,
      mobileNumber: value,
    }));
  };
  const handleEditWhatsappNumber = () => {
    setEditingWhatsaapNumber(true);
  };
  const handleSaveWhatsappNumber = async () => {
    try {
      const userType = userProfileData.userDetails.userType;
      await updateProfile({
        userId: userData._id,
        whatsappNumber: userData.whatsappNumber,
        userType,
      }); // Changed from 'mobile' to 'mobileNumber'
      setEditingWhatsaapNumber(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error
    }
  };
  const handleChangeWhatsappNumber = (e) => {
    const { value } = e.target;

    setUserData((prev) => ({
      ...prev,
      whatsappNumber: value,
    }));
  };

  const updateProfile = async (data) => {
    try {
      const response = await axios.patch(
        `${baseURL}/api/profile/updateProfile`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      setUpdationResp(response.data.message);
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error;
    }
  };

  // State variables to manage form sections visibility
  const [showUserProfile, setShowUserProfile] = useState(true);
  const [showBuilderCompanyDetails, setShowBuilderCompanyDetails] =
    useState(false);
  const [showBuilderOfficeDetails, setShowBuilderOfficeDetails] =
    useState(false);
  const [showBuilderEmployeeDetails, setShowBuilderEmployeeDetails] =
    useState(false);
  const [showSalespersonDocuments, setShowSalespersonDocuments] =
    useState(false);

  const toggleSections = (section) => {
    setShowUserProfile(section === "userProfile");
    setShowBuilderCompanyDetails(section === "builderCompanyDetails");
    setShowBuilderOfficeDetails(section === "builderOfficeDetails");
    setShowBuilderEmployeeDetails(section === "builderEmployeeDetails");
    setShowSalespersonDocuments(section === "salespersonDocuments");
  };

  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  // Axios interceptor for handling 409 errors...
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 409) {
          const message =
            error.response.data.message || "Conflict error occurred";
          setPopupMessage(message);
          setShowPopup(true);
          if (
            message === "Email is already in use by another user" &&
            prevUserData
          ) {
            setUserData((prev) => ({
              ...prev,
              email: prevUserData.email,
            }));
          }
          if (
            message === "Mobile number is already in use by another user" &&
            prevUserData
          ) {
            setUserData((prev) => ({
              ...prev,
              mobileNumber: prevUserData.mobileNumber,
            }));
          }
          if (
            message === "whatsapp number is already in use by another user" &&
            prevUserData
          ) {
            setUserData((prev) => ({
              ...prev,
              whatsappNumber: prevUserData.whatsappNumber,
            }));
          }
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [userData, prevUserData]);

  useEffect(() => {
    if (!showPopup) {
      // Reset the editingMobile state when the popup closes
      setEditingMobile(false);
      // Reset the editingEmail state when the popup closes
      setEditingEmail(false);
      // Reset the editingwhatsapp number state when the popup closes
      setEditingWhatsaapNumber(false);
    }
  }, [showPopup]);

  useEffect(() => {
    const userDataFromLocalStorage = localStorage.getItem("userData");
    if (userDataFromLocalStorage) {
      const parsedUserData = JSON.parse(userDataFromLocalStorage);
      // console.log("User data from local storage:", parsedUserData);
      setUserData(parsedUserData);
    } else {
      console.log("No user data found in local storage");
    }
  }, []);

  const [profileCompleted, setProfileCompleted] = useState(0);

  useEffect(() => {
    if (userProfileData && userData) {
      if (userProfileData.userDetails.userType === "Owner") {
        let ownerCompletion = 0;

        if (
          userProfileData.userDetails.ownerDetails.ownerProfilePhoto &&
          userProfileData.userDetails.ownerDetails.ownerProfilePhoto !== "NA"
        ) {
          ownerCompletion += 25;
        }

        if (userData.name && userData.name !== "NA") {
          ownerCompletion += 25;
        }

        if (userData.email && userData.email !== "NA") {
          ownerCompletion += 25;
        }

        if (userData.mobileNumber && userData.mobileNumber !== "NA") {
          ownerCompletion += 15;
        }

        if (userData.whatsappNumber && userData.whatsappNumber !== "NA") {
          ownerCompletion += 10;
        }

        // Cap the completion percentage at 100%
        ownerCompletion = Math.min(ownerCompletion, 100);

        // Round off the completion percentage to the nearest whole number
        ownerCompletion = Math.round(ownerCompletion);

        setProfileCompleted(ownerCompletion); // Set the final completion percentage
      }

      if (userProfileData.userDetails.userType === "Agent") {
        let agentCompletion = 0;

        const fields = [
          { key: "agentFirmName", weight: 14.28 },
          { key: "agentProfilePhoto", weight: 14.28 },
          { key: "agentFirmLogo", weight: 14.28 },
          { key: "agentReraNo", weight: 14.28 },
          { key: "agentReraNoType", weight: 14.28 },
          { key: "agentOfficeAddress", weight: 14.28 },
          { key: "agentDealsIn", weight: 14.28 },
          { key: "employeesList", weight: 14.28 },
          { key: "name", weight: 7.14 },
          { key: "email", weight: 7.14 },
          { key: "mobileNumber", weight: 4.29 },
          { key: "whatsappNumber", weight: 2.86 },
        ];

        fields.forEach((field) => {
          const value =
            userProfileData.userDetails.agentDetails[field.key] ||
            userData[field.key];
          if (value && value !== null && value !== "") {
            agentCompletion += field.weight;
          }
        });

        // Cap the completion percentage at 100%
        agentCompletion = Math.min(agentCompletion, 100);

        // Round off the completion percentage to the nearest whole number
        agentCompletion = Math.round(agentCompletion);

        setProfileCompleted(agentCompletion); // Set the final completion percentage
      }

      if (userProfileData.userDetails.userType === "Builder") {
        const fields = [
          { key: "builderFirmName", weight: 14.28 },
          { key: "builderProfilePhoto", weight: 14.28 },
          { key: "builderFirmLogo", weight: 14.28 },
          { key: "builderReraNo", weight: 14.28 },
          { key: "builderReraNoType", weight: 14.28 },
          { key: "builderOfficeAddress", weight: 14.28 },
          { key: "employeesList", weight: 14.28 },
          { key: "name", weight: 7.14 },
          { key: "email", weight: 7.14 },
          { key: "mobileNumber", weight: 4.29 },
          { key: "whatsappNumber", weight: 2.86 },
        ];

        let builderCompletion = 0;

        fields.forEach((field) => {
          if (
            (userProfileData.userDetails.builderDetails[field.key] &&
              userProfileData.userDetails.builderDetails[field.key] !== "NA") ||
            (userData[field.key] && userData[field.key] !== "NA")
          ) {
            builderCompletion += field.weight;
          }
        });

        // Cap the completion percentage at 100%
        builderCompletion = Math.min(builderCompletion, 100);

        // Round off the completion percentage to the nearest whole number
        builderCompletion = Math.round(builderCompletion);

        setProfileCompleted(builderCompletion); // Set the final completion percentage
      }

      if (userProfileData.userDetails.userType === "Salesperson") {
        let salespersonCompletion = 0;

        if (
          userProfileData.userDetails.salespersonDetails.employeeImage &&
          userProfileData.userDetails.salespersonDetails.employeeImage !== "NA"
        ) {
          salespersonCompletion += 25;
        }

        if (
          userProfileData.userDetails.salespersonDetails.employeeName &&
          userProfileData.userDetails.salespersonDetails.employeeName !== "NA"
        ) {
          salespersonCompletion += 25;
        }

        if (
          userProfileData.userDetails.salespersonDetails.employeeID &&
          userProfileData.userDetails.salespersonDetails.employeeID !== "NA"
        ) {
          salespersonCompletion += 25;
        }

        if (userData.name && userData.name !== "NA") {
          salespersonCompletion += 25;
        }

        if (userData.email && userData.email !== "NA") {
          salespersonCompletion += 25;
        }

        if (userData.mobileNumber && userData.mobileNumber !== "NA") {
          salespersonCompletion += 15;
        }

        if (userData.whatsappNumber && userData.whatsappNumber !== "NA") {
          salespersonCompletion += 10;
        }

        // Cap the completion percentage at 100%
        salespersonCompletion = Math.min(salespersonCompletion, 100);

        // Round off the completion percentage to the nearest whole number
        salespersonCompletion = Math.round(salespersonCompletion);

        setProfileCompleted(salespersonCompletion); // Set the final completion percentage
      }
    }
  }, [userProfileData, userData]);

  // PROFILE PHOTO MODAL
  const [isProfilePhotoModalVisible, setProfilePhotoModalVisible] =
    useState(false);
  const openProfilePhotoModal = () => setProfilePhotoModalVisible(true);
  const closeProfilePhotoModal = () => setProfilePhotoModalVisible(false);

  // EMPLOYEE DATA UPDATE MODAL
  const [isEmployeeDataUpdateModalVisible, setEmployeeDataUpdateModalVisible] =
    useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const openEmployeeDataUpdateModal = (employee) => {
    setSelectedEmployee(employee);
    setEmployeeDataUpdateModalVisible(true);
  };

  const closeEmployeeDataUpdateModal = () => {
    setEmployeeDataUpdateModalVisible(false);
    setSelectedEmployee(null);
  };

  const [agentEmployeeData, setAgentEmployeeData] = useState({
    employeeUserId: "",
    employeeName: "",
    employeeEmail: "",
    employeeMobileNo: "",
    employeeWhatsappMobile: "",
    employeeImage: null,

    selectedEmployeeIdType: "select id type ...",

    employeeID: "",
    employeeIdCardImage: null,

    employeeAadhaarcardNo: "",
    employeeAadhaarcardFrontImage: null,
    employeeAadhaarcardBackImage: null,

    employeeDrivinglicenseNo: "",
    employeeDrivinglicenseFrontImage: null,
    employeeDrivinglicenseBackImage: null,

    employeePancardNo: "",
    employeePancardImage: null,

    employeePassportNo: "",
    employeePassportImage: null,

    employeeVoterIdNo: "",
    employeeVoterIdFrontImage: null,
    employeeVoterIdBackImage: null,
  });

  useEffect(() => {
    if (agentEmployeeData.selectedEmployeeIdType === "Employee id") {
      setAgentEmployeeData((prev) => ({
        ...prev,
        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeePassportNo: "",
        employeePassportImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (agentEmployeeData.selectedEmployeeIdType === "Aadhaar card") {
      setAgentEmployeeData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeePassportNo: "",
        employeePassportImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (agentEmployeeData.selectedEmployeeIdType === "Driving license") {
      setAgentEmployeeData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeePassportNo: "",
        employeePassportImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (agentEmployeeData.selectedEmployeeIdType === "Pan card") {
      setAgentEmployeeData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePassportNo: "",
        employeePassportImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (agentEmployeeData.selectedEmployeeIdType === "Passport") {
      setAgentEmployeeData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (agentEmployeeData.selectedEmployeeIdType === "Voter id") {
      setAgentEmployeeData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeePassportNo: "",
        employeePassportImage: null,
      }));
    }
  }, [agentEmployeeData.selectedEmployeeIdType]);

  const [builderEmployeeData, setBuilderEmployeeData] = useState({
    employeeUserId: "",
    employeeName: "",
    employeeEmail: "",
    employeeMobileNo: "",
    employeeImage: null,
    selectedEmployeeIdType: "Employee id",
    employeeID: "",
    employeeIdCardImage: null,
  });

  const [salespersonData, setSalespersonData] = useState({
    employeeUserId: "",
    employeeName: "",
    employeeEmail: "",
    employeeMobileNo: "",
    employeeWhatsappMobile: "",
    employeeImage: null,

    selectedEmployeeIdType: "select id type ...",

    employeeID: "",
    employeeIdCardImage: null,

    employeementLetter: null,

    employeeAadhaarcardNo: "",
    employeeAadhaarcardFrontImage: null,
    employeeAadhaarcardBackImage: null,

    employeeDrivinglicenseNo: "",
    employeeDrivinglicenseFrontImage: null,
    employeeDrivinglicenseBackImage: null,

    employeePancardNo: "",
    employeePancardImage: null,

    employeePassportNo: "",
    employeePassportImage: null,

    employeeVoterIdNo: "",
    employeeVoterIdFrontImage: null,
    employeeVoterIdBackImage: null,
  });

  useEffect(() => {
    if (salespersonData.selectedEmployeeIdType === "Employee id") {
      setSalespersonData((prev) => ({
        ...prev,
        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeePassportNo: "",
        employeePassportImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (salespersonData.selectedEmployeeIdType === "Aadhaar card") {
      setSalespersonData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeePassportNo: "",
        employeePassportImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (salespersonData.selectedEmployeeIdType === "Driving license") {
      setSalespersonData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeePassportNo: "",
        employeePassportImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (salespersonData.selectedEmployeeIdType === "Pan card") {
      setSalespersonData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePassportNo: "",
        employeePassportImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (salespersonData.selectedEmployeeIdType === "Passport") {
      setSalespersonData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (salespersonData.selectedEmployeeIdType === "Voter id") {
      setSalespersonData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeePassportNo: "",
        employeePassportImage: null,
      }));
    }
  }, [salespersonData.selectedEmployeeIdType]);

  const handleBuilderEmployeeData = (event) => {
    const { name, value } = event.target;
    setBuilderEmployeeData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setIsEmployeeDataChanged((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const handleSalespersonData = (event) => {
    const { name, value } = event.target;
    setSalespersonData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setIsEmployeeDataChanged((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const handleBuilderEmployeeImage = (event) => {
    const file = event.target.files[0];
    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        setBuilderEmployeeData((prev) => ({
          ...prev,
          employeeImage: file,
        }));

        setIsEmployeeImageChanged((prev) => ({
          ...prev,
          profileImage: true,
        }));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                setBuilderEmployeeData((prev) => ({
                  ...prev,
                  employeeImage: newFile,
                }));
              }, file.type);
            };
          };
        };

        setIsEmployeeImageChanged((prev) => ({
          ...prev,
          profileImage: true,
        }));
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };
  const handleSalespersonEmployeementLetter = (event) => {
    const file = event.target.files[0];
    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        setSalespersonData((prev) => ({
          ...prev,
          employeementLetter: file,
        }));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                setSalespersonData((prev) => ({
                  ...prev,
                  employeementLetter: newFile,
                }));
              }, file.type);
            };
          };
        };

        // setIsEmployeeImageChanged((prev) => ({
        //   ...prev,
        //   profileImage: true,
        // }));
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employeement letter exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeBuilderEmployeeImage = (event) => {
    setBuilderEmployeeData((prev) => ({
      ...prev,
      employeeImage: null,
    }));
  };
  const removeSalespersonEmployeementLetter = (event) => {
    setSalespersonData((prev) => ({
      ...prev,
      employeementLetter: null,
    }));
  };

  const handleBuilderEmployeeIdCardImage = (event) => {
    const file = event.target.files[0];
    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        setBuilderEmployeeData((prev) => ({
          ...prev,
          employeeIdCardImage: file,
        }));

        setIsEmployeeImageChanged((prev) => ({
          ...prev,
          idCardImage: true,
        }));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                setBuilderEmployeeData((prev) => ({
                  ...prev,
                  employeeIdCardImage: newFile,
                }));
              }, file.type);
            };
          };
        };
        setIsEmployeeImageChanged((prev) => ({
          ...prev,
          idCardImage: true,
        }));
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee ID card image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };
  const handleSalespersonEmployeeIdCardImage = (event) => {
    const file = event.target.files[0];
    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        setSalespersonData((prev) => ({
          ...prev,
          employeeIdCardImage: file,
        }));

        // setIsEmployeeImageChanged((prev) => ({
        //   ...prev,
        //   idCardImage: true,
        // }));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                setSalespersonData((prev) => ({
                  ...prev,
                  employeeIdCardImage: newFile,
                }));
              }, file.type);
            };
          };
        };
        // setIsEmployeeImageChanged((prev) => ({
        //   ...prev,
        //   idCardImage: true,
        // }));
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee ID card image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeBuilderEmployeeIdCardImage = (event) => {
    setBuilderEmployeeData((prev) => ({
      ...prev,
      employeeIdCardImage: null,
    }));
  };

  const removeSalespersonIdCardImage = (event) => {
    setSalespersonData((prev) => ({
      ...prev,
      employeeIdCardImage: null,
    }));
  };

  const [isEmployeeImageChanged, setIsEmployeeImageChanged] = useState({
    profileImage: false,
    idCardImage: false,
  });

  const [isEmployeeDataChanged, setIsEmployeeDataChanged] = useState({
    employeeName: false,
    employeeEmail: false,
    employeeMobileNo: false,
    employeeWhatsappMobile: false,
    selectedEmployeeIdType: false,
    employeeID: false,
    employeeAadhaarcardNo: false,
    employeeDrivinglicenseNo: false,
    employeePancardNo: false,
    employeePassportNo: false,
    employeeVoterIdNo: false,
  });

  useEffect(() => {
    if (
      selectedEmployee &&
      userProfileData?.userDetails?.userType === "Agent"
    ) {
      setAgentEmployeeData((prev) => ({
        ...prev,
        employeeUserId: selectedEmployee.userId,
        employeeEmail:
          isEmployeeDataChanged.employeeEmail === false
            ? selectedEmployee.salespersonDetails.employeeEmail
            : prev.employeeEmail,
        employeeMobileNo:
          isEmployeeDataChanged.employeeMobileNo === false
            ? selectedEmployee.salespersonDetails.employeeMobileNo
            : prev.employeeMobileNo,
        employeeName:
          isEmployeeDataChanged.employeeName === false
            ? selectedEmployee.salespersonDetails.employeeName
            : prev.employeeName,
        selectedEmployeeIdType:
          isEmployeeDataChanged.selectedEmployeeIdType === false
            ? selectedEmployee.salespersonDetails.selectedEmployeeIdType
            : prev.selectedEmployeeIdType,
        employeeID:
          selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
            "Employee id" && isEmployeeDataChanged.employeeID === false
            ? selectedEmployee.salespersonDetails.employeeID
            : prev.employeeID,

        employeeAadhaarcardNo:
          selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
            "Aadhaar card" &&
          isEmployeeDataChanged.employeeAadhaarcardNo === false
            ? selectedEmployee.salespersonDetails.employeeAadhaarcardNo
            : prev.employeeAadhaarcardNo,
        employeeAadhaarcardFrontImage:
          selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
          "Aadhaar card"
            ? selectedEmployee.salespersonDetails.employeeAadhaarcardFrontImage
            : null,
        employeeAadhaarcardBackImage:
          selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
          "Aadhaar card"
            ? selectedEmployee.salespersonDetails.employeeAadhaarcardBackImage
            : null,

        employeeDrivinglicenseNo:
          selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
            "Driving license" &&
          isEmployeeDataChanged.employeeDrivinglicenseNo === false
            ? selectedEmployee.salespersonDetails.employeeDrivinglicenseNo
            : prev.employeeDrivinglicenseNo,
        employeeDrivinglicenseFrontImage:
          selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
          "Driving license"
            ? selectedEmployee.salespersonDetails
                .employeeDrivinglicenseFrontImage
            : null,
        employeeDrivinglicenseBackImage:
          selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
          "Driving license"
            ? selectedEmployee.salespersonDetails
                .employeeDrivinglicenseBackImage
            : null,

        employeePancardNo:
          selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
            "Pan card" && isEmployeeDataChanged.employeePancardNo === false
            ? selectedEmployee.salespersonDetails.employeePancardNo
            : prev.employeePancardNo,
        employeePancardImage:
          selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
          "Pan card"
            ? selectedEmployee.salespersonDetails.employeePancardImage
            : null,

        employeePassportNo:
          selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
            "Passport" && isEmployeeDataChanged.employeePassportNo === false
            ? selectedEmployee.salespersonDetails.employeePassportNo
            : prev.employeePassportNo,
        employeePassportImage:
          selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
          "Passport"
            ? selectedEmployee.salespersonDetails.employeePassportImage
            : null,

        employeeVoterIdNo:
          selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
            "Voter id" && isEmployeeDataChanged.employeeVoterIdNo === false
            ? selectedEmployee.salespersonDetails.employeeVoterIdNo
            : prev.employeeVoterIdNo,
        employeeVoterIdFrontImage:
          selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
          "Voter id"
            ? selectedEmployee.salespersonDetails.employeeVoterIdFrontImage
            : null,
        employeeVoterIdBackImage:
          selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
          "Voter id"
            ? selectedEmployee.salespersonDetails.employeeVoterIdBackImage
            : null,
      }));
      if (isEmployeeImageChanged.profileImage === false) {
        setAgentEmployeeData((prev) => ({
          ...prev,
          employeeImage: selectedEmployee.salespersonDetails.employeeImage,
        }));
      }
      if (
        isEmployeeImageChanged.idCardImage === false &&
        selectedEmployee?.salespersonDetails?.selectedEmployeeIdType ===
          "Employee id"
      ) {
        setAgentEmployeeData((prev) => ({
          ...prev,
          employeeIdCardImage:
            selectedEmployee.salespersonDetails.employeeIdCardImage,
        }));
      }
    }
    if (
      selectedEmployee &&
      userProfileData?.userDetails?.userType === "Builder"
    ) {
      setBuilderEmployeeData((prev) => ({
        ...prev,
        employeeUserId: selectedEmployee.userId,
        employeeEmail:
          isEmployeeDataChanged.employeeEmail === false
            ? selectedEmployee.salespersonDetails.employeeEmail
            : prev.employeeEmail,
        employeeMobileNo:
          isEmployeeDataChanged.employeeMobileNo === false
            ? selectedEmployee.salespersonDetails.employeeMobileNo
            : prev.employeeMobileNo,
        employeeName:
          isEmployeeDataChanged.employeeName === false
            ? selectedEmployee.salespersonDetails.employeeName
            : prev.employeeName,
        employeeID:
          selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
            "Employee id" && isEmployeeDataChanged.employeeID === false
            ? selectedEmployee.salespersonDetails.employeeID
            : prev.employeeID,
      }));
      if (isEmployeeImageChanged.profileImage === false) {
        setBuilderEmployeeData((prev) => ({
          ...prev,
          employeeImage: selectedEmployee.salespersonDetails.employeeImage,
        }));
      }
      if (
        isEmployeeImageChanged.idCardImage === false &&
        selectedEmployee?.salespersonDetails?.selectedEmployeeIdType ===
          "Employee id"
      ) {
        setBuilderEmployeeData((prev) => ({
          ...prev,
          employeeIdCardImage:
            selectedEmployee.salespersonDetails.employeeIdCardImage,
        }));
      }
    }
  }, [selectedEmployee]);

  useEffect(() => {
    if (agentEmployeeData.selectedEmployeeIdType === "Employee id") {
      setAgentEmployeeData((prev) => ({
        ...prev,
        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeePassportNo: "",
        employeePassportImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (agentEmployeeData.selectedEmployeeIdType === "Aadhaar card") {
      setAgentEmployeeData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeePassportNo: "",
        employeePassportImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (agentEmployeeData.selectedEmployeeIdType === "Driving license") {
      setAgentEmployeeData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeePassportNo: "",
        employeePassportImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (agentEmployeeData.selectedEmployeeIdType === "Pan card") {
      setAgentEmployeeData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePassportNo: "",
        employeePassportImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (agentEmployeeData.selectedEmployeeIdType === "Passport") {
      setAgentEmployeeData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (agentEmployeeData.selectedEmployeeIdType === "Voter id") {
      setAgentEmployeeData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeePassportNo: "",
        employeePassportImage: null,
      }));
    }
  }, [agentEmployeeData.selectedEmployeeIdType]);

  const handleAgentEmployeeData = (event) => {
    const { name, value } = event.target;
    setAgentEmployeeData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setIsEmployeeDataChanged((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const handleAgentEmployeeImage = (event) => {
    const file = event.target.files[0];
    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        setAgentEmployeeData((prev) => ({
          ...prev,
          employeeImage: file,
        }));

        setIsEmployeeImageChanged((prev) => ({
          ...prev,
          profileImage: true,
        }));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                setAgentEmployeeData((prev) => ({
                  ...prev,
                  employeeImage: newFile,
                }));
              }, file.type);
            };
          };
        };

        setIsEmployeeImageChanged((prev) => ({
          ...prev,
          profileImage: true,
        }));
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeAgentEmployeeImage = (event) => {
    setAgentEmployeeData((prev) => ({
      ...prev,
      employeeImage: null,
    }));
  };

  const handleAgentEmployeeIdCardImage = (event) => {
    const file = event.target.files[0];
    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        setAgentEmployeeData((prev) => ({
          ...prev,
          employeeIdCardImage: file,
        }));

        setIsEmployeeImageChanged((prev) => ({
          ...prev,
          idCardImage: true,
        }));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                setAgentEmployeeData((prev) => ({
                  ...prev,
                  employeeIdCardImage: newFile,
                }));
              }, file.type);
            };
          };
        };
        setIsEmployeeImageChanged((prev) => ({
          ...prev,
          idCardImage: true,
        }));
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee ID card image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeAgentEmployeeIdCardImage = (event) => {
    setAgentEmployeeData((prev) => ({
      ...prev,
      employeeIdCardImage: null,
    }));
  };

  const handleAgentEmployeeAadhaarcardImage = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;

    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        if (name === "employeeAadhaarcardFrontImage") {
          setAgentEmployeeData((prev) => ({
            ...prev,
            employeeAadhaarcardFrontImage: file,
          }));
        } else if (name === "employeeAadhaarcardBackImage") {
          setAgentEmployeeData((prev) => ({
            ...prev,
            employeeAadhaarcardBackImage: file,
          }));
        }
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                if (name === "employeeAadhaarcardFrontImage") {
                  setAgentEmployeeData((prev) => ({
                    ...prev,
                    employeeAadhaarcardFrontImage: newFile,
                  }));
                } else if (name === "employeeAadhaarcardBackImage") {
                  setAgentEmployeeData((prev) => ({
                    ...prev,
                    employeeAadhaarcardBackImage: newFile,
                  }));
                }
              }, file.type);
            };
          };
        };
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee Aadhaar card image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeAgentEmployeeAadhaarcardImage = (event) => {
    setAgentEmployeeData((prev) => ({
      ...prev,
      employeeAadhaarcardFrontImage: null,
      employeeAadhaarcardBackImage: null,
    }));
  };

  const handleAgentEmployeeDrivingLicense = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;

    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        if (name === "employeeDrivinglicenseFrontImage") {
          setAgentEmployeeData((prev) => ({
            ...prev,
            employeeDrivinglicenseFrontImage: file,
          }));
        } else if (name === "employeeDrivinglicenseBackImage") {
          setAgentEmployeeData((prev) => ({
            ...prev,
            employeeDrivinglicenseBackImage: file,
          }));
        }
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                if (name === "employeeDrivinglicenseFrontImage") {
                  setAgentEmployeeData((prev) => ({
                    ...prev,
                    employeeDrivinglicenseFrontImage: newFile,
                  }));
                } else if (name === "employeeDrivinglicenseBackImage") {
                  setAgentEmployeeData((prev) => ({
                    ...prev,
                    employeeDrivinglicenseBackImage: newFile,
                  }));
                }
              }, file.type);
            };
          };
        };
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee driving license image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeAgentEmployeeDrivingLicenseImage = (event) => {
    setAgentEmployeeData((prev) => ({
      ...prev,
      employeeDrivinglicenseFrontImage: null,
      employeeDrivinglicenseBackImage: null,
    }));
  };

  const handleAgentEmployeePanCardImage = (event) => {
    const file = event.target.files[0];

    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        setAgentEmployeeData((prev) => ({
          ...prev,
          employeePancardImage: file,
        }));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                setAgentEmployeeData((prev) => ({
                  ...prev,
                  employeePancardImage: newFile,
                }));
              }, file.type);
            };
          };
        };
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee pan card image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeAgentEmployeePanCardImage = (event) => {
    setAgentEmployeeData((prev) => ({
      ...prev,
      employeePancardImage: null,
    }));
  };

  const handleAgentEmployeePassportImage = (event) => {
    const file = event.target.files[0];

    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        setAgentEmployeeData((prev) => ({
          ...prev,
          employeePassportImage: file,
        }));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                setAgentEmployeeData((prev) => ({
                  ...prev,
                  employeePassportImage: newFile,
                }));
              }, file.type);
            };
          };
        };
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee passport image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeAgentEmployeePaasportImage = (event) => {
    setAgentEmployeeData((prev) => ({
      ...prev,
      employeePassportImage: null,
    }));
  };

  const handleAgentEmployeeVoterIdImage = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;

    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        if (name === "employeeVoterIdFrontImage") {
          setAgentEmployeeData((prev) => ({
            ...prev,
            employeeVoterIdFrontImage: file,
          }));
        } else if (name === "employeeVoterIdBackImage") {
          setAgentEmployeeData((prev) => ({
            ...prev,
            employeeVoterIdBackImage: file,
          }));
        }
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                if (name === "employeeVoterIdFrontImage") {
                  setAgentEmployeeData((prev) => ({
                    ...prev,
                    employeeVoterIdFrontImage: newFile,
                  }));
                } else if (name === "employeeVoterIdBackImage") {
                  setAgentEmployeeData((prev) => ({
                    ...prev,
                    employeeVoterIdBackImage: newFile,
                  }));
                }
              }, file.type);
            };
          };
        };
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee VoterId image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeAgentEmployeeVoterIdImage = (event) => {
    setAgentEmployeeData((prev) => ({
      ...prev,
      employeeVoterIdFrontImage: null,
      employeeVoterIdBackImage: null,
    }));
  };

  const handleSalespersonAadhaarcardImage = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;

    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        if (name === "employeeAadhaarcardFrontImage") {
          setSalespersonData((prev) => ({
            ...prev,
            employeeAadhaarcardFrontImage: file,
          }));
        } else if (name === "employeeAadhaarcardBackImage") {
          setSalespersonData((prev) => ({
            ...prev,
            employeeAadhaarcardBackImage: file,
          }));
        }
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                if (name === "employeeAadhaarcardFrontImage") {
                  setSalespersonData((prev) => ({
                    ...prev,
                    employeeAadhaarcardFrontImage: newFile,
                  }));
                } else if (name === "employeeAadhaarcardBackImage") {
                  setSalespersonData((prev) => ({
                    ...prev,
                    employeeAadhaarcardBackImage: newFile,
                  }));
                }
              }, file.type);
            };
          };
        };
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee Aadhaar card image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeSalespersonAadhaarcardImage = (event) => {
    setSalespersonData((prev) => ({
      ...prev,
      employeeAadhaarcardFrontImage: null,
      employeeAadhaarcardBackImage: null,
    }));
  };

  const handleSalespersonDrivingLicense = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;

    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        if (name === "employeeDrivinglicenseFrontImage") {
          setSalespersonData((prev) => ({
            ...prev,
            employeeDrivinglicenseFrontImage: file,
          }));
        } else if (name === "employeeDrivinglicenseBackImage") {
          setSalespersonData((prev) => ({
            ...prev,
            employeeDrivinglicenseBackImage: file,
          }));
        }
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                if (name === "employeeDrivinglicenseFrontImage") {
                  setSalespersonData((prev) => ({
                    ...prev,
                    employeeDrivinglicenseFrontImage: newFile,
                  }));
                } else if (name === "employeeDrivinglicenseBackImage") {
                  setSalespersonData((prev) => ({
                    ...prev,
                    employeeDrivinglicenseBackImage: newFile,
                  }));
                }
              }, file.type);
            };
          };
        };
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee driving license image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeSalespersonDrivingLicenseImage = (event) => {
    setSalespersonData((prev) => ({
      ...prev,
      employeeDrivinglicenseFrontImage: null,
      employeeDrivinglicenseBackImage: null,
    }));
  };

  const handleSalespersonPanCardImage = (event) => {
    const file = event.target.files[0];

    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        setSalespersonData((prev) => ({
          ...prev,
          employeePancardImage: file,
        }));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                setSalespersonData((prev) => ({
                  ...prev,
                  employeePancardImage: newFile,
                }));
              }, file.type);
            };
          };
        };
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee pan card image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeSalespersonPanCardImage = (event) => {
    setSalespersonData((prev) => ({
      ...prev,
      employeePancardImage: null,
    }));
  };

  const handleSalespersonPassportImage = (event) => {
    const file = event.target.files[0];

    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        setSalespersonData((prev) => ({
          ...prev,
          employeePassportImage: file,
        }));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                setSalespersonData((prev) => ({
                  ...prev,
                  employeePassportImage: newFile,
                }));
              }, file.type);
            };
          };
        };
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee passport image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeSalespersonPaasportImage = (event) => {
    setSalespersonData((prev) => ({
      ...prev,
      employeePassportImage: null,
    }));
  };

  const handleSalespersonVoterIdImage = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;

    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        if (name === "employeeVoterIdFrontImage") {
          setSalespersonData((prev) => ({
            ...prev,
            employeeVoterIdFrontImage: file,
          }));
        } else if (name === "employeeVoterIdBackImage") {
          setSalespersonData((prev) => ({
            ...prev,
            employeeVoterIdBackImage: file,
          }));
        }
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                if (name === "employeeVoterIdFrontImage") {
                  setSalespersonData((prev) => ({
                    ...prev,
                    employeeVoterIdFrontImage: newFile,
                  }));
                } else if (name === "employeeVoterIdBackImage") {
                  setSalespersonData((prev) => ({
                    ...prev,
                    employeeVoterIdBackImage: newFile,
                  }));
                }
              }, file.type);
            };
          };
        };
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee VoterId image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeSalespersonVoterIdImage = (event) => {
    setSalespersonData((prev) => ({
      ...prev,
      employeeVoterIdFrontImage: null,
      employeeVoterIdBackImage: null,
    }));
  };

  // FIELD NAMES TO USE FOR IMAGE STORING IN MULTER
  const [profileImageFieldName, setProfileImageFieldName] = useState(null);
  const [idCardImageFieldName, setIdCardImageFieldName] = useState(null);
  const [aadhaarcardFrontImageFieldName, setAadhaarcardFrontImageFieldName] =
    useState(null);
  const [aadhaarcardBackImageFieldName, setAadhaarcardBackImageFieldName] =
    useState(null);
  const [
    drivinglicenseFrontImageFieldName,
    setDrivinglicenseFrontImageFieldName,
  ] = useState(null);
  const [
    drivinglicenseBackImageFieldName,
    setDrivinglicenseBackImageFieldName,
  ] = useState(null);
  const [pancardImageFieldName, setPancardImageFieldName] = useState(null);
  const [passportImageFieldName, setPassportImageFieldName] = useState(null);
  const [voterIdFrontImageFieldName, setVoterIdFrontImageFieldName] =
    useState(null);
  const [voterIdBackImageFieldName, setVoterIdBackImageFieldName] =
    useState(null);
  const [isImagesFieldNameFetched, setIsImagesFieldNameFetched] =
    useState(false);
  useEffect(() => {
    if (!isImagesFieldNameFetched) {
      const employeesListWithoutBase64 =
        userProfileData?.employeesListWithoutBase64;

      let existingProfileImagePath = employeesListWithoutBase64?.filter(
        (employee) => employee.userId === selectedEmployee?.userId
      )[0]?.salespersonDetails?.employeeImage;

      let existingIdCardImagePath = employeesListWithoutBase64?.filter(
        (employee) => employee.userId === selectedEmployee?.userId
      )[0]?.salespersonDetails?.employeeIdCardImage;

      // AADHAAR CARD

      let existingAadhaarcardFrontImagePath =
        employeesListWithoutBase64?.filter(
          (employee) => employee.userId === selectedEmployee?.userId
        )[0]?.salespersonDetails?.employeeAadhaarcardFrontImage;

      let existingAadhaarcardBackImagePath = employeesListWithoutBase64?.filter(
        (employee) => employee.userId === selectedEmployee?.userId
      )[0]?.salespersonDetails?.employeeAadhaarcardBackImage;

      // DRIVING LICENSE

      let existingDrivinglicenseFrontImagePath =
        employeesListWithoutBase64?.filter(
          (employee) => employee.userId === selectedEmployee?.userId
        )[0]?.salespersonDetails?.employeeDrivinglicenseFrontImage;

      let existingDrivinglicenseBackImagePath =
        employeesListWithoutBase64?.filter(
          (employee) => employee.userId === selectedEmployee?.userId
        )[0]?.salespersonDetails?.employeeDrivinglicenseBackImage;

      // PAN CARD

      let existingPancardImagePath = employeesListWithoutBase64?.filter(
        (employee) => employee.userId === selectedEmployee?.userId
      )[0]?.salespersonDetails?.employeePancardImage;

      // PASSPORT

      let existingPassportImagePath = employeesListWithoutBase64?.filter(
        (employee) => employee.userId === selectedEmployee?.userId
      )[0]?.salespersonDetails?.employeePassportImage;

      // PASSPORT

      let existingVoterIdFrontImagePath = employeesListWithoutBase64?.filter(
        (employee) => employee.userId === selectedEmployee?.userId
      )[0]?.salespersonDetails?.employeeVoterIdFrontImage;

      let existingVoterIdBackImagePath = employeesListWithoutBase64?.filter(
        (employee) => employee.userId === selectedEmployee?.userId
      )[0]?.salespersonDetails?.employeeVoterIdBackImage;

      let missingProfileImagePaths;
      let missingIdCardImagePaths;

      // AADHAAR CARD IMAGE PATHS
      let missingAadhaarcardFrontImagePaths;
      let missingAadhaarcardBackImagePaths;

      // DRIVING LICENSE IMAGE PATHS
      let missingDrivinglicenseFrontImagePaths;
      let missingDrivinglicenseBackImagePaths;

      // PANCARD IMAGE PATHS
      let missingPancardImagePaths;

      // PASSPORT IMAGE PATHS
      let missingPassportImagePaths;

      // VOTER ID IMAGE PATHS
      let missingVoterIdFrontImagePaths;
      let missingVoterIdBackImagePaths;

      if (
        !existingProfileImagePath ||
        !existingIdCardImagePath ||
        !existingAadhaarcardFrontImagePath ||
        !existingAadhaarcardBackImagePath ||
        !existingDrivinglicenseFrontImagePath ||
        !existingDrivinglicenseBackImagePath ||
        !existingPancardImagePath ||
        !existingPassportImagePath ||
        !existingVoterIdFrontImagePath ||
        !existingVoterIdBackImagePath
      ) {
        const Employees = userProfileData?.employeesList;

        const profileImagePaths =
          Employees &&
          Employees.map(
            (employee) => employee.salespersonDetails.employeeImage
          ).filter((imagePath) => imagePath !== null);

        const IdCardImagePaths =
          Employees &&
          Employees.map(
            (employee) => employee.salespersonDetails.employeeIdCardImage
          ).filter((idCardImagePath) => idCardImagePath !== null);

        const aadharcardFrontImagePaths =
          Employees &&
          Employees.map(
            (employee) =>
              employee.salespersonDetails.employeeAadhaarcardFrontImage
          ).filter(
            (aadhaarcardFrontImagePath) => aadhaarcardFrontImagePath !== null
          );

        const aadharcardBackImagePaths =
          Employees &&
          Employees.map(
            (employee) =>
              employee.salespersonDetails.employeeAadhaarcardBackImage
          ).filter(
            (aadhaarcardBackImagePath) => aadhaarcardBackImagePath !== null
          );

        const drivinglicenseFrontImagePaths =
          Employees &&
          Employees.map(
            (employee) =>
              employee.salespersonDetails.employeeDrivinglicenseFrontImage
          ).filter(
            (drivinglicenseFrontImagePath) =>
              drivinglicenseFrontImagePath !== null
          );

        const drivinglicenseBackImagePaths =
          Employees &&
          Employees.map(
            (employee) =>
              employee.salespersonDetails.employeeDrivinglicenseBackImage
          ).filter(
            (drivinglicenseBackImagePath) =>
              drivinglicenseBackImagePath !== null
          );

        const pancardImagePaths =
          Employees &&
          Employees.map(
            (employee) => employee.salespersonDetails.employeePancardImage
          ).filter((pancardImagePath) => pancardImagePath !== null);

        const passportImagePaths =
          Employees &&
          Employees.map(
            (employee) => employee.salespersonDetails.employeePassportImage
          ).filter((passportImagePath) => passportImagePath !== null);

        const voterIdFrontImagePaths =
          Employees &&
          Employees.map(
            (employee) => employee.salespersonDetails.employeeVoterIdFrontImage
          ).filter((voterIdFrontImagePath) => voterIdFrontImagePath !== null);

        const voterIdBackImagePaths =
          Employees &&
          Employees.map(
            (employee) => employee.salespersonDetails.employeeVoterIdBackImage
          ).filter((voterIdBackImagePath) => voterIdBackImagePath !== null);

        const allPossibleProfileImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileData?.userDetails?.userType === "Agent"
              ? `agentEmployeeImage_${i}`
              : userProfileData?.userDetails?.userType === "Builder"
              ? `builderEmployeeImage_${i}`
              : null
        ).filter((path) => path !== null);

        const allPossibleIdCardImagePaths = Array.from({ length: 10 }, (_, i) =>
          userProfileData?.userDetails?.userType === "Agent"
            ? `agentEmployeeIdCardImage_${i}`
            : userProfileData?.userDetails?.userType === "Builder"
            ? `builderEmployeeIdCardImage_${i}`
            : null
        ).filter((path) => path !== null);

        const allPossibleAadhaarcardFrontImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileData?.userDetails?.userType === "Agent"
              ? `agentEmployeeAadhaarcardFrontImage_${i}`
              : userProfileData?.userDetails?.userType === "Builder"
              ? `builderEmployeeAadhaarcardFrontImage_${i}`
              : null
        ).filter((path) => path !== null);

        const allPossibleAadhaarcardBackImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileData?.userDetails?.userType === "Agent"
              ? `agentEmployeeAadhaarcardBackImage_${i}`
              : userProfileData?.userDetails?.userType === "Builder"
              ? `builderEmployeeAadhaarcardBackImage_${i}`
              : null
        ).filter((path) => path !== null);

        const allPossibleDrivinglicenseFrontImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileData?.userDetails?.userType === "Agent"
              ? `agentEmployeeDrivinglicenseFrontImage_${i}`
              : userProfileData?.userDetails?.userType === "Builder"
              ? `builderEmployeeDrivinglicenseFrontImage_${i}`
              : null
        ).filter((path) => path !== null);

        const allPossibleDrivinglicenseBackImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileData?.userDetails?.userType === "Agent"
              ? `agentEmployeeDrivinglicenseBackImage_${i}`
              : userProfileData?.userDetails?.userType === "Builder"
              ? `builderEmployeeDrivinglicenseBackImage_${i}`
              : null
        ).filter((path) => path !== null);

        const allPossiblePancardImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileData?.userDetails?.userType === "Agent"
              ? `agentEmployeePancardImage_${i}`
              : userProfileData?.userDetails?.userType === "Builder"
              ? `builderEmployeePancardImage_${i}`
              : null
        ).filter((path) => path !== null);

        const allPossiblePassportImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileData?.userDetails?.userType === "Agent"
              ? `agentEmployeePassportImage_${i}`
              : userProfileData?.userDetails?.userType === "Builder"
              ? `builderEmployeePassportImage_${i}`
              : null
        ).filter((path) => path !== null);

        const allPossibleVoterIdFrontImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileData?.userDetails?.userType === "Agent"
              ? `agentEmployeeVoterIdFrontImage_${i}`
              : userProfileData?.userDetails?.userType === "Builder"
              ? `builderEmployeeVoterIdFrontImage_${i}`
              : null
        ).filter((path) => path !== null);

        const allPossibleVoterIdBackImagePaths = Array.from(
          { length: 10 },
          (_, i) =>
            userProfileData?.userDetails?.userType === "Agent"
              ? `agentEmployeeVoterIdBackImage_${i}`
              : userProfileData?.userDetails?.userType === "Builder"
              ? `builderEmployeeVoterIdBackImage_${i}`
              : null
        ).filter((path) => path !== null);

        missingProfileImagePaths = allPossibleProfileImagePaths.filter(
          (path) =>
            !(profileImagePaths || [])?.some((profilePath) =>
              profilePath?.includes(path)
            )
        );

        missingIdCardImagePaths = allPossibleIdCardImagePaths.filter(
          (path) =>
            !(IdCardImagePaths || [])?.some((idCardPath) =>
              idCardPath?.includes(path)
            )
        );

        missingAadhaarcardFrontImagePaths =
          allPossibleAadhaarcardFrontImagePaths.filter(
            (path) =>
              !(aadharcardFrontImagePaths || []).some(
                (aadharcardFrontImagePath) =>
                  aadharcardFrontImagePath?.includes(path)
              )
          );

        missingAadhaarcardBackImagePaths =
          allPossibleAadhaarcardBackImagePaths.filter(
            (path) =>
              !(aadharcardBackImagePaths || []).some(
                (aadharcardBackImagePath) =>
                  aadharcardBackImagePath?.includes(path)
              )
          );

        missingDrivinglicenseFrontImagePaths =
          allPossibleDrivinglicenseFrontImagePaths.filter(
            (path) =>
              !(drivinglicenseFrontImagePaths || []).some(
                (drivinglicenseFrontImagePath) =>
                  drivinglicenseFrontImagePath?.includes(path)
              )
          );

        missingDrivinglicenseBackImagePaths =
          allPossibleDrivinglicenseBackImagePaths.filter(
            (path) =>
              !(drivinglicenseBackImagePaths || []).some(
                (drivinglicenseBackImagePath) =>
                  drivinglicenseBackImagePath?.includes(path)
              )
          );

        missingPancardImagePaths = allPossiblePancardImagePaths.filter(
          (path) =>
            !(pancardImagePaths || []).some((pancardImagePath) =>
              pancardImagePath?.includes(path)
            )
        );

        missingPassportImagePaths = allPossiblePassportImagePaths.filter(
          (path) =>
            !(passportImagePaths || []).some((passportImagePath) =>
              passportImagePath?.includes(path)
            )
        );

        missingVoterIdFrontImagePaths =
          allPossibleVoterIdFrontImagePaths.filter(
            (path) =>
              !(voterIdFrontImagePaths || []).some((voterIdFrontImagePath) =>
                voterIdFrontImagePath?.includes(path)
              )
          );

        missingVoterIdBackImagePaths = allPossibleVoterIdBackImagePaths.filter(
          (path) =>
            !(voterIdBackImagePaths || []).some((voterIdBackImagePath) =>
              voterIdBackImagePath?.includes(path)
            )
        );
      }

      const profilePattern =
        userProfileData?.userDetails?.userType === "Agent"
          ? /agentEmployeeImage_\d/
          : userProfileData?.userDetails?.userType === "Builder"
          ? /builderEmployeeImage_\d/
          : null;

      const idCardPattern =
        userProfileData?.userDetails?.userType === "Agent"
          ? /agentEmployeeIdCardImage_\d/
          : userProfileData?.userDetails?.userType === "Builder"
          ? /builderEmployeeIdCardImage_\d/
          : null;

      const aadhaarcardFrontImagePattern =
        userProfileData?.userDetails?.userType === "Agent"
          ? /agentEmployeeAadhaarcardFrontImage_\d/
          : userProfileData?.userDetails?.userType === "Builder"
          ? /builderEmployeeAadhaarcardFrontImage_\d/
          : null;

      const aadhaarcardBackImagePattern =
        userProfileData?.userDetails?.userType === "Agent"
          ? /agentEmployeeAadhaarcardBackImage_\d/
          : userProfileData?.userDetails?.userType === "Builder"
          ? /builderEmployeeAadhaarcardBackImage_\d/
          : null;

      const drivinglicenseFrontImagePattern =
        userProfileData?.userDetails?.userType === "Agent"
          ? /agentEmployeeDrivinglicenseFrontImage_\d/
          : userProfileData?.userDetails?.userType === "Builder"
          ? /builderEmployeeDrivinglicenseFrontImage_\d/
          : null;

      const drivinglicenseBackImagePattern =
        userProfileData?.userDetails?.userType === "Agent"
          ? /agentEmployeeDrivinglicenseBackImage_\d/
          : userProfileData?.userDetails?.userType === "Builder"
          ? /builderEmployeeDrivinglicenseBackImage_\d/
          : null;

      const pancardImagePattern =
        userProfileData?.userDetails?.userType === "Agent"
          ? /agentEmployeePancardImage_\d/
          : userProfileData?.userDetails?.userType === "Builder"
          ? /builderEmployeePancardImage_\d/
          : null;

      const passportImagePattern =
        userProfileData?.userDetails?.userType === "Agent"
          ? /agentEmployeePassportImage_\d/
          : userProfileData?.userDetails?.userType === "Builder"
          ? /builderEmployeePassportImage_\d/
          : null;

      const voterIdFrontImagePattern =
        userProfileData?.userDetails?.userType === "Agent"
          ? /agentEmployeeVoterIdFrontImage_\d/
          : userProfileData?.userDetails?.userType === "Builder"
          ? /builderEmployeeVoterIdFrontImage_\d/
          : null;

      const voterIdBackImagePattern =
        userProfileData?.userDetails?.userType === "Agent"
          ? /agentEmployeeVoterIdBackImage_\d/
          : userProfileData?.userDetails?.userType === "Builder"
          ? /builderEmployeeVoterIdBackImage_\d/
          : null;

      const profilematch = existingProfileImagePath?.match(profilePattern);
      const idCardmatch = existingIdCardImagePath?.match(idCardPattern);
      const aadhaarCardFrontImagematch =
        existingAadhaarcardFrontImagePath?.match(aadhaarcardFrontImagePattern);
      const aadhaarCardBackImagematch = existingAadhaarcardBackImagePath?.match(
        aadhaarcardBackImagePattern
      );

      const drivingLicenseFrontImagematch =
        existingDrivinglicenseFrontImagePath?.match(
          drivinglicenseFrontImagePattern
        );
      const drivingLicenseBackImagematch =
        existingDrivinglicenseBackImagePath?.match(
          drivinglicenseBackImagePattern
        );

      const pancardImagematch =
        existingPancardImagePath?.match(pancardImagePattern);

      const passportImagematch =
        existingPassportImagePath?.match(passportImagePattern);

      const voterIdFrontImagematch = existingVoterIdFrontImagePath?.match(
        voterIdFrontImagePattern
      );
      const voterIdBackImagematch = existingVoterIdBackImagePath?.match(
        voterIdBackImagePattern
      );

      if (
        profileImageFieldName &&
        idCardImageFieldName &&
        aadhaarcardFrontImageFieldName &&
        aadhaarcardBackImageFieldName &&
        drivinglicenseFrontImageFieldName &&
        drivinglicenseBackImageFieldName &&
        pancardImageFieldName &&
        passportImageFieldName &&
        voterIdFrontImageFieldName &&
        voterIdBackImageFieldName
      ) {
        setIsImagesFieldNameFetched(true);
      }

      setProfileImageFieldName(
        profilematch
          ? profilematch[0]
          : missingProfileImagePaths
          ? missingProfileImagePaths[0]
          : null
      );
      setIdCardImageFieldName(
        idCardmatch
          ? idCardmatch[0]
          : missingIdCardImagePaths
          ? missingIdCardImagePaths[0]
          : null
      );
      setAadhaarcardFrontImageFieldName(
        aadhaarCardFrontImagematch
          ? aadhaarCardFrontImagematch[0]
          : missingAadhaarcardFrontImagePaths
          ? missingAadhaarcardFrontImagePaths[0]
          : null
      );
      setAadhaarcardBackImageFieldName(
        aadhaarCardBackImagematch
          ? aadhaarCardBackImagematch[0]
          : missingAadhaarcardBackImagePaths
          ? missingAadhaarcardBackImagePaths[0]
          : null
      );
      setDrivinglicenseFrontImageFieldName(
        drivingLicenseFrontImagematch
          ? drivingLicenseFrontImagematch[0]
          : missingDrivinglicenseFrontImagePaths
          ? missingDrivinglicenseFrontImagePaths[0]
          : null
      );
      setDrivinglicenseBackImageFieldName(
        drivingLicenseBackImagematch
          ? drivingLicenseBackImagematch[0]
          : missingDrivinglicenseBackImagePaths
          ? missingDrivinglicenseBackImagePaths[0]
          : null
      );
      setPancardImageFieldName(
        pancardImagematch
          ? pancardImagematch[0]
          : missingPancardImagePaths
          ? missingPancardImagePaths[0]
          : null
      );
      setPassportImageFieldName(
        passportImagematch
          ? passportImagematch[0]
          : missingPassportImagePaths
          ? missingPassportImagePaths[0]
          : null
      );
      setVoterIdFrontImageFieldName(
        voterIdFrontImagematch
          ? voterIdFrontImagematch[0]
          : missingVoterIdFrontImagePaths
          ? missingVoterIdFrontImagePaths[0]
          : null
      );
      setVoterIdBackImageFieldName(
        voterIdBackImagematch
          ? voterIdBackImagematch[0]
          : missingVoterIdBackImagePaths
          ? missingVoterIdBackImagePaths[0]
          : null
      );
    }
  }, [
    userProfileData,
    selectedEmployee,
    agentEmployeeData,
    isImagesFieldNameFetched,
  ]);

  const saveUpdatedEmployeeDetails = async () => {
    const formdata = new FormData();

    formdata.append("userAccessId", userId);
    if (userProfileData?.userDetails?.userType === "Agent") {
      formdata.append("userId", agentEmployeeData.employeeUserId);
      formdata.append("employeeName", agentEmployeeData.employeeName);
      formdata.append("employeeEmail", agentEmployeeData.employeeEmail);
      formdata.append("employeeMobileNo", agentEmployeeData.employeeMobileNo);
      formdata.append(
        `${profileImageFieldName}`,
        !IsBase64Image(agentEmployeeData.employeeImage)
          ? agentEmployeeData.employeeImage
          : null
      );
      formdata.append(
        "selectedEmployeeIdType",
        agentEmployeeData.selectedEmployeeIdType
      );
      formdata.append("employeeID", agentEmployeeData.employeeID);
      formdata.append(
        `${idCardImageFieldName}`,
        !IsBase64Image(agentEmployeeData.employeeIdCardImage)
          ? agentEmployeeData.employeeIdCardImage
          : null
      );
      formdata.append(
        "employeeAadhaarcardNo",
        agentEmployeeData.employeeAadhaarcardNo
      );
      formdata.append(
        `${aadhaarcardFrontImageFieldName}`,
        !IsBase64Image(agentEmployeeData.employeeAadhaarcardFrontImage)
          ? agentEmployeeData.employeeAadhaarcardFrontImage
          : null
      );
      formdata.append(
        `${aadhaarcardBackImageFieldName}`,
        !IsBase64Image(agentEmployeeData.employeeAadhaarcardBackImage)
          ? agentEmployeeData.employeeAadhaarcardBackImage
          : null
      );
      formdata.append(
        "employeeDrivinglicenseNo",
        agentEmployeeData.employeeDrivinglicenseNo
      );
      formdata.append(
        `${drivinglicenseFrontImageFieldName}`,
        !IsBase64Image(agentEmployeeData.employeeDrivinglicenseFrontImage)
          ? agentEmployeeData.employeeDrivinglicenseFrontImage
          : null
      );
      formdata.append(
        `${drivinglicenseBackImageFieldName}`,
        !IsBase64Image(agentEmployeeData.employeeDrivinglicenseBackImage)
          ? agentEmployeeData.employeeDrivinglicenseBackImage
          : null
      );
      formdata.append("employeePancardNo", agentEmployeeData.employeePancardNo);
      formdata.append(
        `${pancardImageFieldName}`,
        !IsBase64Image(agentEmployeeData.employeePancardImage)
          ? agentEmployeeData.employeePancardImage
          : null
      );
      formdata.append(
        "employeePassportNo",
        agentEmployeeData.employeePassportNo
      );
      formdata.append(
        `${passportImageFieldName}`,
        !IsBase64Image(agentEmployeeData.employeePassportImage)
          ? agentEmployeeData.employeePassportImage
          : null
      );
      formdata.append("employeeVoterIdNo", agentEmployeeData.employeeVoterIdNo);
      formdata.append(
        `${voterIdFrontImageFieldName}`,
        !IsBase64Image(agentEmployeeData.employeeVoterIdFrontImage)
          ? agentEmployeeData.employeeVoterIdFrontImage
          : null
      );
      formdata.append(
        `${voterIdBackImageFieldName}`,
        !IsBase64Image(agentEmployeeData.employeeVoterIdBackImage)
          ? agentEmployeeData.employeeVoterIdBackImage
          : null
      );
    }
    if (userProfileData?.userDetails?.userType === "Builder") {
      formdata.append("userId", builderEmployeeData.employeeUserId);
      formdata.append("employeeName", builderEmployeeData.employeeName);
      formdata.append("employeeEmail", builderEmployeeData.employeeEmail);
      formdata.append("employeeMobileNo", builderEmployeeData.employeeMobileNo);
      formdata.append(
        `${profileImageFieldName}`,
        !IsBase64Image(builderEmployeeData.employeeImage)
          ? builderEmployeeData.employeeImage
          : null
      );
      formdata.append(
        "selectedEmployeeIdType",
        builderEmployeeData.selectedEmployeeIdType
      );
      formdata.append("employeeID", builderEmployeeData.employeeID);
      formdata.append(
        `${idCardImageFieldName}`,
        !IsBase64Image(builderEmployeeData.employeeIdCardImage)
          ? builderEmployeeData.employeeIdCardImage
          : null
      );
    }

    const response = await axios.patch(
      `${baseURL}/api/profile/updateEmployeeProfile`,
      formdata,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.data.message === "Profile updated successfully") {
      setUpdationResp(response.data.message);

      if (userProfileData.userDetails.userType === "Agent") {
        if (agentEmployeeData.selectedEmployeeIdType === "Employee id") {
          setAgentEmployeeData((prev) => ({
            ...prev,
            employeeAadhaarcardNo: "",
            employeeAadhaarcardFrontImage: null,
            employeeAadhaarcardBackImage: null,

            employeeDrivinglicenseNo: "",
            employeeDrivinglicenseFrontImage: null,
            employeeDrivinglicenseBackImage: null,

            employeePancardNo: "",
            employeePancardImage: null,

            employeePassportNo: "",
            employeePassportImage: null,

            employeeVoterIdNo: "",
            employeeVoterIdFrontImage: null,
            employeeVoterIdBackImage: null,
          }));
        }
        if (agentEmployeeData.selectedEmployeeIdType === "Aadhaar card") {
          setAgentEmployeeData((prev) => ({
            ...prev,

            employeeID: "",
            employeeIdCardImage: null,

            employeeDrivinglicenseNo: "",
            employeeDrivinglicenseFrontImage: null,
            employeeDrivinglicenseBackImage: null,

            employeePancardNo: "",
            employeePancardImage: null,

            employeePassportNo: "",
            employeePassportImage: null,

            employeeVoterIdNo: "",
            employeeVoterIdFrontImage: null,
            employeeVoterIdBackImage: null,
          }));
        }
        if (agentEmployeeData.selectedEmployeeIdType === "Driving license") {
          setAgentEmployeeData((prev) => ({
            ...prev,

            employeeID: "",
            employeeIdCardImage: null,

            employeeAadhaarcardNo: "",
            employeeAadhaarcardFrontImage: null,
            employeeAadhaarcardBackImage: null,

            employeePancardNo: "",
            employeePancardImage: null,

            employeePassportNo: "",
            employeePassportImage: null,

            employeeVoterIdNo: "",
            employeeVoterIdFrontImage: null,
            employeeVoterIdBackImage: null,
          }));
        }
        if (agentEmployeeData.selectedEmployeeIdType === "Pan card") {
          setAgentEmployeeData((prev) => ({
            ...prev,

            employeeID: "",
            employeeIdCardImage: null,

            employeeAadhaarcardNo: "",
            employeeAadhaarcardFrontImage: null,
            employeeAadhaarcardBackImage: null,

            employeeDrivinglicenseNo: "",
            employeeDrivinglicenseFrontImage: null,
            employeeDrivinglicenseBackImage: null,

            employeePassportNo: "",
            employeePassportImage: null,

            employeeVoterIdNo: "",
            employeeVoterIdFrontImage: null,
            employeeVoterIdBackImage: null,
          }));
        }
        if (agentEmployeeData.selectedEmployeeIdType === "Passport") {
          setAgentEmployeeData((prev) => ({
            ...prev,

            employeeID: "",
            employeeIdCardImage: null,

            employeeAadhaarcardNo: "",
            employeeAadhaarcardFrontImage: null,
            employeeAadhaarcardBackImage: null,

            employeeDrivinglicenseNo: "",
            employeeDrivinglicenseFrontImage: null,
            employeeDrivinglicenseBackImage: null,

            employeePancardNo: "",
            employeePancardImage: null,

            employeeVoterIdNo: "",
            employeeVoterIdFrontImage: null,
            employeeVoterIdBackImage: null,
          }));
        }
        if (agentEmployeeData.selectedEmployeeIdType === "Voter id") {
          setAgentEmployeeData((prev) => ({
            ...prev,

            employeeID: "",
            employeeIdCardImage: null,

            employeeAadhaarcardNo: "",
            employeeAadhaarcardFrontImage: null,
            employeeAadhaarcardBackImage: null,

            employeeDrivinglicenseNo: "",
            employeeDrivinglicenseFrontImage: null,
            employeeDrivinglicenseBackImage: null,

            employeePancardNo: "",
            employeePancardImage: null,

            employeePassportNo: "",
            employeePassportImage: null,
          }));
        }
      }

      closeEmployeeDataUpdateModal();
    }
  };
  const saveSalespersonDetails = async () => {
    const formdata = new FormData();
    if (
      userProfileData?.userDetails?.userType === "Salesperson" &&
      !userProfileData?.salespersonDetails?.userAccessId
    ) {
      formdata.append("userId", userProfileData?.userDetails?.userId);
      formdata.append(
        "selectedEmployeeIdType",
        salespersonData.selectedEmployeeIdType
      );
      formdata.append(
        "salespersonEmployeementLetter",
        salespersonData.employeementLetter
      );
      formdata.append("employeeID", salespersonData.employeeID);
      formdata.append(
        "salespersonIdCardImage",
        !IsBase64Image(salespersonData.employeeIdCardImage)
          ? salespersonData.employeeIdCardImage
          : null
      );
      formdata.append(
        "employeeAadhaarcardNo",
        salespersonData.employeeAadhaarcardNo
      );
      formdata.append(
        "salespersonAadhaarcardFrontImage",
        !IsBase64Image(salespersonData.employeeAadhaarcardFrontImage)
          ? salespersonData.employeeAadhaarcardFrontImage
          : null
      );
      formdata.append(
        "salespersonAadhaarcardBackImage",
        !IsBase64Image(salespersonData.employeeAadhaarcardBackImage)
          ? salespersonData.employeeAadhaarcardBackImage
          : null
      );
      formdata.append(
        "employeeDrivinglicenseNo",
        salespersonData.employeeDrivinglicenseNo
      );
      formdata.append(
        "salespersonDrivinglicenseFrontImage",
        !IsBase64Image(salespersonData.employeeDrivinglicenseFrontImage)
          ? salespersonData.employeeDrivinglicenseFrontImage
          : null
      );
      formdata.append(
        "salespersonDrivinglicenseBackImage",
        !IsBase64Image(salespersonData.employeeDrivinglicenseBackImage)
          ? salespersonData.employeeDrivinglicenseBackImage
          : null
      );
      formdata.append("employeePancardNo", salespersonData.employeePancardNo);
      formdata.append(
        "salespersonPancardImage",
        !IsBase64Image(salespersonData.employeePancardImage)
          ? salespersonData.employeePancardImage
          : null
      );
      formdata.append("employeePassportNo", salespersonData.employeePassportNo);
      formdata.append(
        "salespersonPassportImage",
        !IsBase64Image(salespersonData.employeePassportImage)
          ? salespersonData.employeePassportImage
          : null
      );
      formdata.append("employeeVoterIdNo", salespersonData.employeeVoterIdNo);
      formdata.append(
        "salespersonVoterIdFrontImage",
        !IsBase64Image(salespersonData.employeeVoterIdFrontImage)
          ? salespersonData.employeeVoterIdFrontImage
          : null
      );
      formdata.append(
        "salespersonVoterIdBackImage",
        !IsBase64Image(salespersonData.employeeVoterIdBackImage)
          ? salespersonData.employeeVoterIdBackImage
          : null
      );
    }

    try {
      const response = await axios.patch(
        `${baseURL}/api/profile/updateProfile`,
        formdata,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setUpdationResp(response.data.message);
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error;
    }

    // if (userProfileData.userDetails.userType === "Agent") {
    //   if (agentEmployeeData.selectedEmployeeIdType === "Employee id") {
    //     setAgentEmployeeData((prev) => ({
    //       ...prev,
    //       employeeAadhaarcardNo: "",
    //       employeeAadhaarcardFrontImage: null,
    //       employeeAadhaarcardBackImage: null,

    //       employeeDrivinglicenseNo: "",
    //       employeeDrivinglicenseFrontImage: null,
    //       employeeDrivinglicenseBackImage: null,

    //       employeePancardNo: "",
    //       employeePancardImage: null,

    //       employeePassportNo: "",
    //       employeePassportImage: null,

    //       employeeVoterIdNo: "",
    //       employeeVoterIdFrontImage: null,
    //       employeeVoterIdBackImage: null,
    //     }));
    //   }
    //   if (agentEmployeeData.selectedEmployeeIdType === "Aadhaar card") {
    //     setAgentEmployeeData((prev) => ({
    //       ...prev,

    //       employeeID: "",
    //       employeeIdCardImage: null,

    //       employeeDrivinglicenseNo: "",
    //       employeeDrivinglicenseFrontImage: null,
    //       employeeDrivinglicenseBackImage: null,

    //       employeePancardNo: "",
    //       employeePancardImage: null,

    //       employeePassportNo: "",
    //       employeePassportImage: null,

    //       employeeVoterIdNo: "",
    //       employeeVoterIdFrontImage: null,
    //       employeeVoterIdBackImage: null,
    //     }));
    //   }
    //   if (agentEmployeeData.selectedEmployeeIdType === "Driving license") {
    //     setAgentEmployeeData((prev) => ({
    //       ...prev,

    //       employeeID: "",
    //       employeeIdCardImage: null,

    //       employeeAadhaarcardNo: "",
    //       employeeAadhaarcardFrontImage: null,
    //       employeeAadhaarcardBackImage: null,

    //       employeePancardNo: "",
    //       employeePancardImage: null,

    //       employeePassportNo: "",
    //       employeePassportImage: null,

    //       employeeVoterIdNo: "",
    //       employeeVoterIdFrontImage: null,
    //       employeeVoterIdBackImage: null,
    //     }));
    //   }
    //   if (agentEmployeeData.selectedEmployeeIdType === "Pan card") {
    //     setAgentEmployeeData((prev) => ({
    //       ...prev,

    //       employeeID: "",
    //       employeeIdCardImage: null,

    //       employeeAadhaarcardNo: "",
    //       employeeAadhaarcardFrontImage: null,
    //       employeeAadhaarcardBackImage: null,

    //       employeeDrivinglicenseNo: "",
    //       employeeDrivinglicenseFrontImage: null,
    //       employeeDrivinglicenseBackImage: null,

    //       employeePassportNo: "",
    //       employeePassportImage: null,

    //       employeeVoterIdNo: "",
    //       employeeVoterIdFrontImage: null,
    //       employeeVoterIdBackImage: null,
    //     }));
    //   }
    //   if (agentEmployeeData.selectedEmployeeIdType === "Passport") {
    //     setAgentEmployeeData((prev) => ({
    //       ...prev,

    //       employeeID: "",
    //       employeeIdCardImage: null,

    //       employeeAadhaarcardNo: "",
    //       employeeAadhaarcardFrontImage: null,
    //       employeeAadhaarcardBackImage: null,

    //       employeeDrivinglicenseNo: "",
    //       employeeDrivinglicenseFrontImage: null,
    //       employeeDrivinglicenseBackImage: null,

    //       employeePancardNo: "",
    //       employeePancardImage: null,

    //       employeeVoterIdNo: "",
    //       employeeVoterIdFrontImage: null,
    //       employeeVoterIdBackImage: null,
    //     }));
    //   }
    //   if (agentEmployeeData.selectedEmployeeIdType === "Voter id") {
    //     setAgentEmployeeData((prev) => ({
    //       ...prev,

    //       employeeID: "",
    //       employeeIdCardImage: null,

    //       employeeAadhaarcardNo: "",
    //       employeeAadhaarcardFrontImage: null,
    //       employeeAadhaarcardBackImage: null,

    //       employeeDrivinglicenseNo: "",
    //       employeeDrivinglicenseFrontImage: null,
    //       employeeDrivinglicenseBackImage: null,

    //       employeePancardNo: "",
    //       employeePancardImage: null,

    //       employeePassportNo: "",
    //       employeePassportImage: null,
    //     }));
    //   }
    // }

    closeEmployeeDataUpdateModal();
  };

  //ADD EMPLOYEE FUNCTIONALITY

  const [isAddEmployeeModalVisible, setIsAddEmployeeModalVisible] =
    useState(false);

  const openAddEmployeeModal = () => {
    setIsAddEmployeeModalVisible(true);
  };
  const closeAddEmployeeModal = () => {
    setIsAddEmployeeModalVisible(false);
  };

  const [isNewEmployeeImageChanged, setIsNewEmployeeImageChanged] = useState({
    profileImage: false,
    idCardImage: false,
  });

  const [isNewEmployeeDataChanged, setIsNewEmployeeDataChanged] = useState({
    employeeName: false,
    employeeEmail: false,
    employeeMobileNo: false,
    employeeWhatsappMobile: false,
    selectedEmployeeIdType: false,
    employeeID: false,
    employeeAadhaarcardNo: false,
    employeeDrivinglicenseNo: false,
    employeePancardNo: false,
    employeePassportNo: false,
    employeeVoterIdNo: false,
  });

  const [newEmployeeData, setNewEmployeeData] = useState({
    employeeUserId: "",
    employeeName: "",
    employeeEmail: "",
    employeeMobileNo: "",
    employeeWhatsappMobile: "",
    employeeImage: null,

    selectedEmployeeIdType: "select id type ...",

    employeeID: "",
    employeeIdCardImage: null,

    employeeAadhaarcardNo: "",
    employeeAadhaarcardFrontImage: null,
    employeeAadhaarcardBackImage: null,

    employeeDrivinglicenseNo: "",
    employeeDrivinglicenseFrontImage: null,
    employeeDrivinglicenseBackImage: null,

    employeePancardNo: "",
    employeePancardImage: null,

    employeePassportNo: "",
    employeePassportImage: null,

    employeeVoterIdNo: "",
    employeeVoterIdFrontImage: null,
    employeeVoterIdBackImage: null,
  });

  // useEffect(() => {
  //     setNewEmployeeData((prev) => ({
  //       ...prev,
  //       employeeUserId: selectedEmployee.userId,
  //       employeeEmail:
  //         isNewEmployeeDataChanged.employeeEmail === false
  //           ? selectedEmployee.salespersonDetails.employeeEmail
  //           : prev.employeeEmail,
  //       employeeMobileNo:
  //         isNewEmployeeDataChanged.employeeMobileNo === false
  //           ? selectedEmployee.salespersonDetails.employeeMobileNo
  //           : prev.employeeMobileNo,
  //       employeeName:
  //         isNewEmployeeDataChanged.employeeName === false
  //           ? selectedEmployee.salespersonDetails.employeeName
  //           : prev.employeeName,
  //       selectedEmployeeIdType:
  //         isNewEmployeeDataChanged.selectedEmployeeIdType === false
  //           ? selectedEmployee.salespersonDetails.selectedEmployeeIdType
  //           : prev.selectedEmployeeIdType,
  //       employeeID:
  //         selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
  //           "Employee id" && isNewEmployeeDataChanged.employeeID === false
  //           ? selectedEmployee.salespersonDetails.employeeID
  //           : prev.employeeID,

  //       employeeAadhaarcardNo:
  //         selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
  //           "Aadhaar card" &&
  //         isNewEmployeeDataChanged.employeeAadhaarcardNo === false
  //           ? selectedEmployee.salespersonDetails.employeeAadhaarcardNo
  //           : prev.employeeAadhaarcardNo,
  //       employeeAadhaarcardFrontImage:
  //         selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
  //         "Aadhaar card"
  //           ? selectedEmployee.salespersonDetails.employeeAadhaarcardFrontImage
  //           : null,
  //       employeeAadhaarcardBackImage:
  //         selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
  //         "Aadhaar card"
  //           ? selectedEmployee.salespersonDetails.employeeAadhaarcardBackImage
  //           : null,

  //       employeeDrivinglicenseNo:
  //         selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
  //           "Driving license" &&
  //         isNewEmployeeDataChanged.employeeDrivinglicenseNo === false
  //           ? selectedEmployee.salespersonDetails.employeeDrivinglicenseNo
  //           : prev.employeeDrivinglicenseNo,
  //       employeeDrivinglicenseFrontImage:
  //         selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
  //         "Driving license"
  //           ? selectedEmployee.salespersonDetails
  //               .employeeDrivinglicenseFrontImage
  //           : null,
  //       employeeDrivinglicenseBackImage:
  //         selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
  //         "Driving license"
  //           ? selectedEmployee.salespersonDetails
  //               .employeeDrivinglicenseBackImage
  //           : null,

  //       employeePancardNo:
  //         selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
  //           "Pan card" && isNewEmployeeDataChanged.employeePancardNo === false
  //           ? selectedEmployee.salespersonDetails.employeePancardNo
  //           : prev.employeePancardNo,
  //       employeePancardImage:
  //         selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
  //         "Pan card"
  //           ? selectedEmployee.salespersonDetails.employeePancardImage
  //           : null,

  //       employeePassportNo:
  //         selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
  //           "Passport" && isNewEmployeeDataChanged.employeePassportNo === false
  //           ? selectedEmployee.salespersonDetails.employeePassportNo
  //           : prev.employeePassportNo,
  //       employeePassportImage:
  //         selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
  //         "Passport"
  //           ? selectedEmployee.salespersonDetails.employeePassportImage
  //           : null,

  //       employeeVoterIdNo:
  //         selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
  //           "Voter id" && isNewEmployeeDataChanged.employeeVoterIdNo === false
  //           ? selectedEmployee.salespersonDetails.employeeVoterIdNo
  //           : prev.employeeVoterIdNo,
  //       employeeVoterIdFrontImage:
  //         selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
  //         "Voter id"
  //           ? selectedEmployee.salespersonDetails.employeeVoterIdFrontImage
  //           : null,
  //       employeeVoterIdBackImage:
  //         selectedEmployee.salespersonDetails.selectedEmployeeIdType ===
  //         "Voter id"
  //           ? selectedEmployee.salespersonDetails.employeeVoterIdBackImage
  //           : null,
  //     }));
  //     if (isNewEmployeeImageChanged.profileImage === false) {
  //       setNewEmployeeData((prev) => ({
  //         ...prev,
  //         employeeImage: selectedEmployee.salespersonDetails.employeeImage,
  //       }));
  //     }
  //     if (
  //       isNewEmployeeImageChanged.idCardImage === false &&
  //       selectedEmployee?.salespersonDetails?.selectedEmployeeIdType ===
  //         "Employee id"
  //     ) {
  //       setNewEmployeeData((prev) => ({
  //         ...prev,
  //         employeeIdCardImage:
  //           selectedEmployee.salespersonDetails.employeeIdCardImage,
  //       }));
  //     }

  // }, [selectedEmployee]);

  useEffect(() => {
    if (newEmployeeData.selectedEmployeeIdType === "Employee id") {
      setNewEmployeeData((prev) => ({
        ...prev,
        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeePassportNo: "",
        employeePassportImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (newEmployeeData.selectedEmployeeIdType === "Aadhaar card") {
      setNewEmployeeData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeePassportNo: "",
        employeePassportImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (newEmployeeData.selectedEmployeeIdType === "Driving license") {
      setNewEmployeeData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeePassportNo: "",
        employeePassportImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (newEmployeeData.selectedEmployeeIdType === "Pan card") {
      setNewEmployeeData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePassportNo: "",
        employeePassportImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (newEmployeeData.selectedEmployeeIdType === "Passport") {
      setNewEmployeeData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeeVoterIdNo: "",
        employeeVoterIdFrontImage: null,
        employeeVoterIdBackImage: null,
      }));
    }
    if (newEmployeeData.selectedEmployeeIdType === "Voter id") {
      setNewEmployeeData((prev) => ({
        ...prev,

        employeeID: "",
        employeeIdCardImage: null,

        employeeAadhaarcardNo: "",
        employeeAadhaarcardFrontImage: null,
        employeeAadhaarcardBackImage: null,

        employeeDrivinglicenseNo: "",
        employeeDrivinglicenseFrontImage: null,
        employeeDrivinglicenseBackImage: null,

        employeePancardNo: "",
        employeePancardImage: null,

        employeePassportNo: "",
        employeePassportImage: null,
      }));
    }
  }, [newEmployeeData.selectedEmployeeIdType]);

  const handleNewEmployeeData = (event) => {
    const { name, value } = event.target;
    setNewEmployeeData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setIsNewEmployeeDataChanged((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const handleNewEmployeeImage = (event) => {
    const file = event.target.files[0];
    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        setNewEmployeeData((prev) => ({
          ...prev,
          employeeImage: file,
        }));

        setIsNewEmployeeImageChanged((prev) => ({
          ...prev,
          profileImage: true,
        }));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                setNewEmployeeData((prev) => ({
                  ...prev,
                  employeeImage: newFile,
                }));
              }, file.type);
            };
          };
        };

        setIsNewEmployeeImageChanged((prev) => ({
          ...prev,
          profileImage: true,
        }));
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeNewEmployeeImage = (event) => {
    setNewEmployeeData((prev) => ({
      ...prev,
      employeeImage: null,
    }));
  };

  const handleNewEmployeeIdCardImage = (event) => {
    const file = event.target.files[0];
    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        setNewEmployeeData((prev) => ({
          ...prev,
          employeeIdCardImage: file,
        }));

        setIsNewEmployeeImageChanged((prev) => ({
          ...prev,
          idCardImage: true,
        }));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                setNewEmployeeData((prev) => ({
                  ...prev,
                  employeeIdCardImage: newFile,
                }));
              }, file.type);
            };
          };
        };
        setIsNewEmployeeImageChanged((prev) => ({
          ...prev,
          idCardImage: true,
        }));
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee ID card image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeNewEmployeeIdCardImage = (event) => {
    setNewEmployeeData((prev) => ({
      ...prev,
      employeeIdCardImage: null,
    }));
  };

  const handleNewEmployeeAadhaarcardImage = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;

    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        if (name === "employeeAadhaarcardFrontImage") {
          setNewEmployeeData((prev) => ({
            ...prev,
            employeeAadhaarcardFrontImage: file,
          }));
        } else if (name === "employeeAadhaarcardBackImage") {
          setNewEmployeeData((prev) => ({
            ...prev,
            employeeAadhaarcardBackImage: file,
          }));
        }
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                if (name === "employeeAadhaarcardFrontImage") {
                  setNewEmployeeData((prev) => ({
                    ...prev,
                    employeeAadhaarcardFrontImage: newFile,
                  }));
                } else if (name === "employeeAadhaarcardBackImage") {
                  setNewEmployeeData((prev) => ({
                    ...prev,
                    employeeAadhaarcardBackImage: newFile,
                  }));
                }
              }, file.type);
            };
          };
        };
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee Aadhaar card image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeNewEmployeeAadhaarcardImage = (event) => {
    setNewEmployeeData((prev) => ({
      ...prev,
      employeeAadhaarcardFrontImage: null,
      employeeAadhaarcardBackImage: null,
    }));
  };

  const handleNewEmployeeDrivingLicense = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;

    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        if (name === "employeeDrivinglicenseFrontImage") {
          setNewEmployeeData((prev) => ({
            ...prev,
            employeeDrivinglicenseFrontImage: file,
          }));
        } else if (name === "employeeDrivinglicenseBackImage") {
          setNewEmployeeData((prev) => ({
            ...prev,
            employeeDrivinglicenseBackImage: file,
          }));
        }
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                if (name === "employeeDrivinglicenseFrontImage") {
                  setNewEmployeeData((prev) => ({
                    ...prev,
                    employeeDrivinglicenseFrontImage: newFile,
                  }));
                } else if (name === "employeeDrivinglicenseBackImage") {
                  setNewEmployeeData((prev) => ({
                    ...prev,
                    employeeDrivinglicenseBackImage: newFile,
                  }));
                }
              }, file.type);
            };
          };
        };
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee driving license image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeNewEmployeeDrivingLicenseImage = (event) => {
    setNewEmployeeData((prev) => ({
      ...prev,
      employeeDrivinglicenseFrontImage: null,
      employeeDrivinglicenseBackImage: null,
    }));
  };

  const handleNewEmployeePanCardImage = (event) => {
    const file = event.target.files[0];

    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        setNewEmployeeData((prev) => ({
          ...prev,
          employeePancardImage: file,
        }));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                setNewEmployeeData((prev) => ({
                  ...prev,
                  employeePancardImage: newFile,
                }));
              }, file.type);
            };
          };
        };
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee pan card image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeNewEmployeePanCardImage = (event) => {
    setNewEmployeeData((prev) => ({
      ...prev,
      employeePancardImage: null,
    }));
  };

  const handleNewEmployeePassportImage = (event) => {
    const file = event.target.files[0];

    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        setNewEmployeeData((prev) => ({
          ...prev,
          employeePassportImage: file,
        }));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                setNewEmployeeData((prev) => ({
                  ...prev,
                  employeePassportImage: newFile,
                }));
              }, file.type);
            };
          };
        };
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee passport image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeNewEmployeePaasportImage = (event) => {
    setNewEmployeeData((prev) => ({
      ...prev,
      employeePassportImage: null,
    }));
  };

  const handleNewEmployeeVoterIdImage = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;

    const premiumMaxSize = 300 * 1024; // Maximum size allowed for premium users: 300KB
    const nonPremiumMaxSize = 150 * 1024; // Maximum size allowed for non-premium users: 150KB

    const maxSize = userData.isPremiumUser ? premiumMaxSize : nonPremiumMaxSize;

    if (file?.size <= maxSize) {
      if (userData.isPremiumUser) {
        if (name === "employeeVoterIdFrontImage") {
          setNewEmployeeData((prev) => ({
            ...prev,
            employeeVoterIdFrontImage: file,
          }));
        } else if (name === "employeeVoterIdBackImage") {
          setNewEmployeeData((prev) => ({
            ...prev,
            employeeVoterIdBackImage: file,
          }));
        }
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0);

            // Load and resize watermark image
            const watermarkImg = new Image();
            watermarkImg.src = watermarkImage; // Assuming `watermarkImage` is the imported watermark image

            watermarkImg.onload = () => {
              const watermarkWidth = watermarkImg.width / 2; // Reduce the width by half
              const watermarkHeight = watermarkImg.height / 2; // Reduce the height by half

              // Set watermark position (bottom right corner)
              const watermarkX = canvas.width - watermarkWidth - 10;
              const watermarkY = canvas.height - watermarkHeight - 10;

              // Draw the resized watermark image onto the canvas
              ctx.drawImage(
                watermarkImg,
                watermarkX,
                watermarkY,
                watermarkWidth,
                watermarkHeight
              );

              // Debug: Draw a border around the canvas
              ctx.strokeStyle = "black";
              ctx.strokeRect(0, 0, canvas.width, canvas.height);

              // Debug: Log canvas dimensions
              console.log(
                `Canvas Width: ${canvas.width}, Canvas Height: ${canvas.height}`
              );

              // Convert the canvas to a Blob and then to a File
              canvas.toBlob((blob) => {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                });
                if (name === "employeeVoterIdFrontImage") {
                  setNewEmployeeData((prev) => ({
                    ...prev,
                    employeeVoterIdFrontImage: newFile,
                  }));
                } else if (name === "employeeVoterIdBackImage") {
                  setNewEmployeeData((prev) => ({
                    ...prev,
                    employeeVoterIdBackImage: newFile,
                  }));
                }
              }, file.type);
            };
          };
        };
      }
    } else {
      // Notify the user about the file size limit
      toast.warn(
        `Selected employee VoterId image exceeds the maximum size limit of ${
          maxSize / 1024
        }KB!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  const removeNewEmployeeVoterIdImage = (event) => {
    setNewEmployeeData((prev) => ({
      ...prev,
      employeeVoterIdFrontImage: null,
      employeeVoterIdBackImage: null,
    }));
  };

  const sendEmployeeEmail = async () => {
    const formData = new FormData();
  
    formData.append("userAccessId", userId);
  
    if (userProfileData?.userDetails?.userType === "Agent") {
      // formData.append("userId", newEmployeeData.employeeUserId);
      formData.append("employeeName", newEmployeeData.employeeName);
      formData.append("employeeEmail", newEmployeeData.employeeEmail);
      formData.append("employeeMobileNo", newEmployeeData.employeeMobileNo);
      formData.append(
        profileImageFieldName,
        !IsBase64Image(newEmployeeData.employeeImage) ? newEmployeeData.employeeImage : ""
      );
      formData.append("selectedEmployeeIdType", newEmployeeData.selectedEmployeeIdType);
      formData.append("employeeID", newEmployeeData.employeeID);
      formData.append(
        idCardImageFieldName,
        !IsBase64Image(newEmployeeData.employeeIdCardImage) ? newEmployeeData.employeeIdCardImage : ""
      );
      formData.append("employeeAadhaarcardNo", newEmployeeData.employeeAadhaarcardNo);
      formData.append(
        aadhaarcardFrontImageFieldName,
        !IsBase64Image(newEmployeeData.employeeAadhaarcardFrontImage) ? newEmployeeData.employeeAadhaarcardFrontImage : ""
      );
      formData.append(
        aadhaarcardBackImageFieldName,
        !IsBase64Image(newEmployeeData.employeeAadhaarcardBackImage) ? newEmployeeData.employeeAadhaarcardBackImage : ""
      );
      formData.append("employeeDrivinglicenseNo", newEmployeeData.employeeDrivinglicenseNo);
      formData.append(
        drivinglicenseFrontImageFieldName,
        !IsBase64Image(newEmployeeData.employeeDrivinglicenseFrontImage) ? newEmployeeData.employeeDrivinglicenseFrontImage : ""
      );
      formData.append(
        drivinglicenseBackImageFieldName,
        !IsBase64Image(newEmployeeData.employeeDrivinglicenseBackImage) ? newEmployeeData.employeeDrivinglicenseBackImage : ""
      );
      formData.append("employeePancardNo", newEmployeeData.employeePancardNo);
      formData.append(
        pancardImageFieldName,
        !IsBase64Image(newEmployeeData.employeePancardImage) ? newEmployeeData.employeePancardImage : ""
      );
      formData.append("employeePassportNo", newEmployeeData.employeePassportNo);
      formData.append(
        passportImageFieldName,
        !IsBase64Image(newEmployeeData.employeePassportImage) ? newEmployeeData.employeePassportImage : ""
      );
      formData.append("employeeVoterIdNo", newEmployeeData.employeeVoterIdNo);
      formData.append(
        voterIdFrontImageFieldName,
        !IsBase64Image(newEmployeeData.employeeVoterIdFrontImage) ? newEmployeeData.employeeVoterIdFrontImage : ""
      );
      formData.append(
        voterIdBackImageFieldName,
        !IsBase64Image(newEmployeeData.employeeVoterIdBackImage) ? newEmployeeData.employeeVoterIdBackImage : ""
      );
    } else if (userProfileData?.userDetails?.userType === "Builder") {
      formData.append("userId", newEmployeeData.employeeUserId);
      formData.append("employeeName", newEmployeeData.employeeName);
      formData.append("employeeEmail", newEmployeeData.employeeEmail);
      formData.append("employeeMobileNo", newEmployeeData.employeeMobileNo);
      formData.append(
        profileImageFieldName,
        !IsBase64Image(newEmployeeData.employeeImage) ? newEmployeeData.employeeImage : ""
      );
      formData.append("selectedEmployeeIdType", newEmployeeData.selectedEmployeeIdType);
      formData.append("employeeID", newEmployeeData.employeeID);
      formData.append(
        idCardImageFieldName,
        !IsBase64Image(newEmployeeData.employeeIdCardImage) ? newEmployeeData.employeeIdCardImage : ""
      );
    }
  
    try {
      const response = await axios.post(
        `${baseURL}/api/mail/sendMail`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response);
      
      if(response.statusText === "OK"){
        closeAddEmployeeModal();

        if(response.data.recruitmentStatus === 'Invitation sent'){
          messageApi.open({
            type: "success",
            content: response.data.message,
          });
        }else if(response.data.recruitmentStatus === 'Already recruited'){
          messageApi.open({
            type: "info",
            content: response.data.message,
          });
        }
      }else{
        closeAddEmployeeModal();
        messageApi.open({
          type: "error",
          content: response.data.message,
        });

      }
    } catch (error) {
      console.error("Error sending email:", error);
      throw error;
    }
  };
  

  return (
    <div className="pp">
      {contextHolder}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
      <div className="pp-sub">
        <div className="pp-container">
          <div className="pp-header">
            <div className="pp-header-sub">
              <GoArrowLeft
                className="pp-header-sub-icon"
                onClick={() => navigate(-1)} // Go back in browser history
              />
              <h3>Profile</h3>
            </div>
          </div>
          <div className="pp-form">
            <div className="profile-form-sub">
              <div className="profile-form-left">
                <div className="profile-form-image">
                  <div
                    className="profile-form-image-sub"
                    onClick={openProfilePhotoModal}
                  >
                    {userProfileData &&
                    userProfileData?.userDetails?.userType === "Owner" &&
                    userProfileData?.userDetails?.ownerDetails
                      ?.ownerProfilePhoto ? (
                      IsBase64Image(
                        userProfileData?.userDetails?.ownerDetails
                          ?.ownerProfilePhoto
                      ) ? (
                        <img
                          src={`data:image/jpeg;base64,${userProfileData?.userDetails?.ownerDetails?.ownerProfilePhoto}`}
                          alt="profilePhoto"
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(
                            userProfileData?.userDetails?.ownerDetails
                              ?.ownerProfilePhoto
                          )}
                          alt="profilePhoto"
                        />
                      )
                    ) : userProfileData &&
                      userProfileData?.userDetails?.userType === "Builder" &&
                      userProfileData?.userDetails?.builderDetails
                        ?.builderProfilePhoto ? (
                      IsBase64Image(
                        userProfileData?.userDetails?.builderDetails
                          ?.builderProfilePhoto
                      ) ? (
                        <img
                          src={`data:image/jpeg;base64,${userProfileData?.userDetails?.builderDetails?.builderProfilePhoto}`}
                          alt="profilePhoto"
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(
                            userProfileData?.userDetails?.builderDetails
                              ?.builderProfilePhoto
                          )}
                          alt="profilePhoto"
                        />
                        // <img src="https://placehold.co/600x400" alt="" />
                      )
                    ) : userProfileData &&
                      userProfileData?.userDetails?.userType === "Agent" &&
                      userProfileData?.userDetails?.agentDetails
                        ?.agentProfilePhoto ? (
                      IsBase64Image(
                        userProfileData?.userDetails?.agentDetails
                          ?.agentProfilePhoto
                      ) ? (
                        <img
                          src={`data:image/jpeg;base64,${userProfileData?.userDetails?.agentDetails?.agentProfilePhoto}`}
                          alt="profilePhoto"
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(
                            userProfileData?.userDetails?.agentDetails
                              ?.agentProfilePhoto
                          )}
                          alt="profilePhoto"
                        />
                      )
                    ) : userProfileData &&
                      userProfileData?.userDetails?.userType ===
                        "Salesperson" &&
                      userProfileData?.userDetails?.salespersonDetails
                        ?.employeeImage ? (
                      IsBase64Image(
                        userProfileData?.userDetails?.salespersonDetails
                          ?.employeeImage
                      ) ? (
                        <img
                          src={`data:image/jpeg;base64,${userProfileData?.userDetails?.salespersonDetails?.employeeImage}`}
                          alt="profilePhoto"
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(
                            userProfileData?.userDetails?.salespersonDetails
                              ?.employeeImage
                          )}
                          alt="profilePhoto"
                        />
                      )
                    ) : (
                      <TbUser className="profile-form-image-sub-icon" />
                    )}
                    <div className="overlay"></div>
                    {!userProfileData?.userDetails?.salespersonDetails
                      ?.userAccessId && <CiImageOn className="edit-icon" />}
                  </div>
                  {isProfilePhotoModalVisible &&
                    !userProfileData?.userDetails?.salespersonDetails
                      ?.userAccessId && (
                      <div
                        className="profile-photo-modal-overlay"
                        onClick={closeProfilePhotoModal}
                      >
                        <div
                          className="profile-photo-modal-content"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <IoMdClose
                            className="profile-photo-close-icon"
                            onClick={closeProfilePhotoModal}
                          />
                          <div className="profile-profilephoto-modal-buttons">
                            {(userProfileData.userDetails.userType ===
                              "Owner" &&
                              !userProfileData.userDetails.ownerDetails
                                .ownerProfilePhoto) ||
                            (userProfileData.userDetails.userType === "Agent" &&
                              !userProfileData.userDetails.agentDetails
                                .agentProfilePhoto) ||
                            (userProfileData.userDetails.userType ===
                              "Builder" &&
                              !userProfileData.userDetails.builderDetails
                                .builderProfilePhoto) ||
                            (userProfileData.userDetails.userType ===
                              "Salesperson" &&
                              !userProfileData.userDetails.salespersonDetails
                                .userAccessId &&
                              !userProfileData.userDetails.salespersonDetails
                                .employeeImage) ? (
                              <div
                                className="profile-profilephoto-modal-buttons-sub"
                                onClick={() =>
                                  document
                                    .getElementById("NewProfilePhoto")
                                    .click()
                                }
                              >
                                <span>Add profile photo</span>
                                <input
                                  type="file"
                                  id="NewProfilePhoto"
                                  style={{ display: "none" }}
                                  accept="image/*"
                                  onChange={(e) =>
                                    userProfileData.userDetails.userType ===
                                    "Owner"
                                      ? handleOwnerProfileImageChange(e)
                                      : userProfileData.userDetails.userType ===
                                        "Agent"
                                      ? handleAgentProfileImageChange(e)
                                      : userProfileData.userDetails.userType ===
                                        "Builder"
                                      ? handleBuilderProfileImageChange(e)
                                      : userProfileData.userDetails.userType ===
                                          "Salesperson" &&
                                        !userProfileData.userDetails
                                          .salespersonDetails.userAccessId
                                      ? handleSalespersonProfileImageChange(e)
                                      : null
                                  }
                                />
                              </div>
                            ) : null}

                            {userProfileData.userDetails.userType ===
                            "Owner" ? (
                              (IsBase64Image(
                                userProfileData.userDetails.ownerDetails
                                  .ownerProfilePhoto
                              ) ||
                                updationResp ===
                                  "Profile updated successfully") &&
                              userProfileData.userDetails.ownerDetails
                                .ownerProfilePhoto ? (
                                <>
                                  <div
                                    className="profile-profilephoto-modal-buttons-sub"
                                    onClick={() =>
                                      document
                                        .getElementById("UpdateProfilePhoto")
                                        .click()
                                    }
                                  >
                                    <span>Update current photo</span>
                                    <input
                                      type="file"
                                      id="UpdateProfilePhoto"
                                      style={{ display: "none" }}
                                      accept="image/*"
                                      onChange={(e) => {
                                        handleOwnerProfileImageChange(e);
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                userProfileData.userDetails.ownerDetails
                                  .ownerProfilePhoto && (
                                  <div
                                    className="profile-profilephoto-modal-buttons-sub"
                                    onClick={handleSaveOwnerProfileImage}
                                  >
                                    <span>Save Changes</span>
                                  </div>
                                )
                              )
                            ) : userProfileData.userDetails.userType ===
                              "Agent" ? (
                              (IsBase64Image(
                                userProfileData.userDetails.agentDetails
                                  .agentProfilePhoto
                              ) ||
                                updationResp ===
                                  "Profile updated successfully") &&
                              userProfileData.userDetails.agentDetails
                                .agentProfilePhoto ? (
                                <>
                                  <div
                                    className="profile-profilephoto-modal-buttons-sub"
                                    onClick={() =>
                                      document
                                        .getElementById("UpdateProfilePhoto")
                                        .click()
                                    }
                                  >
                                    <span>Update current photo</span>
                                    <input
                                      type="file"
                                      id="UpdateProfilePhoto"
                                      style={{ display: "none" }}
                                      accept="image/*"
                                      onChange={(e) => {
                                        handleAgentProfileImageChange(e);
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                userProfileData.userDetails.agentDetails
                                  .agentProfilePhoto && (
                                  <div
                                    className="profile-profilephoto-modal-buttons-sub"
                                    onClick={handleSaveAgentProfileImage}
                                  >
                                    <span>Save Changes</span>
                                  </div>
                                )
                              )
                            ) : userProfileData.userDetails.userType ===
                              "Builder" ? (
                              (IsBase64Image(
                                userProfileData.userDetails.builderDetails
                                  .builderProfilePhoto
                              ) ||
                                updationResp ===
                                  "Profile updated successfully") &&
                              userProfileData.userDetails.builderDetails
                                .builderProfilePhoto ? (
                                <>
                                  <div
                                    className="profile-profilephoto-modal-buttons-sub"
                                    onClick={() =>
                                      document
                                        .getElementById("UpdateProfilePhoto")
                                        .click()
                                    }
                                  >
                                    <span>Update current photo</span>
                                    <input
                                      type="file"
                                      id="UpdateProfilePhoto"
                                      style={{ display: "none" }}
                                      accept="image/*"
                                      onChange={(e) => {
                                        handleBuilderProfileImageChange(e);
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                userProfileData.userDetails.builderDetails
                                  .builderProfilePhoto && (
                                  <div
                                    className="profile-profilephoto-modal-buttons-sub"
                                    onClick={handleSaveBuilderProfileImage}
                                  >
                                    <span>Save Changes</span>
                                  </div>
                                )
                              )
                            ) : userProfileData.userDetails.userType ===
                                "Salesperson" &&
                              !userProfileData.userDetails.salespersonDetails
                                .userAccessId ? (
                              (IsBase64Image(
                                userProfileData.userDetails.salespersonDetails
                                  .employeeImage
                              ) ||
                                updationResp ===
                                  "Profile updated successfully") &&
                              userProfileData.userDetails.salespersonDetails
                                .employeeImage ? (
                                <>
                                  <div
                                    className="profile-profilephoto-modal-buttons-sub"
                                    onClick={() =>
                                      document
                                        .getElementById("UpdateProfilePhoto")
                                        .click()
                                    }
                                  >
                                    <span>Update current photo</span>
                                    <input
                                      type="file"
                                      id="UpdateProfilePhoto"
                                      style={{ display: "none" }}
                                      accept="image/*"
                                      onChange={(e) => {
                                        handleSalespersonProfileImageChange(e);
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                userProfileData.userDetails.salespersonDetails
                                  .employeeImage && (
                                  <div
                                    className="profile-profilephoto-modal-buttons-sub"
                                    onClick={handleSaveSalespersonProfileImage}
                                  >
                                    <span>Save Changes</span>
                                  </div>
                                )
                              )
                            ) : null}
                            <div
                              className="profile-profilephoto-modal-buttons-sub"
                              onClick={closeProfilePhotoModal}
                            >
                              <span className="red">Cancel</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <div className="profile-form-options">
                  <div className="profile-form-options-sub">
                    <h5>LINKS</h5>
                    {userProfileData?.userDetails?.userType === "Builder" ? (
                      <span
                        onClick={() => toggleSections("userProfile")}
                        className={
                          showUserProfile
                            ? "profile-form-options-sub-spanhovered"
                            : "profile-form-options-sub-span"
                        }
                      >
                        Builder Profile
                      </span>
                    ) : userProfileData?.userDetails?.userType ===
                      "Salesperson" ? (
                      <span
                        onClick={() => toggleSections("userProfile")}
                        className={
                          showUserProfile
                            ? "profile-form-options-sub-spanhovered"
                            : "profile-form-options-sub-span"
                        }
                      >
                        Salesperson Profile
                      </span>
                    ) : userProfileData?.userDetails?.userType === "Agent" ? (
                      <span
                        onClick={() => toggleSections("userProfile")}
                        className={
                          showUserProfile
                            ? "profile-form-options-sub-spanhovered"
                            : "profile-form-options-sub-span"
                        }
                      >
                        Agent Profile
                      </span>
                    ) : userProfileData?.userDetails?.userType === "Owner" ? (
                      <span
                        onClick={() => toggleSections("userProfile")}
                        className={
                          showUserProfile
                            ? "profile-form-options-sub-spanhovered"
                            : "profile-form-options-sub-span"
                        }
                      >
                        Owner Profile
                      </span>
                    ) : null}
                    {userProfileData?.userDetails?.userType === "Builder" ? (
                      <span
                        onClick={() => toggleSections("builderCompanyDetails")}
                        className={
                          showBuilderCompanyDetails
                            ? "profile-form-options-sub-spanhovered"
                            : "profile-form-options-sub-span"
                        }
                      >
                        Builder Company Details
                      </span>
                    ) : userProfileData?.userDetails?.userType ===
                      "Salesperson" ? (
                      <span
                        onClick={() => toggleSections("builderCompanyDetails")}
                        className={
                          showBuilderCompanyDetails
                            ? "profile-form-options-sub-spanhovered"
                            : "profile-form-options-sub-span"
                        }
                      >
                        Salesperson Company Details
                      </span>
                    ) : userProfileData?.userDetails?.userType === "Agent" ? (
                      <span
                        onClick={() => toggleSections("builderCompanyDetails")}
                        className={
                          showBuilderCompanyDetails
                            ? "profile-form-options-sub-spanhovered"
                            : "profile-form-options-sub-span"
                        }
                      >
                        Agent Comapny Details
                      </span>
                    ) : null}
                    {userProfileData?.userDetails?.userType === "Builder" ? (
                      <span
                        onClick={() => toggleSections("builderOfficeDetails")}
                        className={
                          showBuilderOfficeDetails
                            ? "profile-form-options-sub-spanhovered"
                            : "profile-form-options-sub-span"
                        }
                      >
                        Builder Office Details
                      </span>
                    ) : userProfileData?.userDetails?.userType ===
                      "Salesperson" ? (
                      <span
                        onClick={() => toggleSections("builderOfficeDetails")}
                        className={
                          showBuilderOfficeDetails
                            ? "profile-form-options-sub-spanhovered"
                            : "profile-form-options-sub-span"
                        }
                      >
                        Salesperson Office Details
                      </span>
                    ) : userProfileData?.userDetails?.userType === "Agent" ? (
                      <span
                        onClick={() => toggleSections("builderOfficeDetails")}
                        className={
                          showBuilderOfficeDetails
                            ? "profile-form-options-sub-spanhovered"
                            : "profile-form-options-sub-span"
                        }
                      >
                        Agent Office Details
                      </span>
                    ) : null}
                    {userProfileData?.userDetails?.userType === "Builder" ? (
                      <span
                        onClick={() => toggleSections("builderEmployeeDetails")}
                        className={
                          showBuilderEmployeeDetails
                            ? "profile-form-options-sub-spanhovered"
                            : "profile-form-options-sub-span"
                        }
                      >
                        Builder Employee Details
                      </span>
                    ) : userProfileData?.userDetails?.userType === "Agent" ? (
                      <span
                        onClick={() => toggleSections("builderEmployeeDetails")}
                        className={
                          showBuilderEmployeeDetails
                            ? "profile-form-options-sub-spanhovered"
                            : "profile-form-options-sub-span"
                        }
                      >
                        Agent Employee Details
                      </span>
                    ) : userProfileData?.userDetails?.userType ===
                        "Salesperson" &&
                      !userProfileData?.salespersonDetails?.userAccessId ? (
                      <span
                        onClick={() => toggleSections("salespersonDocuments")}
                        className={
                          showSalespersonDocuments
                            ? "profile-form-options-sub-spanhovered"
                            : "profile-form-options-sub-span"
                        }
                      >
                        Salesperson Documents
                      </span>
                    ) : null}
                  </div>
                </div>
                <span onClick={handleDeleteAccount}>
                  Deactivate Account / Delete Account
                </span>
                <Modal
                  title="Confirmation"
                  visible={deleteAccountModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  centered
                  okText="OK"
                  cancelText="Cancel"
                >
                  <p>Are you sure you want to delete your account?</p>
                </Modal>
              </div>
              {showUserProfile && (
                <div className="profile-form-right">
                  <div className="profile-form-userDetails">
                    <div className="profile-form-userDetails-left">
                      <h2>{userData.name || "NA"}</h2>
                      <h5>
                        <span>{userProfileData?.userDetails?.userType}</span>
                      </h5>
                      <p>Profile completed: {profileCompleted}/100%</p>
                    </div>
                    <div className="profile-form-userDetails-right">
                      <h5>
                        Last logged in: <span>{lastLoginDate || "NA"}</span>
                      </h5>
                    </div>
                  </div>
                  <div className="profile-form-userProfile">
                    <div className="profile-form-userProfile-sub">
                      <h5>Name</h5>
                      <h5>Email</h5>
                      <h5>Mobile</h5>
                      <h5>WhatsApp Mobile</h5>
                      <h5>Registered As</h5>
                    </div>
                    <div className="profile-form-userProfile-sub">
                      {editingName ? (
                        <div className="profile-form-userProfile-input">
                          <input
                            type="text"
                            value={userData.name}
                            onChange={handleChangeName}
                            onBlur={handleSaveName}
                          />
                          <IoCheckmarkCircle
                            className="profile-form-userProfile-input-icon"
                            onClick={handleSaveName}
                          />
                        </div>
                      ) : (
                        <div className="profile-form-userProfile-input">
                          <span>{userData.name || "NA"}</span>

                          {userProfileData?.userDetails?.userType !==
                            "Salesperson" ||
                          (userProfileData?.userDetails?.userType ===
                            "Salesperson" &&
                            !userProfileData?.userDetails?.salespersonDetails
                              .userAccessId) ? (
                            <MdEdit
                              className="profile-form-userProfile-input-icon"
                              onClick={handleEditName}
                            />
                          ) : null}
                        </div>
                      )}
                      {/* Email Field */}
                      {editingEmail && !showPopup ? (
                        <div className="profile-form-userProfile-input">
                          <input
                            type="text"
                            value={userData.email}
                            onChange={handleChangeEmail}
                            onBlur={handleSaveEmail}
                          />
                          <IoCheckmarkCircle
                            className="profile-form-userProfile-input-icon"
                            onClick={handleSaveEmail}
                          />
                        </div>
                      ) : (
                        <div className="profile-form-userProfile-input">
                          <span>{userData.email || "NA"}</span>
                          {userProfileData?.userDetails?.userType !==
                            "Salesperson" ||
                          (userProfileData?.userDetails?.userType ===
                            "Salesperson" &&
                            !userProfileData?.userDetails?.salespersonDetails
                              .userAccessId) ? (
                            <MdEdit
                              className="profile-form-userProfile-input-icon"
                              onClick={handleEditEmail}
                            />
                          ) : null}
                        </div>
                      )}
                      {/* Mobile Field */}
                      {editingMobile && !showPopup && (
                        <div className="profile-form-userProfile-input">
                          <input
                            type="text"
                            value={userData.mobileNumber} // Changed from 'mobile' to 'mobileNumber'
                            onChange={handleChangeMobile}
                            onBlur={handleSaveMobile}
                          />
                          <IoCheckmarkCircle
                            className="profile-form-userProfile-input-icon"
                            onClick={handleSaveMobile}
                          />
                        </div>
                      )}
                      {!editingMobile && (
                        <div className="profile-form-userProfile-input">
                          {userData.mobileNumber ? (
                            <>
                              <span>
                                +{userData.mobileNumber.substring(0, 2)}{" "}
                                {userData.mobileNumber.substring(2)}
                              </span>
                              {userProfileData?.userDetails?.userType !==
                                "Salesperson" ||
                              (userProfileData?.userDetails?.userType ===
                                "Salesperson" &&
                                !userProfileData?.userDetails
                                  ?.salespersonDetails.userAccessId) ? (
                                <MdEdit
                                  className="profile-form-userProfile-input-icon"
                                  onClick={handleEditMobile}
                                />
                              ) : null}
                            </>
                          ) : (
                            <>
                              <span>NA</span>
                              {userProfileData?.userDetails?.userType !==
                                "Salesperson" ||
                              (userProfileData?.userDetails?.userType ===
                                "Salesperson" &&
                                !userProfileData?.userDetails
                                  ?.salespersonDetails.userAccessId) ? (
                                <MdEdit
                                  className="profile-form-userProfile-input-icon"
                                  onClick={handleEditMobile}
                                />
                              ) : null}
                            </>
                          )}
                        </div>
                      )}

                      {editingWhatsappNumber && !showPopup && (
                        <div className="profile-form-userProfile-input">
                          <input
                            type="text"
                            value={userData.whatsappNumber}
                            onChange={handleChangeWhatsappNumber}
                            onBlur={handleSaveWhatsappNumber}
                          />
                          <IoCheckmarkCircle
                            className="profile-form-userProfile-input-icon"
                            onClick={handleSaveWhatsappNumber}
                          />
                        </div>
                      )}
                      {!editingWhatsappNumber && (
                        <div className="profile-form-userProfile-input">
                          {userData.whatsappNumber ? (
                            <>
                              <span>
                                +{userData.whatsappNumber.substring(0, 2)}{" "}
                                {userData.whatsappNumber.substring(2)}
                              </span>
                              {userProfileData?.userDetails?.userType !==
                                "Salesperson" ||
                              (userProfileData?.userDetails?.userType ===
                                "Salesperson" &&
                                !userProfileData?.userDetails
                                  ?.salespersonDetails.userAccessId) ? (
                                <MdEdit
                                  className="profile-form-userProfile-input-icon"
                                  onClick={handleEditWhatsappNumber}
                                />
                              ) : null}
                            </>
                          ) : (
                            <>
                              <span>NA</span>
                              {userProfileData?.userDetails?.userType !==
                                "Salesperson" ||
                              (userProfileData?.userDetails?.userType ===
                                "Salesperson" &&
                                !userProfileData?.userDetails
                                  ?.salespersonDetails.userAccessId) ? (
                                <MdEdit
                                  className="profile-form-userProfile-input-icon"
                                  onClick={handleEditWhatsappNumber}
                                />
                              ) : null}
                            </>
                          )}
                        </div>
                      )}
                      <div className="profile-form-userProfile-input">
                        <span>{userProfileData?.userDetails?.userType}</span>
                      </div>
                    </div>
                  </div>
                  {userProfileData?.userDetails?.userType === "Salesperson" &&
                    userProfileData?.userDetails?.salespersonDetails
                      ?.userAccessId && (
                      <p
                        style={{
                          fontSize: "12px",
                          textAlign: "justify",
                          marginLeft: "30px",
                          marginTop: "30px",
                        }}
                      >
                        Your profile is registered by your
                        {userProfileData.employeer.userType === "Builder" ? (
                          <>
                            {" "}
                            Builder (
                            <span style={{ color: "red" }}>
                              {
                                userProfileData.employeer.builderDetails
                                  .builderFirmName
                              }
                            </span>
                            )
                          </>
                        ) : userProfileData.employeer.userType === "Agent" ? (
                          <>
                            {" "}
                            Agent (
                            <span style={{ color: "red" }}>
                              {
                                userProfileData.employeer.agentDetails
                                  .agentFirmName
                              }
                            </span>
                            )
                          </>
                        ) : (
                          "employeer"
                        )}
                        . All details mentioned in your profile will be managed
                        and changed by the builder only. You cannot edit any
                        information. If you want to change any details, please
                        contact your builder for more information.
                      </p>
                    )}
                </div>
              )}
              {showBuilderCompanyDetails && (
                <div className="profile-form-right">
                  <div className="profile-form-userDetails">
                    <div className="profile-form-userDetails-left">
                      <h2>
                        {userProfileData && userProfileData.employeer ? (
                          userProfileData?.employeer?.userType === "Builder" &&
                          userProfileData?.employeer?.builderDetails
                            ?.builderFirmName ? (
                            <>
                              {
                                userProfileData?.employeer?.builderDetails
                                  ?.builderFirmName
                              }
                            </>
                          ) : userProfileData &&
                            userProfileData?.employeer?.userType === "Agent" &&
                            userProfileData.employeer?.agentDetails
                              ?.agentFirmName ? (
                            <>
                              {
                                userProfileData.employeer?.agentDetails
                                  ?.agentFirmName
                              }
                            </>
                          ) : (
                            <>Company name</>
                          )
                        ) : userProfileData.userDetails ? (
                          userProfileData?.userDetails?.userType ===
                            "Builder" &&
                          userProfileData?.userDetails?.builderDetails
                            ?.builderFirmName ? (
                            <>
                              {
                                userProfileData?.userDetails?.builderDetails
                                  ?.builderFirmName
                              }
                            </>
                          ) : userProfileData &&
                            userProfileData?.userDetails?.userType ===
                              "Agent" &&
                            userProfileData.userDetails?.agentDetails
                              ?.agentFirmName ? (
                            <>
                              {
                                userProfileData.userDetails?.agentDetails
                                  ?.agentFirmName
                              }
                            </>
                          ) : userProfileData &&
                            userProfileData?.userDetails?.userType ===
                              "Salesperson" &&
                            userProfileData.userDetails?.salespersonDetails
                              ?.salespersonCompanyFirmName ? (
                            <>
                              {
                                userProfileData.userDetails?.salespersonDetails
                                  ?.salespersonCompanyFirmName
                              }
                            </>
                          ) : (
                            <>Company name</>
                          )
                        ) : null}
                      </h2>
                      <h5>
                        <span>
                          <MdLocationPin className="profile-form-userDetails-left-locIcon" />
                          {userProfileData && userProfileData.employeer ? (
                            userProfileData?.employeer?.userType ===
                              "Builder" &&
                            userProfileData?.employeer?.builderDetails
                              ?.builderOfficeAddress ? (
                              <span>
                                {
                                  userProfileData?.employeer?.builderDetails
                                    ?.builderOfficeAddress
                                }
                              </span>
                            ) : userProfileData &&
                              userProfileData?.employeer?.userType ===
                                "Agent" &&
                              userProfileData.employeer?.agentDetails
                                ?.agentOfficeAddress ? (
                              <span>
                                {
                                  userProfileData.employeer?.agentDetails
                                    ?.agentOfficeAddress
                                }
                              </span>
                            ) : (
                              <span>Company location</span>
                            )
                          ) : userProfileData.userDetails ? (
                            userProfileData?.userDetails?.userType ===
                              "Builder" &&
                            userProfileData?.userDetails?.builderDetails
                              ?.builderOfficeAddress ? (
                              <span>
                                {
                                  userProfileData?.userDetails?.builderDetails
                                    ?.builderOfficeAddress
                                }
                              </span>
                            ) : userProfileData &&
                              userProfileData?.userDetails?.userType ===
                                "Agent" &&
                              userProfileData.userDetails?.agentDetails
                                ?.agentOfficeAddress ? (
                              <span>
                                {
                                  userProfileData.userDetails?.agentDetails
                                    ?.agentOfficeAddress
                                }
                              </span>
                            ) : userProfileData &&
                              userProfileData?.userDetails?.userType ===
                                "Salesperson" &&
                              userProfileData.userDetails?.salespersonDetails
                                ?.salespersonCompanyOfficeAddress ? (
                              <>
                                {
                                  userProfileData.userDetails
                                    ?.salespersonDetails
                                    ?.salespersonCompanyOfficeAddress
                                }
                              </>
                            ) : (
                              <span>Company location</span>
                            )
                          ) : null}
                        </span>
                      </h5>
                    </div>
                    <div className="profile-form-userDetails-right">
                      <div className="profile-form-builderCompanyLogo">
                        <div className="profile-form-companyLogo-sub">
                          {userProfileData && userProfileData.employeer ? (
                            userProfileData.employeer.userType === "Builder" &&
                            userProfileData.employeer.builderDetails
                              ?.builderFirmLogo ? (
                              <img
                                src={`data:image/jpeg;base64,${userProfileData.employeer.builderDetails.builderFirmLogo}`}
                                alt="firmLogo"
                              />
                            ) : userProfileData.employeer.userType ===
                                "Agent" &&
                              userProfileData.employeer.agentDetails
                                ?.agentFirmLogo ? (
                              <img
                                src={`data:image/jpeg;base64,${userProfileData.employeer.agentDetails.agentFirmLogo}`}
                                alt="firmLogo"
                              />
                            ) : (
                              <TbUser className="profile-form-image-sub-icon" />
                            )
                          ) : userProfileData && userProfileData.userDetails ? (
                            userProfileData.userDetails.userType ===
                              "Builder" &&
                            userProfileData.userDetails.builderDetails
                              ?.builderFirmLogo ? (
                              <img
                                src={`data:image/jpeg;base64,${userProfileData.userDetails.builderDetails.builderFirmLogo}`}
                                alt="firmLogo"
                              />
                            ) : userProfileData.userDetails.userType ===
                                "Agent" &&
                              userProfileData.userDetails.agentDetails
                                ?.agentFirmLogo ? (
                              <img
                                src={`data:image/jpeg;base64,${userProfileData.userDetails.agentDetails.agentFirmLogo}`}
                                alt="firmLogo"
                              />
                            ) : (
                              <TbUser className="profile-form-image-sub-icon" />
                            )
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile-form-userProfile">
                    <div className="profile-form-userProfile-sub">
                      <h5>Company name</h5>
                      <h5>Company location</h5>
                      <h5>Rera number</h5>
                    </div>
                    <div className="profile-form-userProfile-sub">
                      {editingFirmName ? (
                        <div className="profile-form-userProfile-input">
                          <input
                            type="text"
                            value={
                              userProfileData.userDetails.userType === "Agent"
                                ? userProfileData.userDetails.agentDetails
                                    .agentFirmName
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? userProfileData.userDetails.builderDetails
                                    .builderFirmName
                                : userProfileData.userDetails.userType ===
                                    "salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? userProfileData.userDetails.salespersonDetails
                                    .salespersonCompanyFirmName
                                : null
                            }
                            onChange={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleChangeAgentFirmName
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleChangeBuilderFirmName
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleChangeSalespersonCompanyFirmName
                                : null
                            }
                            onBlur={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleSaveAgentFirmName
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleSaveBuilderFirmName
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleSaveSalespersonCompanyFirmName
                                : null
                            }
                          />
                          <IoCheckmarkCircle
                            className="profile-form-userProfile-input-icon"
                            onClick={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleSaveAgentFirmName
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleSaveBuilderFirmName
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleSaveSalespersonCompanyFirmName
                                : null
                            }
                          />
                        </div>
                      ) : (
                        <div className="profile-form-userProfile-input">
                          {userProfileData && userProfileData.employeer ? (
                            userProfileData?.employeer?.userType ===
                              "Builder" &&
                            userProfileData?.employeer?.builderDetails
                              ?.builderFirmName ? (
                              <span>
                                {
                                  userProfileData?.employeer?.builderDetails
                                    ?.builderFirmName
                                }
                              </span>
                            ) : userProfileData &&
                              userProfileData?.employeer?.userType ===
                                "Agent" &&
                              userProfileData.employeer?.agentDetails
                                ?.agentFirmName ? (
                              <span>
                                {
                                  userProfileData.employeer?.agentDetails
                                    ?.agentFirmName
                                }
                              </span>
                            ) : (
                              <span>Company name</span>
                            )
                          ) : userProfileData.userDetails ? (
                            userProfileData?.userDetails?.userType ===
                              "Builder" &&
                            userProfileData?.userDetails?.builderDetails
                              ?.builderFirmName ? (
                              <span>
                                {
                                  userProfileData?.userDetails?.builderDetails
                                    ?.builderFirmName
                                }
                              </span>
                            ) : userProfileData &&
                              userProfileData?.userDetails?.userType ===
                                "Agent" &&
                              userProfileData.userDetails?.agentDetails
                                ?.agentFirmName ? (
                              <span>
                                {
                                  userProfileData.userDetails?.agentDetails
                                    ?.agentFirmName
                                }
                              </span>
                            ) : userProfileData &&
                              userProfileData?.userDetails?.userType ===
                                "Salesperson" &&
                              !userProfileData?.userDetails?.salespersonDetails
                                ?.userAccessId &&
                              userProfileData.userDetails?.salespersonDetails
                                ?.salespersonCompanyFirmName ? (
                              <span>
                                {
                                  userProfileData.userDetails
                                    ?.salespersonDetails
                                    ?.salespersonCompanyFirmName
                                }
                              </span>
                            ) : (
                              <span>Company name</span>
                            )
                          ) : null}
                          {userProfileData?.userDetails?.userType !==
                            "Salesperson" ||
                          (userProfileData?.userDetails?.userType ===
                            "Salesperson" &&
                            !userProfileData?.userDetails?.salespersonDetails
                              .userAccessId) ? (
                            <MdEdit
                              className="profile-form-userProfile-input-icon"
                              onClick={
                                userProfileData.userDetails.userType === "Agent"
                                  ? handleEditAgentFirmName
                                  : userProfileData.userDetails.userType ===
                                    "Builder"
                                  ? handleEditBuilderFirmName
                                  : userProfileData.userDetails.userType ===
                                      "Salesperson" &&
                                    !userProfileData.userDetails
                                      .salespersonDetails.userAccessId
                                  ? handleEditSalespersonCompanyFirmName
                                  : null
                              }
                            />
                          ) : null}
                        </div>
                      )}
                      {editingOfficeAddress ? (
                        <div className="profile-form-userProfile-input">
                          <input
                            type="text"
                            value={
                              userProfileData.userDetails.userType === "Agent"
                                ? userProfileData.userDetails.agentDetails
                                    .agentOfficeAddress
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? userProfileData.userDetails.builderDetails
                                    .builderOfficeAddress
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? userProfileData.userDetails.salespersonDetails
                                    .salespersonCompanyOfficeAddress
                                : null
                            }
                            onChange={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleChangeAgentOfficeAddress
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleChangeBuilderOfficeAddress
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleChangeSalespersonCompanyOfficeAddress
                                : null
                            }
                            onBlur={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleSaveAgentOfficeAddress
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleSaveBuilderOfficeAddress
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleSaveSalespersonCompanyOfficeAddress
                                : null
                            }
                          />
                          <IoCheckmarkCircle
                            className="profile-form-userProfile-input-icon"
                            onClick={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleSaveAgentOfficeAddress
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleSaveBuilderOfficeAddress
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleSaveSalespersonCompanyOfficeAddress
                                : null
                            }
                          />
                        </div>
                      ) : (
                        <div className="profile-form-userProfile-input">
                          {userProfileData && userProfileData.employeer ? (
                            userProfileData?.employeer?.userType ===
                              "Builder" &&
                            userProfileData?.employeer?.builderDetails
                              ?.builderOfficeAddress ? (
                              <span>
                                {
                                  userProfileData?.employeer?.builderDetails
                                    ?.builderOfficeAddress
                                }
                              </span>
                            ) : userProfileData &&
                              userProfileData?.employeer?.userType ===
                                "Agent" &&
                              userProfileData.employeer?.agentDetails
                                ?.agentOfficeAddress ? (
                              <span>
                                {
                                  userProfileData.employeer?.agentDetails
                                    ?.agentOfficeAddress
                                }
                              </span>
                            ) : (
                              <span>Company location</span>
                            )
                          ) : userProfileData.userDetails ? (
                            userProfileData?.userDetails?.userType ===
                              "Builder" &&
                            userProfileData?.userDetails?.builderDetails
                              ?.builderOfficeAddress ? (
                              <span>
                                {
                                  userProfileData?.userDetails?.builderDetails
                                    ?.builderOfficeAddress
                                }
                              </span>
                            ) : userProfileData &&
                              userProfileData?.userDetails?.userType ===
                                "Agent" &&
                              userProfileData.userDetails?.agentDetails
                                ?.agentOfficeAddress ? (
                              <span>
                                {
                                  userProfileData.userDetails?.agentDetails
                                    ?.agentOfficeAddress
                                }
                              </span>
                            ) : userProfileData &&
                              userProfileData?.userDetails?.userType ===
                                "Salesperson" &&
                              !userProfileData.userDetails.salespersonDetails
                                .userAccessId &&
                              userProfileData.userDetails?.salespersonDetails
                                ?.salespersonCompanyOfficeAddress ? (
                              <span>
                                {
                                  userProfileData.userDetails
                                    ?.salespersonDetails
                                    ?.salespersonCompanyOfficeAddress
                                }
                              </span>
                            ) : (
                              <span>Company location</span>
                            )
                          ) : null}
                          {userProfileData?.userDetails?.userType !==
                            "Salesperson" ||
                          (userProfileData?.userDetails?.userType ===
                            "Salesperson" &&
                            !userProfileData?.userDetails?.salespersonDetails
                              .userAccessId) ? (
                            <MdEdit
                              className="profile-form-userProfile-input-icon"
                              onClick={
                                userProfileData.userDetails.userType === "Agent"
                                  ? handleEditAgentOfficeAddress
                                  : userProfileData.userDetails.userType ===
                                    "Builder"
                                  ? handleEditBuilderOfficeAddress
                                  : userProfileData.userDetails.userType ===
                                      "Salesperson" &&
                                    !userProfileData.userDetails
                                      .salespersonDetails.userAccessId
                                  ? handleEditSalespersonCompanyOfficeAddress
                                  : null
                              }
                            />
                          ) : null}
                        </div>
                      )}
                      {editingReraNo ? (
                        <div className="profile-form-userProfile-input">
                          <input
                            type="text"
                            value={
                              userProfileData.userDetails.userType === "Agent"
                                ? userProfileData.userDetails.agentDetails
                                    .agentReraNo
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? userProfileData.userDetails.builderDetails
                                    .builderReraNo
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? userProfileData.userDetails.salespersonDetails
                                    .salespersonCompanyReraNo
                                : null
                            }
                            onChange={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleChangeAgentReraNo
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleChangeBuilderReraNo
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleChangeSalespersonCompanyReraNo
                                : null
                            }
                            onBlur={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleSaveAgentReraNo
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleSaveBuilderReraNo
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleSaveSalespersonCompanyReraNo
                                : null
                            }
                          />
                          <IoCheckmarkCircle
                            className="profile-form-userProfile-input-icon"
                            onClick={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleSaveAgentReraNo
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleSaveBuilderReraNo
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleSaveSalespersonCompanyReraNo
                                : null
                            }
                          />
                        </div>
                      ) : (
                        <div className="profile-form-userProfile-input">
                          {userProfileData && userProfileData.employeer ? (
                            userProfileData.employeer.userType === "Builder" &&
                            userProfileData.employeer.builderDetails
                              ?.builderReraNo ? (
                              <span>
                                {
                                  userProfileData.employeer.builderDetails
                                    .builderReraNo
                                }
                              </span>
                            ) : userProfileData.employeer.userType ===
                                "Agent" &&
                              userProfileData.employeer.agentDetails
                                ?.agentReraNo ? (
                              <span>
                                {
                                  userProfileData.employeer.agentDetails
                                    .agentReraNo
                                }
                              </span>
                            ) : (
                              <span>Company Rera no</span>
                            )
                          ) : userProfileData && userProfileData.userDetails ? (
                            userProfileData.userDetails.userType ===
                              "Builder" &&
                            userProfileData.userDetails.builderDetails
                              ?.builderReraNo ? (
                              <span>
                                {
                                  userProfileData.userDetails.builderDetails
                                    .builderReraNo
                                }
                              </span>
                            ) : userProfileData.userDetails.userType ===
                                "Agent" &&
                              userProfileData.userDetails.agentDetails
                                ?.agentReraNo ? (
                              <span>
                                {
                                  userProfileData.userDetails.agentDetails
                                    .agentReraNo
                                }
                              </span>
                            ) : userProfileData.userDetails.userType ===
                                "Salesperson" &&
                              !userProfileData.userDetails.salespersonDetails
                                .userAccessId &&
                              userProfileData.userDetails.salespersonDetails
                                ?.salespersonCompanyReraNo ? (
                              <span>
                                {
                                  userProfileData.userDetails.salespersonDetails
                                    .salespersonCompanyReraNo
                                }
                              </span>
                            ) : (
                              <span>Company Rera no</span>
                            )
                          ) : null}

                          {userProfileData?.userDetails?.userType !==
                            "Salesperson" ||
                          (userProfileData?.userDetails?.userType ===
                            "Salesperson" &&
                            !userProfileData?.userDetails?.salespersonDetails
                              .userAccessId) ? (
                            <MdEdit
                              className="profile-form-userProfile-input-icon"
                              onClick={
                                userProfileData.userDetails.userType === "Agent"
                                  ? handleEditAgentReraNo
                                  : userProfileData.userDetails.userType ===
                                    "Builder"
                                  ? handleEditBuilderReraNo
                                  : userProfileData.userDetails.userType ===
                                      "Salesperson" &&
                                    !userProfileData.userDetails
                                      .salespersonDetails.userAccessId
                                  ? handleEditSalespersonCompanyReraNo
                                  : null
                              }
                            />
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                  {userProfileData?.userDetails?.userType === "Salesperson" &&
                    userProfileData?.userDetails?.salespersonDetails
                      ?.userAccessId && (
                      <p
                        style={{
                          fontSize: "12px",
                          textAlign: "justify",
                          marginLeft: "30px",
                          marginTop: "30px",
                        }}
                      >
                        Your profile is registered by your
                        {userProfileData.employeer.userType === "Builder" ? (
                          <>
                            {" "}
                            Builder (
                            <span style={{ color: "red" }}>
                              {
                                userProfileData.employeer.builderDetails
                                  .builderFirmName
                              }
                            </span>
                            )
                          </>
                        ) : userProfileData.employeer.userType === "Agent" ? (
                          <>
                            {" "}
                            Agent (
                            <span style={{ color: "red" }}>
                              {
                                userProfileData.employeer.agentDetails
                                  .agentFirmName
                              }
                            </span>
                            )
                          </>
                        ) : (
                          "employeer"
                        )}
                        . All details mentioned in your profile will be managed
                        and changed by the builder only. You cannot edit any
                        information. If you want to change any details, please
                        contact your builder for more information.
                      </p>
                    )}
                </div>
              )}
              {showBuilderOfficeDetails && (
                <div className="profile-form-right">
                  <div className="profile-form-userDetails">
                    <div className="profile-form-userDetails-left">
                      {userProfileData && userProfileData.employeer ? (
                        userProfileData?.employeer?.userType === "Builder" &&
                        userProfileData?.employeer?.builderDetails
                          ?.builderFirmName ? (
                          <h2>
                            {
                              userProfileData?.employeer?.builderDetails
                                ?.builderFirmName
                            }
                          </h2>
                        ) : userProfileData &&
                          userProfileData?.employeer?.userType === "Agent" &&
                          userProfileData.employeer?.agentDetails
                            ?.agentFirmName ? (
                          <h2>
                            {
                              userProfileData.employeer?.agentDetails
                                ?.agentFirmName
                            }
                          </h2>
                        ) : (
                          <h2>Company name</h2>
                        )
                      ) : userProfileData.userDetails ? (
                        userProfileData?.userDetails?.userType === "Builder" &&
                        userProfileData?.userDetails?.builderDetails
                          ?.builderFirmName ? (
                          <h2>
                            {
                              userProfileData?.userDetails?.builderDetails
                                ?.builderFirmName
                            }
                          </h2>
                        ) : userProfileData &&
                          userProfileData?.userDetails?.userType === "Agent" &&
                          userProfileData.userDetails?.agentDetails
                            ?.agentFirmName ? (
                          <h2>
                            {
                              userProfileData.userDetails?.agentDetails
                                ?.agentFirmName
                            }
                          </h2>
                        ) : userProfileData &&
                          userProfileData?.userDetails?.userType ===
                            "Salesperson" &&
                          userProfileData.userDetails?.salespersonDetails
                            ?.salespersonCompanyFirmName &&
                          !userProfileData.userDetails.salespersonDetails
                            .userAccessId ? (
                          <h2>
                            {
                              userProfileData.userDetails?.salespersonDetails
                                ?.salespersonCompanyFirmName
                            }
                          </h2>
                        ) : (
                          <h2>Company name</h2>
                        )
                      ) : null}
                      <h5>
                        <span>
                          <MdLocationPin className="profile-form-userDetails-left-locIcon" />
                          {userProfileData && userProfileData.employeer ? (
                            userProfileData?.employeer?.userType ===
                              "Builder" &&
                            userProfileData?.employeer?.builderDetails
                              ?.builderOfficeAddress ? (
                              <span>
                                {
                                  userProfileData?.employeer?.builderDetails
                                    ?.builderOfficeAddress
                                }
                              </span>
                            ) : userProfileData &&
                              userProfileData?.employeer?.userType ===
                                "Agent" &&
                              userProfileData.employeer?.agentDetails
                                ?.agentOfficeAddress ? (
                              <span>
                                {
                                  userProfileData.employeer?.agentDetails
                                    ?.agentOfficeAddress
                                }
                              </span>
                            ) : (
                              <span>Company location</span>
                            )
                          ) : userProfileData.userDetails ? (
                            userProfileData?.userDetails?.userType ===
                              "Builder" &&
                            userProfileData?.userDetails?.builderDetails
                              ?.builderOfficeAddress ? (
                              <span>
                                {
                                  userProfileData?.userDetails?.builderDetails
                                    ?.builderOfficeAddress
                                }
                              </span>
                            ) : userProfileData &&
                              userProfileData?.userDetails?.userType ===
                                "Agent" &&
                              userProfileData.userDetails?.agentDetails
                                ?.agentOfficeAddress ? (
                              <span>
                                {
                                  userProfileData.userDetails?.agentDetails
                                    ?.agentOfficeAddress
                                }
                              </span>
                            ) : userProfileData &&
                              userProfileData?.userDetails?.userType ===
                                "Salesperson" &&
                              userProfileData.userDetails?.salespersonDetails
                                ?.salespersonCompanyOfficeAddress &&
                              !userProfileData.userDetails.salespersonDetails
                                .userAccessId ? (
                              <span>
                                {
                                  userProfileData.userDetails
                                    ?.salespersonDetails
                                    ?.salespersonCompanyOfficeAddress
                                }
                              </span>
                            ) : (
                              <span>Company location</span>
                            )
                          ) : null}
                        </span>
                      </h5>
                    </div>
                    <div className="profile-form-userDetails-right"></div>
                  </div>
                  <div className="profile-form-userProfile">
                    <div className="profile-form-userProfile-sub">
                      <h5>Office name</h5>
                      <h5>Office city</h5>
                      <h5>Office locality</h5>
                      <h5>Office web site link</h5>
                    </div>
                    <div className="profile-form-userProfile-sub">
                      {editingOfficeName ? (
                        <div className="profile-form-userProfile-input">
                          <input
                            type="text"
                            value={
                              userProfileData.userDetails.userType === "Agent"
                                ? userProfileData.userDetails.agentDetails
                                    .agentOfficeName
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? userProfileData.userDetails.builderDetails
                                    .builderOfficeName
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? userProfileData.userDetails.salespersonDetails
                                    .salespersonCompanyOfficeName
                                : null
                            }
                            onChange={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleChangeAgentOfficeName
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleChangeBuilderOfficeName
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleChangeSalespersonCompanyOfficeName
                                : null
                            }
                            onBlur={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleSaveAgentOfficeName
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleSaveBuilderOfficeName
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleSaveSalespersonCompanyOfficeName
                                : null
                            }
                          />
                          <IoCheckmarkCircle
                            className="profile-form-userProfile-input-icon"
                            onClick={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleSaveAgentOfficeName
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleSaveBuilderOfficeName
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleSaveSalespersonCompanyOfficeName
                                : null
                            }
                          />
                        </div>
                      ) : (
                        <div className="profile-form-userProfile-input">
                          <span>
                            {userProfileData.userDetails.userType === "Agent" &&
                            userProfileData.userDetails.agentDetails
                              .agentOfficeName
                              ? userProfileData.userDetails.agentDetails
                                  .agentOfficeName
                              : userProfileData.userDetails.userType ===
                                  "Builder" &&
                                userProfileData.userDetails.builderDetails
                                  .builderOfficeName
                              ? userProfileData.userDetails.builderDetails
                                  .builderOfficeName
                              : userProfileData.userDetails.userType ===
                                  "Salesperson" &&
                                !userProfileData.userDetails.salespersonDetails
                                  .userAccessId &&
                                userProfileData.userDetails.salespersonDetails
                                  .salespersonCompanyOfficeName
                              ? userProfileData.userDetails.salespersonDetails
                                  .salespersonCompanyOfficeName
                              : "Office Name"}
                          </span>
                          {userProfileData?.userDetails?.userType !==
                            "Salesperson" ||
                          (userProfileData?.userDetails?.userType ===
                            "Salesperson" &&
                            !userProfileData?.userDetails?.salespersonDetails
                              .userAccessId) ? (
                            <MdEdit
                              className="profile-form-userProfile-input-icon"
                              onClick={
                                userProfileData.userDetails.userType === "Agent"
                                  ? handleEditAgentOfficeName
                                  : userProfileData.userDetails.userType ===
                                    "Builder"
                                  ? handleEditBuilderOfficeName
                                  : userProfileData.userDetails.userType ===
                                      "Salesperson" &&
                                    !userProfileData.userDetails
                                      .salespersonDetails.userAccessId
                                  ? handleEditSalespersonCompanyOfficeName
                                  : null
                              }
                            />
                          ) : null}
                        </div>
                      )}

                      {editingOfficeCity ? (
                        <div className="profile-form-userProfile-input">
                          <input
                            type="text"
                            value={
                              userProfileData.userDetails.userType === "Agent"
                                ? userProfileData?.agentDetails?.agentOfficeCity
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? userProfileData?.userDetails?.builderDetails
                                    .builderOfficeCity
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData?.userDetails
                                    ?.salespersonDetails?.userAccessId
                                ? userProfileData?.userDetails
                                    ?.salespersonDetails
                                    ?.salespersonCompanyOfficeCity
                                : null
                            }
                            onChange={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleChangeAgentOfficeCity
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleChangeBuilderOfficeCity
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleChangeSalespersonCompanyOfficeCity
                                : null
                            }
                            onBlur={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleSaveAgentOfficeCity
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleSaveBuilderOfficeCity
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleSaveSalespersonCompanyOfficeCity
                                : null
                            }
                          />
                          <IoCheckmarkCircle
                            className="profile-form-userProfile-input-icon"
                            onClick={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleSaveAgentOfficeCity
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleSaveBuilderOfficeCity
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleSaveSalespersonCompanyOfficeCity
                                : null
                            }
                          />
                        </div>
                      ) : (
                        <div className="profile-form-userProfile-input">
                          <span>
                            {userProfileData.userDetails.userType === "Agent" &&
                            userProfileData.userDetails.agentDetails
                              .agentOfficeCity
                              ? userProfileData.userDetails.agentDetails
                                  .agentOfficeCity
                              : userProfileData.userDetails.userType ===
                                  "Builder" &&
                                userProfileData.userDetails.builderDetails
                                  .builderOfficeCity
                              ? userProfileData.userDetails.builderDetails
                                  .builderOfficeCity
                              : userProfileData.userDetails.userType ===
                                  "Salesperson" &&
                                !userProfileData.userDetails.salespersonDetails
                                  .userAccessId &&
                                userProfileData.userDetails.salespersonDetails
                                  .salespersonCompanyOfficeCity
                              ? userProfileData.userDetails.salespersonDetails
                                  .salespersonCompanyOfficeCity
                              : "Office City"}
                          </span>
                          {userProfileData?.userDetails?.userType !==
                            "Salesperson" ||
                          (userProfileData?.userDetails?.userType ===
                            "Salesperson" &&
                            !userProfileData?.userDetails?.salespersonDetails
                              .userAccessId) ? (
                            <MdEdit
                              className="profile-form-userProfile-input-icon"
                              onClick={
                                userProfileData.userDetails.userType === "Agent"
                                  ? handleEditAgentOfficeCity
                                  : userProfileData.userDetails.userType ===
                                    "Builder"
                                  ? handleEditBuilderOfficeCity
                                  : userProfileData.userDetails.userType ===
                                      "Salesperson" &&
                                    !userProfileData.userDetails
                                      .salespersonDetails.userAccessId
                                  ? handleEditSalespersonCompanyOfficeCity
                                  : null
                              }
                            />
                          ) : null}
                        </div>
                      )}

                      {editingOfficeLocality ? (
                        <div className="profile-form-userProfile-input">
                          <input
                            type="text"
                            value={
                              userProfileData.userDetails.userType === "Agent"
                                ? userProfileData.userDetails.agentDetails
                                    .agentOfficeLocality
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? userProfileData.userDetails.builderDetails
                                    .builderOfficeLocality
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? userProfileData.userDetails.salespersonDetails
                                    .salespersonCompanyOfficeLocality
                                : null
                            }
                            onChange={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleChangeAgentOfficeLocality
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleChangeBuilderOfficeLocality
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleChangeSalespersonCompanyOfficeLocality
                                : null
                            }
                            onBlur={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleSaveAgentOfficeLocality
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleSaveBuilderOfficeLocality
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleSaveSalespersonCompanyOfficeLocality
                                : null
                            }
                          />
                          <IoCheckmarkCircle
                            className="profile-form-userProfile-input-icon"
                            onClick={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleSaveAgentOfficeLocality
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleSaveBuilderOfficeLocality
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleSaveSalespersonCompanyOfficeLocality
                                : null
                            }
                          />
                        </div>
                      ) : (
                        <div className="profile-form-userProfile-input">
                          <span>
                            {userProfileData.userDetails.userType === "Agent" &&
                            userProfileData.userDetails.agentDetails
                              .agentOfficeLocality
                              ? userProfileData.userDetails.agentDetails
                                  .agentOfficeLocality
                              : userProfileData.userDetails.userType ===
                                  "Builder" &&
                                userProfileData.userDetails.builderDetails
                                  .builderOfficeLocality
                              ? userProfileData.userDetails.builderDetails
                                  .builderOfficeLocality
                              : userProfileData.userDetails.userType ===
                                  "Salesperson" &&
                                !userProfileData.userDetails.salespersonDetails
                                  .userAccessId &&
                                userProfileData.userDetails.salespersonDetails
                                  .salespersonCompanyOfficeLocality
                              ? userProfileData.userDetails.salespersonDetails
                                  .salespersonCompanyOfficeLocality
                              : "Office Locality"}
                          </span>
                          {userProfileData?.userDetails?.userType !==
                            "Salesperson" ||
                          (userProfileData?.userDetails?.userType ===
                            "Salesperson" &&
                            !userProfileData?.userDetails?.salespersonDetails
                              .userAccessId) ? (
                            <MdEdit
                              className="profile-form-userProfile-input-icon"
                              onClick={
                                userProfileData.userDetails.userType === "Agent"
                                  ? handleEditAgentOfficeLocality
                                  : userProfileData.userDetails.userType ===
                                    "Builder"
                                  ? handleEditBuilderOfficeLocality
                                  : userProfileData.userDetails.userType ===
                                      "Salesperson" &&
                                    !userProfileData.userDetails
                                      .salespersonDetails.userAccessId
                                  ? handleEditSalespersonCompanyOfficeLocality
                                  : null
                              }
                            />
                          ) : null}
                        </div>
                      )}

                      {editingOfficeWebsiteLink ? (
                        <div className="profile-form-userProfile-input">
                          <input
                            type="text"
                            value={
                              userProfileData.userDetails.userType === "Agent"
                                ? userProfileData.userDetails.agentDetails
                                    .agentOfficeWebsite
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? userProfileData.userDetails.builderDetails
                                    .builderOfficeWebsite
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? userProfileData.userDetails.salespersonDetails
                                    .salespersonCompanyOfficeWebsite
                                : null
                            }
                            onChange={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleChangeAgentOfficeWebsiteLink
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleChangeBuilderOfficeWebsiteLink
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleChangeSalespersonCompanyOfficeWebsiteLink
                                : null
                            }
                            onBlur={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleSaveAgentOfficeWebsiteLink
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleSaveBuilderOfficeWebsiteLink
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleSaveSalespersonCompanyOfficeWebsiteLink
                                : null
                            }
                          />
                          <IoCheckmarkCircle
                            className="profile-form-userProfile-input-icon"
                            onClick={
                              userProfileData.userDetails.userType === "Agent"
                                ? handleSaveAgentOfficeWebsiteLink
                                : userProfileData.userDetails.userType ===
                                  "Builder"
                                ? handleSaveBuilderOfficeWebsiteLink
                                : userProfileData.userDetails.userType ===
                                    "Salesperson" &&
                                  !userProfileData.userDetails
                                    .salespersonDetails.userAccessId
                                ? handleSaveSalespersonCompanyOfficeWebsiteLink
                                : null
                            }
                          />
                        </div>
                      ) : (
                        <div className="profile-form-userProfile-input">
                          <span>
                            {userProfileData.userDetails.userType === "Agent" &&
                            userProfileData.userDetails.agentDetails
                              .agentOfficeWebsite
                              ? userProfileData.userDetails.agentDetails
                                  .agentOfficeWebsite
                              : userProfileData.userDetails.userType ===
                                  "Builder" &&
                                userProfileData.userDetails.builderDetails
                                  .builderOfficeWebsite
                              ? userProfileData.userDetails.builderDetails
                                  .builderOfficeWebsite
                              : userProfileData.userDetails.userType ===
                                  "Salesperson" &&
                                !userProfileData.userDetails.salespersonDetails
                                  .userAccessId &&
                                userProfileData.userDetails.salespersonDetails
                                  .salespersonCompanyOfficeWebsite
                              ? userProfileData.userDetails.salespersonDetails
                                  .salespersonCompanyOfficeWebsite
                              : "Office Website Link"}
                          </span>
                          {userProfileData?.userDetails?.userType !==
                            "Salesperson" ||
                          (userProfileData?.userDetails?.userType ===
                            "Salesperson" &&
                            !userProfileData?.userDetails?.salespersonDetails
                              .userAccessId) ? (
                            <MdEdit
                              className="profile-form-userProfile-input-icon"
                              onClick={
                                userProfileData.userDetails.userType === "Agent"
                                  ? handleEditAgentOfficeWebsiteLink
                                  : userProfileData.userDetails.userType ===
                                    "Builder"
                                  ? handleEditBuilderOfficeWebsiteLink
                                  : userProfileData.userDetails.userType ===
                                      "Salesperson" &&
                                    !userProfileData.userDetails
                                      .salespersonDetails.userAccessId
                                  ? handleEditSalespersonCompanyOfficeWebsiteLink
                                  : null
                              }
                            />
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                  {userProfileData?.userDetails?.userType === "Salesperson" &&
                    userProfileData?.userDetails?.salespersonDetails
                      ?.userAccessId && (
                      <p
                        style={{
                          fontSize: "12px",
                          textAlign: "justify",
                          marginLeft: "30px",
                          marginTop: "30px",
                        }}
                      >
                        Your profile is registered by your
                        {userProfileData.employeer.userType === "Builder" ? (
                          <>
                            {" "}
                            Builder (
                            <span style={{ color: "red" }}>
                              {
                                userProfileData.employeer.builderDetails
                                  .builderFirmName
                              }
                            </span>
                            )
                          </>
                        ) : userProfileData.employeer.userType === "Agent" ? (
                          <>
                            {" "}
                            Agent (
                            <span style={{ color: "red" }}>
                              {
                                userProfileData.employeer.agentDetails
                                  .agentFirmName
                              }
                            </span>
                            )
                          </>
                        ) : (
                          "employeer"
                        )}
                        . All details mentioned in your profile will be managed
                        and changed by the builder only. You cannot edit any
                        information. If you want to change any details, please
                        contact your builder for more information.
                      </p>
                    )}
                </div>
              )}
              {showBuilderEmployeeDetails && (
                <div className="profile-form-right">
                  <div className="profile-form-userDetails">
                    <div className="profile-form-userDetails-left">
                      <h2>Your Company Employees</h2>
                      <h5>
                        <span>
                          <FaUsersRectangle className="profile-form-userDetails-left-locIcon" />
                          <span>
                            {userProfileData?.employeesList?.length} Employees
                            added
                          </span>
                        </span>
                      </h5>
                    </div>
                    <div className="profile-form-userDetails-right"></div>
                  </div>
                  <div className="profile-form-employees">
                    {userProfileData?.employeesList ? (
                      userProfileData.employeesList.map((data, i) => (
                        <>
                          <div
                            className="profile-form-employees-sub"
                            key={i}
                            onClick={() => openEmployeeDataUpdateModal(data)}
                          >
                            <div className="profile-form-employee-image">
                              {updationResp ===
                                "Profile updated successfully" ||
                              userProfileData?.userDetails?.userType ===
                                "Agent" ? (
                                agentEmployeeData.employeeImage &&
                                !IsBase64Image(
                                  agentEmployeeData.employeeImage
                                ) ? (
                                  <img
                                    src={URL.createObjectURL(
                                      agentEmployeeData?.employeeImage
                                    )}
                                    alt="Employee"
                                  />
                                ) : data?.salespersonDetails?.employeeImage ? (
                                  <img
                                    src={`data:image/jpeg;base64,${data?.salespersonDetails?.employeeImage}`}
                                    alt="Employee"
                                  />
                                ) : (
                                  <FiUser className="profile-form-employee-image-icon" />
                                )
                              ) : updationResp ===
                                  "Profile updated successfully" ||
                                userProfileData?.userDetails?.userType ===
                                  "Builder" ? (
                                builderEmployeeData.employeeImage &&
                                !IsBase64Image(
                                  builderEmployeeData.employeeImage
                                ) ? (
                                  <img
                                    src={URL.createObjectURL(
                                      builderEmployeeData?.employeeImage
                                    )}
                                    alt="Employee"
                                  />
                                ) : data?.salespersonDetails?.employeeImage ? (
                                  <img
                                    src={`data:image/jpeg;base64,${data?.salespersonDetails?.employeeImage}`}
                                    alt="Employee"
                                  />
                                ) : (
                                  <FiUser className="profile-form-employee-image-icon" />
                                )
                              ) : null}
                            </div>
                            <span>
                              {data.salespersonDetails?.employeeName ||
                                "Employee Name"}
                            </span>
                            <span>
                              {data.salespersonDetails?.employeeID || "NA"}
                            </span>
                          </div>
                        </>
                      ))
                    ) : userProfileData?.userDetails?.userType === "Agent" &&
                      userProfileData.userDetails.agentDetails
                        ?.agentEmployeeList ? (
                      <span>
                        {userProfileData.userDetails.agentDetails.agentFirmName}
                      </span>
                    ) : (
                      <>
                        <span>0 Employees added</span>
                        {/* New Employee Add  */}
                        {/* <div className="profile-form-employees-sub">
                          <div className="profile-form-employee-image">
                            <IoMdAdd className="profile-form-employee-image-icon" />
                          </div>
                          <span>Add New</span>
                        </div> */}
                      </>
                    )}

                    <button
                      style={{
                        padding: "10px",
                        backgroundColor: "gray",
                        color: "white",
                        border: "1px solid gray",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      onClick={openAddEmployeeModal}
                    >
                      add employee
                    </button>
                  </div>
                </div>
              )}
              {showSalespersonDocuments && (
                <div className="profile-form-right">
                  <div className="profile-form-userDetails">
                    <div className="profile-form-userDetails-left">
                      <h2>Your Documents</h2>
                      <h5>
                        <span>
                          <FaUsersRectangle className="profile-form-userDetails-left-locIcon" />
                          <span>
                            {userProfileData?.employeesList?.length}Documents
                            added
                          </span>
                        </span>
                      </h5>
                    </div>
                    <div className="profile-form-userDetails-right"></div>
                  </div>
                  {userProfileData?.userDetails?.userType === "Salesperson" &&
                    !userProfileData?.salespersonDetails?.userAccessId && (
                      <div className="pp-inputs-sub">
                        <div className="pp-inputs-sub">
                          <select
                            className="pp-ipnuts-sub-select-agent"
                            value={salespersonData.selectedEmployeeIdType}
                            onChange={(event) => {
                              setSalespersonData((prev) => ({
                                ...prev,
                                selectedEmployeeIdType: event.target.value,
                              }));
                            }}
                          >
                            <option value="select id type ..." disabled>
                              select id type ...
                            </option>
                            <option value="Employee id">Employee id</option>
                            <option value="Aadhaar card">Aadhaar card</option>
                            <option value="Driving license">
                              Driving license
                            </option>
                            <option value="Pan card">Pan card</option>
                            <option value="Passport">Passport</option>
                            <option value="Voter id">Voter id</option>
                          </select>
                          <span>
                            <MdOutlinePermIdentity className="pp-inputs-sub-span-icon" />
                          </span>
                        </div>
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) =>
                              userProfileData?.userDetails?.userType ===
                                "Salesperson" &&
                              !userProfileData?.salespersonDetails
                                ?.userAccessId &&
                              handleSalespersonEmployeementLetter(e)
                            }
                          />
                          <span>Employeement Letter...</span>

                          {userProfileData?.userDetails?.userType ===
                            "Salesperson" &&
                            !userProfileData?.salespersonDetails
                              ?.userAccessId &&
                            salespersonData?.employeementLetter !== null && (
                              <div className="selected-image-container">
                                <>
                                  {" "}
                                  {/* Use fragment to avoid unnecessary wrapper */}
                                  {salespersonData?.employeementLetter &&
                                  IsBase64Image(
                                    salespersonData?.employeementLetter
                                  ) ? (
                                    <img
                                      src={`data:image/jpeg;base64,${salespersonData?.employeementLetter}`}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  ) : (
                                    <>
                                      {" "}
                                      {/* Use fragment to avoid unnecessary wrapper */}
                                      <img
                                        src={URL.createObjectURL(
                                          salespersonData?.employeementLetter
                                        )} // Assuming `builderEmployeeData.employeeImage` is a Blob
                                        alt="Selected"
                                        className="selected-image"
                                      />
                                      <IoIosClose
                                        onClick={
                                          removeSalespersonEmployeementLetter
                                        }
                                        className="remove-icon"
                                      />
                                    </>
                                  )}
                                </>
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                  {/* Selected Employee ID - Employee ID */}
                  {salespersonData.selectedEmployeeIdType === "Employee id" &&
                    !userProfileData?.salespersonDetails?.userAccessId && (
                      <div className="pp-agent-employee-id">
                        <div className="pp-inputs-sub">
                          <input
                            type="text"
                            placeholder="employee id ..."
                            name="employeeID"
                            onChange={(e) =>
                              userProfileData?.userDetails?.userType ===
                                "Salesperson" &&
                              !userProfileData?.salespersonDetails
                                ?.userAccessId &&
                              handleSalespersonData(e)
                            }
                            value={
                              userProfileData?.userDetails?.userType ===
                                "Salesperson" &&
                              !userProfileData?.salespersonDetails
                                ?.userAccessId &&
                              salespersonData.employeeID
                            }
                          ></input>
                          <span>
                            <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                          </span>
                        </div>
                        <div className="pp-inputs-sub">
                          <div className="file-input-wrapper">
                            <input
                              type="file"
                              className="pp-dealer-employee-input-image"
                              onChange={(e) =>
                                userProfileData?.userDetails?.userType ===
                                  "Salesperson" &&
                                !userProfileData?.salespersonDetails
                                  ?.userAccessId &&
                                handleSalespersonEmployeeIdCardImage(e)
                              }
                            />
                            <span>choose employee ID Card photo ...</span>

                            {userProfileData?.userDetails?.userType ===
                              "Salesperson" &&
                              salespersonData?.employeeIdCardImage !== null && (
                                <div className="selected-image-container">
                                  <>
                                    {" "}
                                    {/* Use fragment to avoid unnecessary wrapper */}
                                    {salespersonData?.employeeIdCardImage &&
                                    IsBase64Image(
                                      salespersonData?.employeeIdCardImage
                                    ) ? (
                                      <img
                                        src={`data:image/jpeg;base64,${salespersonData?.employeeIdCardImage}`}
                                        alt="Selected"
                                        className="selected-image"
                                      />
                                    ) : (
                                      <>
                                        {" "}
                                        {/* Use fragment to avoid unnecessary wrapper */}
                                        <img
                                          src={URL.createObjectURL(
                                            salespersonData.employeeIdCardImage
                                          )} // Assuming `employeeIdCardImage` is a Blob
                                          alt="Selected"
                                          className="selected-image"
                                        />
                                        <IoIosClose
                                          onClick={removeSalespersonIdCardImage}
                                          className="remove-icon"
                                        />
                                      </>
                                    )}
                                  </>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                  {/* Selected Employee ID - Aadhaar card */}
                  {salespersonData.selectedEmployeeIdType ===
                    "Aadhaar card" && (
                    <div className="pp-agent-employee-id">
                      <div className="pp-inputs-sub">
                        <input
                          type="text"
                          placeholder="aadhaar card (last 4 digits)"
                          name="employeeAadhaarcardNo"
                          onChange={(e) => handleSalespersonData(e)}
                          value={salespersonData.employeeAadhaarcardNo}
                        ></input>
                        <span>
                          <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                        </span>
                      </div>
                      <div className="pp-inputs-sub">
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            name="employeeAadhaarcardFrontImage"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) =>
                              handleSalespersonAadhaarcardImage(e)
                            }
                          />
                          <span>choose aadhaar front photo ...</span>

                          {salespersonData.employeeAadhaarcardFrontImage !==
                            null && (
                            <div className="selected-image-container">
                              {salespersonData.employeeAadhaarcardFrontImage &&
                              IsBase64Image(
                                salespersonData.employeeAadhaarcardFrontImage
                              ) ? (
                                <img
                                  src={`data:image/jpeg;base64,${salespersonData.employeeAadhaarcardFrontImage}`}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              ) : (
                                <img
                                  src={URL.createObjectURL(
                                    salespersonData.employeeAadhaarcardFrontImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeSalespersonAadhaarcardImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            name="employeeAadhaarcardBackImage"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) =>
                              handleSalespersonAadhaarcardImage(e)
                            }
                          />
                          <span>choose aadhaar back photo ...</span>

                          {salespersonData.employeeAadhaarcardBackImage !==
                            null && (
                            <div className="selected-image-container">
                              {salespersonData.employeeAadhaarcardBackImage &&
                              IsBase64Image(
                                salespersonData.employeeAadhaarcardBackImage
                              ) ? (
                                <img
                                  src={`data:image/jpeg;base64,${salespersonData.employeeAadhaarcardBackImage}`}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              ) : (
                                <img
                                  src={URL.createObjectURL(
                                    salespersonData.employeeAadhaarcardBackImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeSalespersonAadhaarcardImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Selected Employee ID - Driving license */}
                  {salespersonData.selectedEmployeeIdType ===
                    "Driving license" && (
                    <div className="pp-agent-employee-id">
                      <div className="pp-inputs-sub">
                        <input
                          type="text"
                          placeholder="driving license no ..."
                          name="employeeDrivinglicenseNo"
                          onChange={(e) => handleSalespersonData(e)}
                          value={salespersonData.employeeDrivinglicenseNo}
                        ></input>
                        <span>
                          <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                        </span>
                      </div>
                      <div className="pp-inputs-sub">
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            name="employeeDrivinglicenseFrontImage"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) => handleSalespersonDrivingLicense(e)}
                          />
                          <span>choose driving license front ...</span>

                          {salespersonData.employeeDrivinglicenseFrontImage !==
                            null && (
                            <div className="selected-image-container">
                              {salespersonData.employeeDrivinglicenseFrontImage &&
                              IsBase64Image(
                                salespersonData.employeeDrivinglicenseFrontImage
                              ) ? (
                                <img
                                  src={`data:image/jpeg;base64,${salespersonData.employeeDrivinglicenseFrontImage}`}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              ) : (
                                <img
                                  src={URL.createObjectURL(
                                    salespersonData.employeeDrivinglicenseFrontImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeSalespersonDrivingLicenseImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            name="employeeDrivinglicenseBackImage"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) => handleSalespersonDrivingLicense(e)}
                          />
                          <span>choose driving license back ...</span>

                          {salespersonData.employeeDrivinglicenseBackImage !==
                            null && (
                            <div className="selected-image-container">
                              {salespersonData.employeeDrivinglicenseBackImage &&
                              IsBase64Image(
                                salespersonData.employeeDrivinglicenseBackImage
                              ) ? (
                                <img
                                  src={`data:image/jpeg;base64,${salespersonData.employeeDrivinglicenseBackImage}`}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              ) : (
                                <img
                                  src={URL.createObjectURL(
                                    salespersonData.employeeDrivinglicenseBackImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeSalespersonDrivingLicenseImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Selected Employee ID - Pan card */}
                  {salespersonData.selectedEmployeeIdType === "Pan card" && (
                    <div className="pp-agent-employee-id">
                      <div className="pp-inputs-sub">
                        <input
                          type="text"
                          placeholder="pan card no ..."
                          name="employeePancardNo"
                          onChange={(e) => handleSalespersonData(e)}
                          value={salespersonData.employeePancardNo}
                        ></input>
                        <span>
                          <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                        </span>
                      </div>
                      <div className="pp-inputs-sub">
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) => handleSalespersonPanCardImage(e)}
                          />
                          <span>choose pan card photo ...</span>

                          {salespersonData.employeePancardImage !== null && (
                            <div className="selected-image-container">
                              {salespersonData.employeePancardImage &&
                              IsBase64Image(
                                salespersonData.employeePancardImage
                              ) ? (
                                <img
                                  src={`data:image/jpeg;base64,${salespersonData.employeePancardImage}`}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              ) : (
                                <img
                                  src={URL.createObjectURL(
                                    salespersonData.employeePancardImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeSalespersonPanCardImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Selected Employee ID - Passport */}
                  {salespersonData.selectedEmployeeIdType === "Passport" && (
                    <div className="pp-agent-employee-id">
                      <div className="pp-inputs-sub">
                        <input
                          type="text"
                          placeholder="passport id ..."
                          name="employeePassportNo"
                          onChange={(e) => handleSalespersonData(e)}
                          value={salespersonData.employeePassportNo}
                        ></input>
                        <span>
                          <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                        </span>
                      </div>
                      <div className="pp-inputs-sub">
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) => handleSalespersonPassportImage(e)}
                          />
                          <span>choose passport photo ...</span>

                          {salespersonData.employeePassportImage !== null && (
                            <div className="selected-image-container">
                              {salespersonData.employeePassportImage &&
                              IsBase64Image(
                                salespersonData.employeePassportImage
                              ) ? (
                                <img
                                  src={`data:image/jpeg;base64,${salespersonData.employeePassportImage}`}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              ) : (
                                <img
                                  src={URL.createObjectURL(
                                    salespersonData.employeePassportImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeSalespersonPaasportImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Selected Employee ID - Voter */}
                  {salespersonData.selectedEmployeeIdType === "Voter id" && (
                    <div className="pp-agent-employee-id">
                      <div className="pp-inputs-sub">
                        <input
                          type="text"
                          placeholder="voter id ..."
                          name="employeeVoterIdNo"
                          onChange={(e) => handleSalespersonData(e)}
                          value={salespersonData.employeeVoterIdNo}
                        ></input>
                        <span>
                          <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                        </span>
                      </div>
                      <div className="pp-inputs-sub">
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            name="employeeVoterIdFrontImage"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) => handleSalespersonVoterIdImage(e)}
                          />
                          <span>choose voter front photo ...</span>

                          {salespersonData.employeeVoterIdFrontImage !==
                            null && (
                            <div className="selected-image-container">
                              {salespersonData.employeeVoterIdFrontImage &&
                              IsBase64Image(
                                salespersonData.employeeVoterIdFrontImage
                              ) ? (
                                <img
                                  src={`data:image/jpeg;base64,${salespersonData.employeeVoterIdFrontImage}`}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              ) : (
                                <img
                                  src={URL.createObjectURL(
                                    salespersonData.employeeVoterIdFrontImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeSalespersonVoterIdImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            name="employeeVoterIdBackImage"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) => handleSalespersonVoterIdImage(e)}
                          />
                          <span>choose voter back photo ...</span>

                          {salespersonData.employeeVoterIdBackImage !==
                            null && (
                            <div className="selected-image-container">
                              {salespersonData.employeeVoterIdBackImage &&
                              IsBase64Image(
                                salespersonData.employeeVoterIdBackImage
                              ) ? (
                                <img
                                  src={`data:image/jpeg;base64,${salespersonData.employeeVoterIdBackImage}`}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              ) : (
                                <img
                                  src={URL.createObjectURL(
                                    salespersonData.employeeVoterIdBackImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeSalespersonVoterIdImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <button
                    className="button"
                    style={{
                      color: "white",
                      backgroundColor: "gray",
                      padding: "10px",
                      borderRadius: "10%",
                      border: "1px solid gray",
                      cursor: "pointer",
                    }}
                    onClick={saveSalespersonDetails}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Custom popup for 409 errors */}
      {showPopup && (
        <>
          <div className="profile-custom-popup-overlay"></div>
          <div className="profile-custom-popup">
            <div className="profile-custom-popup-sub">
              <p>{popupMessage}</p>
              <button onClick={() => setShowPopup(false)}>Close</button>
            </div>
          </div>
        </>
      )}
      {/* Employee Data Update Modal */}
      {isEmployeeDataUpdateModalVisible && (
        <div
          className="employee-modal-overlay"
          onClick={closeEmployeeDataUpdateModal}
        >
          <div
            className="employee-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <IoMdClose
              className="employee-modal-close-icon"
              onClick={closeEmployeeDataUpdateModal}
            />
            {selectedEmployee && (
              <div className="employee-details-form">
                <div className="pp-type">
                  <h5>
                    Add details of employee/s{" "}
                    <IoHelpCircleOutline className="pp-type-icon" />
                  </h5>
                  <div className="pp-type-sub">
                    <div className="pp-dealer-employee">
                      <div className="pp-inputs-sub">
                        <input
                          type="text"
                          placeholder="employee name ..."
                          name="employeeName"
                          onChange={(e) =>
                            userProfileData?.userDetails?.userType === "Agent"
                              ? handleAgentEmployeeData(e)
                              : userProfileData?.userDetails?.userType ===
                                "Builder"
                              ? handleBuilderEmployeeData(e)
                              : null
                          }
                          value={
                            userProfileData?.userDetails?.userType === "Agent"
                              ? agentEmployeeData.employeeName
                              : userProfileData?.userDetails?.userType ===
                                "Builder"
                              ? builderEmployeeData.employeeName
                              : null
                          }
                        ></input>
                        <span>
                          <MdDriveFileRenameOutline className="pp-inputs-sub-span-icon" />
                        </span>
                      </div>

                      <div className="pp-inputs-sub">
                        <input
                          type="text"
                          placeholder="employee mobile ..."
                          name="employeeMobileNo"
                          onChange={(e) =>
                            userProfileData?.userDetails?.userType === "Agent"
                              ? handleAgentEmployeeData(e)
                              : userProfileData?.userDetails?.userType ===
                                "Builder"
                              ? handleBuilderEmployeeData(e)
                              : null
                          }
                          value={
                            userProfileData?.userDetails?.userType === "Agent"
                              ? agentEmployeeData.employeeMobileNo
                              : userProfileData?.userDetails?.userType ===
                                "Builder"
                              ? builderEmployeeData.employeeMobileNo
                              : null
                          }
                        ></input>
                        <span>
                          <MdOutlinePhoneEnabled className="pp-inputs-sub-span-icon" />
                        </span>
                      </div>
                      <div className="pp-inputs-sub">
                        <input
                          type="text"
                          placeholder="employee email ..."
                          name="employeeEmail"
                          onChange={(e) =>
                            userProfileData?.userDetails?.userType === "Agent"
                              ? handleAgentEmployeeData(e)
                              : userProfileData?.userDetails?.userType ===
                                "Builder"
                              ? handleBuilderEmployeeData(e)
                              : null
                          }
                          value={
                            userProfileData?.userDetails?.userType === "Agent"
                              ? agentEmployeeData.employeeEmail
                              : userProfileData?.userDetails?.userType ===
                                "Builder"
                              ? builderEmployeeData.employeeEmail
                              : null
                          }
                        ></input>
                        <span>
                          <MdOutlineEmail className="pp-inputs-sub-span-icon" />
                        </span>
                      </div>
                      <div className="pp-inputs-sub">
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) =>
                              userProfileData?.userDetails?.userType === "Agent"
                                ? handleAgentEmployeeImage(e)
                                : userProfileData?.userDetails?.userType ===
                                  "Builder"
                                ? handleBuilderEmployeeImage(e)
                                : null
                            }
                          />
                          <span>choose employee photo ...</span>

                          {userProfileData?.userDetails?.userType === "Agent" &&
                            agentEmployeeData?.employeeImage !== null && (
                              <div className="selected-image-container">
                                <>
                                  {" "}
                                  {/* Use fragment to avoid unnecessary wrapper */}
                                  {agentEmployeeData?.employeeImage &&
                                  IsBase64Image(
                                    agentEmployeeData?.employeeImage
                                  ) ? (
                                    <img
                                      src={`data:image/jpeg;base64,${agentEmployeeData?.employeeImage}`}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  ) : (
                                    <>
                                      {" "}
                                      {/* Use fragment to avoid unnecessary wrapper */}
                                      <img
                                        src={URL.createObjectURL(
                                          agentEmployeeData.employeeImage
                                        )} // Assuming `agentEmployeeData.employeeImage` is a Blob
                                        alt="Selected"
                                        className="selected-image"
                                      />
                                      <IoIosClose
                                        onClick={removeAgentEmployeeImage}
                                        className="remove-icon"
                                      />
                                    </>
                                  )}
                                </>
                              </div>
                            )}
                          {userProfileData?.userDetails?.userType ===
                            "Builder" &&
                            builderEmployeeData?.employeeImage !== null && (
                              <div className="selected-image-container">
                                <>
                                  {" "}
                                  {/* Use fragment to avoid unnecessary wrapper */}
                                  {builderEmployeeData?.employeeImage &&
                                  IsBase64Image(
                                    builderEmployeeData?.employeeImage
                                  ) ? (
                                    <img
                                      src={`data:image/jpeg;base64,${builderEmployeeData?.employeeImage}`}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  ) : (
                                    <>
                                      {" "}
                                      {/* Use fragment to avoid unnecessary wrapper */}
                                      <img
                                        src={URL.createObjectURL(
                                          builderEmployeeData.employeeImage
                                        )} // Assuming `builderEmployeeData.employeeImage` is a Blob
                                        alt="Selected"
                                        className="selected-image"
                                      />
                                      <IoIosClose
                                        onClick={removeBuilderEmployeeImage}
                                        className="remove-icon"
                                      />
                                    </>
                                  )}
                                </>
                              </div>
                            )}
                        </div>
                      </div>
                      {userProfileData?.userDetails?.userType === "Agent" && (
                        <div className="pp-inputs-sub">
                          <select
                            className="pp-ipnuts-sub-select-agent"
                            value={agentEmployeeData.selectedEmployeeIdType}
                            onChange={(event) => {
                              setAgentEmployeeData((prev) => ({
                                ...prev,
                                selectedEmployeeIdType: event.target.value,
                              }));
                            }}
                          >
                            <option value="select id type ..." disabled>
                              select id type ...
                            </option>
                            <option value="Employee id">Employee id</option>
                            <option value="Aadhaar card">Aadhaar card</option>
                            <option value="Driving license">
                              Driving license
                            </option>
                            <option value="Pan card">Pan card</option>
                            <option value="Passport">Passport</option>
                            <option value="Voter id">Voter id</option>
                          </select>
                          <span>
                            <MdOutlinePermIdentity className="pp-inputs-sub-span-icon" />
                          </span>
                        </div>
                      )}
                      {/* Selected Employee ID - Employee ID */}
                      {(agentEmployeeData.selectedEmployeeIdType ===
                        "Employee id" ||
                        builderEmployeeData.selectedEmployeeIdType ===
                          "Employee id") && (
                        <div className="pp-agent-employee-id">
                          <div className="pp-inputs-sub">
                            <input
                              type="text"
                              placeholder="employee id ..."
                              name="employeeID"
                              onChange={(e) =>
                                userProfileData?.userDetails?.userType ===
                                "Agent"
                                  ? handleAgentEmployeeData(e)
                                  : userProfileData?.userDetails?.userType ===
                                    "Builder"
                                  ? handleBuilderEmployeeData(e)
                                  : null
                              }
                              value={
                                userProfileData?.userDetails?.userType ===
                                "Agent"
                                  ? agentEmployeeData.employeeID
                                  : userProfileData?.userDetails?.userType ===
                                    "Builder"
                                  ? builderEmployeeData.employeeID
                                  : null
                              }
                            ></input>
                            <span>
                              <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                            </span>
                          </div>
                          <div className="pp-inputs-sub">
                            <div className="file-input-wrapper">
                              <input
                                type="file"
                                className="pp-dealer-employee-input-image"
                                onChange={(e) =>
                                  userProfileData?.userDetails?.userType ===
                                  "Agent"
                                    ? handleAgentEmployeeIdCardImage(e)
                                    : userProfileData?.userDetails?.userType ===
                                      "Builder"
                                    ? handleBuilderEmployeeIdCardImage(e)
                                    : null
                                }
                              />
                              <span>choose employee ID Card photo ...</span>

                              {userProfileData?.userDetails?.userType ===
                                "Agent" &&
                                agentEmployeeData?.employeeIdCardImage !==
                                  null && (
                                  <div className="selected-image-container">
                                    <>
                                      {" "}
                                      {/* Use fragment to avoid unnecessary wrapper */}
                                      {agentEmployeeData?.employeeIdCardImage &&
                                      IsBase64Image(
                                        agentEmployeeData?.employeeIdCardImage
                                      ) ? (
                                        <img
                                          src={`data:image/jpeg;base64,${agentEmployeeData?.employeeIdCardImage}`}
                                          alt="Selected"
                                          className="selected-image"
                                        />
                                      ) : (
                                        <>
                                          {" "}
                                          {/* Use fragment to avoid unnecessary wrapper */}
                                          <img
                                            src={URL.createObjectURL(
                                              agentEmployeeData.employeeIdCardImage
                                            )} // Assuming `employeeIdCardImage` is a Blob
                                            alt="Selected"
                                            className="selected-image"
                                          />
                                          <IoIosClose
                                            onClick={
                                              removeAgentEmployeeIdCardImage
                                            }
                                            className="remove-icon"
                                          />
                                        </>
                                      )}
                                    </>
                                  </div>
                                )}
                              {userProfileData?.userDetails?.userType ===
                                "Builder" &&
                                builderEmployeeData?.employeeIdCardImage !==
                                  null && (
                                  <div className="selected-image-container">
                                    <>
                                      {" "}
                                      {/* Use fragment to avoid unnecessary wrapper */}
                                      {builderEmployeeData?.employeeIdCardImage &&
                                      IsBase64Image(
                                        builderEmployeeData?.employeeIdCardImage
                                      ) ? (
                                        <img
                                          src={`data:image/jpeg;base64,${builderEmployeeData?.employeeIdCardImage}`}
                                          alt="Selected"
                                          className="selected-image"
                                        />
                                      ) : (
                                        <>
                                          {" "}
                                          {/* Use fragment to avoid unnecessary wrapper */}
                                          <img
                                            src={URL.createObjectURL(
                                              builderEmployeeData.employeeIdCardImage
                                            )} // Assuming `employeeIdCardImage` is a Blob
                                            alt="Selected"
                                            className="selected-image"
                                          />
                                          <IoIosClose
                                            onClick={
                                              removeBuilderEmployeeIdCardImage
                                            }
                                            className="remove-icon"
                                          />
                                        </>
                                      )}
                                    </>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Selected Employee ID - Aadhaar card */}
                      {agentEmployeeData.selectedEmployeeIdType ===
                        "Aadhaar card" && (
                        <div className="pp-agent-employee-id">
                          <div className="pp-inputs-sub">
                            <input
                              type="text"
                              placeholder="aadhaar card (last 4 digits)"
                              name="employeeAadhaarcardNo"
                              onChange={(e) => handleAgentEmployeeData(e)}
                              value={agentEmployeeData.employeeAadhaarcardNo}
                            ></input>
                            <span>
                              <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                            </span>
                          </div>
                          <div className="pp-inputs-sub">
                            <div className="file-input-wrapper">
                              <input
                                type="file"
                                name="employeeAadhaarcardFrontImage"
                                className="pp-dealer-employee-input-image"
                                onChange={(e) =>
                                  handleAgentEmployeeAadhaarcardImage(e)
                                }
                              />
                              <span>choose aadhaar front photo ...</span>

                              {agentEmployeeData.employeeAadhaarcardFrontImage !==
                                null && (
                                <div className="selected-image-container">
                                  {agentEmployeeData.employeeAadhaarcardFrontImage &&
                                  IsBase64Image(
                                    agentEmployeeData.employeeAadhaarcardFrontImage
                                  ) ? (
                                    <img
                                      src={`data:image/jpeg;base64,${agentEmployeeData.employeeAadhaarcardFrontImage}`}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(
                                        agentEmployeeData.employeeAadhaarcardFrontImage
                                      )}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  )}
                                  <IoIosClose
                                    onClick={
                                      removeAgentEmployeeAadhaarcardImage
                                    }
                                    className="remove-icon"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="file-input-wrapper">
                              <input
                                type="file"
                                name="employeeAadhaarcardBackImage"
                                className="pp-dealer-employee-input-image"
                                onChange={(e) =>
                                  handleAgentEmployeeAadhaarcardImage(e)
                                }
                              />
                              <span>choose aadhaar back photo ...</span>

                              {agentEmployeeData.employeeAadhaarcardBackImage !==
                                null && (
                                <div className="selected-image-container">
                                  {agentEmployeeData.employeeAadhaarcardBackImage &&
                                  IsBase64Image(
                                    agentEmployeeData.employeeAadhaarcardBackImage
                                  ) ? (
                                    <img
                                      src={`data:image/jpeg;base64,${agentEmployeeData.employeeAadhaarcardBackImage}`}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(
                                        agentEmployeeData.employeeAadhaarcardBackImage
                                      )}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  )}
                                  <IoIosClose
                                    onClick={
                                      removeAgentEmployeeAadhaarcardImage
                                    }
                                    className="remove-icon"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Selected Employee ID - Driving license */}
                      {agentEmployeeData.selectedEmployeeIdType ===
                        "Driving license" && (
                        <div className="pp-agent-employee-id">
                          <div className="pp-inputs-sub">
                            <input
                              type="text"
                              placeholder="driving license no ..."
                              name="employeeDrivinglicenseNo"
                              onChange={(e) => handleAgentEmployeeData(e)}
                              value={agentEmployeeData.employeeDrivinglicenseNo}
                            ></input>
                            <span>
                              <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                            </span>
                          </div>
                          <div className="pp-inputs-sub">
                            <div className="file-input-wrapper">
                              <input
                                type="file"
                                name="employeeDrivinglicenseFrontImage"
                                className="pp-dealer-employee-input-image"
                                onChange={(e) =>
                                  handleAgentEmployeeDrivingLicense(e)
                                }
                              />
                              <span>choose driving license front ...</span>

                              {agentEmployeeData.employeeDrivinglicenseFrontImage !==
                                null && (
                                <div className="selected-image-container">
                                  {agentEmployeeData.employeeDrivinglicenseFrontImage &&
                                  IsBase64Image(
                                    agentEmployeeData.employeeDrivinglicenseFrontImage
                                  ) ? (
                                    <img
                                      src={`data:image/jpeg;base64,${agentEmployeeData.employeeDrivinglicenseFrontImage}`}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(
                                        agentEmployeeData.employeeDrivinglicenseFrontImage
                                      )}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  )}
                                  <IoIosClose
                                    onClick={
                                      removeAgentEmployeeDrivingLicenseImage
                                    }
                                    className="remove-icon"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="file-input-wrapper">
                              <input
                                type="file"
                                name="employeeDrivinglicenseBackImage"
                                className="pp-dealer-employee-input-image"
                                onChange={(e) =>
                                  handleAgentEmployeeDrivingLicense(e)
                                }
                              />
                              <span>choose driving license back ...</span>

                              {agentEmployeeData.employeeDrivinglicenseBackImage !==
                                null && (
                                <div className="selected-image-container">
                                  {agentEmployeeData.employeeDrivinglicenseBackImage &&
                                  IsBase64Image(
                                    agentEmployeeData.employeeDrivinglicenseBackImage
                                  ) ? (
                                    <img
                                      src={`data:image/jpeg;base64,${agentEmployeeData.employeeDrivinglicenseBackImage}`}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(
                                        agentEmployeeData.employeeDrivinglicenseBackImage
                                      )}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  )}
                                  <IoIosClose
                                    onClick={
                                      removeAgentEmployeeDrivingLicenseImage
                                    }
                                    className="remove-icon"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Selected Employee ID - Pan card */}
                      {agentEmployeeData.selectedEmployeeIdType ===
                        "Pan card" && (
                        <div className="pp-agent-employee-id">
                          <div className="pp-inputs-sub">
                            <input
                              type="text"
                              placeholder="pan card no ..."
                              name="employeePancardNo"
                              onChange={(e) => handleAgentEmployeeData(e)}
                              value={agentEmployeeData.employeePancardNo}
                            ></input>
                            <span>
                              <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                            </span>
                          </div>
                          <div className="pp-inputs-sub">
                            <div className="file-input-wrapper">
                              <input
                                type="file"
                                className="pp-dealer-employee-input-image"
                                onChange={(e) =>
                                  handleAgentEmployeePanCardImage(e)
                                }
                              />
                              <span>choose pan card photo ...</span>

                              {agentEmployeeData.employeePancardImage !==
                                null && (
                                <div className="selected-image-container">
                                  {agentEmployeeData.employeePancardImage &&
                                  IsBase64Image(
                                    agentEmployeeData.employeePancardImage
                                  ) ? (
                                    <img
                                      src={`data:image/jpeg;base64,${agentEmployeeData.employeePancardImage}`}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(
                                        agentEmployeeData.employeePancardImage
                                      )}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  )}
                                  <IoIosClose
                                    onClick={removeAgentEmployeePanCardImage}
                                    className="remove-icon"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Selected Employee ID - Passport */}
                      {agentEmployeeData.selectedEmployeeIdType ===
                        "Passport" && (
                        <div className="pp-agent-employee-id">
                          <div className="pp-inputs-sub">
                            <input
                              type="text"
                              placeholder="passport id ..."
                              name="employeePassportNo"
                              onChange={(e) => handleAgentEmployeeData(e)}
                              value={agentEmployeeData.employeePassportNo}
                            ></input>
                            <span>
                              <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                            </span>
                          </div>
                          <div className="pp-inputs-sub">
                            <div className="file-input-wrapper">
                              <input
                                type="file"
                                className="pp-dealer-employee-input-image"
                                onChange={(e) =>
                                  handleAgentEmployeePassportImage(e)
                                }
                              />
                              <span>choose passport photo ...</span>

                              {agentEmployeeData.employeePassportImage !==
                                null && (
                                <div className="selected-image-container">
                                  {agentEmployeeData.employeePassportImage &&
                                  IsBase64Image(
                                    agentEmployeeData.employeePassportImage
                                  ) ? (
                                    <img
                                      src={`data:image/jpeg;base64,${agentEmployeeData.employeePassportImage}`}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(
                                        agentEmployeeData.employeePassportImage
                                      )}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  )}
                                  <IoIosClose
                                    onClick={removeAgentEmployeePaasportImage}
                                    className="remove-icon"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Selected Employee ID - Voter */}
                      {agentEmployeeData.selectedEmployeeIdType ===
                        "Voter id" && (
                        <div className="pp-agent-employee-id">
                          <div className="pp-inputs-sub">
                            <input
                              type="text"
                              placeholder="voter id ..."
                              name="employeeVoterIdNo"
                              onChange={(e) => handleAgentEmployeeData(e)}
                              value={agentEmployeeData.employeeVoterIdNo}
                            ></input>
                            <span>
                              <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                            </span>
                          </div>
                          <div className="pp-inputs-sub">
                            <div className="file-input-wrapper">
                              <input
                                type="file"
                                name="employeeVoterIdFrontImage"
                                className="pp-dealer-employee-input-image"
                                onChange={(e) =>
                                  handleAgentEmployeeVoterIdImage(e)
                                }
                              />
                              <span>choose voter front photo ...</span>

                              {agentEmployeeData.employeeVoterIdFrontImage !==
                                null && (
                                <div className="selected-image-container">
                                  {agentEmployeeData.employeeVoterIdFrontImage &&
                                  IsBase64Image(
                                    agentEmployeeData.employeeVoterIdFrontImage
                                  ) ? (
                                    <img
                                      src={`data:image/jpeg;base64,${agentEmployeeData.employeeVoterIdFrontImage}`}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(
                                        agentEmployeeData.employeeVoterIdFrontImage
                                      )}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  )}
                                  <IoIosClose
                                    onClick={removeAgentEmployeeVoterIdImage}
                                    className="remove-icon"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="file-input-wrapper">
                              <input
                                type="file"
                                name="employeeVoterIdBackImage"
                                className="pp-dealer-employee-input-image"
                                onChange={(e) =>
                                  handleAgentEmployeeVoterIdImage(e)
                                }
                              />
                              <span>choose voter back photo ...</span>

                              {agentEmployeeData.employeeVoterIdBackImage !==
                                null && (
                                <div className="selected-image-container">
                                  {agentEmployeeData.employeeVoterIdBackImage &&
                                  IsBase64Image(
                                    agentEmployeeData.employeeVoterIdBackImage
                                  ) ? (
                                    <img
                                      src={`data:image/jpeg;base64,${agentEmployeeData.employeeVoterIdBackImage}`}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(
                                        agentEmployeeData.employeeVoterIdBackImage
                                      )}
                                      alt="Selected"
                                      className="selected-image"
                                    />
                                  )}
                                  <IoIosClose
                                    onClick={removeAgentEmployeeVoterIdImage}
                                    className="remove-icon"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="pp-inputs-sub">
                        <button onClick={saveUpdatedEmployeeDetails}>
                          Update employee
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {/*Add Employee Data Modal */}
      {isAddEmployeeModalVisible && (
        <div className="employee-modal-overlay" onClick={closeAddEmployeeModal}>
          <div
            className="employee-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <IoMdClose
              className="employee-modal-close-icon"
              onClick={closeAddEmployeeModal}
            />

            <div className="pp-type">
              <h5>
                Add details of employee{" "}
                <IoHelpCircleOutline className="pp-type-icon" />
              </h5>
              <div className="pp-type-sub">
                <div className="pp-dealer-employee">
                  <div className="pp-inputs-sub">
                    <input
                      type="text"
                      placeholder="employee name ..."
                      name="employeeName"
                      onChange={(e) => handleNewEmployeeData(e)}
                      value={newEmployeeData.employeeName}
                    ></input>
                    <span>
                      <MdDriveFileRenameOutline className="pp-inputs-sub-span-icon" />
                    </span>
                  </div>

                  <div className="pp-inputs-sub">
                    <input
                      type="text"
                      placeholder="employee mobile ..."
                      name="employeeMobileNo"
                      onChange={(e) => handleNewEmployeeData(e)}
                      value={newEmployeeData.employeeMobileNo}
                    ></input>
                    <span>
                      <MdOutlinePhoneEnabled className="pp-inputs-sub-span-icon" />
                    </span>
                  </div>
                  <div className="pp-inputs-sub">
                    <input
                      type="text"
                      placeholder="employee email ..."
                      name="employeeEmail"
                      onChange={(e) => handleNewEmployeeData(e)}
                      value={newEmployeeData.employeeEmail}
                    ></input>
                    <span>
                      <MdOutlineEmail className="pp-inputs-sub-span-icon" />
                    </span>
                  </div>
                  <div className="pp-inputs-sub">
                    <div className="file-input-wrapper">
                      <input
                        type="file"
                        className="pp-dealer-employee-input-image"
                        onChange={(e) => handleNewEmployeeImage(e)}
                      />
                      <span>choose employee photo ...</span>

                      {newEmployeeData.employeeImage !== null && (
                        <div className="selected-image-container">
                          {newEmployeeData.employeeImage && (
                            <img
                              src={URL.createObjectURL(
                                newEmployeeData.employeeImage
                              )}
                              alt="Selected"
                              className="selected-image"
                            />
                          )}
                          <IoIosClose
                            onClick={removeNewEmployeeImage}
                            className="remove-icon"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="pp-inputs-sub">
                    <select
                      className="pp-ipnuts-sub-select-agent"
                      value={newEmployeeData.selectedEmployeeIdType}
                      onChange={(event) => {
                        setNewEmployeeData((prev) => ({
                          ...prev,
                          selectedEmployeeIdType: event.target.value,
                        }));
                      }}
                    >
                      <option value="select id type ..." disabled>
                        select id type ...
                      </option>
                      <option value="Employee id">Employee id</option>
                      {userProfileData?.userDetails?.userType === "Agent" && 
                      <>
                      <option value="Aadhaar card">Aadhaar card</option>
                      <option value="Driving license">Driving license</option>
                      <option value="Pan card">Pan card</option>
                      <option value="Passport">Passport</option>
                      <option value="Voter id">Voter id</option>
                      </>
                      }
                    </select>
                    <span>
                      <MdOutlinePermIdentity className="pp-inputs-sub-span-icon" />
                    </span>
                  </div>
                  {/* Selected Employee ID - Employee ID */}
                  {newEmployeeData.selectedEmployeeIdType === "Employee id" && (
                    <div className="pp-agent-employee-id">
                      <div className="pp-inputs-sub">
                        <input
                          type="text"
                          placeholder="employee id ..."
                          name="employeeID"
                          onChange={(e) => handleNewEmployeeData(e)}
                          value={newEmployeeData.employeeID}
                        ></input>
                        <span>
                          <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                        </span>
                      </div>
                      <div className="pp-inputs-sub">
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) => handleNewEmployeeIdCardImage(e)}
                          />
                          <span>choose employee ID Card photo ...</span>

                          {newEmployeeData.employeeIdCardImage !== null && (
                            <div className="selected-image-container">
                              {newEmployeeData.employeeIdCardImage && (
                                <img
                                  src={URL.createObjectURL(
                                    newEmployeeData.employeeIdCardImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeNewEmployeeIdCardImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Selected Employee ID - Aadhaar card */}
                  {newEmployeeData.selectedEmployeeIdType ===
                    "Aadhaar card" && userProfileData?.userDetails?.userType === "Agent" && (
                    <div className="pp-agent-employee-id">
                      <div className="pp-inputs-sub">
                        <input
                          type="text"
                          placeholder="aadhaar card (last 4 digits)"
                          name="employeeAadhaarcardNo"
                          onChange={(e) => handleNewEmployeeData(e)}
                          value={newEmployeeData.employeeAadhaarcardNo}
                        ></input>
                        <span>
                          <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                        </span>
                      </div>
                      <div className="pp-inputs-sub">
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            name="employeeAadhaarcardFrontImage"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) =>
                              handleNewEmployeeAadhaarcardImage(e)
                            }
                          />
                          <span>choose aadhaar front photo ...</span>

                          {newEmployeeData.employeeAadhaarcardFrontImage !==
                            null && (
                            <div className="selected-image-container">
                              {newEmployeeData.employeeAadhaarcardFrontImage && (
                                <img
                                  src={URL.createObjectURL(
                                    newEmployeeData.employeeAadhaarcardFrontImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeNewEmployeeAadhaarcardImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            name="employeeAadhaarcardBackImage"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) =>
                              handleNewEmployeeAadhaarcardImage(e)
                            }
                          />
                          <span>choose aadhaar back photo ...</span>

                          {newEmployeeData.employeeAadhaarcardBackImage !==
                            null && (
                            <div className="selected-image-container">
                              {newEmployeeData.employeeAadhaarcardBackImage && (
                                <img
                                  src={URL.createObjectURL(
                                    newEmployeeData.employeeAadhaarcardBackImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeNewEmployeeAadhaarcardImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Selected Employee ID - Driving license */}
                  {newEmployeeData.selectedEmployeeIdType ===
                    "Driving license" && userProfileData?.userDetails?.userType === "Agent" && (
                    <div className="pp-agent-employee-id">
                      <div className="pp-inputs-sub">
                        <input
                          type="text"
                          placeholder="driving license no ..."
                          name="employeeDrivinglicenseNo"
                          onChange={(e) => handleNewEmployeeData(e)}
                          value={newEmployeeData.employeeDrivinglicenseNo}
                        ></input>
                        <span>
                          <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                        </span>
                      </div>
                      <div className="pp-inputs-sub">
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            name="employeeDrivinglicenseFrontImage"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) => handleNewEmployeeDrivingLicense(e)}
                          />
                          <span>choose driving license front ...</span>

                          {newEmployeeData.employeeDrivinglicenseFrontImage !==
                            null && (
                            <div className="selected-image-container">
                              {newEmployeeData.employeeDrivinglicenseFrontImage && (
                                <img
                                  src={URL.createObjectURL(
                                    newEmployeeData.employeeDrivinglicenseFrontImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeNewEmployeeDrivingLicenseImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            name="employeeDrivinglicenseBackImage"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) => handleNewEmployeeDrivingLicense(e)}
                          />
                          <span>choose driving license back ...</span>

                          {newEmployeeData.employeeDrivinglicenseBackImage !==
                            null && (
                            <div className="selected-image-container">
                              {newEmployeeData.employeeDrivinglicenseBackImage && (
                                <img
                                  src={URL.createObjectURL(
                                    newEmployeeData.employeeDrivinglicenseBackImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeNewEmployeeDrivingLicenseImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Selected Employee ID - Pan card */}
                  {newEmployeeData.selectedEmployeeIdType === "Pan card" && userProfileData?.userDetails?.userType === "Agent" && (
                    <div className="pp-agent-employee-id">
                      <div className="pp-inputs-sub">
                        <input
                          type="text"
                          placeholder="pan card no ..."
                          name="employeePancardNo"
                          onChange={(e) => handleNewEmployeeData(e)}
                          value={newEmployeeData.employeePancardNo}
                        ></input>
                        <span>
                          <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                        </span>
                      </div>
                      <div className="pp-inputs-sub">
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) => handleNewEmployeePanCardImage(e)}
                          />
                          <span>choose pan card photo ...</span>

                          {newEmployeeData.employeePancardImage !== null && (
                            <div className="selected-image-container">
                              {newEmployeeData.employeePancardImage && (
                                <img
                                  src={URL.createObjectURL(
                                    newEmployeeData.employeePancardImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeNewEmployeePanCardImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Selected Employee ID - Passport */}
                  {newEmployeeData.selectedEmployeeIdType === "Passport" && userProfileData?.userDetails?.userType === "Agent" && (
                    <div className="pp-agent-employee-id">
                      <div className="pp-inputs-sub">
                        <input
                          type="text"
                          placeholder="passport id ..."
                          name="employeePassportNo"
                          onChange={(e) => handleNewEmployeeData(e)}
                          value={newEmployeeData.employeePassportNo}
                        ></input>
                        <span>
                          <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                        </span>
                      </div>
                      <div className="pp-inputs-sub">
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) => handleNewEmployeePassportImage(e)}
                          />
                          <span>choose passport photo ...</span>

                          {newEmployeeData.employeePassportImage !== null && (
                            <div className="selected-image-container">
                              {newEmployeeData.employeePassportImage && (
                                <img
                                  src={URL.createObjectURL(
                                    newEmployeeData.employeePassportImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeNewEmployeePaasportImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Selected Employee ID - Voter */}
                  {newEmployeeData.selectedEmployeeIdType === "Voter id" && userProfileData?.userDetails?.userType === "Agent" && (
                    <div className="pp-agent-employee-id">
                      <div className="pp-inputs-sub">
                        <input
                          type="text"
                          placeholder="voter id ..."
                          name="employeeVoterIdNo"
                          onChange={(e) => handleNewEmployeeData(e)}
                          value={newEmployeeData.employeeVoterIdNo}
                        ></input>
                        <span>
                          <LiaAddressCardSolid className="pp-inputs-sub-span-icon" />
                        </span>
                      </div>
                      <div className="pp-inputs-sub">
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            name="employeeVoterIdFrontImage"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) => handleNewEmployeeVoterIdImage(e)}
                          />
                          <span>choose voter front photo ...</span>

                          {newEmployeeData.employeeVoterIdFrontImage !==
                            null && (
                            <div className="selected-image-container">
                              {newEmployeeData.employeeVoterIdFrontImage && (
                                <img
                                  src={URL.createObjectURL(
                                    newEmployeeData.employeeVoterIdFrontImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeNewEmployeeVoterIdImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                        <div className="file-input-wrapper">
                          <input
                            type="file"
                            name="employeeVoterIdBackImage"
                            className="pp-dealer-employee-input-image"
                            onChange={(e) => handleNewEmployeeVoterIdImage(e)}
                          />
                          <span>choose voter back photo ...</span>

                          {newEmployeeData.employeeVoterIdBackImage !==
                            null && (
                            <div className="selected-image-container">
                              {newEmployeeData.employeeVoterIdBackImage && (
                                <img
                                  src={URL.createObjectURL(
                                    newEmployeeData.employeeVoterIdBackImage
                                  )}
                                  alt="Selected"
                                  className="selected-image"
                                />
                              )}
                              <IoIosClose
                                onClick={removeNewEmployeeVoterIdImage}
                                className="remove-icon"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="pp-inputs-sub">
                    <button onClick={sendEmployeeEmail}>Add employee</button>
                  </div>
                </div>
                {/* {agentEmployeeList &&
                              agentEmployeeList.map((employee, index) => (
                                <div className="pp-dealer-selected-employee">
                                  <h5>{index + 1}.</h5>
                                  <span>
                                    {employee.employeeName || "Not Available"}
                                  </span>
                                  <span>
                                    {employee.employeeEmail || "Not Available"}
                                  </span>
                                  <span>
                                    {employee.employeeMobileNo ||
                                      "Not Available"}
                                  </span>
                                  <span>
                                    {employee.employeeID ||
                                      employee.employeeAadhaarcardNo ||
                                      employee.employeeDrivinglicenseNo ||
                                      employee.employeePancardNo ||
                                      employee.employeePassportNo ||
                                      employee.employeeVoterIdNo ||
                                      "Not Available"}
                                  </span>
                                  {employee.employeeImage ? (
                                    <img
                                      src={URL.createObjectURL(
                                        employee.employeeImage
                                      )}
                                      alt="employee"
                                    />
                                  ) : (
                                    <LuUser2 className="pp-dealer-selected-employee-icon-one" />
                                  )}
                                  <IoIosClose
                                    onClick={() =>
                                      removeFromAgentEmployeeList(index)
                                    }
                                    className="pp-dealer-selected-employee-icon"
                                  />
                                </div>
                              ))} */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
