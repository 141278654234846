import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import "./AdminUsers.css";

// Ant Design Import
import { Select, Space, Table, Tag, Modal, message } from "antd";


import {newAdminUserSchema} from "../../../schemas/adminPanelSchema";
function AdminUsers() {
  const baseURL = process.env.REACT_APP_base_URL;

  const { Column } = Table;

  const [data, setData] = useState([]);

  const getAllAdmins = useCallback(async () => {
    try {
      const res = await axios.get(`${baseURL}/api/admin/getAllAdminUsers`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (
        res.data.message === "Admins found successfully" ||
        res.status === 200
      ) {
        return res.data;
      }
    } catch (error) {
      console.error("Error fetching all admins:", error);
    }
  }, [baseURL]);

  const [isAdminRoleNotModified, setIsAdminRoleNotModified] = useState(true);
  const [adminCount, setAdminCount] = useState(0);

  useEffect(() => {
    const fetchAllAdmins = async () => {
      const AllAdmins = await getAllAdmins();
      if (AllAdmins) {
        setData(AllAdmins.adminList);
        setAdminCount(AllAdmins.adminUsersCount);
      }
    };
    if (isAdminRoleNotModified) {
      fetchAllAdmins();
    }
    return () => setIsAdminRoleNotModified(false);
  }, [getAllAdmins, isAdminRoleNotModified]);

  const [isModifyRoleModalVisible, setIsModifyRoleModalVisible] =
    useState(false);
  const [selectedAdminRole, setSelectedAdminRole] = useState("");
  const [selectedAdminUserId, setSelectedAdminUserId] = useState("");
  const showModifyRoleModal = (id) => {
    setSelectedAdminUserId(id);
    setIsModifyRoleModalVisible(true);
  };

  const [newAdmin, setNewAdmin] = useState({
    userEmail: "",
    userMobileNo: "",
  });

  const handleNewAdminData = (e) => {
    const { name, value } = e.target;

    if (name === "Email") {
      setNewAdmin((prev) => ({
        ...prev,
        userEmail: value,
      }));
    } else if (name === "Mobile") {
      setNewAdmin((prev) => ({
        ...prev,
        userMobileNo: value,
      }));
    }
  };

  const handleChange = (value) => {
    setSelectedAdminRole(value);
  };

  const handleAddAdmin = async () => {
    if (selectedAdminRole === "") {
      return message.error("please select one admin role");
    }
    if (newAdmin.userEmail === "") {
      return message.error("please provide admin's email address");
    }else if(newAdminUserSchema.safeParse({adminEmail: newAdmin.userEmail}).success === false){
      const errorMessage = newAdminUserSchema.safeParse({
        adminEmail: newAdmin.userEmail,
      }).error.issues[0].message;
      return message.error(errorMessage);
    }
    else if (newAdmin.userMobileNo === "") {
      return message.error("please provide admin's Mobile number");
    }
    try {
      const formData = new FormData();
      formData.append("userEmail", newAdmin.userEmail);
      formData.append("userMobileNo", newAdmin.userMobileNo);
      formData.append("selectedAdminRole", selectedAdminRole)
      const res = await axios.post(
        `${baseURL}/api/admin/addNewAdmin`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (
        res.data.message === "Admin role updated successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        message.success(res.data.message);
        setIsAdminRoleNotModified(true);
      }
    } catch (error) {
      message.error(error.response.data.message);
      console.error("Error updating admin role:", error);
    }
  };

  const handleModifyRoleOk = async () => {
    if (selectedAdminRole === "") {
      return message.error("please select one admin role");
    }
    try {
      const formData = new FormData();
      formData.append("selectedAdminUserId", selectedAdminUserId);
      formData.append("selectedAdminRole", selectedAdminRole);
      const res = await axios.post(
        `${baseURL}/api/admin/updateAdminRole`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (
        res.data.message === "Admin role updated successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        message.success(res.data.message);
        setIsAdminRoleNotModified(true);
      }
    } catch (error) {
      message.error(error.response.data.message);
      console.error("Error updating admin role:", error);
    }
    setIsModifyRoleModalVisible(false);
  };

  const handleModifyRoleCancel = () => {
    setIsModifyRoleModalVisible(false);
  };

  // User Delete Modal
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };
  const handleDeleteOk = () => {
    setIsDeleteModalVisible(false);
  };
  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  return (
    <div className="adashboard">
      <div className="adashboard-sub">
        <div className="adashboard-header">
          <h2>Admin Users</h2>
        </div>
        <div className="ausers-add">
          <div className="ausers-add-sub">
            <h5>Add new admin role or member</h5>
          </div>
          <div className="ausers-add-sub">
            <input
              type="email"
              placeholder="Email"
              name="Email"
              value={newAdmin.userEmail}
              onChange={handleNewAdminData}
            />
            <input
              type="number"
              placeholder="Mobile"
              name="Mobile"
              value={newAdmin.userMobileNo}
              onChange={handleNewAdminData}
            />
            <Select
              value={
                selectedAdminRole && selectedAdminRole !== ""
                  ? selectedAdminRole
                  : "Select a role"
              }
              onChange={handleChange}
              className="ausers-add-sub-select-ant-design"
              options={[
                {
                  value: "subAdmin",
                  label: "Sub Admin",
                },
                {
                  value: "reviewer",
                  label: "Reviewer",
                },
              ]}
            />
            <button className="button" onClick={handleAddAdmin}>
              Add
            </button>
          </div>
        </div>
        <div className="ausers">
          <div className="ausers-sub">
            <h5>All Admin members ({adminCount}/10)</h5>
          </div>
          <div className="ausers-sub">
            <Table dataSource={data} className="ausers-sub-table-ant-design">
              <Column title="ID" dataIndex="id" key="id" />
              <Column title="Full Name" dataIndex="fullName" key="fullName" />
              <Column title="Email" dataIndex="email" key="email" />
              <Column title="Mobile" dataIndex="mobile" key="mobile" />
              <Column
                title="Role"
                dataIndex="roles"
                key="roles"
                render={(roles) => (
                  <>
                    {roles ? (
                      roles.map((role) => {
                        let color;
                        if (role === "superAdmin") {
                          color = "green";
                        } else if (role === "subAdmin") {
                          color = "geekblue";
                        } else if (role === "reviewer") {
                          color = "volcano";
                        }
                        return (
                          <Tag color={color} key={role}>
                            {role.toUpperCase()}
                          </Tag>
                        );
                      })
                    ) : (
                      <Tag color="gray" key={roles}>
                        {"Not assigned"}
                      </Tag>
                    )}
                  </>
                )}
              />
              <Column
                title="Action"
                key="action"
                render={(data) => (
                  <Space size="middle">
                    <a onClick={() => showModifyRoleModal(data.id)}>
                      Modify Role
                    </a>
                    <a onClick={showDeleteModal}>Delete</a>
                  </Space>
                )}
              />
            </Table>
          </div>
        </div>
      </div>
      <Modal
        title="Modify Role for the admin or reviewer"
        visible={isModifyRoleModalVisible}
        onOk={handleModifyRoleOk}
        onCancel={handleModifyRoleCancel}
        okText="Modify"
        cancelText="Cancel"
      >
        <div className="aprofile-modal-reject-reason">
          <p>
            You can modify to (Sub Admin or Reviewer), but only one role at a
            time.
          </p>
          <Select
            name="adminsLists"
            className="aprofile-modal-reject-reason-select"
            value={
              selectedAdminRole !== "" ? selectedAdminRole : "Select Admin Type"
            }
            onChange={(value) => setSelectedAdminRole(value)}
            options={[
              {
                value: "subAdmin",
                label: "Sub Admin",
              },
              {
                value: "reviewer",
                label: "Reviewer",
              },
            ]}
          />
        </div>
      </Modal>
      <Modal
        title="Are you sure to remove this sub admin or reviewer?"
        visible={isDeleteModalVisible}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        okText="Yes"
        okType="danger"
        cancelText="No"
      >
        <div className="aprofile-modal-reject-reason">
          <p>This action is permanent and cannot be undone.</p>
        </div>
      </Modal>
    </div>
  );
}

export default AdminUsers;
