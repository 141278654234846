import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// STYLING IMPORT
import "./Dashboard.css";

// SVG IMPORT
import faviconIcon from "../../assets/svgs/favicon.svg";

// ANT DESIGN IMPORT
import {
  AppstoreOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EnvironmentOutlined,
  ExclamationCircleFilled,
  ExclamationOutlined,
  ExpandAltOutlined,
  EyeOutlined,
  FileDoneOutlined,
  HeartOutlined,
  HomeOutlined,
  NotificationOutlined,
  PieChartOutlined,
  ProjectOutlined,
  SafetyCertificateOutlined,
  ShareAltOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu, Modal } from "antd";

// REACT ICONS
import { PiBathtubDuotone, PiBedDuotone } from "react-icons/pi";
import { AiOutlineFileImage, AiOutlineProject } from "react-icons/ai";
import { BsHouse } from "react-icons/bs";
import { MdOutlineSpeakerNotes } from "react-icons/md";

// PRICE FORMATTER
import PriceFormatter from "../../components/PriceFormatter/PriceFormatter";

// RECOIL
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../../store/atoms/userDataAtom";

function Dashboard() {
  const baseURL = process.env.REACT_APP_base_URL;
  const userDetails = useRecoilValue(userDataAtom);
  const userId = userDetails?.user?._id;
  const userType = userDetails?.userProfile?.userType;
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Proptzo Dashboard - Manage Your Listings Efficiently";
  }, []);

  const [fetchedPosts, setFetchedPosts] = useState([]);
  const [fetchedProjects, setFetchedProjects] = useState([]);
  useEffect(() => {
    const fetchUserListings = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/post/getpost/${userId}`
        );
        setFetchedPosts(response.data.posts);
        setFetchedProjects(response.data.projects);
      } catch (error) {
        console.error("Error fetching user posts:", error);
      }
    };
    fetchUserListings();
  }, [baseURL, userId]);

  // Project Share
  const handleProjectShare = async (projectId) => {
    const projectUrl = `https://proptzo.com/viewprojects/${projectId}`;
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Check out this project!",
          url: projectUrl,
        });
      } else {
        alert(`Copy this link to share: ${projectUrl}`);
      }
    } catch (error) {
      console.error("Error sharing the project:", error);
    }
  };
  // Property Share
  const handleShare = async (postId) => {
    const propertyUrl = `https://proptzo.com/viewproperty/${postId}`;
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Check out this property!",
          url: propertyUrl,
        });
      } else {
        alert(`Copy this link to share: ${propertyUrl}`);
      }
    } catch (error) {
      console.error("Error sharing the property:", error);
    }
  };

  const [selectedKey, setSelectedKey] = useState("1");
  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
  };
  // Sidebar Secondary
  const sidebarItems = [
    {
      key: "1",
      icon: <AppstoreOutlined className="dash-sidebar-secondary-icon" />,
      label: "Dashboard",
    },
    ...(userType !== "Owner" && userType !== "Salesperson"
      ? [
          {
            key: "2",
            icon: <ProjectOutlined className="dash-sidebar-secondary-icon" />,
            label: "Projects",
          },
        ]
      : []),
    {
      key: "3",
      icon: <HomeOutlined className="dash-sidebar-secondary-icon" />,
      label: "Properties",
    },
    {
      key: "link",
      icon: <UserOutlined className="dash-sidebar-secondary-icon" />,
      label: (
        <span
          onClick={() => {
            navigate("/userprofile");
          }}
        >
          Go to Profile
        </span>
      ),
    },
  ];

  // Progress Bar
  const [currentStep, setCurrentStep] = useState(2);
  const totalSteps = 8;
  const calculateProgressWidth = (currentStep, totalSteps) => {
    if (currentStep <= 1) {
      return 0;
    } else if (currentStep === 2) {
      return 35;
    } else {
      return 35 + ((currentStep - 2) / (totalSteps - 2)) * 65;
    }
  };
  const progressWidth = calculateProgressWidth(currentStep, totalSteps);

  // Confirm Delete Modal
  const { confirm } = Modal;
  const showProjectDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this project?",
      icon: <ExclamationCircleFilled />,
      content: "This action is permanent and cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const showPropertyDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this property?",
      icon: <ExclamationCircleFilled />,
      content: "This action is permanent and cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // Project Verification Modal state and handlers
  const [isProjectVerificationModalOpen, setIsProjectVerificationModalOpen] =
    useState(false);
  const showProjectVerificationModal = () => {
    setIsProjectVerificationModalOpen(true);
  };
  const handleProjectVerificationOk = () => {
    setIsProjectVerificationModalOpen(false);
  };
  const handleProjectVerificationCancel = () => {
    setIsProjectVerificationModalOpen(false);
  };

  // Property Verification Modal state and handlers
  const [isPropertyVerificationModalOpen, setIsPropertyVerificationModalOpen] =
    useState(false);
  const showPropertyVerificationModal = () => {
    setIsPropertyVerificationModalOpen(true);
  };
  const handlePropertyVerificationOk = () => {
    setIsPropertyVerificationModalOpen(false);
  };
  const handlePropertyVerificationCancel = () => {
    setIsPropertyVerificationModalOpen(false);
  };

  return (
    <div className="dash">
      <div className="dash-sidebar">
        <div className="dash-sidebar-one">
          <img src={faviconIcon} alt="" />
          <img src="https://placehold.co/50x50?text=B" alt="" />
        </div>
        <Menu
          className="dash-sidebar-two"
          defaultSelectedKeys={["1"]}
          // defaultOpenKeys={["sub1"]}
          mode={"inline"}
          theme={"light"}
          items={sidebarItems}
          onClick={handleMenuClick}
        />
      </div>
      <div className="dash-container">
        {selectedKey === "1" && (
          <div className="dash-container-sub">
            <div className="dash-header">
              <h2>Dashboard</h2>
            </div>
            <div className="dash-category">
              <div className="dash-category-sub">
                <div className="dash-category-icons">
                  <EyeOutlined className="dash-category-icons-icon" />
                </div>
                <h5>Total Views (Incl. Projects & Properties)</h5>
                <p>15 Views (2 Projects & 13 Properties)</p>
              </div>
              <div className="dash-category-sub">
                <div className="dash-category-icons">
                  <PieChartOutlined className="dash-category-icons-icon" />
                </div>
                <h5>Total Listings (Incl. Projects & Properties)</h5>
                <p>15 Listings (2 Projects & 13 Properties)</p>
              </div>
              <div className="dash-category-sub">
                <div className="dash-category-icons">
                  <FileDoneOutlined className="dash-category-icons-icon" />
                </div>
                <h5>Total Brochure Downloaded (Incl. Projects & Properties)</h5>
                <p>15 Listings (2 Projects & 13 Properties)</p>
              </div>
              <div className="dash-category-sub">
                <div className="dash-category-icons">
                  <NotificationOutlined className="dash-category-icons-icon" />
                </div>
                <h5>Total Contacted (Incl. Projects & Properties)</h5>
                <p>15 Listings (2 Projects & 13 Properties)</p>
              </div>
            </div>
          </div>
        )}
        {selectedKey === "2" && (
          <div className="dash-container-sub">
            <div className="dash-header">
              <h2>Projects</h2>
            </div>
            <div className="dash-listings">
              <h5>
                {fetchedProjects.length}{" "}
                {fetchedProjects.length === 1
                  ? "Project Showing"
                  : "Projects Showing"}
              </h5>
              <div className="dash-listings-sub">
                {fetchedProjects &&
                  fetchedProjects.map((project) => (
                    <div key={project._id} className="dash-listings-listing">
                      <div className="ps-property-main ps-property-main-dashboard">
                        <div className="trending-prop-img trending-prop-img-dashboard">
                          {project.projectLogo ? (
                            <img
                              src={`data:image/jpeg;base64,${project?.projectLogo}`}
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://placehold.co/800x450?text=proptzo";
                              }}
                            />
                          ) : (
                            <img
                              src="https://placehold.co/800x450?text=proptzo"
                              alt=""
                            ></img>
                          )}
                          <div className="trending-for">
                            <h5>{project?.projectStatus.toUpperCase()}</h5>
                          </div>
                          <div className="trending-type">
                            <h5>
                              {project?.projectOwnershipType.toUpperCase()}
                            </h5>
                          </div>
                          <div className="trending-rera">
                            <SafetyCertificateOutlined className="trending-rera-icon" />
                            <h5>RERA CERTIFIED</h5>
                          </div>
                          <div className="trending-prop-img-icons">
                            <ShareAltOutlined
                              className="trending-prop-img-icons-icon"
                              onClick={() => handleProjectShare(project._id)}
                            />
                            <EditOutlined
                              className="trending-prop-img-icons-icon"
                              onClick={() =>
                                navigate(`/projectupdate/${project._id}`)
                              }
                            />
                            <DeleteOutlined
                              className="trending-prop-img-icons-icon"
                              onClick={showProjectDeleteConfirm}
                            />
                            <EyeOutlined
                              className="trending-prop-img-icons-icon"
                              onClick={() =>
                                navigate(`/viewprojects/${project._id}`)
                              }
                            />
                          </div>
                        </div>
                        <div className="ps-property-main-sub ps-property-main-sub-dashboard">
                          <h5>
                            {project?.projectName} (
                            {project?.projectPriceRange?.priceFrom &&
                            project?.projectPriceRange?.priceTo ? (
                              <>
                                <PriceFormatter
                                  amount={project.projectPriceRange.priceFrom}
                                  currencyType={project.projectCurrencyType}
                                />
                                {" - "}
                                <PriceFormatter
                                  amount={project.projectPriceRange.priceTo}
                                  currencyType={project.projectCurrencyType}
                                />
                              </>
                            ) : (
                              "loading price ..."
                            )}
                            )
                          </h5>
                          <div className="trending-prop-loc">
                            <EnvironmentOutlined className="trending-prop-loc-icon" />
                            <p>
                              {project.projectLocality}, {project.projectCity}
                            </p>
                          </div>
                          <div className="trending-prop-data">
                            <ExpandAltOutlined className="trending-prop-data-icon" />
                            <h5>
                              {project.projectArea}, {project.projectAreaUnit}
                            </h5>
                          </div>
                          <div className="trending-prop-footer-dashboard">
                            <div className="utr-progress-bar utr-progress-bar-dashboard">
                              <div className="utr-progress-bar-background">
                                <div
                                  className="utr-progress-bar-sub"
                                  style={{ width: `${progressWidth}%` }}
                                ></div>
                              </div>
                              <div className="utr-progress-bar-icons">
                                <div
                                  className={`utr-progress-bar-category ${
                                    currentStep >= 1 ? "selected" : ""
                                  }`}
                                >
                                  <AiOutlineProject className="utr-progress-bar-category-icon" />
                                </div>
                                <div
                                  className={`utr-progress-bar-category ${
                                    currentStep >= 2 ? "selected" : ""
                                  }`}
                                >
                                  <MdOutlineSpeakerNotes className="utr-progress-bar-category-icon" />
                                </div>
                                <div className="utr-progress-bar-category selected-yellow">
                                  <ExclamationOutlined className="utr-progress-bar-category-icon" />
                                </div>
                                <div
                                  className={`utr-progress-bar-category ${
                                    currentStep >= 5 ? "selected" : ""
                                  }`}
                                >
                                  <CheckOutlined className="utr-progress-bar-category-icon" />
                                </div>
                                <div
                                  className={`utr-progress-bar-category ${
                                    currentStep >= 8 ? "selected" : ""
                                  }`}
                                >
                                  <CloseOutlined className="utr-progress-bar-category-icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="trending-prop-footer-dashboard">
                            <h4 onClick={showProjectVerificationModal}>
                              Check Status of Verification
                            </h4>
                          </div>
                          <Modal
                            title="Project Verification Status"
                            visible={isProjectVerificationModalOpen}
                            onOk={handleProjectVerificationOk}
                            onCancel={handleProjectVerificationCancel}
                            footer={null}
                            centered
                            className="vp-brochure-modal"
                          >
                            <div className="dash-status-modal">
                              <AiOutlineProject className="dash-status-modal-icon selected" />
                              <div className="dash-status-modal-sub">
                                <h5 className="selected">Project Submitted</h5>
                                <p className="selected">
                                  The project was submitted on July 7, 2024.
                                </p>
                              </div>
                            </div>
                            <div className="dash-status-modal">
                              <MdOutlineSpeakerNotes className="dash-status-modal-icon" />
                              <div className="dash-status-modal-sub">
                                <h5>Review Started</h5>
                                <p>
                                  Your project is currently under review, which
                                  commenced on July 9, 2024. Please check back
                                  later for further updates. Once successfully
                                  verified, your project will be live.
                                </p>
                              </div>
                            </div>
                            <div className="dash-status-modal">
                              <ExclamationOutlined className="dash-status-modal-icon" />
                              <div className="dash-status-modal-sub">
                                <h5>Changes Required</h5>
                                <p>
                                  Your submitted project requires the following
                                  updates: Title and Description. Please review
                                  the submission as it does not comply with our
                                  policies. Update the project with the
                                  specified changes and resubmit.
                                </p>
                              </div>
                            </div>
                            <div className="dash-status-modal">
                              <CheckOutlined className="dash-status-modal-icon" />
                              <div className="dash-status-modal-sub">
                                <h5>Verification Successful</h5>
                                <p>
                                  Congratulations! The project has been
                                  successfully verified and is now live on our
                                  Proptzo website.
                                </p>
                              </div>
                            </div>
                            <div className="dash-status-modal">
                              <CloseOutlined className="dash-status-modal-icon" />
                              <div className="dash-status-modal-sub">
                                <h5>Unusual Activity Detected</h5>
                                <p>
                                  Our team has detected fraudulent activity
                                  related to this project. You are required to
                                  delete this project as it does not meet our
                                  terms and policies.
                                </p>
                              </div>
                            </div>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
        {selectedKey === "3" && (
          <div className="dash-container-sub">
            <div className="dash-header">
              <h2>Properties</h2>
            </div>
            <div className="dash-listings">
              <h5>
                {fetchedPosts.length}{" "}
                {fetchedPosts.length === 1
                  ? "Property Showing"
                  : "Properties Showing"}
              </h5>
              <div className="dash-listings-sub">
                {fetchedPosts &&
                  fetchedPosts.map((property, index) => (
                    <div key={property._id} className="dash-listings-listing">
                      <div className="ps-property-main ps-property-main-dashboard">
                        <div className="trending-prop-img trending-prop-img-dashboard">
                          {property?.propertyImages?.length === 1 ? (
                            <img
                              src={`data:image/jpeg;base64,${property?.propertyImages[0]}`}
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://placehold.co/800x450?text=proptzo";
                              }}
                            />
                          ) : (
                            <img
                              src="https://placehold.co/800x450?text=proptzo"
                              alt=""
                            ></img>
                          )}
                          <div className="trending-for">
                            <h5>
                              FOR {property?.propertyAction.toUpperCase()}
                            </h5>
                          </div>
                          <div className="trending-type">
                            <h5>{property.propertySubType.toUpperCase()}</h5>
                          </div>
                          <div className="trending-rera">
                            <SafetyCertificateOutlined className="trending-rera-icon" />
                            <h5>RERA CERTIFIED</h5>
                          </div>
                          <div className="trending-prop-img-icons">
                            <ShareAltOutlined
                              className="trending-prop-img-icons-icon"
                              onClick={() => handleShare(property._id)}
                            />
                            <EditOutlined
                              className="trending-prop-img-icons-icon"
                              onClick={() =>
                                navigate(`/postupdate/${property._id}`)
                              }
                            />
                            <DeleteOutlined
                              className="trending-prop-img-icons-icon"
                              onClick={() =>
                                showPropertyDeleteConfirm(property._id)
                              }
                            />
                            <EyeOutlined
                              className="trending-prop-img-icons-icon"
                              onClick={() =>
                                navigate(`/viewproperty/${property._id}`)
                              }
                            />
                          </div>
                        </div>
                        <div className="ps-property-main-sub ps-property-main-sub-dashboard">
                          <h5>
                            {property.propertyTitle} (
                            <PriceFormatter
                              amount={property.propertyPrice}
                              currencyType={property.propertyCurrencyType}
                            />
                            )
                          </h5>
                          <div className="trending-prop-loc">
                            <EnvironmentOutlined className="trending-prop-loc-icon" />
                            <p>
                              {property.propertyLocality},{" "}
                              {property.propertyCity}
                            </p>
                          </div>
                          <div className="trending-prop-data">
                            <PiBedDuotone className="trending-prop-data-icon" />
                            <h5>{property.propertyBedroomCount || "NA"}</h5>
                            <PiBathtubDuotone className="trending-prop-data-icon" />
                            <h5>{property.propertyBathroomCount || "NA"}</h5>
                            <ExpandAltOutlined className="trending-prop-data-icon" />
                            <h5>
                              {property.propertyArea},{" "}
                              {property.propertyAreaUnit}
                            </h5>
                          </div>
                          <div className="trending-prop-footer-dashboard">
                            <div className="utr-progress-bar utr-progress-bar-dashboard">
                              <div className="utr-progress-bar-background">
                                <div
                                  className="utr-progress-bar-sub"
                                  style={{ width: `${progressWidth}%` }}
                                ></div>
                              </div>
                              <div className="utr-progress-bar-icons">
                                <div
                                  className={`utr-progress-bar-category ${
                                    currentStep >= 1 ? "selected" : ""
                                  }`}
                                >
                                  <AiOutlineProject className="utr-progress-bar-category-icon" />
                                </div>
                                <div
                                  className={`utr-progress-bar-category ${
                                    currentStep >= 2 ? "selected" : ""
                                  }`}
                                >
                                  <MdOutlineSpeakerNotes className="utr-progress-bar-category-icon" />
                                </div>
                                <div className="utr-progress-bar-category selected-yellow">
                                  <ExclamationOutlined className="utr-progress-bar-category-icon" />
                                </div>
                                <div
                                  className={`utr-progress-bar-category ${
                                    currentStep >= 5 ? "selected" : ""
                                  }`}
                                >
                                  <CheckOutlined className="utr-progress-bar-category-icon" />
                                </div>
                                <div
                                  className={`utr-progress-bar-category ${
                                    currentStep >= 8 ? "selected" : ""
                                  }`}
                                >
                                  <CloseOutlined className="utr-progress-bar-category-icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="trending-prop-footer-dashboard">
                            <h4 onClick={showPropertyVerificationModal}>
                              Check Status of Property Verification
                            </h4>
                          </div>
                          <Modal
                            title="Property Verification Status"
                            visible={isPropertyVerificationModalOpen}
                            onOk={handlePropertyVerificationOk}
                            onCancel={handlePropertyVerificationCancel}
                            footer={null}
                            centered
                            className="vp-brochure-modal"
                          >
                            <div className="dash-status-modal">
                              <AiOutlineProject className="dash-status-modal-icon selected" />
                              <div className="dash-status-modal-sub">
                                <h5 className="selected">Property Submitted</h5>
                                <p className="selected">
                                  The property was submitted on July 7, 2024.
                                </p>
                              </div>
                            </div>
                            <div className="dash-status-modal">
                              <MdOutlineSpeakerNotes className="dash-status-modal-icon" />
                              <div className="dash-status-modal-sub">
                                <h5>Review Started</h5>
                                <p>
                                  Your property is currently under review, which
                                  commenced on July 9, 2024. Please check back
                                  later for further updates. Once successfully
                                  verified, your property will be live.
                                </p>
                              </div>
                            </div>
                            <div className="dash-status-modal">
                              <ExclamationOutlined className="dash-status-modal-icon" />
                              <div className="dash-status-modal-sub">
                                <h5>Changes Required</h5>
                                <p>
                                  Your submitted property requires the following
                                  updates: Title and Description. Please review
                                  the submission as it does not comply with our
                                  policies. Update the property with the
                                  specified changes and resubmit.
                                </p>
                              </div>
                            </div>
                            <div className="dash-status-modal">
                              <CheckOutlined className="dash-status-modal-icon" />
                              <div className="dash-status-modal-sub">
                                <h5>Verification Successful</h5>
                                <p>
                                  Congratulations! The property has been
                                  successfully verified and is now live on our
                                  Proptzo website.
                                </p>
                              </div>
                            </div>
                            <div className="dash-status-modal">
                              <CloseOutlined className="dash-status-modal-icon" />
                              <div className="dash-status-modal-sub">
                                <h5>Unusual Activity Detected</h5>
                                <p>
                                  Our team has detected fraudulent activity
                                  related to this property. You are required to
                                  delete this property as it does not meet our
                                  terms and policies.
                                </p>
                              </div>
                            </div>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
