import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";

import "./AdminProperties.css";

// Ant Design Import
import { Space, Table, Modal, Input, Select, message } from "antd";
import { userDataAtom } from "../../../store/atoms/userDataAtom";
import { useRecoilValue } from "recoil";

const { TextArea } = Input;
const { Column } = Table;

function AdminProperties() {
  const baseURL = process.env.REACT_APP_base_URL;

  const userDetails = useRecoilValue(userDataAtom);
  const [userId, setUserId] = useState(null);
  const [isAdminRoleNotModified, setIsAdminRoleNotModified] = useState(true);

  useMemo(() => {
    if (userDetails) {
      setUserId(userDetails.user._id);
    }
  }, [userDetails]);

  const [unverifiedProperties, setUnverifiedProperties] = useState([]);
  const getUnVerifiedProperties = useCallback(async () => {
    try {
      const res = await axios.get(
        `${baseURL}/api/admin/getUnVerifiedProperties`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching unverified properties:", error);
    }
  }, [baseURL]);

  useMemo(() => {
    const fetchData = async () => {
      const properties = await getUnVerifiedProperties();
      if (properties) {
        setUnverifiedProperties(properties);
      }
    };
    fetchData();
  }, [getUnVerifiedProperties]);

  const [data, setData] = useState([]);
  const [
    assignedUnverifiedPropertiesData,
    setAssignedUnverifiedPropertiesData,
  ] = useState([]);

  const getAllAdmins = useCallback(async () => {
    try {
      const res = await axios.get(`${baseURL}/api/admin/getAllAdminUsers`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (
        res.data.message === "Admins found successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        return res.data;
      }
    } catch (error) {
      console.error("Error fetching all admins:", error);
    }
  }, [baseURL]);

  const getAllAssignedUnverifiedProperties = useCallback(async () => {
    try {
      const res = await axios.get(
        `${baseURL}/api/admin/getAllAssignedUnverifiedProperties`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          params: { userId: userId },
        }
      );

      if (
        res.data.message ===
          "Assigned unverified properties fetched successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        return res.data;
      }
    } catch (error) {
      console.error("Error fetching all admins:", error);
    }
  }, [baseURL, userId]);

  useEffect(() => {
    const fetchAllAdmins = async () => {
      const AllAdmins = await getAllAdmins();
      const AllAssignedUnverifiedProperties =
        await getAllAssignedUnverifiedProperties();
      if (AllAdmins) {
        setData(AllAdmins.adminList);
      }
      if (AllAssignedUnverifiedProperties) {
        setAssignedUnverifiedPropertiesData(
          AllAssignedUnverifiedProperties.unverifiedProperties
        );
      }
    };

    if (isAdminRoleNotModified) {
      fetchAllAdmins();
    }
    return () => setIsAdminRoleNotModified(false);
  }, [
    getAllAdmins,
    getAllAssignedUnverifiedProperties,
    isAdminRoleNotModified,
  ]);

  const unverifiedData = unverifiedProperties.map((property, index) => ({
    id: property.id,
    key: index,
    title: property.propertyTitle,
    area: `${property.propertyArea} ${property.propertyAreaUnit}`,
    price: `${property.propertyCurrencyType} ${property.propertyPrice}`,
    city: property.propertyCity,
    propertyImage: property.propertyImage,
  }));
  const assignedData = assignedUnverifiedPropertiesData.map((property, index) => ({
    key: index,
    title: property.propertyTitle,
    area: `${property.propertyArea} ${property.propertyAreaUnit}`,
    price: `${property.propertyCurrencyType} ${property.propertyPrice}`,
    city: property.propertyCity,
    propertyImage: property.propertyImage,
  }));
  // const rejectedData = unverifiedProperties.map((property, index) => ({
  //   key: index,
  //   title: property.propertyTitle,
  //   area: `${property.propertyArea} ${property.propertyAreaUnit}`,
  //   price: `${property.propertyCurrencyType} ${property.propertyPrice}`,
  //   city: property.propertyCity,
  //   propertyImage: property.propertyImage,
  // }));

  // const unverifiedData = [
  //   {
  //     key: "1",
  //     title: "This is property Title ...",
  //     area: "2250 sqft",
  //     price: "₹ 15Cr",
  //     city: "Chandigarh",
  //     propertyImage: "https://placehold.co/600x400?text=U",
  //   },
  // ];

  const rejectedData = [
    {
      key: "1",
      title: "This is property Title",
      area: "2250 sqft",
      price: "₹ 15Cr",
      city: "Chandigarh",
      propertyImage: "https://placehold.co/600x400?text=U",
    },
  ];

  // Property Reject Modal & Reject Reason
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const showRejectModal = () => {
    setIsRejectModalVisible(true);
  };
  const handleRejectOk = () => {
    console.log("Reject reason:", rejectReason);
    setIsRejectModalVisible(false);
    setRejectReason("");
  };
  const handleRejectCancel = () => {
    setIsRejectModalVisible(false);
    setRejectReason("");
  };

  // Property Reject Reason Modal
  const [isRejectReasonModalVisible, setIsRejectReasonModalVisible] =
    useState(false);
  const showRejectReasonModal = () => {
    setIsRejectReasonModalVisible(true);
  };
  const handleRejectReasonOk = () => {
    setIsRejectReasonModalVisible(false);
  };
  const handleRejectReasonCancel = () => {
    setIsRejectReasonModalVisible(false);
  };

  // Property Assign Modal
  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
  const [selectedAssignedAdmin, setSelectedAssignedAdmin] = useState("");
  const [selectedUnverfiedPropertyId, setSelectedUnverfiedPropertyId] =
    useState("");

  const showAssignModal = (id) => {
    setIsAssignModalVisible(true);
    setSelectedUnverfiedPropertyId(id);
  };
  const handleAssignOk = async () => {
    if (selectedAssignedAdmin === "") {
      return message.error("please select admin to assign this task");
    }
    if (selectedUnverfiedPropertyId === "") {
      return message.error("please select a task to assign");
    }
    try {
      const formData = new FormData();
      formData.append("assignedBy", userId);
      formData.append("assignedTo", selectedAssignedAdmin);
      formData.append("unverifiedPropertyId", selectedUnverfiedPropertyId);
      const res = await axios.post(
        `${baseURL}/api/admin/assignTask`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (
        res.data.message === "Task assigned successfully" ||
        res.status === 200 ||
        res.statusText === "OK"
      ) {
        message.success(res.data.message);
        // setIsAdminRoleNotModified(true);
        setIsAssignModalVisible(false);
        if (selectedAssignedAdmin === userId) {
          setIsAdminRoleNotModified(true);
        }
        setSelectedAssignedAdmin("");
        setSelectedUnverfiedPropertyId("");
      }
    } catch (error) {
      message.error(error.response.data.message);
      setIsAssignModalVisible(false);
      console.error("Error assigning task:", error);
    }
  };
  const handleAssignCancel = () => {
    setIsAssignModalVisible(false);
    setSelectedAssignedAdmin("");
    setSelectedUnverfiedPropertyId("");
  };

  return (
    <div className="adashboard">
      <div className="adashboard-sub">
        <div className="adashboard-header">
          <h2>Property Verifications</h2>
        </div>
        <div className="ausers">
          <div className="ausers-sub">
            <h5>Pending for verifications ({unverifiedData.length})</h5>
          </div>
          <div className="ausers-sub">
            <Table
              dataSource={unverifiedData}
              className="ausers-sub-table-ant-design"
            >
              <Column title="Title" dataIndex="title" key="title" />
              <Column title="Area" dataIndex="area" key="area" />
              <Column title="Price" dataIndex="price" key="price" />
              <Column title="City" dataIndex="city" key="city" />
              <Column
                title="Property Image"
                dataIndex="propertyImage"
                key="propertyImage"
                render={(propertyImage) => (
                  <img
                    style={{
                      height: "45px",
                      width: "45px",
                      borderRadius: "50%",
                    }}
                    src={`data:image/jpeg;base64,${propertyImage}`}
                    alt=""
                  />
                )}
              />
              <Column
                title="Action"
                key="action"
                render={(action) => (
                  <Space size="middle">
                    <a>Start Verification</a>
                    <a onClick={() => showAssignModal(action.id)}>
                      Assign task
                    </a>
                    <a onClick={showRejectModal}>Reject</a>
                  </Space>
                )}
              />
            </Table>
          </div>
        </div>
        {assignedData.length > 0 && <div className="ausers">
          <div className="ausers-sub">
            <h5>Assigned Properties ({assignedData.length})</h5>
          </div>
          <div className="ausers-sub">
          <Table
              dataSource={assignedData}
              className="ausers-sub-table-ant-design"
            >
              <Column title="Title" dataIndex="title" key="title" />
              <Column title="Area" dataIndex="area" key="area" />
              <Column title="Price" dataIndex="price" key="price" />
              <Column title="City" dataIndex="city" key="city" />
              <Column
                title="Property Image"
                dataIndex="propertyImage"
                key="propertyImage"
                render={(propertyImage) => (
                  <img
                    style={{
                      height: "45px",
                      width: "45px",
                      borderRadius: "50%",
                    }}
                    src={`data:image/jpeg;base64,${propertyImage}`}
                    alt=""
                  />
                )}
              />
              <Column
                title="Action"
                key="action"
                render={(action) => (
                  <Space size="middle">
                    <a>
                      Continue Verification
                    </a>
                    <a onClick={showRejectModal}>Reject</a>
                  </Space>
                )}
              />
            </Table>
          </div>
        </div>}
        <div className="ausers">
          <div className="ausers-sub">
            <h5>Rejected Properties (3)</h5>
          </div>
          <div className="ausers-sub">
            <Table
              dataSource={rejectedData}
              className="ausers-sub-table-ant-design"
            >
              <Column title="Title" dataIndex="title" key="title" />
              <Column title="Area" dataIndex="area" key="area" />
              <Column title="Price" dataIndex="price" key="price" />
              <Column title="City" dataIndex="city" key="city" />
              <Column
                title="Property Image"
                dataIndex="propertyImage"
                key="propertyImage"
                render={(propertyImage) => (
                  <img
                    style={{
                      height: "45px",
                      width: "45px",
                      borderRadius: "50%",
                    }}
                    src={`data:image/jpeg;base64,${propertyImage}`}
                    alt=""
                  />
                )}
              />
              <Column
                title="Action"
                key="action"
                render={() => (
                  <Space size="middle">
                    <a onClick={showRejectReasonModal}>View Rejected Reason</a>
                  </Space>
                )}
              />
            </Table>
          </div>
        </div>
      </div>
      <Modal
        title="Are you sure to reject this property verification?"
        visible={isRejectModalVisible}
        onOk={handleRejectOk}
        onCancel={handleRejectCancel}
        okText="Reject"
        okType="danger"
        cancelText="Cancel"
      >
        <div className="aprofile-modal-reject-reason">
          <p>This action is permanent and cannot be undone.</p>
          <TextArea
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
            placeholder="Enter reject reason"
            autoSize={{
              minRows: 4,
              maxRows: 8,
            }}
          />
        </div>
      </Modal>
      <Modal
        title="Property Verification Rejected Reason"
        visible={isRejectReasonModalVisible}
        footer={null}
        onOk={handleRejectReasonOk}
        onCancel={handleRejectReasonCancel}
      >
        <div className="aprofile-modal-reject-reason">
          <p>The reson of rejection will come here.</p>
          <p>
            (Note: The user will also see the reason provided for rejection of
            his/her property.)
          </p>
        </div>
      </Modal>
      <Modal
        title="Assign this property verification"
        visible={isAssignModalVisible}
        onOk={handleAssignOk}
        onCancel={handleAssignCancel}
        okText="Assign"
        cancelText="Cancel"
      >
        <div className="aprofile-modal-reject-reason">
          <p>
            You can assign to any admin (Sub Admin or Reviewer), but only assign
            one admin at a time.
          </p>
          <Select
            showSearch
            name="adminsLists"
            value={
              selectedAssignedAdmin && selectedAssignedAdmin !== ""
                ? selectedAssignedAdmin
                : "Select Admin or Reviewer"
            }
            className="aprofile-modal-reject-reason-select"
            onChange={(value) => setSelectedAssignedAdmin(value)}
            optionFilterProp="label"
            options={[
              {
                label: <span>Sub Admin</span>,
                title: "manager",
                options: data
                  .filter((admin) => admin.roles[0] === "subAdmin")
                  .map((admin) => ({
                    label: admin.fullName,
                    value: admin.id,
                  })),
              },
              {
                label: <span>Reviewer</span>,
                title: "engineer",
                options: data
                  .filter((admin) => admin.roles[0] === "reviewer")
                  .map((admin) => ({
                    label: admin.fullName,
                    value: admin.id,
                  })),
              },
            ]}
          />
          <p>
            (Note: The selected admin will be allowed to verify the property of
            the user only.)
          </p>
        </div>
      </Modal>
    </div>
  );
}

export default AdminProperties;
