import React from "react";

// STYLES IMPORT
import "./Features.css";

// SVGS IMPORT
import buy from "../../assets/svgs/Features/buy.svg";
import rent from "../../assets/svgs/Features/rent.svg";
import sell from "../../assets/svgs/Features/sell.svg";

// ANT DESIGN ICONS
import { CheckCircleTwoTone, RightOutlined } from "@ant-design/icons";

function Features() {
  return (
    <div className="features">
      <div className="features-sub">
        <div className="features-left">
          <h5>WHY CHOOSE US</h5>
          <h1>Discover What Sets Our Real Estate Expertise Apart</h1>
          <p>
            Our commitment to excellence and deep understanding of the real
            estate market ensures that you receive unparalleled service and
            results tailored to your specific needs. Whether you're buying,
            selling, or renting, our team is here to guide you every step of the
            way. We pride ourselves on our transparent partnerships, ensuring
            that you are fully informed and confident in every decision you
            make. With proven expertise and a keen eye for detail, we provide
            customized solutions that cater to your unique requirements. Our
            extensive local area knowledge empowers us to offer insights that
            other agencies might overlook, making your real estate journey as
            smooth and successful as possible.
          </p>
          <div className="features-options">
            <div className="features-options-sub">
              <CheckCircleTwoTone className="features-options-sub-icon" />
              <p>Transparent Partnerships</p>
            </div>
            <div className="features-options-sub">
              <CheckCircleTwoTone className="features-options-sub-icon" />
              <p>Proven Expertise</p>
            </div>
          </div>
          <div className="features-options">
            <div className="features-options-sub">
              <CheckCircleTwoTone className="features-options-sub-icon" />
              <p>Customize Solutions</p>
            </div>
            <div className="features-options-sub">
              <CheckCircleTwoTone className="features-options-sub-icon" />
              <p>Local Area Knowledge</p>
            </div>
          </div>
          <span>
            Contact Us
            <RightOutlined className="features-left-span-icon" />
          </span>
        </div>
        <div className="features-right">
          <div className="features-right-sub">
            <img src={buy} alt="Buy" />
            <div className="features-item">
              <h5>Buy a New Home</h5>
              <p>
                Explore diverse properties and expertise guidance for a seamless
                buying experience.
              </p>
              <span>
                Learn More
                <RightOutlined className="features-item-span-icon" />
              </span>
            </div>
          </div>
          <div className="features-right-sub">
            <img src={rent} alt="Rent" />
            <div className="features-item">
              <h5>Rent a House</h5>
              <p>
                Explore a diverse variety of listings tailored precisely to suit
                your unique lifestyle needs.
              </p>
              <span>
                Learn More
                <RightOutlined className="features-item-span-icon" />
              </span>
            </div>
          </div>
          <div className="features-right-sub">
            <img src={sell} alt="Sell" />
            <div className="features-item">
              <h5>Buy a New Home</h5>
              <p>
                Explore diverse properties and expertise guidance for a seamless
                buying experience
              </p>
              <span>
                Learn More
                <RightOutlined className="features-item-span-icon" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
