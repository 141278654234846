import React, { useEffect, useState } from "react";
import "./AdminDashboard.css";
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../../../store/atoms/userDataAtom";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/AdminLoader";
function AdminDashboard() {
  const userDetails = useRecoilValue(userDataAtom);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (userDetails?.user.isAdmin === false) {
      return navigate("/");
    } else if (userDetails?.user.isAdmin === true) {
      setLoading(false);
    }
  }, [userDetails, navigate]);

  return (
    <>
      {loading && <Loader />}
      <div className="adashboard">
        <div className="adashboard-sub">
          <div className="adashboard-header">
            <h2>Dashboard</h2>
          </div>
          <div className="adashboard-container-one">
            <div className="adashboard-container-one-sub"></div>
            <div className="adashboard-container-one-sub"></div>
            <div className="adashboard-container-one-sub"></div>
            <div className="adashboard-container-one-sub"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminDashboard;
