import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Home.css";

// ANT DESIGN IMPORT
import { FloatButton } from "antd";

// COMPONENTS IMPORT
import Hero from "../../components/Hero/Hero";
import UnderConstruction from "../../components/UnderConstruction/UnderConstruction";
import Searches from "../../components/Searches/Searches";
import RecentlyViewed from "../../components/RecentlyViewed/RecentlyViewed";
import Features from "../../components/Features/Features";
import TrendingProperties from "../../components/TrendingProperties/TrendingProperties";
import TrendingProjects from "../../components/TrendingProjects/TrendingProjects";
import LatestNews from "../../components/LatestNews/LatestNews";
import Map from "../../components/Map/Map";
import TopCities from "../../components/TopCities/TopCities";

function Home() {
  useEffect(() => {
    document.title = "Proptzo - Sustainable Real Estate Solutions";
  }, []);


  return (
    <div className="home">
      <Hero />
      <UnderConstruction />
      <Searches />
      <Map/>
      <RecentlyViewed />
      <TrendingProperties />
      <Features />
      <LatestNews />
      <TrendingProjects />
      <TopCities />
      <FloatButton.BackTop
        duration={600}
        target={() => window}
        visibilityHeight={1800}
      />
    </div>
  );
}

export default Home;
