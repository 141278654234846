import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import PriceFormatter from "../../components/PriceFormatter/PriceFormatter";

// Styling Import
import "./AllProperties.css";

// Ant Design Imports
import { Select } from "antd";
import {
  SafetyCertificateOutlined,
  HeartOutlined,
  EyeOutlined,
  ShareAltOutlined,
  EnvironmentOutlined,
  ExpandAltOutlined,
} from "@ant-design/icons";

// REACT ICONS
import { PiBedDuotone, PiBathtubDuotone } from "react-icons/pi";

// Leaflet Map
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import L, { divIcon, point } from "leaflet";
import { IsBase64Image } from "../../utils/IsBase64";
import { fetchWishlistAtom } from "../../store/atoms/updateWIshlist";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userDataAtom } from "../../store/atoms/userDataAtom";
import { FaHeart } from "react-icons/fa";

function AllProperties() {
  const baseURL = process.env.REACT_APP_base_URL;
  const navigate = useNavigate();

  const userDetails = useRecoilValue(userDataAtom);
  const userId = userDetails?.user?._id;

  useEffect(() => {
    document.title = "Proptzo - Explore Properties & Projects";
  }, []);

  const [selectedCategory, setSelectedCategory] = useState("Properties");
  const [properties, setProperties] = useState([]);
  const [projects, setProjects] = useState([]);
  const [sortOption, setSortOption] = useState("Relevance");
  const [wishlist, setWishlist] = useState([]);

  const setCheckLatestWishlistCount = useSetRecoilState(fetchWishlistAtom);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleSortChange = (value) => {
    setSortOption(value);
  };

  const convertToSquareMeters = (area, unit) => {
    const unitConversion = {
      hectares: 10000,
      acres: 4046.86,
      "sq.yards": 0.836127,
      "sq.millimeters": 1e-6,
      "sq.miles": 2.59e6,
      "sq.meters": 1,
      "sq.kilometers": 1e6,
      "sq.feet": 0.092903,
      "sq.centimeters": 1e-4,
    };
    return area * (unitConversion[unit] || 1);
  };

  useEffect(() => {
    axios
      .get(`${baseURL}/api/post/publicposts`)
      .then((response) => {
        let fetchedProperties = response.data;

        switch (sortOption) {
          case "PriceLowToHigh":
            fetchedProperties = fetchedProperties.sort(
              (a, b) => a.propertyPrice - b.propertyPrice
            );
            break;
          case "PriceHighToLow":
            fetchedProperties = fetchedProperties.sort(
              (a, b) => b.propertyPrice - a.propertyPrice
            );
            break;
          case "Newest":
            fetchedProperties = fetchedProperties.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            break;
          case "Oldest":
            fetchedProperties = fetchedProperties.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            );
            break;
          case "AreaHighToLow":
            fetchedProperties = fetchedProperties.sort(
              (a, b) =>
                convertToSquareMeters(b.propertyArea, b.propertyAreaUnit) -
                convertToSquareMeters(a.propertyArea, a.propertyAreaUnit)
            );
            break;
          case "AreaLowToHigh":
            fetchedProperties = fetchedProperties.sort(
              (a, b) =>
                convertToSquareMeters(a.propertyArea, a.propertyAreaUnit) -
                convertToSquareMeters(b.propertyArea, b.propertyAreaUnit)
            );
            break;
          default:
            // Shuffle for relevance (you can implement a custom shuffle function)
            fetchedProperties = fetchedProperties.sort(
              () => Math.random() - 0.5
            );
            break;
        }

        setProperties(fetchedProperties);
      })
      .catch((error) => {
        console.error("There was an error fetching the properties!", error);
      });
  }, [baseURL, sortOption]);

  useEffect(() => {
    axios
      .get(`${baseURL}/api/project/publicprojects`)
      .then((response) => {
        let fetchedProjects = response.data;

        switch (sortOption) {
          case "PriceLowToHigh":
            fetchedProjects = fetchedProjects.sort(
              (a, b) =>
                a.projectPriceRange.priceFrom - b.projectPriceRange.priceFrom
            );
            break;
          case "PriceHighToLow":
            fetchedProjects = fetchedProjects.sort(
              (a, b) =>
                b.projectPriceRange.priceFrom - a.projectPriceRange.priceFrom
            );
            break;
          case "Newest":
            fetchedProjects = fetchedProjects.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            break;
          case "Oldest":
            fetchedProjects = fetchedProjects.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            );
            break;
          case "AreaHighToLow":
            fetchedProjects = fetchedProjects.sort(
              (a, b) =>
                convertToSquareMeters(b.projectArea, b.projectAreaUnit) -
                convertToSquareMeters(a.projectArea, a.projectAreaUnit)
            );
            break;
          case "AreaLowToHigh":
            fetchedProjects = fetchedProjects.sort(
              (a, b) =>
                convertToSquareMeters(a.projectArea, a.projectAreaUnit) -
                convertToSquareMeters(b.projectArea, b.projectAreaUnit)
            );
            break;
          default:
            // Shuffle for relevance (you can implement a custom shuffle function)
            fetchedProjects = fetchedProjects.sort(() => Math.random() - 0.5);
            break;
        }

        setProjects(fetchedProjects);
      })
      .catch((error) => {
        console.error("There was an error fetching the properties!", error);
      });
  }, [baseURL, sortOption]);

  // Property Share
  const handleShare = async (postId) => {
    const propertyUrl = `https://proptzo.com/viewproperty/${postId}`;
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Check out this property!",
          url: propertyUrl,
        });
      } else {
        alert(`Copy this link to share: ${propertyUrl}`);
      }
    } catch (error) {
      console.error("Error sharing the property:", error);
    }
  };
  const fetchWishlist = useCallback(async () => {
    try {
      const response = await axios.get(`${baseURL}/api/wishlist/${userId}`);
      setWishlist(response.data.wishlist);
    } catch (error) {
      console.error("Error fetching wishlist:", error);
    }
  },[baseURL, userId]);

  useMemo(() => {
    if(userId){
      fetchWishlist();
    }
  }, [userId, fetchWishlist]);

  const handleWishlistToggle = async (postId) => {
    if(userId){
      try {
      const response = await axios.post(`${baseURL}/api/wishlist/toggle`, {
        userId,
        postId,
      });
      if (response.data.isAdded) {
        setWishlist([...wishlist, { userId, postId }]);
      } else {
        setWishlist(wishlist.filter((item) => item.postId !== postId));
      }
    } catch (error) {
      console.error("Error toggling wishlist:", error);
    }
  }
  };
  // Map functionality

  const createCustomClusterIcon = (cluster) => {
    return new divIcon({
      html: `<div class = "cluster-icon">
  ${cluster.getChildCount()}
  </div>`,
      iconSize: point(33, 33, true),
      className: "custom-marker-cluster",
    });
  };

  const CustomMarker = ({
    position,
    propertyPrice,
    propertyCurrencyType,
    title,
    image,
    postId,
  }) => {
    const formattedPrice = PriceFormatter({
      amount: propertyPrice,
      currencyType: propertyCurrencyType,
    });

    const icon = L.divIcon({
      className: "custom-marker",
      html: `<div class = "custom-marker-sub">${formattedPrice.props.children[0]} ${formattedPrice.props.children[1]} ${formattedPrice.props.children[2]}
        <div class = "arrow-down"></div>
        </div>`,
    });

    const location = position;
    const latitude = location.latitude;
    const longitude = location.longitude;

    if (latitude && longitude) {
      return (
        <Marker position={[latitude, longitude]} icon={icon}>
          <Popup position={[latitude, longitude]}>
            <div className="custom-marker-popup">
              <div className="custom-popup-image">
                {IsBase64Image(image) ? (
                  <img
                    src={`data:image/jpeg;base64,${image}`}
                    alt="propertyimage"
                  />
                ) : (
                  <img src={image} alt="propertyimage" />
                )}
              </div>
              <div className="custom-popup-content">
                <h5>{title}</h5>
                <h4>
                  <PriceFormatter
                    amount={propertyPrice}
                    currencyType={propertyCurrencyType}
                  />
                </h4>
                <Link
                  to={`/viewproperty/${postId}`}
                  className="custom-popup-content-link"
                >
                  View Deal
                </Link>
              </div>
            </div>
          </Popup>
        </Marker>
      );
    }
  };
  const CustomMarkerForProject = ({
    position,
    projectName,
    projectCurrencyType,
    projectPriceRange,
    image,
    projectId,
  }) => {
    const formattedPriceFrom = PriceFormatter({
      amount: projectPriceRange.priceFrom,
      currencyType: projectCurrencyType,
    });
    const formattedPriceTo = PriceFormatter({
      amount: projectPriceRange.priceTo,
      currencyType: projectCurrencyType,
    });

    const generateInitials = (name) => {
      return name
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");
    };
    const projectInitials = generateInitials(projectName);
    const projectIcon = L.divIcon({
      className: "custom-marker-project",
      html: `<div class="project-details-container-map">
          <img src="data:image/jpeg;base64,${image}" onerror="this.src='https://placehold.co/31x31?text=${projectInitials}'" class="project-logo-map" />
        </div>`,
    });

    const location = position;
    const latitude = location.latitude;
    const longitude = location.longitude;

    if (latitude && longitude) {
      return (
        <Marker position={[latitude, longitude]} icon={projectIcon}>
          <Popup position={[latitude, longitude]}>
            <div className="custom-marker-popup">
              <div className="custom-popup-image">
                <img
                  src={`data:image/jpeg;base64,${image}`}
                  onerror="this.src='https://placehold.co/301x301?text=proptzo'"
                  alt={projectName}
                />
              </div>
              <div className="custom-popup-content">
                <h5>{projectName}</h5>
                <h4>
                  <PriceFormatter
                    amount={projectPriceRange.priceFrom}
                    currencyType={projectCurrencyType}
                  />{" "}
                  -{" "}
                  <PriceFormatter
                    amount={projectPriceRange.priceTo}
                    currencyType={projectCurrencyType}
                  />
                </h4>
                <Link
                  to={`/viewprojects/${projectId}`}
                  className="custom-popup-content-link"
                >
                  View Deal
                </Link>
              </div>
            </div>
          </Popup>
        </Marker>
      );
    }
  };

  // REFERENCE FOR LEAFLET MAP FUNCTIONALITY
  const propertyMapRef = useRef(null);

  const [currentHoveredProperty, setCurrentHoveredProperty] = useState(null);

  const gethoveredPropertyDetails = (property) => {
    let position = property.propertyLocation;
    if (
      position.latitude !== currentHoveredProperty?.[0] &&
      position.longitude !== currentHoveredProperty?.[1]
    ) {
      return setCurrentHoveredProperty([
        property.propertyLocation.latitude,
        property.propertyLocation.longitude,
      ]);
    } else {
      return;
    }
  };
  useEffect(() => {
    // Access the map instance from the MapContainer component (assuming it's a parent)

    // Function to center the map on user's location (if available)
    const centerOnUserLocation = () => {
      if (propertyMapRef.current) {
        // Check if mapInstance exists
        if (currentHoveredProperty?.length) {
          propertyMapRef.current.setView(
            [currentHoveredProperty[0], currentHoveredProperty[1]],
            15
          );
        }
      } else {
        console.error("Map instance not available yet"); // Handle the case where map is not ready
      }
    };

    // Call the centering function on component mount and when userCurrentLocation changes
    centerOnUserLocation();
  }, [currentHoveredProperty, propertyMapRef]);

  const projectMapRef = useRef(null);

  const [currentHoveredProject, setCurrentHoveredProject] = useState(null);

  const gethoveredProjectDetails = (project) => {
    let position = project.projectLocation;
    if (
      position.latitude !== currentHoveredProject?.[0] &&
      position.longitude !== currentHoveredProject?.[1]
    ) {
      return setCurrentHoveredProject([
        project.projectLocation.latitude,
        project.projectLocation.longitude,
      ]);
    } else {
      return;
    }
  };
  useEffect(() => {
    // Access the map instance from the MapContainer component (assuming it's a parent)

    // Function to center the map on user's location (if available)
    const centerOnUserLocation = () => {
      if (projectMapRef.current) {
        // Check if mapInstance exists
        if (currentHoveredProject?.length) {
          projectMapRef.current.setView(
            [currentHoveredProject[0], currentHoveredProject[1]],
            15
          );
        }
      } else {
        console.error("Map instance not available yet"); // Handle the case where map is not ready
      }
    };

    // Call the centering function on component mount and when userCurrentLocation changes
    centerOnUserLocation();
  }, [currentHoveredProject, projectMapRef]);

  return (
    <div className="ps">
      <div className="ps-sub">
        <div className="ps-search">
          <div className="ps-search-sub">
            <h5>Search</h5>
            <div className="ps-search-options">
              <div
                className={`ps-search-options-sub ${
                  selectedCategory === "Properties" ? "selected" : ""
                }`}
                onClick={() => handleCategoryChange("Properties")}
              >
                Properties
              </div>
              <div
                className={`ps-search-options-sub ${
                  selectedCategory === "Projects" ? "selected" : ""
                }`}
                onClick={() => handleCategoryChange("Projects")}
              >
                Projects
              </div>
            </div>
            {selectedCategory === "Properties" ? (
              <>
                <div className="ps-search-category">
                  <h5>Property Type</h5>
                  <Select
                    showSearch
                    mode="multiple"
                    allowClear
                    placeholder="Select Type"
                    optionFilterProp="label"
                    className="ps-search-category-select"
                    options={[
                      {
                        value: "Residential",
                        label: "Residential",
                      },
                      {
                        value: "Commercial",
                        label: "Commercial",
                      },
                      {
                        value: "Industrial",
                        label: "Industrial",
                      },
                    ]}
                  />
                </div>
                <div className="ps-search-category">
                  <h5>Property Sub-Type</h5>
                  <Select
                    showSearch
                    mode="multiple"
                    allowClear
                    placeholder="Select Sub-Type"
                    optionFilterProp="label"
                    className="ps-search-category-select"
                    options={[
                      {
                        value: "Flat / Apartment",
                        label: "Flat / Apartment",
                      },
                      {
                        value: "Independent House",
                        label: "Independent House",
                      },
                      {
                        value: "Penthouse",
                        label: "Penthouse",
                      },
                      {
                        value: "Villa",
                        label: "Villa",
                      },
                      {
                        value: "Plot",
                        label: "Plot",
                      },
                      {
                        value: "Builder Floor",
                        label: "Builder Floor",
                      },
                      {
                        value: "Shop",
                        label: "Shop",
                      },
                      {
                        value: "Showroom",
                        label: "Showroom",
                      },
                      {
                        value: "Booth",
                        label: "Booth",
                      },
                      {
                        value: "SOHO",
                        label: "SOHO",
                      },
                      {
                        value: "Office Space",
                        label: "Office Space",
                      },
                      {
                        value: "Co-Working Space",
                        label: "Co-Working Space",
                      },
                      {
                        value: "Industrial Plot",
                        label: "Industrial Plot",
                      },
                      {
                        value: "Industrial Space",
                        label: "Industrial Space",
                      },
                      {
                        value: "Industrial Shed",
                        label: "Industrial Shed",
                      },
                      {
                        value: "Godown",
                        label: "Godown",
                      },
                      {
                        value: "PG",
                        label: "PG",
                      },
                    ]}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="ps-search-category">
                  <h5>Project Status</h5>
                  <Select
                    showSearch
                    mode="multiple"
                    allowClear
                    placeholder="Select Status"
                    optionFilterProp="label"
                    className="ps-search-category-select"
                    options={[
                      {
                        value: "Ready to Move",
                        label: "Ready To move",
                      },
                      {
                        value: "Under Construction",
                        label: "Under Construction",
                      },
                    ]}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="ps-properties">
          <div className="ps-properties-sub">
            <div className="ps-properties-header">
              <h3>{selectedCategory}</h3>
              <Select
                showSearch
                placeholder="Sort By"
                optionFilterProp="label"
                defaultValue={"Relevance"}
                className="ps-properties-header-select"
                onChange={handleSortChange}
                options={[
                  {
                    value: "Relevance",
                    label: "Sort By (Relevance)",
                  },
                  {
                    value: "PriceLowToHigh",
                    label: "Sort By (Price: Low to High)",
                  },
                  {
                    value: "PriceHighToLow",
                    label: "Sort By (Price: High to Low)",
                  },
                  {
                    value: "Newest",
                    label: "Sort By (Newest)",
                  },
                  {
                    value: "Oldest",
                    label: "Sort By (Oldest)",
                  },
                  {
                    value: "AreaHighToLow",
                    label: "Sort By (Area: High to Low)",
                  },
                  {
                    value: "AreaLowToHigh",
                    label: "Sort By (Area: Low to High)",
                  },
                ]}
              />
            </div>
            <div className="ps-properties-allproperties">
              {selectedCategory === "Properties" ? (
                properties.length > 0 ? (
                  properties.map((property) => (
                    <div
                      key={property.id}
                      className="ps-property-main"
                      onMouseEnter={() => gethoveredPropertyDetails(property)}
                    >
                      <div className="trending-prop-img trending-prop-img-allproperties">
                        <img
                          src={`data:image/jpeg;base64,${property.propertyImages[0]}`}
                          alt="property"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              "https://placehold.co/800x450?text=proptzo";
                          }}
                        />
                        <div className="trending-for">
                          <h5>FOR {property.propertyAction.toUpperCase()}</h5>
                        </div>
                        <div className="trending-type">
                          <h5>{property.propertySubType.toUpperCase()}</h5>
                        </div>
                        <div className="trending-rera">
                          <SafetyCertificateOutlined className="trending-rera-icon" />
                          <h5>RERA CERTIFIED</h5>
                        </div>
                        <div className="trending-prop-img-icons">
                          <ShareAltOutlined
                            className="trending-prop-img-icons-icon"
                            onClick={() => handleShare(property._id)}
                          />

                          {wishlist.some(
                            (item) => item.postId === property._id
                          ) ? (
                            <FaHeart
                              className="trending-prop-img-icons-icon red"
                              onClick={() => {
                                handleWishlistToggle(property._id);
                                setCheckLatestWishlistCount(true);
                              }}
                              />
                            ) : (
                              <HeartOutlined
                              className="trending-prop-img-icons-icon"
                              onClick={() => {
                                handleWishlistToggle(property._id);
                                setCheckLatestWishlistCount(true);
                              }}
                            />
                          )}

                          <EyeOutlined
                            className="trending-prop-img-icons-icon"
                            onClick={() =>
                              navigate(`/viewproperty/${property._id}`)
                            }
                          />
                        </div>
                      </div>
                      <div className="ps-property-main-sub">
                        <h5>{property.propertyTitle}</h5>
                        <div className="trending-prop-loc">
                          <EnvironmentOutlined className="trending-prop-loc-icon" />
                          <p>
                            {property.propertyLocality}, {property.propertyCity}
                          </p>
                        </div>
                        <div className="trending-prop-data">
                          <PiBedDuotone className="trending-prop-data-icon" />
                          <h5>{property.propertyBedroomCount}</h5>
                          <PiBathtubDuotone className="trending-prop-data-icon" />
                          <h5>{property.propertyBathroomCount}</h5>
                          <ExpandAltOutlined className="trending-prop-data-icon" />
                          <h5>
                            {property.propertyArea}, {property.propertyAreaUnit}
                          </h5>
                        </div>
                        <div className="trending-prop-footer trending-prop-footer-ps-property">
                          <h5 className="trending-prop-footer-name">
                            {property.sellerName}
                          </h5>
                          <h5 className="trending-prop-footer-price">
                            <PriceFormatter
                              amount={property.propertyPrice}
                              currencyType={property.propertyCurrencyType}
                            />
                          </h5>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No properties found !</div>
                )
              ) : projects.length > 0 ? (
                projects.map((project) => (
                  <div
                    key={project.id}
                    className="ps-property-main"
                    onMouseEnter={() => gethoveredProjectDetails(project)}
                  >
                    <div className="trending-prop-img trending-prop-img-allproperties">
                      <img
                        src={`data:image/jpeg;base64,${project.projectLogo}`}
                        alt="project"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://placehold.co/800x450?text=proptzo";
                        }}
                      />
                      <div className="trending-for">
                        <h5>{project.projectStatus}</h5>
                      </div>
                      <div className="trending-type">
                        <h5>{project.projectOwnershipType}</h5>
                      </div>
                      <div className="trending-rera">
                        <SafetyCertificateOutlined className="trending-rera-icon" />
                        <h5>RERA CERTIFIED</h5>
                      </div>
                      <div className="trending-prop-img-icons">
                        <ShareAltOutlined
                          className="trending-prop-img-icons-icon"
                          onClick={() => handleShare(project._id)}
                        />
                        <EyeOutlined
                          className="trending-prop-img-icons-icon"
                          onClick={() =>
                            navigate(`/viewprojects/${project._id}`)
                          }
                        />
                      </div>
                    </div>
                    <div className="ps-property-main-sub">
                      <h5>{project.projectName}</h5>
                      <div className="trending-prop-loc">
                        <EnvironmentOutlined className="trending-prop-loc-icon" />
                        <p>
                          {project.projectLocality}, {project.projectCity}
                        </p>
                      </div>
                      <div className="trending-prop-data">
                        <ExpandAltOutlined className="trending-prop-data-icon" />
                        <h5>
                          {project.projectArea}, {project.projectAreaUnit}
                        </h5>
                      </div>
                      <div className="trending-prop-footer trending-prop-footer-ps-property">
                        <h5 className="trending-prop-footer-name">
                          {project.sellerName}
                        </h5>
                        <h5 className="trending-prop-footer-price">
                          {project?.projectPriceRange?.priceFrom &&
                          project?.projectPriceRange?.priceTo ? (
                            <>
                              <PriceFormatter
                                amount={project.projectPriceRange.priceFrom}
                                currencyType={project.projectCurrencyType}
                              />
                              {" - "}
                              <PriceFormatter
                                amount={project.projectPriceRange.priceTo}
                                currencyType={project.projectCurrencyType}
                              />
                            </>
                          ) : (
                            "Loading..."
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>No projects found !</div>
              )}
            </div>
          </div>
        </div>
        <div className="ps-map">
          {selectedCategory === "Properties" ? (
            <MapContainer
              center={[20.5937, 78.9629]}
              zoom={5}
              className="ps-map-sub"
              ref={propertyMapRef}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              <MarkerClusterGroup
                chunkedLoading
                iconCreateFunction={createCustomClusterIcon}
              >
                {properties &&
                  properties.map((property, index) => (
                    <CustomMarker
                      key={index}
                      postId={property._id}
                      position={property.propertyLocation}
                      title={property.propertyTitle}
                      image={
                        property.propertyImages.length > 0
                          ? property.propertyImages[0]
                          : "https://placehold.co/250x250?text=p"
                      }
                      propertyCurrencyType={property.propertyCurrencyType}
                      propertyPrice={property.propertyPrice}
                    ></CustomMarker>
                  ))}
              </MarkerClusterGroup>
            </MapContainer>
          ) : (
            <MapContainer
              center={[20.5937, 78.9629]}
              zoom={5}
              className="ps-map-sub"
              ref={projectMapRef}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <MarkerClusterGroup
                chunkedLoading
                iconCreateFunction={createCustomClusterIcon}
              >
                {projects &&
                  projects.map((project, index) => (
                    <CustomMarkerForProject
                      key={index}
                      projectId={project._id}
                      position={project.projectLocation}
                      projectName={project.projectName}
                      image={
                        project.projectLogo
                          ? project.projectLogo
                          : "https://placehold.co/250x250?text=p"
                      }
                      projectCurrencyType={project.projectCurrencyType}
                      projectPriceRange={project.projectPriceRange}
                    ></CustomMarkerForProject>
                  ))}
              </MarkerClusterGroup>
            </MapContainer>
          )}
        </div>
      </div>
    </div>
  );
}

export default AllProperties;
