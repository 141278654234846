import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

// STYLING IMPORT
import "../../pages/PostProperty/AddProperty.css";
import "../SignUp/InitialProfile.css";

// LOADER IMPORT
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";

// REACT ICONS
import { GoArrowLeft } from "react-icons/go";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { GrPowerReset } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import {
  MdCompareArrows,
  MdOutlineBedroomParent,
  MdOutlineCompareArrows,
  MdOutlineDashboardCustomize,
  MdOutlineKitchen,
} from "react-icons/md";
import { LuFile, LuFileImage } from "react-icons/lu";
import { PiCity } from "react-icons/pi";
import {
  BsBuilding,
  BsHouseDoor,
  BsBuildingLock,
  BsFillHouseFill,
  BsFillGrid1X2Fill,
  BsGrid3X3GapFill,
  BsShop,
  BsShopWindow,
  BsBriefcase,
  BsBuildingUp,
  BsBox,
  BsFillBriefcaseFill,
  BsBank,
  BsHouse,
  BsCurrencyRupee,
  BsCurrencyDollar,
  BsBuildingCheck,
} from "react-icons/bs";
import {
  AiOutlineAlert,
  AiOutlineFileImage,
  AiOutlineExpandAlt,
  AiOutlineProject,
} from "react-icons/ai";
import { FaRegCircleCheck } from "react-icons/fa6";
import { CgAdd, CgTag } from "react-icons/cg";
import { GrCurrency } from "react-icons/gr";
import { GiNewShoot } from "react-icons/gi";
import { HiOutlineBuildingStorefront } from "react-icons/hi2";
import { MdOutlineSpeakerNotes } from "react-icons/md";
import {
  FaBatteryFull,
  FaBuilding,
  FaCar,
  FaChess,
  FaChild,
  FaCoffee,
  FaCompass,
  FaDumbbell,
  FaFutbol,
  FaGolfBall,
  FaHome,
  FaHospital,
  FaLeaf,
  FaMoneyCheckAlt,
  FaPhoneAlt,
  FaRunning,
  FaSchool,
  FaShieldAlt,
  FaShoppingCart,
  FaSwimmingPool,
  FaTint,
  FaTools,
  FaUsers,
} from "react-icons/fa";

// ANT DESIGN IMPORT
import { message, Popconfirm, Select, Checkbox } from "antd";
import {
  AimOutlined,
  LoadingOutlined,
  PushpinOutlined,
} from "@ant-design/icons";

// CITIES IMPORT
import citiesData from "../../utils/CitiesData";

// LEAFLET MAP INTEGRATION
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import locationPin from "../../assets/svgs/locpin.svg";
import L from "leaflet";
import { addPropertySchema } from "../../schemas/addPropertySchema";
import { IsBase64Image } from "../../utils/IsBase64";
import { useRecoilValue } from "recoil";
import { userDataAtom } from "../../store/atoms/userDataAtom";

// REUSABLE FORM INPUT COMPONENT
const FormInput = ({
  icon: Icon,
  placeholder,
  value = "",
  onChange,
  type = "text",
}) => (
  <div className="utr-form-input">
    <Icon className="utr-form-input-icon" />
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={value.trim() !== "" ? "utr-form-input-selected" : ""}
    />
  </div>
);

// REUSABLE IMAGE UPLOAD COMPONENT
const ImageUpload = ({ image, onImageChange, onRemove, label }) => (
  <>
    {image ? (
      <div className="utr-form-images-sub">
        {IsBase64Image(image) ? (
          <img src={`data:image/jpeg;base64,${image}`} alt={`${label}`} />
        ) : (
          <img src={URL.createObjectURL(image)} alt={`${label}`} />
        )}
        <div className="utr-form-images-remove" onClick={onRemove}>
          <IoClose className="utr-form-images-remove-icon" />
        </div>
      </div>
    ) : (
      <div className="utr-form-images-sub">
        <label htmlFor={`utr-image-upload-${label}`}>
          <LuFileImage className="utr-form-images-sub-icon" />
          <h5>{label}</h5>
        </label>
        <input
          id={`utr-image-upload-${label}`}
          type="file"
          accept="image/*"
          onChange={onImageChange}
          style={{ display: "none" }}
        />
      </div>
    )}
  </>
);

const MultipleImageUpload = ({
  images,
  onImageChange,
  onRemove,
  label,
  maxImages,
}) => {
  return (
    <div className="utr-form-images">
      {images.map((image, index) => (
        <div key={index} className="utr-form-images-sub">
          {IsBase64Image(image.base64ImgString) ? (
            <img
              src={`data:image/jpeg;base64,${image.base64ImgString}`}
              alt={`${label}-${index}`}
            />
          ) : (
            <img src={URL.createObjectURL(image)} alt={`${label}-${index}`} />
          )}
          <div
            className="utr-form-images-remove"
            onClick={() => onRemove(index)}
          >
            <IoClose className="utr-form-images-remove-icon" />
          </div>
        </div>
      ))}
      {images.length < maxImages && (
        <div className="utr-form-images-sub">
          <label htmlFor={`utr-image-upload-${label}`}>
            <LuFileImage className="utr-form-images-sub-icon" />
            <h5>{label}</h5>
          </label>
          <input
            id={`utr-image-upload-${label}`}
            type="file"
            accept="image/*"
            onChange={onImageChange}
            style={{ display: "none" }}
            multiple
          />
        </div>
      )}
    </div>
  );
};

// REUSABLE FILE UPLOAD COMPONENT
const FileUpload = ({ file, onFileChange, onRemove, label }) => (
  <>
    {file ? (
      <div className="utr-form-images-sub utr-form-images-sub-pdf">
        {file.type === "application/pdf" ? (
          <>
            {/* <iframe
              src={URL.createObjectURL(file)}
              title={label}
              className="utr-form-files-preview"
            /> */}
            <div className="utr-form-files-preview-doc">
              <LuFile className="utr-form-images-sub-icon" />
              <h5>{file.name || 'property brochure'}</h5>
            </div>
          </>
        ) : (
          <div className="utr-form-files-preview-doc">
            <LuFile className="utr-form-images-sub-icon" />
            <h5>{file.name || 'property brochure'}</h5>
          </div>
        )}
        <div className="utr-form-images-remove" onClick={onRemove}>
          <IoClose className="utr-form-images-remove-icon" />
        </div>
      </div>
    ) : (
      <div className="utr-form-images-sub">
        <label htmlFor={`utr-file-upload-${label}`}>
          <LuFileImage className="utr-form-images-sub-icon" />
          <h5>{label}</h5>
        </label>
        <input
          id={`utr-file-upload-${label}`}
          type="file"
          accept=".pdf,.doc,.docx"
          onChange={onFileChange}
          style={{ display: "none" }}
        />
      </div>
    )}
  </>
);

function UpdateProperty() {
  const baseURL = process.env.REACT_APP_base_URL;
  const userDetails = useRecoilValue(userDataAtom);
  const userId = userDetails?.user?._id;
  const userType = userDetails?.userProfile?.userType;
  const { dashboardPropertyId } = useParams();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Proptzo - Update Property Details";
  }, []);

  // Steps
  const [currentStep, setCurrentStep] = useState(1);
  // const [currentStep, setCurrentStep] = useState(userType === "Owner" ? 2 : 1);
  const nextStep = () => {
    if (
      currentStep === 1 &&
      propertyDetails.associatedProjectId &&
      addPropertySchema.safeParse({ floorPlan: propertyDetails.floorPlan })
        .success === false
    ) {
      message.error(
        `Please select property floor plan if the property is associated with ${selectedProjectName}`
      );
      return;
    }

    if (
      currentStep === 2 &&
      addPropertySchema.safeParse({ action: propertyDetails.action })
        .success === false
    ) {
      message.error(
        `Please select one option: 'Sale / Rent/Lease / PG/Hostel'`
      );
      return;
    }

    if (
      currentStep === 2 &&
      addPropertySchema.safeParse({ type: propertyDetails.type }).success ===
        false
    ) {
      message.error(`Please select your property type`);
      return;
    }

    if (
      currentStep === 2 &&
      addPropertySchema.safeParse({ subType: propertyDetails.subType })
        .success === false
    ) {
      message.error(`Please select your property sub-type`);
      return;
    }

    if (
      currentStep === 3 &&
      addPropertySchema.safeParse({ title: propertyDetails.title }).success ===
        false
    ) {
      const errorMessage = addPropertySchema.safeParse({
        title: propertyDetails.title,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Property title");
      message.error(customMessage);
      return;
    }

    if (
      currentStep === 3 &&
      addPropertySchema.safeParse({ description: propertyDetails.description })
        .success === false
    ) {
      const errorMessage = addPropertySchema.safeParse({
        description: propertyDetails.description,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Property description"
      );
      message.error(customMessage);
      return;
    }

    if (
      currentStep === 4 &&
      addPropertySchema.safeParse({ city: propertyDetails.city }).success ===
        false
    ) {
      message.error("Select property city.");
      return;
    }

    if (
      currentStep === 4 &&
      (propertyDetails.location.latitude === 0 ||
        propertyDetails.location.longitude === 0)
    ) {
      message.error("Please provide estimate nearby property location on map");
      return;
    }

    if (
      currentStep === 4 &&
      addPropertySchema.safeParse({ locality: propertyDetails.locality })
        .success === false
    ) {
      const errorMessage = addPropertySchema.safeParse({
        locality: propertyDetails.locality,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Property locality"
      );
      message.error(customMessage);
      return;
    }

    if (
      currentStep === 5 &&
      addPropertySchema.safeParse({ area: propertyDetails.area }).success ===
        false
    ) {
      const errorMessage = addPropertySchema.safeParse({
        area: propertyDetails.area,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Property area");
      message.error(customMessage);
      return;
    }

    if (
      currentStep === 5 &&
      addPropertySchema.safeParse({ areaUnit: propertyDetails.areaUnit })
        .success === false
    ) {
      message.error("Select your Area Type before proceeding!");
      return;
    }

    if (
      currentStep === 5 &&
      addPropertySchema.safeParse({ price: propertyDetails.price }).success ===
        false
    ) {
      const errorMessage = addPropertySchema.safeParse({
        price: propertyDetails.price,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Property price");
      message.error(customMessage);
      return;
    }

    if (
      currentStep === 5 &&
      addPropertySchema.safeParse({
        currencyType: propertyDetails.currencyType,
      }).success === false
    ) {
      const errorMessage = addPropertySchema.safeParse({
        currencyType: propertyDetails.currencyType,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(
        /^String/,
        "Property currency type"
      );
      message.error(customMessage);
      return;
    }

    setLoading(true);
    setTimeout(() => {
      if (currentStep === 5 && propertyDetails.subType === "Plot") {
        setCurrentStep(7); // Skip step 6 for 'Plot' subtype
      } else {
        setCurrentStep(currentStep + 1);
      }
      setLoading(false);
    }, 300);
  };
  const prevStep = () => {
    setLoading(true);
    setTimeout(() => {
      if (currentStep === 7 && propertyDetails.subType === "Plot") {
        setCurrentStep(5); // Directly go back to step 5 from step 7 for 'Plot' subtype
      } else {
        setCurrentStep(currentStep - 1);
      }
      setLoading(false);
    }, 300);
  };

  // Form Reset
  const confirmFormReset = (e) => {
    message.success("Profile Data Reset success!");
    setPropertyDetails({
      associatedProjectId: "",
      floorPlan: "",
      action: "",
      type: "",
      subType: "",
      status: "",
      dealType: "",
      ownershipType: "",
      furnishedType: "",
      title: "",
      description: "",
      city: "",
      location: {
        latitude: 0,
        longitude: 0,
      },
      locality: "",
      area: "",
      builtupArea: "",
      carpetArea: "",
      areaUnit: "",
      price: "",
      currencyType: "₹",
      additionalRooms: [],
      bedroom: "",
      bathroom: "",
      balcony: "",
      onFloor: "",
      totalFloor: "",
      totalMasterBedroom: "",
      totalKitchen: "",
      amenities: [],
      customAmenity: "",
      showCustomAmenityInput: false,
      images: [],
      layout: null,
      brochure: null,
    });
    setTitleCharacterCount(0);
    setDescriptionCharacterCount(0);
    // Reset Map States
    setClickedPosition(null);
    setPlaceName("");
    setPrevPlaceName("");
    setZoomLevel(12);
    setSelectedCityCoordinates({
      longitude: 0,
      latitude: 0,
    });
    setShowAllBedrooms(false);
    setShowAllBathrooms(false);
    setShowAllBalconies(false);
    setIsInitialPropertyDetailsUpdateDone(false);
    propertyDetailsFromApi.current = null;
    setCurrentStep(1);
  };
  const cancelFormReset = (e) => {
    message.error("Reset cancelled!");
  };

  // Progress Bar
  const totalSteps = 8;
  const calculateProgressWidth = (currentStep, totalSteps) => {
    if (currentStep <= 1) {
      return 0;
    } else if (currentStep === 2) {
      return 35;
    } else {
      return 35 + ((currentStep - 2) / (totalSteps - 2)) * 65;
    }
  };
  const progressWidth = calculateProgressWidth(currentStep, totalSteps);

  // Cities with their GeoCodes
  const cityOptions = citiesData.map((city) => ({
    cityName: city.city,
    geocode: city.geoCode,
  }));
  const filterCityData = (selectedCity) =>
    citiesData.filter((city) => city.city === selectedCity)[0];

  const getPropertyData = useCallback(
    async (dashboardPropertyId) => {
      try {
        const response = await axios.get(
          `${baseURL}/api/post/dashboardPosts/${dashboardPropertyId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        return response.data;
      } catch (err) {
        console.log(err);
      }
    },
    [baseURL]
  );

  const propertyDetailsFromApi = useRef(null);
  useMemo(async () => {
    if (dashboardPropertyId) {
      propertyDetailsFromApi.current = await getPropertyData(
        dashboardPropertyId
      );
    }
  }, [dashboardPropertyId, getPropertyData]);

  // Property

  const [propertyDetails, setPropertyDetails] = useState({
    associatedProjectId: "",
    floorPlan: "",
    action: "",
    type: "",
    subType: "",
    status: "",
    dealType: "",
    ownershipType: "",
    furnishedType: "",
    title: "",
    description: "",
    city: "",
    location: {
      latitude: 0,
      longitude: 0,
    },
    locality: "",
    area: "",
    builtupArea: "",
    carpetArea: "",
    areaUnit: "",
    price: "",
    currencyType: "₹",
    additionalRooms: [],
    bedroom: "",
    bathroom: "",
    balcony: "",
    onFloor: "",
    totalFloor: "",
    totalMasterBedroom: "",
    totalKitchen: "",
    amenities: [],
    customAmenity: "",
    showCustomAmenityInput: false,
    images: [],
    layout: null,
    brochure: null,
  });
  const { OptGroup, Option } = Select;
  const [userProjects, setUserProjects] = useState(null);
  const [allProjects, setAllProjects] = useState(null);
  const fetchProjectsFromAPI = useCallback(async () => {
    try {
      const response = await axios.get(
        `${baseURL}/api/project/getprojects/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      if (
        response.status === 200 ||
        response.statusText === "OK" ||
        response?.data
      ) {
        setUserProjects(response.data.userProjects);
        setAllProjects(response.data.allProjects);
      } else {
        throw new Error("Failed to fetch projects from API");
      }
    } catch (error) {
      console.error("Error fetching userType:", error);
    }
  }, [baseURL, userId]);
  useMemo(() => {
    fetchProjectsFromAPI();
  }, [fetchProjectsFromAPI]);
  const [selectedProjectFloorPlans, setSelectedProjectFloorPlans] =
    useState(null);
  const [selectedProjectName, setSelectedProjectName] = useState(null);
  const selectAssociatedProject = useCallback(
    (associatedProjectId) => {
      const selectedProjectFloorPlans =
        userProjects?.filter(
          (project) => project._id === associatedProjectId
        )[0]?.projectFloorPlans ||
        allProjects?.filter((project) => project._id === associatedProjectId)[0]
          ?.projectFloorPlans;

      const selectedProjectName =
        userProjects?.filter(
          (project) => project._id === associatedProjectId
        )[0]?.projectName ||
        allProjects?.filter((project) => project._id === associatedProjectId)[0]
          ?.projectName;

      return { selectedProjectFloorPlans, selectedProjectName };
    },
    [allProjects, userProjects]
  );
  useMemo(() => {
    setSelectedProjectFloorPlans(
      selectAssociatedProject(propertyDetails.associatedProjectId)
        .selectedProjectFloorPlans
    );
    setSelectedProjectName(
      selectAssociatedProject(propertyDetails.associatedProjectId)
        .selectedProjectName
    );
  }, [propertyDetails.associatedProjectId, selectAssociatedProject]);

  // Property Associated Project Floor Plan
  const handleAssociatedProjectFloorPlanChange = (status) => {
    setPropertyDetails((prev) => ({
      ...prev,
      floorPlan: status,
    }));
  };
  const is1BhkSelected = propertyDetails.floorPlan === "1 BHK";
  const is2BhkSelected = propertyDetails.floorPlan === "2 BHK";
  const is3BhkSelected = propertyDetails.floorPlan === "3 BHK";
  const is4BhkSelected = propertyDetails.floorPlan === "4 BHK";
  const is5BhkSelected = propertyDetails.floorPlan === "5 BHK";
  const is6BhkSelected = propertyDetails.floorPlan === "6 BHK";
  const isSohoSelected = propertyDetails.floorPlan === "SOHO";
  const isStudioApartmentSelected =
    propertyDetails.floorPlan === "Studio Apartment";

  // Property Action
  const handleActionChange = (action) => {
    setPropertyDetails((prev) => ({
      ...prev,
      action,
      // type: "",
      subType: "",
      status:
        action === "Rent / Lease" || action === "PG / Hostel"
          ? "Ready to Move"
          : "",
    }));
  };
  const isSaleSelected = propertyDetails.action === "Sale";
  const isRentLeaseSelected = propertyDetails.action === "Rent / Lease";
  const isPgHostelSelected = propertyDetails.action === "PG / Hostel";

  // Property Type
  const handleTypeChange = (type) => {
    setPropertyDetails((prev) => ({
      ...prev,
      type,
      subType: "",
    }));
  };
  const isResidentialSelected = propertyDetails.type === "Residential";
  const isCommercialSelected = propertyDetails.type === "Commercial";
  const isIndustrialSelected = propertyDetails.type === "Industrial";

  // Property Sub-Type
  const handleSubTypeChange = (subType) => {
    setPropertyDetails((prev) => ({
      ...prev,
      subType,
      furnishedType: "",
      bedroom: "",
      bathroom: "",
      balcony: "",
      onFloor: "",
      totalFloor: "",
      totalMasterBedroom: "",
      totalKitchen: "",
    }));
    setShowAllBedrooms(false);
    setShowAllBathrooms(false);
    setShowAllBalconies(false);
  };
  const validSubTypes = {
    Sale: {
      Residential: [
        "Flat / Apartment",
        "Independent House",
        "Penthouse",
        "Villa",
        "Plot",
        "Builder Floor",
      ],
      Commercial: [
        "Shop",
        "Showroom",
        "Booth",
        "SOHO",
        "Office Space",
        "Co-Working Space",
      ],
      Industrial: [
        "Industrial Plot",
        "Industrial Space",
        "Industrial Shed",
        "Godown",
      ],
    },
    "Rent / Lease": {
      Residential: [
        "PG",
        "Flat / Apartment",
        "Independent House",
        "Penthouse",
        "Villa",
        "Plot",
        "Builder Floor",
      ],
      Commercial: [
        "Shop",
        "Showroom",
        "Booth",
        "SOHO",
        "Office Space",
        "Co-Working Space",
      ],
      Industrial: [
        "Industrial Plot",
        "Industrial Space",
        "Industrial Shed",
        "Godown",
      ],
    },
    "PG / Hostel": {
      Residential: ["PG", "Flat / Apartment", "Independent House"],
      Commercial: [],
      Industrial: [],
    },
  };
  const renderSubTypes = () => {
    const subTypes =
      validSubTypes[propertyDetails.action]?.[propertyDetails.type] || [];
    return subTypes.map((subType) => (
      <div
        key={subType}
        className={`utr-form-types-sub ${
          propertyDetails.subType === subType ? "selected" : ""
        }`}
        onClick={() => handleSubTypeChange(subType)}
      >
        {getSubTypeIcon(subType)}
        <h5>{subType}</h5>
      </div>
    ));
  };
  const getSubTypeIcon = (subType) => {
    switch (subType) {
      case "Flat / Apartment":
        return <BsBuilding className="utr-form-types-sub-icon" />;
      case "Independent House":
        return <BsHouseDoor className="utr-form-types-sub-icon" />;
      case "Penthouse":
        return <BsBuildingLock className="utr-form-types-sub-icon" />;
      case "Villa":
        return <BsFillHouseFill className="utr-form-types-sub-icon" />;
      case "Plot":
        return <BsFillGrid1X2Fill className="utr-form-types-sub-icon" />;
      case "Builder Floor":
        return <BsGrid3X3GapFill className="utr-form-types-sub-icon" />;
      case "Shop":
        return <BsShop className="utr-form-types-sub-icon" />;
      case "Showroom":
        return <BsShopWindow className="utr-form-types-sub-icon" />;
      case "Booth":
        return <BsBriefcase className="utr-form-types-sub-icon" />;
      case "SOHO":
        return <BsBuildingUp className="utr-form-types-sub-icon" />;
      case "Office Space":
        return <BsBox className="utr-form-types-sub-icon" />;
      case "Co-Working Space":
        return <BsFillBriefcaseFill className="utr-form-types-sub-icon" />;
      case "Industrial Plot":
        return <BsBank className="utr-form-types-sub-icon" />;
      case "Industrial Space":
        return <BsBuilding className="utr-form-types-sub-icon" />;
      case "Industrial Shed":
        return (
          <HiOutlineBuildingStorefront className="utr-form-types-sub-icon" />
        );
      case "Godown":
        return (
          <HiOutlineBuildingStorefront className="utr-form-types-sub-icon" />
        );
      case "PG":
        return <BsBuilding className="utr-form-types-sub-icon" />;
      default:
        return null;
    }
  };

  // Property Status
  const handleStatusChange = (status) => {
    setPropertyDetails((prev) => ({
      ...prev,
      status,
    }));
  };
  const isReadyToMoveSelected = propertyDetails.status === "Ready to Move";
  const isUnderConstructionSelected =
    propertyDetails.status === "Under Construction";

  // Property Deal Type
  const handleDealTypeChange = (status) => {
    setPropertyDetails((prev) => ({
      ...prev,
      dealType: status,
    }));
  };
  const isNewSelected = propertyDetails.dealType === "New";
  const isReSaleSelected = propertyDetails.dealType === "Re-Sale";
  useEffect(() => {
    if (userType === "Builder") {
      setPropertyDetails((prev) => ({
        ...prev,
        dealType: "New",
      }));
    }
  }, [userType]);

  // Property Ownership Type
  const handleOwnershipTypeChange = (status) => {
    setPropertyDetails((prev) => ({
      ...prev,
      ownershipType: status,
    }));
  };
  const isFreeholdSelected = propertyDetails.ownershipType === "Freehold";
  const isLeaseholdSelected = propertyDetails.ownershipType === "Leasehold";
  const isPowerOfAttorneySelected =
    propertyDetails.ownershipType === "Power of Attorney";
  const isCoOperativeSocietySelected =
    propertyDetails.ownershipType === "Co-operative Society";
  const handleClearOwnershipTypeSelection = () => {
    setPropertyDetails((prev) => ({
      ...prev,
      ownershipType: "",
    }));
  };

  // Property Furnished Type
  const handleFurnishedTypeChange = (status) => {
    setPropertyDetails((prev) => ({
      ...prev,
      furnishedType: status,
    }));
  };
  const isUnfurnishedSelected =
    propertyDetails.furnishedType === "Un-Furnished";
  const isSemifurnishedSelected =
    propertyDetails.furnishedType === "Semi-Furnished";
  const isFullyfurnishedSelected =
    propertyDetails.furnishedType === "Fully-Furnished";
  const handleClearFurnishedTypeSelection = () => {
    setPropertyDetails((prev) => ({
      ...prev,
      furnishedType: "",
    }));
  };

  // Property Title
  const [titleCharacterCount, setTitleCharacterCount] = useState(0);
  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    if (newTitle.length <= 150) {
      setPropertyDetails((prev) => ({
        ...prev,
        title: newTitle,
      }));
      setTitleCharacterCount(newTitle.length);
    } else {
      setPropertyDetails((prev) => ({
        ...prev,
        title: newTitle.slice(0, 150),
      }));
      setTitleCharacterCount(150);
    }
  };
  const titleSpanContent =
    titleCharacterCount === 150
      ? "Max character limit reached"
      : "Provide property title";

  // Property Description
  const [descriptionCharacterCount, setDescriptionCharacterCount] = useState(0);
  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    if (newDescription.length <= 2500) {
      setPropertyDetails((prev) => ({
        ...prev,
        description: newDescription,
      }));
      setDescriptionCharacterCount(newDescription.length);
    } else {
      setPropertyDetails((prev) => ({
        ...prev,
        description: newDescription.slice(0, 2500),
      }));
      setDescriptionCharacterCount(2500);
    }
  };
  const descriptionSpanContent =
    descriptionCharacterCount === 2500
      ? "Max character limit reached"
      : "Provide property description";

  // Property Locality
  const [localityCharacterCount, setLocalityCharacterCount] = useState(0);
  const handleLocalityChange = (e) => {
    const newLocality = e.target.value;
    if (newLocality.length <= 500) {
      setPropertyDetails((prev) => ({
        ...prev,
        locality: newLocality,
      }));
      setLocalityCharacterCount(newLocality.length);
    } else {
      setPropertyDetails((prev) => ({
        ...prev,
        locality: newLocality.slice(0, 500),
      }));
      setLocalityCharacterCount(500);
    }
  };
  const localitySpanContent =
    localityCharacterCount === 500
      ? "Max character limit reached"
      : "Provide property locality";
  // Property Location
  const [clickedPosition, setClickedPosition] = useState(null);
  const [placeName, setPlaceName] = useState("");
  const [prevPlaceName, setPrevPlaceName] = useState("");
  const [zoomLevel, setZoomLevel] = useState(12);
  const [selectedCityCoordinates, setSelectedCityCoordinates] = useState({
    longitude: 0,
    latitude: 0,
  });
  function ClickHandler() {
    const map = useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        setClickedPosition([lat, lng]);
        setPropertyDetails((prev) => ({
          ...prev,
          location: { latitude: lat, longitude: lng },
        }));
        const fetchLocality = async () =>
          await axios
            .get(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&addressdetails=${1}`
            )
            .then((response) => {
              const { display_name } = response.data;
              setPlaceName(display_name);

              setPropertyDetails((prev) => ({
                ...prev,
                locality: display_name,
              }));
            })
            .catch((error) => {
              console.error("Error fetching place name:", error);
              setPlaceName(null);
            });
        fetchLocality();
      },
    });
    useEffect(() => {
      if (placeName !== "" && placeName !== prevPlaceName) {
        setPropertyDetails((prev) => ({
          ...prev,
          locality: placeName,
        }));
        setPrevPlaceName(placeName);
      }
    }, [placeName]);
    useEffect(() => {
      if (
        selectedCityCoordinates.latitude !== 0 &&
        selectedCityCoordinates.longitude !== 0 &&
        map
      ) {
        map.setView(
          [selectedCityCoordinates.latitude, selectedCityCoordinates.longitude],
          zoomLevel
        );
      }
    }, [selectedCityCoordinates, zoomLevel, map]);
    useEffect(() => {
      const handleZoomEnd = () => {
        if (map) {
          setZoomLevel(map.getZoom());
        }
      };
      const handleMoveEnd = () => {
        if (map) {
          const { lng, lat } = map.getCenter();
          setSelectedCityCoordinates((prevCityCoordinates) => ({
            ...prevCityCoordinates,
            latitude: lat,
            longitude: lng,
          }));
        }
      };
      if (map) {
        map.on("zoomend", handleZoomEnd);
        map.on("dragend", handleMoveEnd);
      }
      return () => {
        if (map) {
          map.off("zoomend", handleZoomEnd);
          map.off("dragend", handleMoveEnd);
        }
      };
    }, [map]);
    return null;
  }
  const customIcon = L.icon({
    iconUrl: locationPin,
    iconSize: [40, 40],
    iconAnchor: [16, 32],
  });
  const getLiveLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setSelectedCityCoordinates({
            latitude: latitude,
            longitude: longitude,
          });
          setClickedPosition([latitude, longitude]);
          setPropertyDetails((prev) => ({
            ...prev,
            location: { latitude: latitude, longitude: longitude },
          }));
          setZoomLevel(18);
          (async function fetchLocationAddress() {
            await axios
              .get(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=${1}`
              )
              .then((response) => {
                const { display_name } = response.data;
                setPlaceName(display_name);

                setPropertyDetails((prev) => ({
                  ...prev,
                  locality: display_name,
                }));
              })
              .catch((error) => {
                console.error("Error fetching place name:", error);
                setPlaceName(null);
              });
          })();
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser");
    }
  };

  // Property Price
  const formatPrice = (price, currencyType) => {
    const numericPrice = parseFloat(price);
    if (!isNaN(numericPrice)) {
      let formattedPrice = "";
      if (currencyType === "₹") {
        if (numericPrice >= 10000000) {
          formattedPrice = `₹ ${numericPrice / 10000000} Cr`;
        } else if (numericPrice >= 100000) {
          formattedPrice = `₹ ${numericPrice / 100000} Lakh`;
        } else if (numericPrice >= 1000) {
          formattedPrice = `₹ ${numericPrice / 1000} K`;
        } else {
          formattedPrice = `₹ ${numericPrice}`;
        }
      } else if (currencyType === "$") {
        if (numericPrice >= 1000000) {
          formattedPrice = `$ ${numericPrice / 1000000} M`;
        } else if (numericPrice >= 1000) {
          formattedPrice = `$ ${numericPrice / 1000} K`;
        } else {
          formattedPrice = `$ ${numericPrice}`;
        }
      }
      return formattedPrice;
    } else {
      return "";
    }
  };
  const [isPriceTyping, setIsPriceTyping] = useState(false);
  const handlePriceChange = (value) => {
    setIsPriceTyping(!!value);
    setPropertyDetails((prev) => ({
      ...prev,
      price: value,
    }));
  };

  // Property Additional Rooms
  const handleAdditionalRoomsChange = (additionalRoom) => {
    setPropertyDetails((prev) => {
      const isSelected = prev.additionalRooms.includes(additionalRoom);
      const newdditionalRooms = isSelected
        ? prev.additionalRooms.filter((plan) => plan !== additionalRoom)
        : [...prev.additionalRooms, additionalRoom];
      return {
        ...prev,
        additionalRooms: newdditionalRooms,
      };
    });
  };
  const handleClearAdditionalRoomsSelection = () => {
    setPropertyDetails((prev) => ({
      ...prev,
      additionalRooms: [],
    }));
  };
  const isAddtionalRoomsSelected = (additionalRoom) =>
    propertyDetails.additionalRooms.includes(additionalRoom);

  // Property Bedroom
  const handleBedroomChange = (status) => {
    setPropertyDetails((prev) => ({
      ...prev,
      bedroom: status,
    }));
  };
  const isBedroomSelected = (bedroom) => propertyDetails.bedroom === bedroom;
  const bedrooms = Array.from({ length: 15 }, (_, i) => (i + 1).toString());
  const handleClearBedroomSelection = () => {
    setPropertyDetails((prev) => ({
      ...prev,
      bedroom: "",
    }));
    setShowAllBedrooms(false);
  };
  const [showAllBedrooms, setShowAllBedrooms] = useState(false);

  // Property Bathroom
  const handleBathroomChange = (status) => {
    setPropertyDetails((prev) => ({
      ...prev,
      bathroom: status,
    }));
  };
  const isBathroomSelected = (bathroom) =>
    propertyDetails.bathroom === bathroom;
  const bathrooms = Array.from({ length: 10 }, (_, i) => (i + 1).toString());
  const handleClearBathroomSelection = () => {
    setPropertyDetails((prev) => ({
      ...prev,
      bathroom: "",
    }));
    setShowAllBathrooms(false);
  };
  const [showAllBathrooms, setShowAllBathrooms] = useState(false);

  // Property Balcony
  const handleBalconyChange = (status) => {
    setPropertyDetails((prev) => ({
      ...prev,
      balcony: status,
    }));
  };
  const isBalconySelected = (balcony) => propertyDetails.balcony === balcony;
  const balconies = Array.from({ length: 5 }, (_, i) => (i + 1).toString());
  const handleClearBalconySelection = () => {
    setPropertyDetails((prev) => ({
      ...prev,
      balcony: "",
    }));
    setShowAllBalconies(false);
  };
  const [showAllBalconies, setShowAllBalconies] = useState(false);

  // Property Amenities
  const predefinedAmenities = [
    "Car Parking",
    "Power Backup",
    "24*7 Security",
    "Swimming Pool",
    "Landscaped Gardens",
    "Gym",
    "Children's Playarea",
    "Club House",
    "Cafeteria",
    "Golf Course",
    "Multipurpose Room",
    "Sports Facility",
    "Rainwater Harvesting",
    "Intercom",
    "Jogging Track",
    "Indoor Games",
    "Maintenance Staff",
    "Staff Quarter",
    "Vastu Compliant",
    "Shopping Mall",
    "School",
    "Hospital",
    "ATM",
  ];
  const handleAmenityClick = (amenity) => {
    setPropertyDetails((prev) => {
      const isSelected = prev.amenities.includes(amenity);
      const updatedAmenities = isSelected
        ? prev.amenities.filter((item) => item !== amenity)
        : [...prev.amenities, amenity];

      return { ...prev, amenities: updatedAmenities };
    });
  };
  const handleCustomAmenitySubmit = () => {
    if (
      addPropertySchema.safeParse({
        customAmenity: propertyDetails.customAmenity,
      }).success === false
    ) {
      const errorMessage = addPropertySchema.safeParse({
        customAmenity: propertyDetails.customAmenity,
      }).error.issues[0].message;
      const customMessage = errorMessage.replace(/^String/, "Custom amenity");
      message.error(customMessage);
      return;
    }

    setPropertyDetails((prev) => {
      const updatedAmenities = [...prev.amenities, prev.customAmenity];
      return {
        ...prev,
        customAmenity: "",
        amenities: updatedAmenities,
        showCustomAmenityInput: false,
      };
    });
  };
  const handleResetAll = () => {
    setPropertyDetails((prev) => ({
      ...prev,
      amenities: [],
    }));
  };
  const handleResetCustomAmenities = () => {
    setPropertyDetails((prev) => ({
      ...prev,
      amenities: prev.amenities.filter((amenity) =>
        predefinedAmenities.includes(amenity)
      ),
    }));
  };
  const renderAmenityName = (name) => {
    const parts = name.split(" ");
    return (
      <>
        {parts.map((part, index) => (
          <h5 key={index}>{part}</h5>
        ))}
      </>
    );
  };

  // Property Images
  const getMaxImages = () => {
    switch (userType) {
      case "Owner":
        return 5;
      case "Builder":
        return 15;
      case "Agent":
        return 10;
      case "Salesperson":
        return 10;
      default:
        return 5;
    }
  };
  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files).slice(
      0,
      getMaxImages() - propertyDetails.images.length
    );
    setPropertyDetails((prev) => ({
      ...prev,
      images: [...prev.images, ...selectedImages],
    }));
  };
  const handleRemoveImage = (index) => {
    setPropertyDetails((prev) => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
    }));
  };
  const renderImageHeading = () => {
    if (propertyDetails.images.length > 0) {
      return (
        <h4>
          Property Photos (Selected {propertyDetails.images.length}/
          {getMaxImages()})
        </h4>
      );
    } else {
      return <h4>Property Photos (upto {getMaxImages()} only)</h4>;
    }
  };

  // Terms & Conditions
  const [isCheckedOne, setIsCheckedOne] = useState(false);
  const [isCheckedTwo, setIsCheckedTwo] = useState(false);
  const onTermAgreedOne = (e) => {
    setIsCheckedOne(e.target.checked);
  };
  const onTermAgreedTwo = (e) => {
    setIsCheckedTwo(e.target.checked);
  };
  const [
    isInitialPropertyDetailsUpdateDone,
    setIsInitialPropertyDetailsUpdateDone,
  ] = useState(false);

  useEffect(() => {
    if (
      propertyDetailsFromApi?.current &&
      !isInitialPropertyDetailsUpdateDone
    ) {
      setPropertyDetails({
        associatedProjectId: propertyDetailsFromApi.current.associatedProjectId,
        floorPlan: propertyDetailsFromApi.current.propertyFloorPlan,
        action: propertyDetailsFromApi.current.propertyAction,
        type: propertyDetailsFromApi.current.propertyType,
        subType: propertyDetailsFromApi.current.propertySubType,
        status: propertyDetailsFromApi.current.propertyStatus,
        dealType: propertyDetailsFromApi.current.propertyDealType,
        ownershipType: propertyDetailsFromApi.current.propertyOwnershipType,
        furnishedType: propertyDetailsFromApi.current.propertyFurnishedType,
        title: propertyDetailsFromApi.current.propertyTitle,
        description: propertyDetailsFromApi.current.propertyDescription,
        city: propertyDetailsFromApi.current.propertyCity,
        location: {
          latitude: propertyDetailsFromApi.current.propertyLocation.latitude,
          longitude: propertyDetailsFromApi.current.propertyLocation.longitude,
        },
        locality: propertyDetailsFromApi.current.propertyLocality,
        area: propertyDetailsFromApi.current.propertyArea,
        builtupArea: propertyDetailsFromApi.current.propertyBuiltupArea,
        carpetArea: propertyDetailsFromApi.current.propertyCarpetArea,
        areaUnit: propertyDetailsFromApi.current.propertyAreaUnit,
        price: propertyDetailsFromApi.current.propertyPrice,
        currencyType: propertyDetailsFromApi.current.propertyCurrencyType,
        additionalRooms: [
          ...propertyDetailsFromApi.current.propertyAdditionalRooms,
        ],
        bedroom: propertyDetailsFromApi.current.propertyBedroomCount,
        bathroom: propertyDetailsFromApi.current.propertyBathroomCount,
        balcony: propertyDetailsFromApi.current.propertyBalconiesCount,
        onFloor: propertyDetailsFromApi.current.propertyOnFloor,
        totalFloor: propertyDetailsFromApi.current.propertyTotalFloor,
        totalMasterBedroom:
          propertyDetailsFromApi.current.propertyTotalMasterBedroom,
        totalKitchen: propertyDetailsFromApi.current.propertyTotalKitchen,
        amenities: [...propertyDetailsFromApi.current.propertyAmenities],
        customAmenity: "",
        showCustomAmenityInput: false,
        images: [...propertyDetailsFromApi.current.propertyImages],
        layout: propertyDetailsFromApi?.current?.propertyLayout,
        brochure: propertyDetailsFromApi?.current?.propertyBrochure,
      });

      setClickedPosition([
        propertyDetailsFromApi.current.propertyLocation.latitude,
        propertyDetailsFromApi.current.propertyLocation.longitude,
      ]);

      setZoomLevel(18);

      setIsInitialPropertyDetailsUpdateDone(true);
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validations
    if (!userType) {
      message.error("User type not found. Please log in again!");
      return;
    }

    if (propertyDetails.images.length < 2) {
      message.error("Property must contain at least 2 images");
      return;
    }

    if (!isCheckedOne || !isCheckedTwo) {
      message.error("Please agree to terms and conditions");
      return;
    }

    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("userType", userType);
    // Step 1
    formData.append("associatedProjectId", propertyDetails.associatedProjectId);
    formData.append("propertyFloorPlan", propertyDetails.floorPlan);
    // Step 2
    formData.append("propertyAction", propertyDetails.action);
    formData.append("propertyType", propertyDetails.type);
    formData.append("propertySubType", propertyDetails.subType);
    formData.append("propertyStatus", propertyDetails.status);
    formData.append("propertyDealType", propertyDetails.dealType);
    // Step 3
    formData.append("propertyOwnershipType", propertyDetails.ownershipType);
    formData.append("propertyFurnishedType", propertyDetails.furnishedType);
    formData.append("propertyTitle", propertyDetails.title);
    formData.append("propertyDescription", propertyDetails.description);
    // Step 4
    formData.append("propertyCity", propertyDetails.city);
    formData.append(
      "propertyLocation",
      JSON.stringify(propertyDetails.location)
    );
    formData.append("propertyLocality", propertyDetails.locality);
    // Step 5
    formData.append("propertyArea", propertyDetails.area);
    formData.append("propertyBuiltupArea", propertyDetails.builtupArea);
    formData.append("propertyCarpetArea", propertyDetails.carpetArea);
    formData.append("propertyAreaUnit", propertyDetails.areaUnit);
    formData.append("propertyPrice", propertyDetails.price);
    formData.append("propertyCurrencyType", propertyDetails.currencyType);
    formData.append(
      "propertyAdditionalRooms",
      JSON.stringify(propertyDetails.additionalRooms)
    );
    // Step 6
    formData.append("propertyBedroomCount", propertyDetails.bedroom);
    formData.append("propertyBathroomCount", propertyDetails.bathroom);
    formData.append("propertyBalconiesCount", propertyDetails.balcony);
    formData.append("propertyOnFloor", propertyDetails.onFloor);
    formData.append("propertyTotalFloor", propertyDetails.totalFloor);
    formData.append(
      "propertyTotalMasterBedroom",
      propertyDetails.totalMasterBedroom
    );
    formData.append("propertyTotalKitchen", propertyDetails.totalKitchen);
    // Step 7
    formData.append(
      "propertyAmenities",
      JSON.stringify(propertyDetails.amenities)
    );
    // Step 8
    // Create an array to store base64 string images
    const base64Images = [];

    // Iterate through the propertyImages array
    propertyDetails.images.forEach((image, index) => {
      // Check if the image is a base64 string
      if (typeof image === "object" && image.base64ImgString) {
        // If it's a base64 string, append it to the base64Images array
        base64Images.push(image.imagePath);
      } else {
        // If it's not a base64 string, append it with the default name
        formData.append(`propertyImage_${index}`, image);
      }
    });

    // Now, the base64Images array contains only the base64 string images

    // Iterate through base64Images and append them with a different name
    base64Images.forEach((image, index) => {
      formData.append(`base64PropertyImage_${index}`, image);
    });
    if (!IsBase64Image(propertyDetails.layout)) {
      formData.append("propertyLayout", propertyDetails.layout);
    }

    // PROPERTY BROCHURE
    // Check if brochureFile is not a base64 string before appending
    if (!IsBase64Image(propertyDetails.brochure)) {
      formData.append("propertyBrochure", propertyDetails.brochure);
    }

    if (buttonLoading || loading) {
      return;
    }
    setButtonLoading(true);
    try {
      const response = await axios.patch(
        `${baseURL}/api/post/updatepost/${dashboardPropertyId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (
        response.status === 200 ||
        response?.data ||
        response.data.message ===
          "Property updated successfully & under verification again!"
      ) {
        setTimeout(() => {
          setButtonLoading(false);
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
          }, 500);
          message.info(
            response?.data?.message ||
              "Property Updated successfully & under verification again!"
          );
          navigate("/dashboard");
        }, 2000);
      }
    } catch (error) {
      setButtonLoading(false);
      message.error(error.response.data.message);
    }
  };

  return (
    <div className="utr">
      {loading && <LoadingOverlay />}
      <div className="utr-sub">
        <div className="utr-container">
          <div className="utr-header">
            <div className="utr-header-sub">
              <GoArrowLeft
                className="utr-header-sub-icon"
                onClick={() => (currentStep === 1 ? navigate(-1) : prevStep())}
              />
              <h3>Update Property</h3>
              <Popconfirm
                title="Reset the data"
                description="Are you sure to rest this form?"
                onConfirm={confirmFormReset}
                onCancel={cancelFormReset}
                okText="Reset"
                cancelText="Cancel"
              >
                <GrPowerReset className="utr-header-sub-icon-reset" />
              </Popconfirm>
            </div>
          </div>
          <div className="utr-progress-bar">
            <div className="utr-progress-bar-background">
              <div
                className="utr-progress-bar-sub"
                style={{ width: `${progressWidth}%` }}
              ></div>
            </div>
            <div className="utr-progress-bar-icons">
              <div
                className={`utr-progress-bar-category ${
                  currentStep >= 1 ? "selected" : ""
                }`}
              >
                <AiOutlineProject className="utr-progress-bar-category-icon" />
              </div>
              <div
                className={`utr-progress-bar-category ${
                  currentStep >= 2 ? "selected" : ""
                }`}
              >
                <BsHouse className="utr-progress-bar-category-icon" />
              </div>
              <div
                className={`utr-progress-bar-category ${
                  currentStep >= 5 ? "selected" : ""
                }`}
              >
                <MdOutlineSpeakerNotes className="utr-progress-bar-category-icon" />
              </div>
              <div
                className={`utr-progress-bar-category ${
                  currentStep >= 8 ? "selected" : ""
                }`}
              >
                <AiOutlineFileImage className="utr-progress-bar-category-icon" />
              </div>
            </div>
          </div>
          <div className="utr-form">
            {currentStep === 1 && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h4>
                    Select Associated Project (for this property) (if any)
                  </h4>
                  <div className="utr-form-inputs">
                    {" "}
                    <div className="utr-form-input">
                      <AiOutlineProject className="utr-form-input-icon" />
                      <Select
                        showSearch
                        name="associatedProjectId"
                        className="utr-form-input-select"
                        placeholder="Search Available Projects"
                        optionFilterProp="children"
                        value={propertyDetails.associatedProjectId || undefined}
                        onChange={(value) =>
                          setPropertyDetails((prev) => ({
                            ...prev,
                            associatedProjectId: value,
                          }))
                        }
                      >
                        <OptGroup
                          label={<span>Your Projects</span>}
                          key="your-projects"
                        >
                          {userProjects?.map((project) => (
                            <Option key={project._id} value={project._id}>
                              {project.projectName}
                            </Option>
                          ))}
                        </OptGroup>
                        <OptGroup
                          label={<span>All Projects</span>}
                          key="all-projects"
                        >
                          {allProjects?.map((project) => (
                            <Option key={project._id} value={project._id}>
                              {project.projectName}
                            </Option>
                          ))}
                        </OptGroup>
                      </Select>
                    </div>
                  </div>
                  <h6>
                    (Select a project from the list to register and display your
                    property under that project. To list your property
                    separately, simply skip this step.)
                  </h6>
                </div>
                {propertyDetails.associatedProjectId && (
                  <>
                    <div className="utr-form-category">
                      <h4>Associated Project (Floor Plans Available)</h4>
                      <div className="utr-form-types">
                        {selectedProjectFloorPlans?.includes("1 BHK") && (
                          <div
                            className={`utr-form-types-sub ${
                              is1BhkSelected ? "selected" : ""
                            }`}
                            onClick={() =>
                              handleAssociatedProjectFloorPlanChange("1 BHK")
                            }
                          >
                            <BsBuildingLock className="utr-form-types-sub-icon" />
                            <h5>1 BHK</h5>
                          </div>
                        )}
                        {selectedProjectFloorPlans?.includes("2 BHK") && (
                          <div
                            className={`utr-form-types-sub ${
                              is2BhkSelected ? "selected" : ""
                            }`}
                            onClick={() =>
                              handleAssociatedProjectFloorPlanChange("2 BHK")
                            }
                          >
                            <BsBuildingLock className="utr-form-types-sub-icon" />
                            <h5>2 BHK</h5>
                          </div>
                        )}
                        {selectedProjectFloorPlans?.includes("3 BHK") && (
                          <div
                            className={`utr-form-types-sub ${
                              is3BhkSelected ? "selected" : ""
                            }`}
                            onClick={() =>
                              handleAssociatedProjectFloorPlanChange("3 BHK")
                            }
                          >
                            <BsBuildingLock className="utr-form-types-sub-icon" />
                            <h5>3 BHK</h5>
                          </div>
                        )}
                        {selectedProjectFloorPlans?.includes("4 BHK") && (
                          <div
                            className={`utr-form-types-sub ${
                              is4BhkSelected ? "selected" : ""
                            }`}
                            onClick={() =>
                              handleAssociatedProjectFloorPlanChange("4 BHK")
                            }
                          >
                            <BsBuildingLock className="utr-form-types-sub-icon" />
                            <h5>4 BHK</h5>
                          </div>
                        )}
                        {selectedProjectFloorPlans?.includes("5 BHK") && (
                          <div
                            className={`utr-form-types-sub ${
                              is5BhkSelected ? "selected" : ""
                            }`}
                            onClick={() =>
                              handleAssociatedProjectFloorPlanChange("5 BHK")
                            }
                          >
                            <BsBuildingLock className="utr-form-types-sub-icon" />
                            <h5>5 BHK</h5>
                          </div>
                        )}
                        {selectedProjectFloorPlans?.includes("6 BHK") && (
                          <div
                            className={`utr-form-types-sub ${
                              is6BhkSelected ? "selected" : ""
                            }`}
                            onClick={() =>
                              handleAssociatedProjectFloorPlanChange("6 BHK")
                            }
                          >
                            <BsBuildingLock className="utr-form-types-sub-icon" />
                            <h5>6 BHK</h5>
                          </div>
                        )}
                        {selectedProjectFloorPlans?.includes("SOHO") && (
                          <div
                            className={`utr-form-types-sub ${
                              isSohoSelected ? "selected" : ""
                            }`}
                            onClick={() =>
                              handleAssociatedProjectFloorPlanChange("SOHO")
                            }
                          >
                            <BsBuildingLock className="utr-form-types-sub-icon" />
                            <h5>SOHO</h5>
                          </div>
                        )}
                        {selectedProjectFloorPlans?.includes(
                          "Studio Apartment"
                        ) && (
                          <div
                            className={`utr-form-types-sub ${
                              isStudioApartmentSelected ? "selected" : ""
                            }`}
                            onClick={() =>
                              handleAssociatedProjectFloorPlanChange(
                                "Studio Apartment"
                              )
                            }
                          >
                            <BsBuildingLock className="utr-form-types-sub-icon" />
                            <h5>Studio Apartment</h5>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="utr-form-category">
                      <h4>Note</h4>
                      <h6>
                        (After submission, your property post is forwarded to
                        the owner of the 'Sushma Grande NXT' project listing for
                        review. They will ensure that you have entered the
                        correct details, and upon approval, your property will
                        be published live.)
                      </h6>
                    </div>
                  </>
                )}
              </div>
            )}
            {currentStep === 2 && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h4>I want to:</h4>
                  <div className="utr-form-types">
                    <div
                      className={`utr-form-types-sub ${
                        isSaleSelected ? "selected" : ""
                      }`}
                      onClick={() => handleActionChange("Sale")}
                    >
                      <h5>Sale</h5>
                    </div>
                    <div
                      className={`utr-form-types-sub ${
                        isRentLeaseSelected ? "selected" : ""
                      }`}
                      onClick={() => handleActionChange("Rent / Lease")}
                    >
                      <h5>Rent / Lease</h5>
                    </div>
                    <div
                      className={`utr-form-types-sub ${
                        isPgHostelSelected ? "selected" : ""
                      }`}
                      onClick={() => handleActionChange("PG / Hostel")}
                    >
                      <h5>PG / Hostel</h5>
                    </div>
                  </div>
                </div>
                <div className="utr-form-category">
                  <h4>Your Property Type:</h4>
                  <div className="utr-form-types">
                    <div
                      className={`utr-form-types-sub ${
                        isResidentialSelected ? "selected" : ""
                      }`}
                      onClick={() => handleTypeChange("Residential")}
                    >
                      <h5>Residential</h5>
                    </div>
                    {propertyDetails.action !== "PG / Hostel" && (
                      <>
                        <div
                          className={`utr-form-types-sub ${
                            isCommercialSelected ? "selected" : ""
                          }`}
                          onClick={() => handleTypeChange("Commercial")}
                        >
                          <h5>Commercial</h5>
                        </div>
                        <div
                          className={`utr-form-types-sub ${
                            isIndustrialSelected ? "selected" : ""
                          }`}
                          onClick={() => handleTypeChange("Industrial")}
                        >
                          <h5>Industrial</h5>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {propertyDetails.action && propertyDetails.type && (
                  <div className="utr-form-category">
                    <h4>Select Property Sub-Type</h4>
                    <div className="utr-form-types">{renderSubTypes()}</div>
                  </div>
                )}
                <div className="utr-form-category">
                  <h4>Property Status</h4>
                  <div className="utr-form-types">
                    <div
                      className={`utr-form-types-sub ${
                        isReadyToMoveSelected ? "selected" : ""
                      }`}
                      onClick={() => handleStatusChange("Ready to Move")}
                    >
                      <FaRegCircleCheck className="utr-form-types-sub-icon" />
                      <h5>Ready to Move</h5>
                    </div>
                    {propertyDetails.action !== "Rent / Lease" &&
                      propertyDetails.action !== "PG / Hostel" && (
                        <div
                          className={`utr-form-types-sub ${
                            isUnderConstructionSelected ? "selected" : ""
                          }`}
                          onClick={() =>
                            handleStatusChange("Under Construction")
                          }
                        >
                          <AiOutlineAlert className="utr-form-types-sub-icon" />
                          <h5>Under Construction</h5>
                        </div>
                      )}
                  </div>
                </div>
                <div className="utr-form-category utr-form-category-last">
                  <h4>Deal Type</h4>
                  <div className="utr-form-types">
                    <div
                      className={`utr-form-types-sub ${
                        isNewSelected ? "selected" : ""
                      }`}
                      onClick={() => handleDealTypeChange("New")}
                    >
                      <GiNewShoot className="utr-form-types-sub-icon" />
                      <h5>New</h5>
                    </div>
                    {userType !== "Builder" && (
                      <div
                        className={`utr-form-types-sub ${
                          isReSaleSelected ? "selected" : ""
                        }`}
                        onClick={() => handleDealTypeChange("Re-Sale")}
                      >
                        <MdCompareArrows className="utr-form-types-sub-icon" />
                        <h5>Re-Sale</h5>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {currentStep === 3 && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h4>Ownership Type:</h4>
                  <div className="utr-form-types">
                    <div
                      className={`utr-form-types-sub ${
                        isFreeholdSelected ? "selected" : ""
                      }`}
                      onClick={() => handleOwnershipTypeChange("Freehold")}
                    >
                      <h5>Freehold</h5>
                    </div>
                    <div
                      className={`utr-form-types-sub ${
                        isLeaseholdSelected ? "selected" : ""
                      }`}
                      onClick={() => handleOwnershipTypeChange("Leasehold")}
                    >
                      <h5>Leasehold</h5>
                    </div>
                    <div
                      className={`utr-form-types-sub ${
                        isPowerOfAttorneySelected ? "selected" : ""
                      }`}
                      onClick={() =>
                        handleOwnershipTypeChange("Power of Attorney")
                      }
                    >
                      <h5>Power of Attorney</h5>
                    </div>
                    <div
                      className={`utr-form-types-sub ${
                        isCoOperativeSocietySelected ? "selected" : ""
                      }`}
                      onClick={() =>
                        handleOwnershipTypeChange("Co-operative Society")
                      }
                    >
                      <h5>Co-operative Society</h5>
                    </div>
                    {propertyDetails.ownershipType && (
                      <div className="utr-form-types-clearselection">
                        <span onClick={handleClearOwnershipTypeSelection}>
                          - Clear Selection
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                {propertyDetails.subType !== "Plot" && (
                  <div className="utr-form-category">
                    <h4>Furnishing Status:</h4>
                    <div className="utr-form-types">
                      <div
                        className={`utr-form-types-sub ${
                          isUnfurnishedSelected ? "selected" : ""
                        }`}
                        onClick={() =>
                          handleFurnishedTypeChange("Un-Furnished")
                        }
                      >
                        <h5>Un-Furnished</h5>
                      </div>
                      <div
                        className={`utr-form-types-sub ${
                          isSemifurnishedSelected ? "selected" : ""
                        }`}
                        onClick={() =>
                          handleFurnishedTypeChange("Semi-Furnished")
                        }
                      >
                        <h5>Semi-Furnished</h5>
                      </div>
                      <div
                        className={`utr-form-types-sub ${
                          isFullyfurnishedSelected ? "selected" : ""
                        }`}
                        onClick={() =>
                          handleFurnishedTypeChange("Fully-Furnished")
                        }
                      >
                        <h5>Fully-Furnished</h5>
                      </div>
                      {propertyDetails.furnishedType && (
                        <div className="utr-form-types-clearselection">
                          <span onClick={handleClearFurnishedTypeSelection}>
                            - Clear Selection
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="utr-form-category">
                  <h4>Property Details (Title & Description)</h4>
                  <div className="utr-form-inputs">
                    <div className="utr-form-input utr-form-input-flex">
                      <textarea
                        placeholder="Title"
                        value={propertyDetails.title}
                        onChange={handleTitleChange}
                        className={
                          titleCharacterCount === 150
                            ? "utr-form-input-max-limit-reached"
                            : propertyDetails.title.trim() !== ""
                            ? "utr-form-input-selected"
                            : ""
                        }
                        // rows={5}
                      />
                      <div
                        className={
                          titleCharacterCount === 150
                            ? "utr-form-input-sub-max-limit-reached"
                            : "utr-form-input-sub"
                        }
                      >
                        <span>{titleSpanContent}</span>
                        <span>
                          {titleCharacterCount ||
                            propertyDetails?.title?.length}{" "}
                          / 150
                        </span>
                      </div>
                    </div>
                    <div className="utr-form-input utr-form-input-flex">
                      <textarea
                        placeholder="Description"
                        value={propertyDetails.description}
                        onChange={handleDescriptionChange}
                        className={
                          descriptionCharacterCount === 2500
                            ? "utr-form-input-max-limit-reached"
                            : propertyDetails.description.trim() !== ""
                            ? "utr-form-input-selected"
                            : ""
                        }
                        rows={5}
                      />
                      <div
                        className={
                          descriptionCharacterCount === 2500
                            ? "utr-form-input-sub-max-limit-reached"
                            : "utr-form-input-sub"
                        }
                      >
                        <span>{descriptionSpanContent}</span>
                        <span>
                          {descriptionCharacterCount ||
                            propertyDetails?.description?.length}{" "}
                          / 2500
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {currentStep === 4 && (
              <div className="utr-form-sub">
                {" "}
                <div className="utr-form-category">
                  <h4>Property Location Details</h4>
                  <div className="utr-form-inputs">
                    <div className="utr-form-input">
                      <PiCity className="utr-form-input-icon" />
                      <Select
                        showSearch
                        className="utr-form-input-select"
                        placeholder="Select a city"
                        value={propertyDetails.city || undefined}
                        optionFilterProp="label"
                        onChange={(value) => {
                          setPropertyDetails((prev) => ({
                            ...prev,
                            city: value,
                          }));
                          const cityData = filterCityData(value);
                          setSelectedCityCoordinates({
                            latitude: cityData.geoCode[0],
                            longitude: cityData.geoCode[1],
                          });
                        }}
                        options={cityOptions.map((option) => ({
                          value: option.cityName,
                          label: option.cityName,
                        }))}
                      />
                    </div>
                    <div className="utr-form-input"></div>
                    <div className="utr-form-input utr-form-input-flex utr-form-input-mtmap">
                      <textarea
                        placeholder="Property Locality"
                        value={propertyDetails.locality}
                        onChange={handleLocalityChange}
                        className={
                          localityCharacterCount === 500
                            ? "utr-form-input-max-limit-reached"
                            : propertyDetails?.locality?.trim() !== ""
                            ? "utr-form-input-selected"
                            : ""
                        }
                        rows={5}
                      />
                      <div
                        className={
                          localityCharacterCount === 500
                            ? "utr-form-input-sub-max-limit-reached"
                            : "utr-form-input-sub"
                        }
                      >
                        <span>{localitySpanContent}</span>
                        <span>
                          {localityCharacterCount ||
                            propertyDetails?.locality?.length}{" "}
                          / 500
                        </span>
                      </div>
                    </div>
                    <div className="utr-form-input">
                      <div className="utr-form-input-map">
                        <AimOutlined
                          className="utr-form-map-live-Loc"
                          onClick={getLiveLocation}
                        />
                        <MapContainer
                          center={[...clickedPosition] || [20.5937, 78.9629]}
                          zoom={zoomLevel}
                          className="pp-current-location-onMap"
                        >
                          <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <ClickHandler />
                          {clickedPosition && (
                            <Marker
                              position={[...clickedPosition]}
                              icon={customIcon}
                            >
                              {/* <Popup>{projectLocality}</Popup> */}
                            </Marker>
                          )}
                        </MapContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {currentStep === 5 && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h4>Tell Us Your Property Area</h4>
                  <div className="utr-form-inputs">
                    <FormInput
                      type="number"
                      icon={AiOutlineExpandAlt}
                      placeholder="Area"
                      value={propertyDetails.area}
                      onChange={(e) =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          area: e.target.value,
                        }))
                      }
                    />
                    <FormInput
                      type="number"
                      icon={AiOutlineExpandAlt}
                      placeholder="Builtup Area"
                      value={propertyDetails.builtupArea}
                      onChange={(e) =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          builtupArea: e.target.value,
                        }))
                      }
                    />
                    <FormInput
                      type="number"
                      icon={AiOutlineExpandAlt}
                      placeholder="Carpet Area"
                      value={propertyDetails.carpetArea}
                      onChange={(e) =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          carpetArea: e.target.value,
                        }))
                      }
                    />
                    <div className="utr-form-input">
                      <MdOutlineCompareArrows className="utr-form-input-icon" />
                      <Select
                        showSearch
                        name="areaUnit"
                        className="utr-form-input-select"
                        placeholder="Select Area unit"
                        value={propertyDetails.areaUnit || undefined}
                        optionFilterProp="label"
                        onChange={(value) =>
                          setPropertyDetails((prev) => ({
                            ...prev,
                            areaUnit: value,
                          }))
                        }
                        options={[
                          {
                            value: "hectares",
                            label: "Hectares",
                          },
                          {
                            value: "acres",
                            label: "Acres",
                          },
                          {
                            value: "sq.yards",
                            label: "Sq Yards",
                          },
                          {
                            value: "sq.milimeters",
                            label: "Sq Milimeters",
                          },
                          {
                            value: "sq.miles",
                            label: "Sq Miles",
                          },
                          {
                            value: "sq.meters",
                            label: "Sq Meters",
                          },
                          {
                            value: "sq.kilometers",
                            label: "Sq Kilometers",
                          },
                          {
                            value: "sq.feet",
                            label: "Sq Feet",
                          },
                          {
                            value: "sq.centimeters",
                            label: "Sq Centimeters",
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="utr-form-category">
                  <h4>
                    What Is The Expected Price{" "}
                    {isPriceTyping && (
                      <>
                        - (
                        {formatPrice(
                          propertyDetails.price,
                          propertyDetails.currencyType
                        )}
                        )
                      </>
                    )}
                  </h4>
                  <div className="utr-form-inputs">
                    <FormInput
                      type="number"
                      icon={
                        propertyDetails.currencyType === "₹"
                          ? BsCurrencyRupee
                          : BsCurrencyDollar
                      }
                      placeholder="Price"
                      value={propertyDetails.price}
                      onChange={(e) => handlePriceChange(e.target.value)}
                    />
                    <div className="utr-form-input">
                      <GrCurrency className="utr-form-input-icon" />
                      <Select
                        showSearch
                        name="currencyType"
                        className="utr-form-input-select"
                        placeholder="Select Currency Type"
                        value={propertyDetails.currencyType || undefined}
                        optionFilterProp="label"
                        onChange={(value) =>
                          setPropertyDetails((prev) => ({
                            ...prev,
                            currencyType: value,
                          }))
                        }
                        options={[
                          {
                            value: "₹",
                            label: "INR - Rupees (₹)",
                          },
                          {
                            value: "$",
                            label: "USD - Dollars ($)",
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="utr-form-category utr-form-category-last">
                  <h4>Additional Rooms (if any)</h4>
                  <div className="utr-form-types">
                    {[
                      "Prayer Room",
                      "Study Room",
                      "Store Room",
                      "Servant Room",
                    ].map((additionalRoom) => (
                      <div
                        key={additionalRoom}
                        className={`utr-form-types-sub ${
                          isAddtionalRoomsSelected(additionalRoom)
                            ? "selected"
                            : ""
                        }`}
                        onClick={() =>
                          handleAdditionalRoomsChange(additionalRoom)
                        }
                      >
                        <CgTag className="utr-form-types-sub-icon" />
                        <h5>{additionalRoom}</h5>
                      </div>
                    ))}
                    {propertyDetails.additionalRooms.length > 0 && (
                      <div className="utr-form-types-clearselection">
                        <span onClick={handleClearAdditionalRoomsSelection}>
                          - Clear Selection
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {currentStep === 6 && propertyDetails.subType !== "Plot" && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h4>Select Bedroom/s</h4>
                  <div className="utr-form-types">
                    {(showAllBedrooms ? bedrooms : bedrooms.slice(0, 5)).map(
                      (bedroom) => (
                        <div
                          key={bedroom}
                          className={`utr-form-types-sub ${
                            isBedroomSelected(bedroom) ? "selected" : ""
                          }`}
                          onClick={() => handleBedroomChange(bedroom)}
                        >
                          <h5>{bedroom}</h5>
                        </div>
                      )
                    )}
                    {!showAllBedrooms && bedrooms.length > 5 && (
                      <div className="utr-form-types-clearselection">
                        <span onClick={() => setShowAllBedrooms(true)}>
                          + Show All
                        </span>
                      </div>
                    )}
                    {propertyDetails.bedroom && (
                      <div className="utr-form-types-clearselection">
                        <span onClick={handleClearBedroomSelection}>
                          - Clear Selection
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="utr-form-category">
                  <h4>Select Bathroom/s</h4>
                  <div className="utr-form-types">
                    {(showAllBathrooms ? bathrooms : bathrooms.slice(0, 5)).map(
                      (bathroom) => (
                        <div
                          key={bathroom}
                          className={`utr-form-types-sub ${
                            isBathroomSelected(bathroom) ? "selected" : ""
                          }`}
                          onClick={() => handleBathroomChange(bathroom)}
                        >
                          <h5>{bathroom}</h5>
                        </div>
                      )
                    )}
                    {!showAllBathrooms && bathrooms.length > 5 && (
                      <div className="utr-form-types-clearselection">
                        <span onClick={() => setShowAllBathrooms(true)}>
                          + Show All
                        </span>
                      </div>
                    )}
                    {propertyDetails.bathroom && (
                      <div className="utr-form-types-clearselection">
                        <span onClick={handleClearBathroomSelection}>
                          - Clear Selection
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="utr-form-category">
                  <h4>Select Balcony/s</h4>
                  <div className="utr-form-types">
                    {(showAllBalconies ? balconies : balconies.slice(0, 5)).map(
                      (balcony) => (
                        <div
                          key={balcony}
                          className={`utr-form-types-sub ${
                            isBalconySelected(balcony) ? "selected" : ""
                          }`}
                          onClick={() => handleBalconyChange(balcony)}
                        >
                          <h5>{balcony}</h5>
                        </div>
                      )
                    )}
                    {!showAllBalconies && balconies.length > 5 && (
                      <div className="utr-form-types-clearselection">
                        <span onClick={() => setShowAllBalconies(true)}>
                          + Show All
                        </span>
                      </div>
                    )}
                    {propertyDetails.balcony && (
                      <div className="utr-form-types-clearselection">
                        <span onClick={handleClearBalconySelection}>
                          - Clear Selection
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="utr-form-category utr-form-category-last">
                  <h4>More Details</h4>
                  <div className="utr-form-inputs">
                    {(propertyDetails.subType === "Flat / Apartment" ||
                      propertyDetails.subType === "Builder Floor") && (
                      <FormInput
                        type="number"
                        icon={BsBuildingCheck}
                        placeholder="Property On Floor No."
                        value={propertyDetails.onFloor}
                        onChange={(e) =>
                          setPropertyDetails((prev) => ({
                            ...prev,
                            onFloor: e.target.value,
                          }))
                        }
                      />
                    )}
                    <FormInput
                      type="number"
                      icon={BsBuildingUp}
                      placeholder="Total Floor No."
                      value={propertyDetails.totalFloor}
                      onChange={(e) =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          totalFloor: e.target.value,
                        }))
                      }
                    />
                    <FormInput
                      type="number"
                      icon={MdOutlineBedroomParent}
                      placeholder="Total Master Bedroom No."
                      value={propertyDetails.totalMasterBedroom}
                      onChange={(e) =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          totalMasterBedroom: e.target.value,
                        }))
                      }
                    />
                    <FormInput
                      type="number"
                      icon={MdOutlineKitchen}
                      placeholder="Total Kitchen No."
                      value={propertyDetails.totalKitchen}
                      onChange={(e) =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          totalKitchen: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {currentStep === 7 && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  <h4>Choose Amenities (if any)</h4>
                  <div className="utr-form-types">
                    {[
                      { name: "Car Parking", icon: FaCar },
                      { name: "Power Backup", icon: FaBatteryFull },
                      { name: "24*7 Security", icon: FaShieldAlt },
                      { name: "Swimming Pool", icon: FaSwimmingPool },
                      { name: "Landscaped Gardens", icon: FaLeaf },
                      { name: "Gym", icon: FaDumbbell },
                      { name: "Children's Playarea", icon: FaChild },
                      { name: "Club House", icon: FaBuilding },
                      { name: "Cafeteria", icon: FaCoffee },
                      { name: "Golf Course", icon: FaGolfBall },
                      { name: "Multipurpose Room", icon: FaUsers },
                      { name: "Sports Facility", icon: FaFutbol },
                      { name: "Rainwater Harvesting", icon: FaTint },
                      { name: "Intercom", icon: FaPhoneAlt },
                      { name: "Jogging Track", icon: FaRunning },
                      { name: "Indoor Games", icon: FaChess },
                      { name: "Maintenance Staff", icon: FaTools },
                      { name: "Staff Quarter", icon: FaHome },
                      { name: "Vastu Compliant", icon: FaCompass },
                      { name: "Shopping Mall", icon: FaShoppingCart },
                      { name: "School", icon: FaSchool },
                      { name: "Hospital", icon: FaHospital },
                      { name: "ATM", icon: FaMoneyCheckAlt },
                    ].map(({ name, icon: Icon }) => (
                      <div
                        key={name}
                        className={`utr-form-amenity ${
                          propertyDetails.amenities.includes(name)
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => handleAmenityClick(name)}
                      >
                        <Icon className="utr-form-amenity-icon" />
                        {renderAmenityName(name)}
                      </div>
                    ))}
                    {propertyDetails.amenities.length > 0 && (
                      <div
                        className="utr-form-amenity utr-form-amenity-hovered"
                        onClick={handleResetAll}
                      >
                        <GrPowerReset className="utr-form-amenity-icon" />
                        <h5>Reset All</h5>
                        <h5>Amenities</h5>
                      </div>
                    )}
                    <div
                      className="utr-form-amenity utr-form-amenity-hovered"
                      onClick={() =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          showCustomAmenityInput: !prev.showCustomAmenityInput,
                        }))
                      }
                    >
                      <CgAdd className="utr-form-amenity-icon" />
                      <h5>Custom</h5>
                      <h5>Amenity</h5>
                    </div>
                  </div>
                </div>
                {propertyDetails.showCustomAmenityInput && (
                  <div className="utr-form-category utr-form-category-last">
                    <h4>Add Custom Amenity</h4>
                    <div className="utr-form-inputs">
                      <FormInput
                        icon={MdOutlineDashboardCustomize}
                        placeholder="Enter Amenity"
                        value={propertyDetails.customAmenity}
                        onChange={(e) =>
                          setPropertyDetails((prev) => ({
                            ...prev,
                            customAmenity: e.target.value,
                          }))
                        }
                      />
                      <h6 onClick={handleCustomAmenitySubmit}>
                        Submit Custom Amenity
                      </h6>
                    </div>
                  </div>
                )}
                {propertyDetails.amenities.filter(
                  (amenity) => !predefinedAmenities.includes(amenity)
                ).length > 0 && (
                  <div className="utr-form-category">
                    <h4>Custom Amenities</h4>
                    <div className="utr-form-types">
                      {propertyDetails.amenities
                        .filter(
                          (amenity) => !predefinedAmenities.includes(amenity)
                        )
                        .map((customAmenity, index) => (
                          <div
                            key={index}
                            className="utr-form-amenity selected"
                          >
                            <PushpinOutlined className="utr-form-amenity-icon" />
                            {renderAmenityName(customAmenity)}
                          </div>
                        ))}
                      <div
                        className="utr-form-amenity utr-form-amenity-hovered"
                        onClick={handleResetCustomAmenities}
                      >
                        <GrPowerReset className="utr-form-amenity-icon" />
                        <h5>Reset Custom</h5>
                        <h5>Amenities</h5>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {currentStep === 8 && (
              <div className="utr-form-sub">
                <div className="utr-form-category">
                  {renderImageHeading()}

                  <MultipleImageUpload
                    images={propertyDetails.images}
                    onImageChange={handleImageChange}
                    onRemove={handleRemoveImage}
                    label="Add Image"
                    maxImages={getMaxImages()}
                  />
                </div>
                <div className="utr-form-category">
                  <h4>
                    Property Layout & Property Brcohure (Brochure Formats: .pdf,
                    .doc, .docx!)
                  </h4>
                  <div className="utr-form-images">
                    <ImageUpload
                      image={propertyDetails.layout}
                      onImageChange={(e) =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          layout: e.target.files[0],
                        }))
                      }
                      onRemove={() =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          layout: null,
                        }))
                      }
                      label=" Add Layout"
                    />
                    <FileUpload
                      file={propertyDetails.brochure}
                      onFileChange={(e) =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          brochure: e.target.files[0],
                        }))
                      }
                      onRemove={() =>
                        setPropertyDetails((prev) => ({
                          ...prev,
                          brochure: null,
                        }))
                      }
                      label="Add Brochure"
                    />
                  </div>
                </div>
                <div className="utr-form-category">
                  <h4>Terms & Conditions</h4>
                  <div className="utr-form-inputs">
                    <Checkbox
                      onChange={onTermAgreedOne}
                      className={`utr-form-input-checkbox ${
                        isCheckedOne ? "checked" : ""
                      }`}
                    >
                      I agree to exclusively list this project on Proptzo.
                    </Checkbox>
                  </div>
                  <div className="utr-form-inputs">
                    <Checkbox
                      onChange={onTermAgreedTwo}
                      className={`utr-form-input-checkbox ${
                        isCheckedTwo ? "checked" : ""
                      }`}
                    >
                      I agree to Proptzo{" "}
                      <span className="utr-form-input-checkbox-span">T&C</span>,{" "}
                      <span className="utr-form-input-checkbox-span">
                        Privacy Policy
                      </span>
                      , &{" "}
                      <span className="utr-form-input-checkbox-span">
                        Cookie Policy
                      </span>
                    </Checkbox>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="utr-footer">
            {currentStep > 1 && (
              <RiArrowLeftSLine
                className="utr-form-footer-icon"
                onClick={prevStep}
              />
            )}
            {currentStep < 8 ? (
              <RiArrowRightSLine
                className="utr-form-footer-icon"
                onClick={nextStep}
              />
            ) : (
              <button
                className="button"
                onClick={handleSubmit}
                disabled={buttonLoading || loading}
              >
                {buttonLoading ? <LoadingOutlined /> : "Update Property"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateProperty;
