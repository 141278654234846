import React from "react";
import PropTypes from "prop-types";
import { formatPrice } from "../../utils/priceFormatter";

const PriceFormatter = ({ amount, currencyType }) => {
  // Format the price based on currency type
  const formattedPrice = formatPrice(amount, currencyType);
  return <span className="price">{currencyType} {formattedPrice}</span>;
};

PriceFormatter.propTypes = {
  amount: PropTypes.number.isRequired,
  currencyType: PropTypes.string.isRequired,
};

export default PriceFormatter;
