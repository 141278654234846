import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./RecentlyViewed.css";

// ANT DESIGN ICONS
import { EyeOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { recentSearches } from "../../store/atoms/recentSearches";
import axios from "axios";
import PriceFormatter from "../PriceFormatter/PriceFormatter";
import { useNavigate } from "react-router-dom";

function RecentlyViewed() {
  const baseURL = process.env.REACT_APP_base_URL;
  const [history, setHistory] = useRecoilState(recentSearches);
  const [deletedPosts, setDeletedPosts] = useState([]);
  const navigate = useNavigate();

  const StoredHistory = JSON.parse(localStorage.getItem("history"));
  const data = { StoredHistory };

  const fetchSearchData = useCallback(async () => {
    try {
      const response = await axios.post(
        `${baseURL}/api/post/getRecentSearchedProperties`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
          setHistory(response.data.postData);
          setDeletedPosts(response.data.deletedPostIds || []); // Update deletedPosts
          return response.data.postData;
        } catch (error) {
          console.error("Error fetching recent searches:", error);
          // Set deletedPosts to an empty array in case of error
          setDeletedPosts([]); // Handle potential error scenario
        }
      }, [baseURL]);
    
      // Fetch data when necessary
           useEffect(() => {
        if (
          (StoredHistory?.length > 0 && history?.length !== StoredHistory?.length) // Check for length difference
        ) {
          fetchSearchData();
        }
      }, [history, StoredHistory?.length, fetchSearchData]);
    
      // Filter localStorage based on deletedPosts (using useEffect for reactivity)
      useEffect(() => {
        if (!deletedPosts.length) return; // Avoid unnecessary updates
    
        const filteredHistory = StoredHistory.filter(
          (historyId, index) => !deletedPosts.includes(historyId)
        );
    
        localStorage.setItem("history", JSON.stringify(filteredHistory));
      }, [deletedPosts, StoredHistory]); // Re-run when deletedPosts changes

  return (
    history &&
    history?.length > 0 && (
      <div className="searches searches-rv">
        <div className="searches-sub">
          <div className="searches-header">
            <h5>PROPERTIES</h5>
            <div className="searches-header-sub">
              <h1>Recently Viewed</h1>
            </div>
          </div>
          <div className="rv">
            {history && history?.length > 0 && (
              history?.map((search, index) => (
                <div
                  className="rv-sub"
                  onClick={() => navigate(`/viewproperty/${search?.postId}`)}
                >
                  <div className="rv-data">
                    <h5>{search?.propertyTitle}</h5>
                    <h5>
                      <PriceFormatter
                        amount={search?.propertyPrice}
                        currencyType={search.propertyCurrencyType}
                      />
                    </h5>
                  </div>
                  <EyeOutlined className="trending-prop-img-icons-icon" />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    )
  );
}

export default RecentlyViewed;
