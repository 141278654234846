import React from "react";
import "./AdminLoader.css";

function AdminLoader() {
  return (
    <div className="aloader-overlay">
      <div className="aloader"></div>
    </div>
  );
}

export default AdminLoader;
