import React from "react";
import "./HomeMobile.css";

// Components Import
import HeroMobile from "../../Components/HeroMobile/HeroMobile";
import LiveUsersMobile from "../../Components/LiveUsersMobile/LiveUsersMobile";

function HomeMobile() {
  return (
    <div className="homem">
      <div className="homem-sub">
        <HeroMobile />
        <LiveUsersMobile />
      </div>
    </div>
  );
}

export default HomeMobile;
