import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Notifications.css";

// ANT DESIGN IMPORTS
import { Segmented, Avatar, List, Empty } from "antd";
import {
  BarsOutlined,
  FilePdfOutlined,
  UserOutlined,
  CloseCircleFilled,
  CheckCircleFilled,
} from "@ant-design/icons";

// REACT ICONS
import { GoDotFill } from "react-icons/go";

// RECOIL
import { useRecoilState } from "recoil";
import {
  allNotificationsAtom,
  checkedNotificationsAtom,
  fetchNotifications,
} from "../../store/atoms/notificationAtoms";
import axios from "axios";

function Notifications() {
  const [allNotifications, setAllNotifications] = useRecoilState(allNotificationsAtom);

  const baseURL = process.env.REACT_APP_base_URL;

  const navigate = useNavigate();

  const [selectedNotification, setSelectedNotification] = useState(null);

  const [notificationTypeFilter, setNotificationTypeFilter] = useState("All");

    // Fetch notifications on component mount
    useEffect(() => {
      const fetchData = async () => {
        const notifications = await fetchNotifications();
        setAllNotifications(notifications);
      };
  
      fetchData();
    }, [setAllNotifications]);

  // Function to filter notifications based on the selected type
  const filterNotifications = (type) => {
    if (type === "Brochure Download" && allNotifications) {
      return allNotifications.brochureNotifications;
    } else if (type === "Contact Info" && allNotifications) {
      return allNotifications.contactNotifications;
    } else if(allNotifications){
      // If "All" or any other type, combine both arrays
      return [
        ...allNotifications.brochureNotifications,
        ...allNotifications.contactNotifications,
      ];
    }else{
      return  []
    }
  };

  // Get the filtered notifications
  const notifications = filterNotifications(notificationTypeFilter);

  const [checkedNotifications, setCheckedNotifications] = useRecoilState(
    checkedNotificationsAtom
  );

  const notificationChecked = (item) => {
    setCheckedNotifications((prev) => {
      if (!prev.includes(item._id)) {
        return [...prev, item._id];
      }
      return prev;
    });

    const storedCheckedNotifications = localStorage.getItem("CN");

    // Initialize checkedNotifications to an empty array if there is no stored data
    let checkedNotifications = storedCheckedNotifications
      ? JSON.parse(storedCheckedNotifications)
      : [];

    // Add the item._id to checkedNotifications if it is not already included
    if (!checkedNotifications.includes(item._id)) {
      checkedNotifications.push(item._id);
      // Save the updated checkedNotifications array back to localStorage
      localStorage.setItem("CN", JSON.stringify(checkedNotifications));
    }

    setSelectedNotification(item);
  };

  const [postData, setPostData] = useState(null);

  const fetchPostById = useCallback(async () => {
    console.log("fetchPostById callback ran");
    try {
      const response = selectedNotification.propertyId
        ? await axios.get(
            `${baseURL}/api/post/publicposts/${selectedNotification.propertyId}`
          )
        : selectedNotification.projectId
        ? await axios.get(
            `${baseURL}/api/project/getproject/${selectedNotification.projectId}`
          )
        : null;
      if (response) {
        setPostData(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [selectedNotification, baseURL]);

  useEffect(() => {
    if (selectedNotification) {
      fetchPostById();
    }
  }, [selectedNotification, fetchPostById]);

  const updatedDate = useMemo(() => {
    if (!selectedNotification?.updatedAt) return null;

    const formattedDate = new Date(
      selectedNotification?.updatedAt
    ).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true, // This ensures the time is in 12-hour format with AM/PM
      timeZone: "Asia/Kolkata",
    });
    return formattedDate;
  }, [selectedNotification?.updatedAt]);

  const formatContactInfoMobileDisplay = (mobile) => {
    const formatted = mobile.replace(/(\d{2})(\d{0,10})/, "+$1 $2");
    return formatted;
  };

  console.log(selectedNotification);
  return (
    <div className="notification">
      <div className="notification-sub">
        <div className="notification-left">
          <div className="notification-header">
            <Segmented
              options={[
                {
                  label: "All",
                  value: "All",
                  icon: <BarsOutlined />,
                },
                {
                  label: "Brochure downloaded",
                  value: "Brochure Download",
                  icon: <FilePdfOutlined />,
                },
                {
                  label: "Contacted",
                  value: "Contact Info",
                  icon: <UserOutlined />,
                },
              ]}
              onChange={(value) => {
                setNotificationTypeFilter(value); // string
              }}
            />
          </div>
          <div className="notifications-main">
            <List
              pagination={
                notifications &&
                notifications.length > 0 && {
                  position: "bottom",
                  align: "start",
                }
              }
              className="notifications-main-list"
              dataSource={notifications}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    !checkedNotifications.includes(item._id) && (
                      <GoDotFill color="red" />
                    ),
                  ]}
                  className={`notifications-main-list-item ${
                    !checkedNotifications.includes(item._id) && "highlight"
                  }`}
                  onClick={() => notificationChecked(item)}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={`https://placehold.co/100x100?text=${
                          item.notificationType === "Brochure Download"
                            ? item.brochureDownloadDetails.senderName.charAt(0)
                            : item.notificationType === "Requested Contact Info"
                            ? item.requestedContactInfoDetails.senderName.charAt(
                                0
                              )
                            : "U"
                        }`}
                      />
                    }
                    title={
                      item.notificationType === "Brochure Download"
                        ? item.brochureDownloadDetails.senderName
                        : item.notificationType === "Requested Contact Info"
                        ? item.requestedContactInfoDetails.senderName
                        : "Username"
                    }
                    description={
                      item.notificationType === "Brochure Download"
                        ? `${item.brochureDownloadDetails.senderName} has downloaded your brochure. Click here for more details ...`
                        : item.notificationType === "Requested Contact Info"
                        ? `${item.requestedContactInfoDetails.senderName} has sent you a message. Click here for more details ...`
                        : "new message ..."
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
        <div className="notification-right">
          {selectedNotification ? (
            <div className="notification-right-container">
              <div className="notification-right-container-image">
                <img
                  src={`data:image/jpeg;base64,${
                    selectedNotification.propertyId
                      ? postData?.propertyImages?.[0]
                      : selectedNotification.projectId
                      ? postData?.projectLogo
                      : null
                  }`}
                  alt="postImg"
                />
              </div>
              <div className="notification-right-container-main-heading">
                <h2>
                  {selectedNotification.propertyId
                    ? postData?.propertyTitle
                    : selectedNotification.projectId
                    ? postData?.projectName
                    : "Post Title"}
                </h2>
                <button
                  onClick={() => {
                selectedNotification.propertyId ?
                    navigate(`/viewproperty/${postData?._id}`)
                    : selectedNotification.projectId ?
                    navigate(`/viewprojects/${postData?._id}`)
                    : 
                    navigate(`/`)
                  }
                }
                >
                 {selectedNotification.projectId ? "View Project" : selectedNotification.propertyId ? "View Property": 'View Post'}
                </button>
              </div>
              <div className="notification-right-flex">
                <Avatar
                  src={`https://placehold.co/100x100?text=${
                    selectedNotification.notificationType ===
                    "Brochure Download"
                      ? selectedNotification.brochureDownloadDetails.senderName.charAt(
                          0
                        )
                      : selectedNotification.notificationType ===
                        "Requested Contact Info"
                      ? selectedNotification.requestedContactInfoDetails.senderName.charAt(
                          0
                        )
                      : "U"
                  }`}
                  className="notification-right-flex-avatar"
                />
                <h1>
                  {selectedNotification.notificationType === "Brochure Download"
                    ? selectedNotification.brochureDownloadDetails.senderName
                    : selectedNotification.notificationType ===
                      "Requested Contact Info"
                    ? selectedNotification.requestedContactInfoDetails
                        .senderName
                    : "Username"}
                </h1>
              </div>
              <div className="notification-right-column">
                <h5>Date and Time:</h5>
                <p>{updatedDate}</p>
              </div>
              {selectedNotification.notificationType === "Brochure Download" ? (
                <>
                  {selectedNotification.brochureDownloadDetails.reasonToBuy && (
                    <div className="notification-right-column">
                      <h5>Reason to buy is:</h5>
                      <p>
                        {
                          selectedNotification.brochureDownloadDetails
                            .reasonToBuy
                        }
                      </p>
                    </div>
                  )}
                  {selectedNotification.brochureDownloadDetails
                    .isPropertyDealer && (
                    <div className="notification-right-column">
                      <h5>Is property dealer?:</h5>
                      <p>Yes</p>
                    </div>
                  )}

                  {selectedNotification.brochureDownloadDetails
                    .estimatedPlanToBuy && (
                    <div className="notification-right-column">
                      <h5>Planned to buy in:</h5>
                      <p>3 months</p>
                    </div>
                  )}
                  <div className="notification-right-column">
                    <h5>Interested in Home Loan:</h5>
                    {selectedNotification.brochureDownloadDetails
                      .isInterestedInHomeLoan ? (
                      <CheckCircleFilled className="notification-right-column-icon green" />
                    ) : (
                      <CloseCircleFilled className="notification-right-column-icon red" />
                    )}
                  </div>
                  <div className="notification-right-column">
                    <h5>Interested in Site Visits</h5>
                    {selectedNotification.brochureDownloadDetails
                      .isInterestedInSiteVisits ? (
                      <CheckCircleFilled className="notification-right-column-icon green" />
                    ) : (
                      <CloseCircleFilled className="notification-right-column-icon red" />
                    )}
                  </div>
                </>
              ) : selectedNotification.notificationType ===
                "Requested Contact Info" ? (
                <>
                  <div className="notification-right-column">
                    <h5>Reason to contact:</h5>
                    <p>{`${selectedNotification.requestedContactInfoDetails.senderName} has requested for your contact information.`}</p>
                  </div>
                  <div className="notification-right-column">
                    <h5>Message:</h5>
                    <p>
                      {
                        selectedNotification.requestedContactInfoDetails
                          .senderMessage
                      }
                    </p>
                  </div>
                </>
              ) : null}
              <div className="notification-right-column">
                <h5>Mobile number:</h5>
                <p>
                  {selectedNotification.notificationType === "Brochure Download"
                    ? formatContactInfoMobileDisplay(
                        selectedNotification.brochureDownloadDetails
                          .senderMobileNo
                      )
                    : selectedNotification.notificationType ===
                      "Requested Contact Info"
                    ? formatContactInfoMobileDisplay(
                        selectedNotification.requestedContactInfoDetails
                          .senderMobileNo
                      )
                    : "Mobile no."}
                </p>
                <h5>Email:</h5>
                <p>
                  {selectedNotification.notificationType === "Brochure Download"
                    ? selectedNotification.brochureDownloadDetails.senderEmail
                    : selectedNotification.notificationType ===
                      "Requested Contact Info"
                    ? selectedNotification.requestedContactInfoDetails
                        .senderEmail
                    : "Email"}
                </p>
              </div>
            </div>
          ) : (
            <div className="notification-right-sub">
              <Empty description="Select a notification to view details" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Notifications;
