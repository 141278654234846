import axios from "axios";
import { atom } from "recoil";

export const newNotificationsCountAtom = atom({
  key: "newNotificationsCountAtom",
  default: 0,
});

export const checkedNotificationsAtom = atom({
  key: "checkedNotificationsAtom",
  default: JSON.parse(localStorage.getItem("CN")) || [],
});

export const allNotificationsAtom = atom({
  key: "allNotificationsAtom",
  default: {
    brochureNotifications: [],
    contactNotifications: []
  },
});

export const fetchNotifications = async () => {
  const baseURL = process.env.REACT_APP_base_URL;
  const userId = localStorage.getItem("userId");
  try {
    const res = await axios.get(
      `${baseURL}/api/notification/getNotifications/${userId}`
    );
    if (res.data) {
      return res.data;
    } else {
      return {
        brochureNotifications: [],
        contactNotifications: []
      };
    }
  } catch (err) {
    console.log(err);
    return {
      brochureNotifications: [],
      contactNotifications: []
    };
  }
};
