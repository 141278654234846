import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// STYLINGS IMPORT
import "./Header.css";

// ASSETS IMPORT
import logo from "../../assets/svgs/main-logo.svg";

//ANT DESIGN
import { Badge, Tooltip, Avatar, Drawer, Dropdown, Menu } from "antd";

// ANT DESIGN ICONS
import {
  PlusCircleOutlined,
  HeartOutlined,
  BellOutlined,
  MenuOutlined,
  UserOutlined,
  ShoppingOutlined,
  RetweetOutlined,
  ShopOutlined,
  AppstoreOutlined,
  RiseOutlined,
  DownloadOutlined,
  CrownOutlined,
  FileProtectOutlined,
  IssuesCloseOutlined,
  SlidersOutlined,
  CustomerServiceOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { fetchWishlistAtom } from "../../store/atoms/updateWIshlist";
import {
  allNotificationsAtom,
  checkedNotificationsAtom,
  fetchNotifications,
  newNotificationsCountAtom,
} from "../../store/atoms/notificationAtoms";
import { userDataAtom } from "../../store/atoms/userDataAtom";

function Header() {
  const baseURL = process.env.REACT_APP_base_URL;
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const location = useLocation();

  const [show, setShow] = useState(true);

  const [openHam, setOpenHam] = useState(false);
  const showDrawer = () => {
    setOpenHam(true);
  };
  const onCloseHam = () => {
    setOpenHam(false);
  };
  const userDetails = useRecoilValue(userDataAtom);
  const resetUserDetails = useResetRecoilState(userDataAtom);

  const [userData, setUserData] = useState(null);
  const [userProfileData, setUserProfileData] = useState(null);
  const [selectUserType, setSelectUserType] = useState(null);

  useMemo(() => {
    setUserData(userDetails?.user);
    setUserProfileData(userDetails?.userProfile);
    setSelectUserType(userDetails?.userProfile?.userType);
  }, [userDetails]);

  const handleLogout = async () => {
    try {
      await axios.post(`${baseURL}/api/auth/logout`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      localStorage.clear();
      resetUserDetails();
      navigate("/signup");
      // window.location.reload();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const [checklatestWishlistCount, setCheckLatestWishlistCount] =
    useRecoilState(fetchWishlistAtom);

  // Wishlist count state
  const [wishlistCount, setWishlistCount] = useState(null);

  useEffect(() => {
    const fetchWishlistCountFromAPI = async () => {
      // Fetch wishlist count
      try {
        const response = await axios.get(
          `${baseURL}/api/wishlist/count/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        if (
          response.status === 200 ||
          response.statusText === "OK" ||
          response.data.count
        ) {
          const newCount = response.data.count;
          if (newCount !== wishlistCount) {
            setWishlistCount(newCount);
          }
        } else {
          throw new Error("Failed to fetch wishlist count from API");
        }
      } catch (error) {
        console.error("Error fetching wishlist count:", error);
      }
    };
    if (userId) {
      fetchWishlistCountFromAPI();
    }
    // const intervalId = setInterval(fetchWishlistCountFromAPI, 500);
    return () => setCheckLatestWishlistCount(false);
  }, [baseURL, userId, wishlistCount, checklatestWishlistCount]);

  const projectOptions = [
    selectUserType !== "Owner" &&
      selectUserType !== "Salesperson" && {
        key: "1",
        label: (
          <span
            className="header-dropdown-options"
            onClick={() => navigate("/postproject")}
          >
            Add Project
          </span>
        ),
      },
    {
      key: "2",
      label: (
        <span
          className="header-dropdown-options"
          onClick={() => navigate("/postproperty")}
        >
          Post Property
        </span>
      ),
    },
  ];
  const profileOptions = [
    {
      key: "1",
      label: (
        <span
          className="header-dropdown-options"
          onClick={() => navigate("/profile")}
        >
          Profile
        </span>
      ),
    },
    {
      key: "2",
      label: <span className="header-dropdown-options">Recent Searches</span>,
    },
    {
      key: "3",
      label: (
        <span className="header-dropdown-options" onClick={handleLogout}>
          Log out
        </span>
      ),
    },
  ];

  const [isHomepage, setIsHomepage] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    setIsHomepage(location.pathname === "/");
  }, [location.pathname]);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const viewportHeight = window.innerHeight;
      setIsScrolled(isHomepage && scrollPosition >= viewportHeight * 0.1);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isHomepage]);

  // Notifications functionality
  const [allNotifications, setAllNotifications] =
    useRecoilState(allNotificationsAtom);
  // Fetch notifications on component mount
  useEffect(() => {
    const fetchData = async () => {
      const notifications = await fetchNotifications();
      setAllNotifications(notifications);
    };

    fetchData();
  }, [setAllNotifications]);
  const [newNotificationsCount, setNewNotificationsCount] = useRecoilState(
    newNotificationsCountAtom
  );
  const [checkedNotifications, setCheckedNotifications] = useRecoilState(
    checkedNotificationsAtom
  );

  const calculateNewNotificationsCount = useCallback(() => {
    const newNotifications = allNotifications
      ? [
          ...allNotifications.brochureNotifications,
          ...allNotifications.contactNotifications,
        ]
      : [];

    const newNotificationsCount =
      newNotifications.length - checkedNotifications.length;
    if (newNotifications.length <= 0) {
      return 0;
    } else {
      return newNotificationsCount;
    }
  }, [allNotifications, checkedNotifications]);

  useMemo(() => {
    const storedCheckNotifications =
      JSON.parse(localStorage.getItem("CN")) || [];

    const newNotifications = allNotifications
      ? [
          ...allNotifications?.brochureNotifications,
          ...allNotifications?.contactNotifications,
        ]
      : [];

    if (storedCheckNotifications.length > 0 && newNotifications.length) {
      const refreshStoredNotifications = storedCheckNotifications.filter(
        (storedNotification) =>
          newNotifications.some(
            (newNotification) => newNotification._id === storedNotification
          )
      );
      localStorage.setItem("CN", JSON.stringify(refreshStoredNotifications));
      setCheckedNotifications([...refreshStoredNotifications]);
    }
  }, [allNotifications, setCheckedNotifications]);

  useEffect(() => {
    return setNewNotificationsCount(calculateNewNotificationsCount());
  }, [calculateNewNotificationsCount, setNewNotificationsCount]);

  return (
    <div className={`header ${isHomepage && !isScrolled ? "scrolled" : ""}`}>
      <div className="header-sub">
        <img
          src={logo}
          alt="proptzo"
          className="header-sub-logo"
          onClick={() => navigate("/")}
        />
      </div>
      <div className="header-sub">
        <ul>
          <li
            className={`${
              isHomepage && !isScrolled ? "header-sub-ul-li-scrolled" : ""
            }`}
          >
            About
          </li>
          <li
            className={`${
              isHomepage && !isScrolled ? "header-sub-ul-li-scrolled" : ""
            }`}
          >
            Buy
          </li>
          <li
            className={`${
              isHomepage && !isScrolled ? "header-sub-ul-li-scrolled" : ""
            }`}
          >
            Compare
          </li>
          <li
            className={`${
              isHomepage && !isScrolled ? "header-sub-ul-li-scrolled" : ""
            }`}
          >
            Proptzo prime
          </li>
          {userData?.isAdmin && (
            <li
              className={`${
                isHomepage && !isScrolled ? "header-sub-ul-li-scrolled" : ""
              }`}
              onClick={() => navigate("/admin")}
            >
              Admin
            </li>
          )}
        </ul>
        <Dropdown
          overlay={
            <Menu>
              {projectOptions.map((option) => (
                <Menu.Item key={option.key}>{option.label}</Menu.Item>
              ))}
            </Menu>
          }
          placement="bottom"
          arrow
        >
          <PlusCircleOutlined className="header-sub-icon" />
        </Dropdown>

        {!userData && (
          <>
            <ul
              className={`${
                isHomepage && !isScrolled
                  ? "header-sub-ul-m0-scrolled"
                  : "header-sub-ul-m0"
              }`}
              onClick={() => navigate("/signup")}
            >
              <li
                className={`${
                  isHomepage && !isScrolled
                    ? "header-sub-ul-m0-scrolled"
                    : "header-sub-ul-m0"
                }`}
              >
                Sign in
              </li>
            </ul>
            <Badge
              dot
              className="header-sub-icon header-sub-icon-avatar"
              onClick={() => navigate("/signup")}
            >
              <Avatar icon={<UserOutlined />} />
            </Badge>
          </>
        )}
        {userData && (
          <>
            <Tooltip
              placement="bottom"
              title="Wishlist"
              onClick={() => navigate("/wishlist")}
            >
              <Badge
                count={wishlistCount}
                overflowCount={9}
                color="#3a86ff"
                className={`header-sub-icon ${
                  isHomepage && !isScrolled ? "scrolled" : ""
                }`}
              >
                <HeartOutlined />
              </Badge>
            </Tooltip>
            <Tooltip
              placement="bottom"
              title="Notifications"
              onClick={() => navigate("/notifications")}
            >
              <Badge
                count={newNotificationsCount}
                overflowCount={99}
                className={`header-sub-icon ${
                  isHomepage && !isScrolled ? "scrolled" : ""
                }`}
              >
                <BellOutlined />
              </Badge>
            </Tooltip>
            <Dropdown
              overlay={<Menu items={profileOptions} />}
              placement="bottom"
              arrow
            >
              <Badge dot className="header-sub-icon">
                {userProfileData &&
                selectUserType === "Owner" &&
                userProfileData?.ownerDetails?.ownerProfilePhoto ? (
                  <img
                    src={`data:image/jpeg;base64,${userProfileData.ownerDetails.ownerProfilePhoto}`}
                    alt=""
                    className="header-profile-img"
                  />
                ) : userProfileData &&
                  selectUserType === "Builder" &&
                  userProfileData?.builderDetails?.builderProfilePhoto ? (
                  <img
                    src={`data:image/jpeg;base64,${userProfileData?.builderDetails?.builderProfilePhoto}`}
                    alt=""
                    className="header-profile-img"
                  />
                ) : userProfileData &&
                  selectUserType === "Agent" &&
                  userProfileData?.agentDetails?.agentProfilePhoto ? (
                  <img
                    src={`data:image/jpeg;base64,${userProfileData?.agentDetails?.agentProfilePhoto}`}
                    alt=""
                    className="header-profile-img"
                  />
                ) : userProfileData &&
                  selectUserType === "Salesperson" &&
                  userProfileData?.salespersonDetails?.employeeImage ? (
                  <img
                    src={`data:image/jpeg;base64,${userProfileData?.salespersonDetails?.employeeImage}`}
                    alt=""
                    className="header-profile-img"
                  />
                ) : (
                  <Avatar>
                    {userData.name ? userData.name.charAt(0) : "U"}
                  </Avatar>
                )}
              </Badge>
            </Dropdown>
          </>
        )}
        <Badge
          dot={show}
          className={`header-sub-icon ${
            isHomepage && !isScrolled ? "scrolled" : ""
          }`}
          onClick={showDrawer}
        >
          <MenuOutlined />
        </Badge>
      </div>
      <Drawer
        title={
          userData
            ? `${selectUserType || ""} ${userData.name || "Welcome Guest!"}`
            : "Welcome Guest!"
        }
        onClose={onCloseHam}
        open={openHam}
      >
        <div className="header-ham-category">
          <h5>Services</h5>
          {userData?.isAdmin && (
            <div
              className="header-ham-category-sub"
              onClick={() => {
                navigate("/admin");
                onCloseHam();
              }}
            >
              <ShoppingOutlined className="header-ham-category-sub-icon" />
              Admin
            </div>
          )}
          <div
            className="header-ham-category-sub"
            onClick={() => {
              navigate("/properties");
              onCloseHam();
            }}
          >
            <ShoppingOutlined className="header-ham-category-sub-icon" />
            Buy
          </div>
          <div
            className="header-ham-category-sub"
            onClick={() => {
              navigate("/compare");
              onCloseHam();
            }}
          >
            <RetweetOutlined className="header-ham-category-sub-icon" />
            Compare
          </div>
        </div>
        <div className="header-ham-category">
          <h5>Property Management</h5>
          {userData &&
            selectUserType !== "Owner" &&
            selectUserType !== "Salesperson" && (
              <div
                className="header-ham-category-sub"
                onClick={() => {
                  navigate("/postproject");
                  onCloseHam();
                }}
              >
                <PlusCircleOutlined className="header-ham-category-sub-icon" />
                Add Project
              </div>
            )}
          <div
            className="header-ham-category-sub"
            onClick={() => {
              navigate("/postproperty");
              onCloseHam();
            }}
          >
            <ShopOutlined className="header-ham-category-sub-icon" />
            Post Property
          </div>
          {userData && (
            <div
              className="header-ham-category-sub"
              onClick={() => {
                navigate("/dashboard");
                onCloseHam();
              }}
            >
              <AppstoreOutlined className="header-ham-category-sub-icon" />
              My Dashboard
            </div>
          )}
        </div>
        <div className="header-ham-category">
          <h5>Features</h5>
          {userData && (
            <div
              className="header-ham-category-sub"
              onClick={() => {
                navigate("/wishlist");
                onCloseHam();
              }}
            >
              <Badge
                count={wishlistCount}
                overflowCount={9}
                color="#3a86ff"
                className="header-ham-category-sub-icon"
              >
                <HeartOutlined />
              </Badge>
              Wishlist
            </div>
          )}
          <div
            className="header-ham-category-sub"
            onClick={() => {
              navigate("/trending");
              onCloseHam();
            }}
          >
            <RiseOutlined className="header-ham-category-sub-icon" />
            Trending
          </div>
          <div
            className="header-ham-category-sub"
            onClick={() => {
              navigate("/download-app");
              onCloseHam();
            }}
          >
            <DownloadOutlined className="header-ham-category-sub-icon" />
            Download App
          </div>
        </div>
        <div className="header-ham-category">
          <h5>Membership</h5>
          <div
            className="header-ham-category-sub"
            onClick={() => {
              navigate("/proptzo-prime");
              onCloseHam();
            }}
          >
            <CrownOutlined className="header-ham-category-sub-icon" />
            Proptzo Prime
          </div>
          <div
            className="header-ham-category-sub"
            onClick={() => {
              navigate("/prime-benefits");
              onCloseHam();
            }}
          >
            <FileProtectOutlined className="header-ham-category-sub-icon" />
            Prime Benefits
          </div>
        </div>
        <div className="header-ham-category">
          <h5>About</h5>
          <div
            className="header-ham-category-sub"
            onClick={() => {
              navigate("/about");
              onCloseHam();
            }}
          >
            <IssuesCloseOutlined className="header-ham-category-sub-icon" />
            About
          </div>
          <div
            className="header-ham-category-sub"
            onClick={() => {
              navigate("/insights");
              onCloseHam();
            }}
          >
            <SlidersOutlined className="header-ham-category-sub-icon" />
            Insights
          </div>
          <div
            className="header-ham-category-sub"
            onClick={() => {
              navigate("/notifications");
              onCloseHam();
            }}
          >
            <BellOutlined className="header-ham-category-sub-icon" />
            Notifications
          </div>
        </div>
        <div className="header-ham-category">
          <h5>Help & Settings</h5>
          <div
            className="header-ham-category-sub"
            onClick={() => {
              navigate("/customer-care");
              onCloseHam();
            }}
          >
            <CustomerServiceOutlined className="header-ham-category-sub-icon" />
            Customer Care
          </div>
          <div
            className="header-ham-category-sub"
            onClick={() => {
              navigate("/office-location");
              onCloseHam();
            }}
          >
            <EnvironmentOutlined className="header-ham-category-sub-icon" />
            Office Location
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default Header;
