import React from "react";
import "./AdminFeedbacks.css";

function AdminFeedbacks() {
  return (
    <div className="adashboard">
      <div className="adashboard-sub">Admin Feedbacks</div>
    </div>
  );
}

export default AdminFeedbacks;
