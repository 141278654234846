import React from 'react';
import { Typography } from 'antd';
import { ToolOutlined } from '@ant-design/icons';
import './UnderConstruction.css'; // Assuming you have a CSS file for custom styles

const { Title, Text } = Typography;

const UnderConstruction = () => {
  return (
    <div className="under-construction">
      <ToolOutlined style={{ fontSize: '48px', color: '#ff2d2d' }} />
      <Title level={2}>Website Under Testing</Title>
      <Text>
        We are working hard to improve our website. Please check back later for updates.
      </Text>
    </div>
  );
};

export default UnderConstruction;
