import z from "zod";

export const ownerSchema = z.object({
  fullname: z.string().min(1).optional(),
  email: z.string().email().min(1).optional(),
  mobile: z.string().min(12).max(12).optional(),
  whatsappMobile: z.string().min(12).max(12).optional(),
});

export const builderSchema = z.object({
  builderFirmName: z.string().min(1).optional(),
  builderReraNo: z.string().min(1).optional(),
  builderReraNoType: z.string().min(1).optional(),
  builderOfficeCity: z.string().min(1).optional(),
  builderOfficeWebsite: z.string().url().min(1).optional(),
  builderAddress: z.string().min(1).optional(),
});

export const builderEmployeeSchema = z.object({
  employeeFullName: z.string().min(1).optional(),
  employeeDesignation: z.string().min(1).optional(),
  employeeEmail: z.string().email().min(1).optional(),
  employeeMobileNo: z.string().min(12).max(12).optional(),
  employeeId: z.string().min(1).optional(),
});

export const agentSchema = z.object({
  agentFirmName: z.string().min(1).optional(),
  agentReraNo: z.string().min(1).optional(),
  agentReraNoType: z.string().min(1).optional(),
  agentOfficeCity: z.string().min(1).optional(),
  agentOfficeWebsite: z.string().url().min(1).optional(),
  agentAddress: z.string().min(1).optional(),
});

export const agentEmployeeSchema = z.object({
  employeeFullName: z.string().min(1).optional(),
  employeeDesignation: z.string().min(1).optional(),
  employeeEmail: z.string().email().min(1).optional(),
  employeeMobileNo: z.string().min(12).max(12).optional(),
  employeeIdType: z.string().min(1).optional(),
  employeeID: z.string().min(1).optional(),
  employeeAadhaarCardNo: z.string().min(1).min(4).max(4).optional(),
  employeePanCardNo: z.string().min(1).optional(),
  employeeVoterCardNo: z.string().min(1).optional(),
  employeePassportNo: z.string().min(1).optional(),
  employeeDrivingLicenseNo: z.string().min(1).optional(),
});

export const salespersonSchema = z.object({
  employeeFullName: z.string().min(1).optional(),
  employeeDesignation: z.string().min(1).optional(),
  employeeEmail: z.string().email().min(1).optional(),
  employeeMobileNo: z.string().min(12).max(12).optional(),
  employeeWhatsappMobile: z.string().min(12).max(12).optional(),
  employeeIdType: z.string().min(1).optional(),
  employeeID: z.string().min(1).optional(),
  employeeAadhaarCardNo: z.string().min(1).min(4).max(4).optional(),
  employeePanCardNo: z.string().min(1).optional(),
  employeeVoterCardNo: z.string().min(1).optional(),
  employeePassportNo: z.string().min(1).optional(),
  employeeDrivingLicenseNo: z.string().min(1).optional(),
});

export const projectSchema = z.object({
  projectName: z.string().min(1).optional(),
  projectReraNo: z.string().min(1).optional(),
  projectDescription: z.string().min(200).optional(),
  projectStatus: z.string().min(1).optional(),
  projectCompletionYear: z.string().min(1).optional(),
  projectOwnershipType: z.string().min(1).optional(),
  projectArea: z.string().min(1).optional(),
  projectAreaUnit: z.string().min(1).optional(),
  projectPriceRangePriceFrom: z.string().min(1).optional(),
  projectPriceRangePriceTo: z.string().min(1).optional(),
  projectCurrencyType: z.string().min(1).optional(),
  projectCity: z.string().min(1).optional(),
  projectLocality: z.string().min(1).optional(),
});
