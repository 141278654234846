import React from "react";
import "./Blogs.css";

function Blogs() {
  return (
    <div className="blogs">
      <div className="blogs-sub">
        <h1>Latest Blogs</h1>
        <h5>Stay updated with our newest posts and articles</h5>
      </div>
      <div className="blogs-container">
        <div className="blogs-container-sub">
          {[...Array(12)].map((_, index) => (
            <div key={index} className="latestnews">
              <div className="trending-prop-img trending-prop-img-latestnews">
                <img
                  src="https://placehold.co/800x450?text=proptzo"
                  alt="property"
                />
                <div className="trending-type trending-type-latestnews">
                  <h5>JANUARY 26, 2024</h5>
                </div>
              </div>
              <div className="latestnews-sub">
                <h5>Esther</h5>
                <h4>
                  Building Gains Into Housing Stocks And How To Trade The Sector
                </h4>
                <p>
                  In today's dynamic real estate market, understanding the
                  nuances of housing stocks is crucial for maximizing investment
                  returns. Esther delves into the latest trends and strategies,
                  highlighting key factors that drive gains in housing stocks.
                  From market analysis to actionable trading tips, discover how
                  to navigate the sector effectively and make informed decisions
                  that can lead to significant financial growth. Whether you're
                  a seasoned investor or just starting out, this comprehensive
                  guide provides valuable insights to help you succeed in
                  trading housing stocks.
                </p>
              </div>
            </div>
          ))}
        </div>
        <button className="button">Load more</button>
      </div>
    </div>
  );
}

export default Blogs;
